exports.ContentType = {
  SECTION: "SECT",
  CURRICULUM: "CC",
  VIDEO: "VIDEO",
  MULTIVIDEO: "MULTIVIDEO",
  LIVE: "LIVE",
  PAGE: "PAGE",
  EPUB: "EPUB",
  MULTIEPUB: "MULTIEPUB",
  FILE: "FILE",
  AUDIO: "AUDIO",
  MULTIAUDIO: "MULTIAUDIO",
  QUIZ: "QUIZ",
  QUESTION: "QUESTION",
  CODING: "CODING",
  COACHING: "COACHING",
  APPLICATION: "APPLICATION",
};

exports.QuizStatus = {
  NONE: "NONE",
  PROGRESS: "PROGRESS",
  SUBMIT: "SUBMIT",
  PASS: "PASS",
  FAIL: "FAIL",
  COMPLETE: "COMPLETE",
};

exports.QuestionType = {
  OBJECT: "OBJT",
  SUBJECT: "SBJT",
  DESC: "DESC",
  FILE: "FILE",
};

exports.ApplicationStatus = {
  NONE: "NONE",
  PROGRESS: "PROGRESS",
  SUBMIT: "SUBMIT",
  PICK: "PICK",
  DROP: "DROP",
  HOLD: "HOLD",
  PASS: "PASS",
  FAIL: "FAIL",
  COMPLETE: "COMPLETE",
};
