<template>
  <div class="p-label">
    <div class="d-flex">
      <span v-if="required" class="error--text mr-1">*</span>
      <span class="p-label--text">{{ label }}</span>
    </div>

    <div
      v-if="infoText"
      :hover-tooltip="infoText"
      :tooltip-position="position"
      class="p-label--icon ml-1 d-flex"
    >
      <p-icon icon="Info/Blue" class="cursor-pointer" size="20" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PoinUiLabel",
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
    },
    position: {
      type: String,
      default: "left", //left or bottom
    },
  },
};
</script>

<style lang="scss" scoped>
.p-label {
  display: inline-flex;
  align-items: flex-start;
  span {
    line-height: 20px;
  }
  &--text {
    font-size: 14px;
    color: $gray8;
    line-height: 20px;
  }

  &--icon {
    display: flex;
    align-items: baseline;
  }
}
[hover-tooltip] {
  $caret-height: 4px;
  $caret-width: 6px;
  $tooltip-bg-color: $black;

  position: relative;

  &:hover {
    // text-box
    &::before {
      content: attr(hover-tooltip);
      font-size: 14px;
      position: absolute;
      display: block;
      top: 10px;
      width: max-content;
      height: fit-content;
      transform: translate(0%, -50%);
      animation: fade-in 300ms ease;
      background: $tooltip-bg-color;
      border-radius: 4px;
      padding: 10px;
      color: $white;
      font-weight: 500;
      z-index: 9;
      left: 30px;
      max-width: 420px;
      white-space: pre-line;
    }
    // arrow
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      left: 36px;
      top: 10px;
      border: 4px solid transparent;
      border-left: 0;
      border-right: 6px solid $tooltip-bg-color;
      transform: translate(calc(-100% - 5px), -50%);
      animation: fade-in 300ms ease;
    }
  }
  &[tooltip-position="bottom"] {
    &:hover {
      &::before {
        top: 48px;
        transform: translate(-60%, -50%);
        animation: fade-in 300ms ease;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 26px;
        left: 24px;
        border: 8px solid transparent;
        border-color: transparent transparent $tooltip-bg-color;
        border-width: 0 $caret-width $caret-height;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
