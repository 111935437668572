import isEmpty from "lodash.isempty";

export function checkNumber(data) {
  return typeof data === "number" && data >= 0;
}

export function checkSelectSalesType(data) {
  const isSalesTypeNotEmpty = checkNotEmpty(data);
  if (isSalesTypeNotEmpty) {
    return data !== "NONE";
  }
  return isSalesTypeNotEmpty;
}

export function checkNotUndefined(data) {
  return data !== null && data !== undefined;
}

export function checkNotEmpty(data) {
  return !isEmpty(data);
}

export function checkNotEmptyString(data) {
  return data !== "";
}

// validate email
export function checkEmail(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }

    if (!data.trim()) return false;

    // get from https://emailregex.com/
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(data);
  }

  return false;
}

// validate password
// https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export function checkPassword(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }

    // ^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[{}[]/?.,;:|)*~!^\-_+<>@\#$%&\\\=\(\'\"])[A-Za-z\d\{\}\[\]\/?.,;:|\)*~!^-_+<>@#$%&\=('"]{8,}
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?<>_\-~:;.,#$^+=!*()@%&]).{8,12}$/;
    return passwordRegex.test(data);
  }
  return false;
}

// validate check name
export function checkName(data, letterLength = 20) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }
    // const stringByteLength = data.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
    if (!data.trim()) return false;
    if (data.length <= letterLength) {
      return true;
    }
  }

  return false;
}

export function checkMobilePhone(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string" && typeof data !== "number") {
      throw new Error("please pass string or number");
    }

    const mobileRegex = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
    const phoneRegex = /^\d{2,3}-\d{3,4}-\d{4}$/;
    const phone4NumberRegex = /^\d{4}-\d{4}$/;

    if (
      !mobileRegex.test(data) &&
      !phoneRegex.test(data) &&
      !phone4NumberRegex.test(data)
    ) {
      return false;
    }
    return true;
  }

  return false;
}

export function checkBirthday(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string" && typeof data !== "number") {
      throw new Error("please pass string or number");
    }
    const birthdayRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    return birthdayRegex.test(data);
  }

  return false;
}

export function checkBizNumber(value) {
  var valueMap = value
    .replace(/-/gi, "")
    .split("")
    .map(function (item) {
      return parseInt(item, 10);
    });

  if (valueMap.length === 10) {
    var multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    var checkSum = 0;

    for (var i = 0; i < multiply.length; ++i) {
      checkSum += multiply[i] * valueMap[i];
    }

    checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
    return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10;
  }
  return false;
}

export function checkUrl(val) {
  if (!val) return false;
  const urlRegex =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return urlRegex.test(val);
}

export function passwordRules(value) {
  if (value.length < 8) {
    return "8글자 이상 입력해주세요.";
  } else if (value.length > 12) {
    return "12글자 이하로 입력해주세요.";
  } else if (!value.match(/[a-z]/)) {
    return "영어 소문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/[A-Z]/)) {
    return "영어 대문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/[?<>_\-~:;.,#$^+=!*()@%&]/)) {
    return "특수문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/\d/)) {
    return "숫자를 1글자 이상 포함해서 입력해주세요.";
  } else {
    return "영어(대문자 + 소문자), 숫자, 특수문자 조합 8~12자를 입력해주세요.";
  }
}
