<template>
  <nav>
    <ul class="routing-container">
      <li v-for="(routeItem, index) in routingLinks" :key="index">
        <a
          v-if="routeItem.show && routeItem.newTab"
          :key="index"
          class="nav-item"
          :href="routeItem.href"
          :target="routeItem.newTabYn ? '_blank' : '_self'"
          @mouseover="$set(routeItem, 'hover', true)"
          @mouseleave="$set(routeItem, 'hover', false)"
        >
          {{ routeItem.name }}
          <p-icon
            v-if="routeItem.newTabYn"
            size="16"
            class="ml-1"
            :icon="`ExternalLink/${routeItem.hover ? 'Primary' : 'Gray8'}`"
          />
        </a>

        <router-link
          v-else-if="routeItem.show"
          :key="index"
          class="nav-item"
          :class="{
            'help-active': routeItem.name === '고객센터' && isHelp,
          }"
          :style="{ color: routeItem.color }"
          :to="routeItem.to"
        >
          {{ routeItem.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import { Authority, MemberStatus } from "@/enums/membersEnum";
import { isDev, isLocal } from "@/config";
export default {
  name: "RoutingMenu",
  components: {},
  data() {
    return {
      isHelp: false,
      routingLinks: [],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      campusInfo: "campuses/getCampusInfo",
      campusUuid: "campuses/getCampusUuid",
      campusDomain: "campuses/getCampusDomain",
      campusOnly: "common/campusOnly",
      userUuid: "users/getUserUuid",
      isCampusJoinMember: "members/isCampusJoinMember",
      isCampusMember: "members/isCampusMember",
      memberStatus: "members/getMemberStatus",
      isCampusOwner: "campuses/isCampusOwner",
      memberAuthorityCodes: "members/getMemberAuthorityCodes",
      hasRoleOrAuth: "members/hasRoleOrAuth",
      isMobile: "common/isMobile",
      menus: "campuses/getRoutes",
    }),
    helpNewTabHref() {
      return (
        this.$router.resolve({
          name: "campus-help-faq",
          params: {
            campusDomain: this.campusDomain || "home",
          },
          query: this.campusDomain ? null : { campusUuid: this.campusUuid },
        }).href || "#"
      );
    },
  },
  methods: {
    setRoutingLinks() {
      // 캠퍼스홈
      const options = {
        params: { campusDomain: this.campusDomain || "home" },
        ...(!this.campusDomain && {
          query: {
            campusUuid: this.campusUuid,
          },
        }),
      };

      if (this.campusOnly && this.menus?.length > 0) {
        const menus = this.menus?.reduce((acc, menu) => {
          acc.push({ ...menu, hover: false });
          return acc;
        }, []);

        this.routingLinks = [...menus];
        return;
      } else if (this.campusOnly) {
        this.routingLinks = [
          {
            name: "프로덕트",
            to: { name: "campus-product-home", ...options },
            ...options,
            show: true,
          },
          {
            name: "커뮤니티",
            to: { name: "campus-community-home", ...options },
            show: true,
            // show: this.isCampusJoinMember,
          },
          {
            name: "공지사항",
            to: { name: "campus-notice-home", ...options },
            ...options,
            show: true,
          },
        ];
        return;
      }
      // 캠퍼스 홈 제외
      this.routingLinks = [
        {
          to: { name: "poin-features" },
          name: "기능",
          show: true,
        },
        {
          to: { name: "poin-pricing" },
          name: "요금 플랜",
          show: true,
        },
        {
          to: { name: "poin-addons" },
          name: "부가 서비스",
          show: true,
        },
        {
          to: { name: "poin-promotion" },
          name: "가치 제안",
          show: true,
        },
        {
          to: { name: "poin-voucher-home" },
          name: "바우처",
          show: true,
        },
      ];
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        if (route?.name?.includes("campus-help")) {
          this.isHelp = true;
        } else {
          this.isHelp = false;
        }
      },
    },
  },
  created() {
    this.setRoutingLinks();
  },
};
</script>

<style lang="scss" scoped>
.routing-container {
  display: flex;
  flex-wrap: row nowrap;
  justify-content: space-evenly;
  position: relative;
}

.routing-container .nav-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: $primary;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -6px;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    color: $primary;
    transition: all 0.3s ease-in-out;
    &::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.routing-container a.router-link-exact-active {
  color: $primary;
}

.help-active {
  font-weight: 500 !important;
  color: $primary !important;
}
</style>
