<template>
  <img
    :src="iconSrc"
    :id="_uid"
    @click="$emit('click')"
    :style="`width: ${size}px; height: auto; object-fit: contain`"
  />
</template>

<script>
export default {
  name: "PoinUiIcon",
  props: {
    name: {
      type: String,
      default: "BlankImage",
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
  computed: {
    iconSrc() {
      return `https://file.poincampus.com/assets/images/${this.name}.svg`;
    },
  },
};

/* guide */
// https://www.figma.com/file/xJhCGbTktJSsMziqFDEDXE/%ED%8F%AC%EC%9D%B8%EC%BA%A0%ED%8D%BC%EC%8A%A4-Design-System?node-id=32%3A607

/* path list */
// Apple/20
// Apple/48
// Email/20
// Email/48
// Facebook/20
// Facebook/48
// Google/20
// Google/48
// GuideAsset/Email
// GuideAsset/HelpCenter
// GuideAsset/Management
// GuideAsset/Pass
// GuideAsset/Pricing
// GuideAsset/Promotion
// Kakao/20
// Kakao/48
// Link/20
// Link/48
// Naver/20
// Naver/48
// PayLogo/KakaoPay
// PayLogo/LPay
// PayLogo/NaverPay
// PayLogo/Payco
// PayLogo/SamsungPay
// PayLogo/SsgPay
// ProductAsset/24
// ProductAsset/40
// ProductAsset/80
// BlankImage
// Excel/Default
// Excel/White
// Excel/Gray
// Excel
// Service/AI
// Service/Application
// Service/Cloud
// Service/Coding
// Service/Diploma
// Service/EtcPayment
// Service/ManagementProduct
// Service/Quiz
// Colorless
</script>
