export default {
  userAskItems: [],
  userAskLastKey: "",
  userReplies: [],
  userReplyLastKey: "",
  campusAskItems: [],
  campusAskLastKey: "",
  campusReplies: [],
  campusReplyLastKey: "",
  ask: null,
  askSearchOptions: {
    first: true,
  },
  askCnt: {
    todayCnt: 0,
    resultsCnt: 0,
  },
  faqItems: [],
  faqLastKey: "",
  faqCategories: [],
  faq: null,
  faqSearchOptions: {
    first: true,
  },
  faqTemplates: [],
  userFaqItems: [],
  userFaqCategories: [],
  userFaq: null,
};
