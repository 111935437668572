<script setup>
import { useImage } from "@vueuse/core";
import { computed, toRefs } from "vue";

const props = defineProps({
  src: {
    type: String,
  },
  width: {
    type: [String, Number],
    default: "100%",
  },
  maxWidth: {
    type: [String, Number],
  },
  height: {
    type: [String, Number],
  },
  aspectRatio: {
    type: [String, Number],
  },
  borderRadius: {
    type: String,
    default: "0",
  },
  objectFit: {
    type: String,
    default: "contain",
  },
});
const { src, width, maxWidth, height, aspectRatio, borderRadius, objectFit } =
  toRefs(props);
const styles = computed(() => {
  return {
    width: width.value,
    ...(maxWidth.value && { maxWidth: maxWidth.value }),
    ...(height.value && { height: height.value }),
    ...(aspectRatio.value && { aspectRatio: aspectRatio.value }),
    ...(borderRadius.value && { borderRadius: borderRadius.value }),
    objectFit: objectFit.value,
  };
});
const { isLoading } = useImage({ src: src.value });
</script>

<template>
  <div v-if="isLoading" class="skeleton" :style="styles"></div>
  <img v-else-if="src" :src="src" :style="styles" />
</template>
