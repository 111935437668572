<template lang="html">
  <div class="s3-file-uploader-container">
    <form id="fileUploadForm" enctype="multipart/form-data" method="post">
      <input
        :ref="fileInputRef"
        :accept="accept"
        :multiple="multiple"
        class="file-reader"
        type="file"
        value="dataFile"
        @change="handleFileUpload"
      />
    </form>
    <progress-popup
      :bus="progressBus"
      :open="progressPopupOpen"
      :progressItems="progressItems"
      @close="progressPopupClose"
      @handleRequest="handleRequest"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { numberFormat } from "@/utils";
import { aws, s3 } from "@/config";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

import { mapActions, mapGetters } from "vuex";
import ProgressPopup from "@/components/common/Progress-Popup.vue";

export default {
  name: "file-uploader",
  props: {
    uploaderBus: {
      type: Object,
      required: true,
    },
    fileSizeLimit: {
      type: Number,
      default: 3 * 1024 * 1024 * 1024, // 기본 3기가
    },
    uploadLimit: {
      type: Number,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
    },
    downloadOnly: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
    checkUsage: {
      type: Boolean,
      default: false,
    },
    acl: {
      type: String,
      default: "public-read",
    },
    aclType: {
      type: String,
    },
  },
  components: {
    ProgressPopup,
  },
  data() {
    return {
      progressItems: [],
      progressBus: new Vue(),
      uploadRequests: [],
      fileList: [],
      successfulUploads: [],
      uploadOnly: false,
      progressPopupOpen: false,
      bucket: null,
      // uploader 컴포넌트 여러개 등록시, refs 속성 중복되는 문제. unique한 ref 가지도록 변경
      fileInputRef: uuidv4(),
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/getAuth",
      userUuid: "users/getUserUuid",
      campusId: "campuses/getCampusUuid",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      reqRefreshToken: "auth/reqRefreshToken",
      setAuth: "auth/setAuth",
      checkVolumes: "campuses/checkVolumes",
    }),
    async handleFileUpload(files) {
      // 업로드할 파일이 전달되지 않은 경우
      if (!files || files.length < 1) {
        const msg = "파일이 선택되지 않았습니다.";
        this.$eventBus.$emit("alert", {
          open: true,
          msg,
        });
        return;
      }

      // 초기화
      this.initData();

      // 파일은 최대 10개까지 업로드 가능하다.
      if (files.length > 10) {
        const msg = "파일은 한번에 최대 10개까지만 업로드할 수 있습니다.";
        this.$eventBus.$emit("alert", {
          open: true,
          msg,
        });
        return;
      }

      if (this.uploadOnly && files && files.length > 0) {
        this.fileList = files;
      } else {
        this.fileList = this.$refs[this.fileInputRef].files;
      }

      // 기존 사용량 + 업로드할 파일들의 용량 이 캠퍼스 용량을 초과하는 경우 stop
      if (this.checkUsage) {
        const size = Array.from(this.fileList).reduce((acc, cur) => {
          return acc + cur.size;
        }, 0);
        const check = await this.checkVolumes(size);
        if (!check) {
          this.$eventBus.$emit("alert", {
            open: true,
            msg: `저장 용량을 초과했습니다. 용량 업그레이드가 필요합니다.`,
          });
          return;
        }
      }

      // file size limit 초과 시 stop
      const fileError = Array.from(this.fileList).findIndex(
        (file) => file.size > this.fileSizeLimit
      );
      if (fileError >= 0) {
        const msg = `파일은 최대 ${numberFormat.fileSizeFormat(
          this.fileSizeLimit
        )}까지 업로드할 수 있습니다.`;
        this.$eventBus.$emit("alert", {
          open: true,
          title: "파일 업로드 안내",
          msg,
          csLink: true,
        });
        return;
      }

      if (this.fileList && this.fileList?.length > this.uploadLimit) {
        this.$eventBus.$emit("alert", {
          open: true,
          title: "개수 초과",
          msg: `최대 ${this.uploadLimit}개까지 업로드 가능합니다.`,
        });
        return;
      }

      this.progressPopupOpen = true;
      Array.from(this.fileList).forEach(async (fileItem, index) => {
        await this.upload(fileItem, index);
      });
      this.uploadOnly = false;
    },
    initData() {
      this.progressPopupOpen = false;
      this.fileList = [];
      this.progressItems = [];
      this.uploadRequests = [];
    },
    initAWS() {
      AWS.config.region = aws.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: aws.identityPoolId,
      });
      this.updateAwsConfig();
    },
    updateAwsConfig() {
      AWS.config.update({
        accessKeyId: s3.s3AccessKeyId,
        secretAccessKey: s3.s3SecretAccessKey,
      });
      this.bucket = new AWS.S3();
    },
    // https://docs.aws.amazon.com/ko_kr/sdk-for-javascript/v2/developer-guide/s3-example-photo-album.html
    async upload(file, index) {
      this.$set(this.progressItems, index, {
        value: 0,
        file,
        err: null,
        success: false,
        complete: false,
      });

      const isExpired = new Date(this.auth.expireTime) - Date.now() <= 0;
      if (isExpired) {
        await this.reqRefreshToken();
      }
      this.updateAwsConfig();
      let key = this.userUuid + "/" + Date.now();
      if (this.path) {
        key = this.path + "/" + key;
      } else if (this.acl === "private") {
        key = "private/" + this.campusId + "/" + key;
        if (this.aclType) {
          key = `private/${this.aclType}/${this.campusId}/${
            this.userUuid
          }/${Date.now()}`;
        }
      }
      const params = {
        Bucket: s3.attatchmentBucketName,
        Key: key,
        ContentType: file.type,
        Body: file,
        ACL: this.acl,
        ContentDisposition: `${
          this.downloadOnly ? "attachment" : "inline"
        }; filename*=UTF-8''${encodeURI(
          file.name.normalize().replace(/,/g, " ")
        )}`,
        StorageClass: "INTELLIGENT_TIERING",
      };

      const uploadRequest = await this.bucket
        .upload(params, async (err, data) => {
          if (err) {
            switch (err.code) {
              case "RequestAbortedError":
                break;
              case "ExpiredToken":
                await this.reqRefreshToken();
                this.updateAwsConfig();
                break;
              default:
                this.$eventBus.$emit("alert", {
                  open: true,
                  msg: "파일 업로드 도중 문제가 발생했습니다. 다시 시도해주세요.",
                });
                break;
            }
            console.log(err.code);
            this.$set(this.progressItems, index, {
              ...this.progressItems[index],
              success: false,
              err,
            });
            this.$emit("upload", { success: false });
          } else {
            const filePath = `https://${s3.attatchmentBucketName}/${key}`;
            this.$set(this.progressItems, index, {
              ...this.progressItems[index],
              success: true,
              err: null,
            });

            // 성공한 업로드 결과와 인덱스를 함께 저장
            const uploadResult = {
              success: true,
              path: filePath,
              key,
              size: file.size,
              fileName: file.name.normalize(),
              file,
            };

            // 오브젝트 리턴
            this.$emit("upload", uploadResult);

            // 배열 리턴
            this.$set(this.successfulUploads, index, uploadResult);
          }
          this.$set(this.progressItems, index, {
            ...this.progressItems[index],
            complete: true,
          });
          this.checkAllUploadComplete();

          // 모든 업로드가 완료되었는지 확인하고 successfulUploads 결과 리턴
          this.checkSuccessfulUploads();
        })
        .on("httpUploadProgress", ({ loaded, total }) => {
          const progressPercent = Math.round((loaded / total) * 100);
          this.$set(this.progressItems, index, {
            ...this.progressItems[index],
            value: progressPercent,
          });
        });
      this.$set(this.uploadRequests, index, uploadRequest);
      // this.uploadRequests.splice(index, 0, uploadRequest)
    },

    checkSuccessfulUploads() {
      const isAllComplete = this.progressItems.every((item) => item.complete);
      if (isAllComplete) {
        // successfulUploads 배열을 리턴하고 초기화
        this.$emit("uploads", this.successfulUploads);
        this.successfulUploads = [];
      }
    },

    onCancelUploadRequest(idx) {
      const uploadRequest = this.uploadRequests[idx];
      if (uploadRequest) {
        uploadRequest.abort();
      }
    },
    handleRequest({ index, mode }) {
      const uploadRequest = this.uploadRequests[index];
      if (!uploadRequest) return;
      const file = this.fileList[index];
      switch (mode) {
        case "abort":
          uploadRequest.abort();
          break;
        case "redo":
          this.upload(file, index);
          break;

        default:
          break;
      }
    },
    checkAllUploadComplete() {
      let complete = true;
      let success = true;
      this.progressItems.forEach((progressItem) => {
        if (!progressItem.complete) {
          complete = false;
        }
        if (!progressItem.success) {
          success = false;
        }
      });
      if (complete) {
        if (success) {
          this.progressPopupOpen = false;
        }
        this.$emit("uploadComplete", success);
      }
    },
    progressPopupClose() {
      this.uploadRequests.forEach((req) => {
        req.abort();
      });
      this.initData();
      // this.progressPopupOpen = false
    },
    async handleFileDelete({ files, callback }) {
      let result = {
        success: false,
      };
      if (files && files.length > 0) {
        const isExpired = new Date(this.auth.expireTime) - Date.now() <= 0;
        if (isExpired) {
          await this.reqRefreshToken();
        }
        this.updateAwsConfig();
        const params = {
          Bucket: s3.attatchmentBucketName,
          Delete: {
            Objects: files.map((file) => {
              return { Key: file.key };
            }),
          },
        };
        await this.bucket.deleteObjects(params, (err, data) => {
          if (err) {
            const msg = "파일 삭제 도중 오류가 발생했습니다.";
            this.$eventBus.$emit("alert", {
              open: true,
              msg,
            });
            console.log(err);
          } else {
            result.success = true;
            result.deleted = data.Deleted.map((item) => {
              return { key: item.Key };
            });
          }
          callback(result);
        });
      }
    },
  },
  mounted() {
    this.initData();
    this.initAWS();
    this.uploaderBus.$on("click", () => {
      // 업로드 가능한 파일 형식 제한을 위한 accept props가 input 태그에 반영된 후 클릭하기 위해 nextTick 사용.
      this.$nextTick(() => {
        this.$refs[this.fileInputRef].value = "";
        this.$refs[this.fileInputRef].click();
      });
    });
    this.uploaderBus.$on("upload", (files) => {
      this.uploadOnly = true;
      this.handleFileUpload(files);
    });
    this.uploaderBus.$on("delete", this.handleFileDelete);
    this.uploaderBus.$on("reset", this.initData);
  },
};
</script>
<style scoped>
.s3-file-uploader-container {
  height: 0;
}

.file-reader {
  width: 0;
  height: 0;
  opacity: 0;
}

#fileUploadForm {
  display: none;
}
</style>
