import isEmpty from "lodash.isempty";
import { differenceInMilliseconds } from "date-fns";
import AES from "crypto-js/aes";
import { passwordSecretKey } from "@/config";
import globalStore from "@/store";
import * as webview from "@/utils/webview";
import { getSearchParamAuth } from "@/router/utils/auth-utils";

export default (apiInstance, localStorage, sessionStorage) => ({
  checkAuthentication: async (store) => {
    let searchParamAuth = getSearchParamAuth();
    let searchParamAuthValid = searchParamAuth?.validUntil > Date.now();
    if (searchParamAuthValid) {
      store.commit("setAuth", {
        auth: searchParamAuth,
        snsType: searchParamAuth.snsType,
      });
    }
    const { snsType, expireTime, accessKeyId } = store.getters["getAuth"] || {};
    if (!snsType) {
      // no auth
      store.commit("setAuthenticated", false);
    } else {
      const needRefresh =
        searchParamAuthValid ||
        differenceInMilliseconds(new Date(expireTime), new Date()) <= 0;
      let initSuccess, refreshSuccess;
      initSuccess = await globalStore.dispatch("users/setInitialze", {
        setAccInfo: true,
        // refresh 필요한 경우, signature 빼고 api 호출하도록 한다.
        // signature빼고 보내면 최종 접속 정보를 현재 접속정보로 업데이트해주므로
        // 중복 로그인에 걸리지 않는다.
        sigYn: !needRefresh,
        accessKeyId,
      });
      if (needRefresh) {
        refreshSuccess = await store.dispatch("reqRefreshToken");
      }
      if (!initSuccess || (needRefresh && !refreshSuccess)) {
        store.dispatch("signOut");
        return;
      }

      store.commit("setAuthenticated", true);
    }
  },
  setAuth: async (store, payload) => {
    const { snsType, auth } = payload;
    store.commit("setAuth", {
      snsType,
      auth,
    });

    if (isEmpty(auth)) {
      localStorage.setAuth({});
      sessionStorage.setAuth({});
    } else {
      // 브라우저 저장소에는 사용자 정보 저장하지 않도록 수정.
      const storageAuth = {
        ...auth,
        user: null,
        instructor: null,
        email: null,
      };
      delete storageAuth.user;
      delete storageAuth.instructor;
      delete storageAuth.email;
      sessionStorage.setAuth({
        ...storageAuth,
        snsType,
      });
      if (store.rootGetters["common/getKeepLogin"]) {
        localStorage.setAuth({
          ...storageAuth,
          snsType,
        });
      }
    }
  },
  setAuthenticated: async (store, authenticated) => {
    store.commit("setAuthenticated", authenticated);
  },
  reqAuthLogin: async (store, payload) => {
    const { snsType, code, state } = payload;
    let token = null;
    let refreshToken = null;
    // let code = null;
    if (snsType === "NONE") {
      const { email, password } = payload;
      const uglyPassword = AES.encrypt(password, passwordSecretKey).toString();
      const result = await apiInstance.auth.login({
        snsType,
        email,
        password: uglyPassword,
      });
      return result;
    }
    const result = await apiInstance.auth.login({
      snsType,
      token,
      code,
      refreshToken,
      state,
    });
    return result;
  },
  checkUserPool: async (store, payload) => {
    // check user db
    const cognitoReqResult = await globalStore.dispatch(
      "users/requestCognitoId",
      payload
    );
    return cognitoReqResult;
  },
  saveUserPool: async (store, payload) => {
    // save user db
    const { userInfo } = payload;
    const data = {
      cognitoId: globalStore.getters["users/getCognitoId"],
      ...userInfo,
    };
    const result = await apiInstance.users.postUsers(data);
    if (result.success) {
      return true;
    } else {
      return false;
    }
  },
  checkSignUser: async (store, payload) => {
    const { snsType } = payload;
    const resAuthLogin = await store.dispatch("reqAuthLogin", payload);
    if (resAuthLogin.success && !isEmpty(resAuthLogin.data)) {
      const authInfo = resAuthLogin.data;
      await globalStore.dispatch("users/setCongitoId", authInfo);
      let success = !!authInfo.user;
      // 로그인 성공시(기존 유저인 경우) user 정보 함께 전달됨.
      if (success) {
        store.dispatch("setAuth", {
          snsType,
          auth: authInfo,
        });
        globalStore.dispatch("users/setUser", { user: authInfo.user });
        if (authInfo.instructor) {
          globalStore.dispatch("users/setInstructor", {
            instructor: authInfo.instructor,
          });
        }
        store.commit("setAuthenticated", true);
      }
      return {
        success,
        authInfo: authInfo,
      };
    }
    return {
      success: false,
      authInfo: null,
      ...resAuthLogin,
    };
  },
  login: async (store, payload) => {
    const { snsType } = payload;
    const deviceId = window.sessionStorage.getItem("firebaseToken");
    const isPoinWebview = globalStore.getters["common/isPoinWebview"];
    if (snsType === "NONE") {
      let checkSignUser = await store.dispatch("checkSignUser", payload);
      if (checkSignUser.success) {
        // exist user pool
        store.commit("setAuthenticated", true);
      } else {
        if (checkSignUser.msg) {
          return checkSignUser;
        } else {
          // exist cognito but not exist user pool
          const result = await store.dispatch("saveUserPool", {
            userInfo: {
              name: checkSignUser.authInfo.idToken.payload.name,
              email: payload.email,
              nickname: checkSignUser.authInfo.idToken.payload.nickname,
              ...(checkSignUser.authInfo.idToken.payload["custom:agreeYn"] &&
                checkSignUser.authInfo.idToken.payload["custom:agreeDttm"] && {
                  agreeYn:
                    checkSignUser.authInfo.idToken.payload["custom:agreeYn"],
                  agreeDttm:
                    checkSignUser.authInfo.idToken.payload["custom:agreeDttm"],
                }),
              snsType,
            },
          });

          if (result) {
            checkSignUser = await store.dispatch("checkSignUser", payload);
            if (checkSignUser.success) {
              store.commit("setAuthenticated", true);
            }
          }
          store.commit("deleteJoinUser");
        }
      }
      store.dispatch("setAuth", {
        snsType,
        auth: checkSignUser.authInfo,
      });
      if (checkSignUser.success) {
        if (deviceId && !isPoinWebview) {
          globalStore.dispatch("users/reqPutUpdateUsers", {
            deviceId: deviceId,
          });
        }
      }
      return checkSignUser;
    } else {
      const checkSignUser = await store.dispatch("checkSignUser", payload);
      store.dispatch("setAuth", {
        snsType,
        auth: checkSignUser.authInfo,
      });
      if (checkSignUser.success) {
        store.commit("setAuthenticated", true);
      }
      if (checkSignUser.success) {
        if (deviceId && !isPoinWebview) {
          globalStore.dispatch("users/reqPutUpdateUsers", {
            deviceId: deviceId,
          });
        }
      }
      return checkSignUser;
    }
  },
  signUp: async (store, payload) => {
    const { snsType } = payload;
    if (snsType === "NONE") {
      const {
        name,
        email,
        password,
        redirectUrl,
        nickname = "",
        agreeYn = false,
        agreeDttm = null,
      } = payload;
      const uglyPassword = AES.encrypt(password, passwordSecretKey).toString();
      const result = await apiInstance.auth.signUp({
        name,
        email,
        password: uglyPassword,
        redirectUrl,
        ...(nickname && { nickname }),
        ...(agreeYn &&
          agreeDttm && {
            agreeYn,
            agreeDttm,
          }),
      });
      if (result.success) {
        store.commit("setJoinUser", {
          email,
          password,
        });
      }
      return result;
    } else {
      // save user pool
      const {
        snsType,
        name,
        email,
        cognitoId,
        nickname = "",
        agreeYn = false,
        agreeDttm = null,
      } = payload;
      const result = await apiInstance.users.postUsers({
        cognitoId,
        name,
        email,
        snsType,
        ...(nickname && { nickname }),
        ...(agreeYn &&
          agreeDttm && {
            agreeYn: "Y",
            agreeDttm,
          }),
      });
      return result;
    }
  },
  signOut: (store, payload) => {
    store.dispatch("setAuth", {
      snsType: "",
      auth: {},
    });
    store.commit("setAuthenticated", false);
    globalStore.dispatch("users/setClearUser");
    globalStore.dispatch("members/clearMemberItem");
    globalStore.commit("products/clearProductsUser");
    globalStore.commit("activities/clearPlaylist");
    globalStore.dispatch("common/setLocationPath", "");
    webview.signOut();
  },
  clearAuth: (store, payload) => {
    store.dispatch("setAuth", {
      snsType: "",
      auth: {},
    });
    store.commit("setAuthenticated", false);
    globalStore.dispatch("users/setClearUser");
    globalStore.dispatch("members/clearMemberItem");
    // globalStore.dispatch("common/setLocationPath", "");
  },
  reqForgotPassword: async (store, payload) => {
    const { email } = payload;
    if (email) {
      const result = await apiInstance.auth.forgotPassword({
        email,
      });
      return result;
    }
  },
  reqResetPassword: async (store, payload) => {
    const { code, password, email } = payload;
    const uglyPassword = AES.encrypt(password, passwordSecretKey).toString();
    if (email) {
      const result = await apiInstance.auth.resetPassword({
        email,
        code,
        password: uglyPassword,
      });
      return result;
    }
  },
  emailVerification: async (store, payload) => {
    const { email, password } = payload;
    const result = await apiInstance.auth.emailVerification({
      email,
    });
    if (result.success) {
      store.commit("setJoinUser", {
        email,
        ...(password && { password }),
      });
    }
    return result;
  },
  reqChangePassword: async (store, payload) => {
    const { newPassword, oldPassword } = payload;
    const email = store.rootGetters["users/getUserEmail"];
    const newUglyPassword = AES.encrypt(
      newPassword,
      passwordSecretKey
    ).toString();
    const oldUglyPassword = AES.encrypt(
      oldPassword,
      passwordSecretKey
    ).toString();
    const result = await apiInstance.auth.changePassword({
      email,
      newPassword: newUglyPassword,
      oldPassword: oldUglyPassword,
    });
    return result;
  },
  authVerification: async (store, payload) => {
    const { code, email = store.getters["getJoinUser"]?.email } = payload;
    const result = await apiInstance.auth.authVerification({
      code,
      email,
    });
    return result;
  },
  reqRefreshToken: async (store, payload) => {
    const snsType = store.getters["getSnsType"];
    const auth = globalStore.getters["auth/getAuth"];
    const userUuid = store.rootGetters["users/getUserUuid"];
    if (auth) {
      const result = await apiInstance.auth.refreshToken({
        accessKeyId: auth.accessKeyId,
        cognitoId: auth.cognitoId,
        userUuid,
      });

      if (result.success) {
        const { data } = result;
        store.dispatch("setAuth", {
          snsType,
          auth: data,
        });
      }
      return result.success;
    }
    return false;
  },
  setTokenRefreshing: async (store, { tokenRefreshing }) => {
    store.commit("setTokenRefreshing", tokenRefreshing);
  },
  checkLocalAuth: (store, changeEvent) => {
    const { key, newValue } = changeEvent;
    if (key !== "auth") return;
    const auth = store.getters["getAuth"];
    const localAuth = JSON.parse(newValue);
    if (localAuth.cognitoId && localAuth.cognitoId === auth.cognitoId) {
      const isRefreshed =
        new Date(localAuth.expireTime) - new Date(auth.expireTime) > 0;
      if (isRefreshed) {
        store.commit("setAuth", {
          snsType: localAuth.snsType,
          auth: localAuth,
        });
        sessionStorage.setAuth(localAuth);
      }
    }
  },
});
