<template lang="html">
  <div class="video-upload-popup">
    <div
      class="d-flex align-center w-full pa-4 gap-3 cursor-pointer"
      @click="panelChange"
    >
      <v-progress-circular
        v-if="!entireYn"
        indeterminate
        width="2"
        color="primary"
        size="16"
      />
      <p class="black--text text-body-1-regular flex-fill">
        {{ entireCompletion }} ({{ completedCnt }} / {{ progressItems.length }})
      </p>
      <p-icon
        :icon="getVideoPanelOpen ? 'DownSm/Gray8' : 'UpSm/Gray8'"
        class="cursor-pointer"
        size="24"
      />
      <p-icon
        icon="Close/Gray8"
        @click.native.stop="abortClick"
        class="cursor-pointer"
        size="24"
      />
    </div>
    <v-expand-transition>
      <div
        class="w-full gray-3-border-top panel-content"
        v-show="getVideoPanelOpen"
      >
        <ul class="d-flex-column w-full py-3">
          <li
            v-for="(item, index) in progressItems"
            class="d-flex-column gap-2 py-3 px-4 w-full"
            :key="index"
            :value="index"
          >
            <div class="w-full d-flex align-center">
              <div class="item-title">
                <p-asset :name="videoIcon" size="20" />
                <p class="single-line">
                  {{ item.contentName }}
                </p>
                <p-icon
                  v-if="
                    item.status === 'contentSaved' && !item.abort && !item.err
                  "
                  icon="Check/Success"
                  size="20"
                  class="ml-auto cursor-pointer"
                />
                <p-icon
                  v-else-if="canDeleteVideo(index) && !item.abort"
                  @click="deleteVimeo(index)"
                  icon="Close/Black"
                  class="ml-auto cursor-pointer"
                  size="20"
                />
                <p
                  class="ml-auto text-detail-1-regular gray--text text--darken-3"
                  v-if="item.abort && !item.err"
                >
                  취소됨
                </p>
                <p
                  class="ml-auto text-detail-1-regular error--text"
                  v-else-if="item.err"
                >
                  실패
                </p>
              </div>
            </div>
            <div
              class="w-full d-flex-column align-center gap-1"
              v-if="
                item.vimeoStatus.status !== 'available' &&
                !item.abort &&
                !item.err
              "
            >
              <v-progress-linear
                class="w-full"
                :color="item.err ? 'error' : 'primary'"
                :value="item.uploadStatus.progress"
                background-color="gray lighten-3"
                height="6"
                rounded
              />
              <div class="progress">
                <p class="progress-message">
                  {{ item.message }}
                </p>
                <p class="progress-percent">
                  {{ item.uploadStatus.progress }}%
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dateFormat, textFormat } from "@/utils";
import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";

export default {
  name: "MultiVideoProgressPopup",
  data() {
    return {
      progressItems: [],
      // 콘텐츠 저장시 보낼 data
      contentList: [],
      canLeaveSite: true,
      //완료된 것의 갯수
      completedCnt: 0,
      uploadCompletedCnt: 0,
      timer: [],
      cancelCnt: 0,
      errCnt: 0,
      heightOffset: 0,
      //진행 중인 것의 갯수
      progressCnt: 0,
      CancelTokenSource: [],
      videoIcon: "Contents/VIDEO/On",
    };
  },
  computed: {
    ...mapGetters({
      getVideoPanel: "common/getVideoPanel",
      getVideoPanelOpen: "common/getVideoPanelOpen",
      getVideoPanelHeight: "common/getVideoPanelHeight",
      campusInfo: "campuses/getCampusInfo",
      user: "users/getUser",
      videoFiles: "common/getVideoFiles",
    }),
    entireYn() {
      return this.completedCnt === this.progressItems.length - this.cancelCnt;
    },
    entireCompletion() {
      if (this.entireYn) {
        return "업로드 완료";
      }
      return "업로드 중...";
    },
  },
  methods: {
    ...mapActions({
      postContent: "contents/postContent",
    }),
    ...mapMutations({
      setVideoPanel: "common/setVideoPanel",
      setVideoPanelOpen: "common/setVideoPanelOpen",
      setVideoPanelHeight: "common/setVideoPanelHeight",
      setVideoFiles: "common/setVideoFiles",
    }),
    updateUploadProgress(index, currentProgress) {
      if (currentProgress === 100) {
        // 업로드 완료, 변환시작
        currentProgress = 99;
      }
      this.$set(
        this.progressItems[index].uploadStatus,
        "progress",
        currentProgress
      );
    },
    updateUploadStatus(index, status) {
      if (status) {
        const prevStatus = this.progressItems[index].uploadStatus.status;
        this.$set(this.progressItems[index].uploadStatus, "status", status);
        if (status === "prepare" || status === "verify") {
          this.$set(this.progressItems[index], "message", "준비 중...");
        } else if (status === "upload") {
          this.$set(this.progressItems[index], "message", "업로드 중...");
        } else if (status === "done") {
          // up소load 끝나고 취소
          this.uploadCompletedCnt++;
          // 한개 업로드 끝나면 그다음 upload 시작
          // nextIdx : 다음에 업로드할 index, 취소되거나 error X
          let nextIdx = index + 1;
          while (nextIdx < this.progressItems.length) {
            if (
              this.progressItems[nextIdx].abort === false &&
              this.progressItems[nextIdx].err === false
            ) {
              break;
            }
            nextIdx++;
          }
          if (this.progressItems.length > nextIdx) {
            this.startUpload(nextIdx, {
              file: this.progressItems[nextIdx].file,
            });
          }
          this.$set(this.contentList[index], "regDttm", Date.now());
          this.$set(
            this.progressItems[index],
            "message",
            "업로드 완료... 변환 준비 중..."
          );
        } else if (status === "abort" && prevStatus === "done") {
          this.uploadCompletedCnt--;
        } else if (status === "cancel") {
          //업로드 중 취소 다음 거 업로드 시작
          let nextIdx = index + 1;
          if (this.progressItems.length > nextIdx) {
            this.startUpload(nextIdx, {
              file: this.progressItems[nextIdx].file,
            });
          }
        }
      } else if (status == "err") {
        this.errCnt++;
      } else {
        this.$set(this.progressItems[index], "message", "");
        this.$set(this.progressItems[index].uploadStatus, "status", "");
      }
    },
    async startUpload(index, { file }) {
      if (
        !this.getVideoPanel ||
        this.progressItems[index].uploadStatus.status === "cancel" ||
        this.progressItems[index]?.abort === true
      ) {
        return;
      }
      const videoDate = dateFormat.getDateFormat(
        new Date(),
        "yyyy년 MM월 dd일 a hh시 mm분"
      );
      const videoName = textFormat.removeEmoji(this.contentList[index]?.name);
      const videoDesc = `캠퍼스명: ${
        this.campusInfo ? this.campusInfo.name + "\n" : ""
      }콘텐츠명: ${videoName}\n작성자: ${this.user.name}\n등록일: ${videoDate}`;
      this.updateUploadStatus(index, "prepare");
      // Vimeo Upload
      const createResult = await this.$vimeo.vimeo.startUploadVideo({
        fileSize: file.size,
        videoName,
        description: videoDesc,
      });
      if (
        !this.getVideoPanel ||
        this.progressItems[index]?.uploadStatus?.status === "cancel" ||
        this.progressItems[index]?.abort === true
      ) {
        return;
      }
      if (createResult.success) {
        const { upload, uri } = createResult.data;
        const vimeo = {
          videoUri: uri,
          uploadLink: upload.upload_link,
          size: file.size,
        };
        this.setVimeoInfo(index, vimeo);
        this.progressUpload(index, upload.upload_link, file);
      } else {
        this.$eventBus.$emit("alert", {
          open: true,
          title: "파일 업로드 오류",
          msg: `<p>오류내용: ${
            createResult?.errData?.error || "-"
          }</p><p>문제가 계속될 경우 오류 내용과 함께 1833-5396 또는 help@poinblack.com으로 문의해주세요.</p>`,
        });
      }
    },
    /*
    [3]
    vimeo에 영상 업로드가 finish 되었는지 체크
    아직 finish 되지 않은 상태라면 업로드 진행
    */
    async progressUpload(index, uploadLink, file) {
      if (
        !this.getVideoPanel ||
        this.progressItems[index]?.uploadStatus?.status === "cancel" ||
        this.progressItems[index]?.abort === true
      ) {
        return;
      }
      this.updateUploadStatus(index, "verify");
      const verifyResult = await this.$vimeo.vimeo.verifyUploadVideoStatus({
        uploadLink,
      });
      const { finish, offset, progress, size } = verifyResult;
      if (!this.getVideoPanel) {
        return;
      }
      this.updateUploadProgress(index, progress);
      if (
        !this.getVideoPanel ||
        this.progressItems[index]?.uploadStatus?.status === "cancel" ||
        this.progressItems[index]?.abort === true
      ) {
        return;
      }
      if (!finish) {
        this.uploadVideo(index, {
          uploadLink,
          file,
          uploadOffset: offset,
        });
      }
    },
    async cancelUpload(index) {
      this.CancelTokenSource[index].cancel("uploadCancel");
      this.CancelTokenSource[index] = null;
    },
    /*
   [4]
   파일을 vimeo에 실제로 업로드
   */
    async uploadVideo(index, { uploadLink, file, uploadOffset }) {
      if (
        !this.getVideoPanel ||
        this.progressItems[index]?.uploadStatus?.status === "cancel" ||
        this.progressItems[index]?.abort === true
      ) {
        return;
      }
      this.updateUploadStatus(index, "upload");
      const result = await this.$vimeo.vimeo.uploadVideo(
        {
          uploadLink,
          file,
          uploadOffset,
          onProgress: (percent) => {
            this.updateUploadProgress(index, percent);
          },
        },
        this.CancelTokenSource[index]
      );

      if (this.CancelTokenSource[index]) {
        this.updateUploadStatus(index, "done");
      }
      this.CancelTokenSource[index] = null;

      if (!result.success) {
        if (result?.msg === "uploadCancel") {
          //업로드 취소한 경우
          return;
        }
        this.$set(this.progressItems[index], "err", true);
        this.updateUploadStatus(index, "err");
        this.calHeightOffset();
        this.setVideoPanelHeight(this.heightOffset);
      }
    },
    calHeightOffset() {
      if (this.getVideoPanel) {
        // 패널이 존재할 때, 헤더 기본값
        this.heightOffset = 72;
      }
      if (this.getVideoPanelOpen) {
        let overFlow = false;
        for (const item of this.progressItems) {
          if (
            item.vimeoStatus.status !== "available" &&
            !item.abort &&
            !item.err
          ) {
            this.heightOffset += 85;
          } else {
            this.heightOffset += 45;
          }
          if (this.heightOffset >= 400) {
            overFlow = true;
            this.heightOffset = 416;
            break;
          }
        }
        if (!overFlow) {
          this.heightOffset += 24;
        }
      }
    },
    panelChange() {
      if (!this.getVideoPanel) {
        return;
      }
      if (this.getVideoPanelOpen === true) {
        //닫혔을 때
        this.setVideoPanelOpen(false);
        this.heightOffset = 72;
        this.setVideoPanelHeight(this.heightOffset);
      } else {
        //열릴 때
        this.setVideoPanelOpen(true);
        this.calHeightOffset();
        this.setVideoPanelHeight(this.heightOffset);
      }
    },
    getErrMsg(err) {
      let errMsg = "";
      switch (err.code) {
        case "RequestAbortedError":
          errMsg = "취소";
          break;

        default:
          errMsg = "오류 발생";
          break;
      }
      return errMsg;
    },
    // 콘텐츠 저장
    async saveContent(index) {
      let content = this.contentList[index];
      const result = await this.postContent(content);
      if (!result.success) {
        let msg = result.message;
        switch (result.name) {
          case "ACCESS_DENIED":
          case "UNAUTHORIZE":
            msg = this.$t("error.access");
            break;
          case "NOT_SUPPORTED":
            msg = "지원하지 않는 요금제입니다.";
            break;
          case "BAD_REQUEST_BODY":
            msg = this.$t("error.invalid");
            break;
          case "UNAVAILABLE":
            msg = this.$t("error.authorize");
            break;
          default:
            msg = result.message;
        }
        this.$eventBus.$emit("alert", {
          open: true,
          title: this.$t("adminContentEdit.developFail"),
          msg,
        });
        if (result.local) {
          const refreshVimeo = async (content) => {
            if (content.clip?.url && !content.clip?.time) {
              const videoUri = content.clip?.url;
              const result = await this.$vimeo.vimeo.getVideo({
                videoUri,
              });
              if (result.success) {
                const { duration } = result.data;
                if (duration) {
                  return {
                    ...content,
                    clip: {
                      ...content.clip,
                      time: duration,
                    },
                  };
                }
              }
            }
            return content;
          };
          content = await refreshVimeo(content);
        }
      } else {
        this.progressItems[index].status = "contentSaved";
      }
    },
    uploadListener(index, { uploadStatus, vimeo, duration }) {
      if (!this.getVideoPanel) {
        return;
      }
      // index를 사용하여 필요한 작업을 수행
      if (vimeo) {
        this.setVimeoInfo(index, vimeo);
      }
      if (duration) {
        this.$set(this.contentList[index].clip, "time", duration);
      }
    },
    setVimeoInfo(index, vimeo) {
      if (vimeo && vimeo.videoUri) {
        this.$set(this.contentList[index], "clip", {
          ...vimeo,
          url: vimeo.videoUri,
          videoId: vimeo.videoUri?.replace("/videos/", ""),
        });

        if (this.progressItems[index].vimeo.videoUri !== vimeo.videoUri) {
          this.$set(this.progressItems[index], "vimeo", vimeo);
        }
        this.checkVimeoAvailable(index);
      } else {
        this.$set(this.progressItems[index], "vimeo", vimeo);
        this.$set(this.contentList[index], "clip", {
          size: 0,
          videoId: "",
          time: 0,
          url: "",
        });
      }
    },
    changeVimeoStatus(index, videoStatus, transcodeStatus, uploadStatus) {
      if (!this.getVideoPanel) {
        return;
      }
      this.$set(this.progressItems[index].vimeoStatus, "status", videoStatus);
      if (videoStatus === "available") {
        this.$set(this.progressItems[index], "progress", "100%");
        this.$set(this.progressItems[index], "message", "변환완료");
        this.completedCnt++;
        this.calHeightOffset();
        this.setVideoPanelHeight(this.heightOffset);
      } else if (
        this.progressItems[index].vimeoStatus.status === "transcode_starting"
      ) {
        this.$set(this.progressItems[index], "message", "변환 준비중..");
      } else if (
        this.progressItems[index].vimeoStatus.status === "transcoding"
      ) {
        this.$set(this.progressItems[index], "message", "변환 중..");
      }

      this.$set(
        this.progressItems[index].vimeoStatus,
        "transcodeStatus",
        transcodeStatus || ""
      );
      this.$set(
        this.progressItems[index].vimeoStatus,
        "uploadStatus",
        uploadStatus || ""
      );
      if (
        videoStatus === "transcoding_error" ||
        videoStatus === "uploading_error"
      ) {
        this.progressItems[index].err = true;
        this.calHeightOffset();
        this.setVideoPanelHeight(this.heightOffset);
      }
    },
    async verifyVideo(index) {
      if (!this.getVideoPanel) {
        clearTimeout(this.timer[index]);
        return;
      }
      if (this.progressItems[index].vimeoStatus?.status === "uploading_error") {
        this.changeVimeoStatus(index, "uploading_error");
        this.vimeoPropsReset(index);
        return null;
      }
      this.checkVimeoAvailable(index);
      return;
    },
    async checkVimeoAvailable(index) {
      if (!this.getVideoPanel) {
        return;
      }
      if (
        !this.getVideoPanel ||
        this.progressItems[index].vimeoStatus.status === "available"
      ) {
        clearTimeout(this.timer[index]);
        return;
      }
      const videoUri = this.contentList[index].clip?.url;

      if (!videoUri) {
        this.changeVimeoStatus(index, "empty");
        return null;
      }

      const result = await this.$vimeo.vimeo.getVideo({ videoUri });
      if (!result.success) {
        // api 호출 횟수 제한에 걸린 경우, 다음 api 호출 횟수 초기화 시점에 다시 호출하도록 한다. 단, 3회까지만 허용
        if (result.status === 429 && this.progressItems[index].retryCnt < 3) {
          this.$set(
            this.progressItems[index],
            "retryCnt",
            (this.progressItems[index].retryCnt ?? 0) + 1
          );
          if (this.timer[index]) {
            clearTimeout(this.timer[index]);
          }
          const limitResetHeader = result.headers?.["x-ratelimit-reset"];
          const timeout = limitResetHeader
            ? new Date(limitResetHeader).getTime() - Date.now()
            : 30000;
          this.timer[index] = setTimeout(() => {
            this.verifyVideo(index);
          }, timeout);
          return;
        }
        this.changeVimeoStatus(index, "transcoding_error");
        this.errCnt++;
        clearTimeout(this.timer[index]);
        return null;
      }
      const { status, transcode, upload, duration } = result.data;
      if (duration) {
        this.uploadListener(index, { duration });
      }
      this.changeVimeoStatus(index, status, transcode.status, upload.status);
      if (
        this.getVideoPanel &&
        this.progressItems[index].vimeoStatus.status === "uploading"
      ) {
        if (this.timer[index]) {
          clearTimeout(this.timer[index]);
        }
        this.timer[index] = setTimeout(() => {
          this.verifyVideo(index);
        }, 30000);
      } else if (
        this.getVideoPanel &&
        this.progressItems[index].vimeoStatus.status === "available"
      ) {
        this.saveContent(index);
        clearTimeout(this.timer[index]);
      } else if (
        this.getVideoPanel &&
        (this.progressItems[index].vimeoStatus.status ===
          "transcode_starting" ||
          this.progressItems[index].vimeoStatus.status === "transcoding")
      ) {
        if (this.timer[index]) {
          clearTimeout(this.timer[index]);
        }
        this.timer[index] = setTimeout(() => {
          this.verifyVideo(index);
        }, 30000);
      }
    },
    vimeoPropsReset(index) {
      if (!this.getVideoPanel) {
        return;
      }
      this.progressItems[index].vimeo = {};
    },
    abortClick() {
      if (this.entireYn) {
        // 전체 업로드 완료시
        this.setVideoPanel(false);
        this.setVideoFiles([]);
        this.setVideoPanelOpen(false);
        this.setVideoPanelHeight(0);
        this.timer.forEach((t) => clearTimeout(t));
        this.completedCnt = 0;
        this.uploadCompletedCnt = 0;
        this.cancelCnt = 0;
        this.errCnt = 0;
        this.timer = [];
        this.progressItems = [];
        this.contentList = [];
        this.canLeaveSite = true;
        this.CancelTokenSource = [];
      } else {
        this.$eventBus.$emit("confirmPopup", {
          open: true,
          title: "업로드 취소",
          msg: "취소 시 완료 이외에 나머지 콘텐츠의 올리기가 취소됩니다.",
          btnTxtConfirm: "업로드 취소",
          btnTxtCancel: "아니오",
          reverse: true,
          callback: async (confirm) => {
            if (confirm) {
              await this.abortProgress();
            }
          },
        });
      }
    },
    async abortProgress() {
      this.setVideoPanel(false);
      this.setVideoFiles([]);
      this.setVideoPanelOpen(false);
      this.setVideoPanelHeight(0);
      this.timer.forEach((t) => clearTimeout(t));
      const proms = this.progressItems.map((item, i) => {
        if (this.canDeleteVideo(i)) {
          return this.deleteVimeo(i);
        }
      });
      await Promise.all(proms);
      this.completedCnt = 0;
      this.uploadCompletedCnt = 0;
      this.cancelCnt = 0;
      this.errCnt = 0;
      this.CancelTokenSource = [];
      this.timer = [];
      this.progressItems = [];
      this.contentList = [];
      this.canLeaveSite = true;
    },
    canDeleteVideo(index) {
      //이미 삭제된 경우 or 콘텐츠 저장이 완료된 경우 or 에러가 발생한경우 return fasle
      return (
        this.progressItems[index].vimeoStatus?.status !== "available" &&
        this.progressItems[index].uploadStatus.status !== "abort" &&
        this.contentList[index]?.clip?.videoUri !== undefined &&
        !this.progressItems[index].err &&
        this.progressItems[index].status !== "contentSaved"
      );
    },
    async deleteVimeo(index) {
      if (this.getVideoPanel) {
        //전체삭제가 아닌 경우
        this.$set(this.progressItems[index], "abort", true);
        this.calHeightOffset();
        this.setVideoPanelHeight(this.heightOffset);
      }
      clearTimeout(this.timer[index]);
      this.cancelCnt++;
      if (this.progressItems[index].uploadStatus.status === "upload") {
        this.updateUploadStatus(index, "cancel");
        this.cancelUpload(index);
      }
      if (this.canDeleteVideo(index)) {
        this.updateUploadStatus(index, "abort");
        this.$set(this.progressItems[index], "abort", true);
        const result = await this.$vimeo.vimeo.deleteVideo({
          videoUri: this.contentList[index]?.clip?.videoUri,
        });

        if (result.success) {
          if (this.getVideoPanel) {
            this.vimeoPropsReset(index);
            this.$set(this.contentList[index], "clip", {
              size: 0,
              videoId: "",
              time: 0,
              url: "",
            });
            this.compKey = uuid();
          }
          return;
        }
        this.$eventBus.$emit("alert", {
          open: true,
          msg: this.$t("adminContentEdit.deleteFail"),
        });
      }
    },
    unLoadEvent(event) {
      if (this.canLeaveSite) return;
      event.preventDefault();
      event.returnValue = "";
    },
  },
  watch: {
    getVideoPanel: {
      immediate: true,
      handler(val) {
        if (val) {
          this.canLeaveSite = false;
          this.calHeightOffset();
          this.setVideoPanelHeight(this.heightOffset);
        }
      },
    },
    entireYn: {
      handler(val) {
        if (val) {
          this.canLeaveSite = true;
        }
      },
    },
    videoFiles: {
      immediate: true,
      deep: true,
      handler(val) {
        // val => 기존에 this.progressItems + 새로운 progressItems
        if (val.length > this.progressItems.length) {
          let newFiles;
          this.progressCnt = 0;
          this.progressItems.forEach((item) => {
            if (
              item.vimeoStatus?.status !== "available" &&
              item.abort === false &&
              item.err === false
            ) {
              this.progressCnt++;
            }
          });
          // 추가할 수 있는 파일의 갯수
          let residue = 5 - this.progressCnt;
          // val.length - this.progressItems.length == 추가된 파일의 갯수
          // 새로 추가된게 추가할 수 있는 파일의 갯수 이상일 때
          if (residue - (val.length - this.progressItems.length) < 0) {
            this.$eventBus.$emit("alert", {
              open: true,
              msg: this.$t("최대 5개 까지 업로드 가능합니다."),
            });
            newFiles = [
              ...val.slice(
                this.progressItems.length,
                this.progressItems.length + residue
              ),
            ];
          } else {
            newFiles = [...val.slice(this.progressItems.length)];
          }
          const nullArr = new Array(newFiles.length);
          this.timer = [...this.timer, ...nullArr];
          // propfiles 깊은 복사
          const deepCopiedFiles = _.cloneDeep(newFiles);
          const previousLength = this.progressItems.length;
          deepCopiedFiles.forEach((item) => {
            this.progressItems.push({
              ...item,
              abort: false,
              err: false,
              message: "준비 중",
              status: "before", // before, abort ,contentSaved
              //upload status
              uploadStatus: {
                status: "yetStarted",
                progress: "0",
              },
              // transcoding status
              vimeoStatus: {
                status: "spinner",
                transcodeStatus: "",
                uploadStatus: "",
              },
              vimeo: {}, // size, uploadLink, videoUri
              retryCnt: 0,
            });
            const content = {
              name: item.contentName,
              contentType: ContentTypeEnum.VIDEO,
              vod: {},
              desc: "",
              clip: {
                size: 0,
                videoId: "",
                time: 0,
                url: "",
              },
            };
            this.contentList.push(content);
            this.CancelTokenSource.push(axios.CancelToken.source());
          });
          if (this.getVideoPanelOpen) {
            this.calHeightOffset();
            this.setVideoPanelHeight(this.heightOffset);
          }
          // startUpload -> progressUpload -> uploadVideo -> startUpload(nextIdx) 반복됨
          //  startUpload param 인 index는
          // 1) 초기 : previousLength === 0
          // 2) 모든 업로드가 완료된 후 파일이 추가 되었을 때 : this.uploadCompletedCnt === previousLength - this.cancelCnt
          if (
            previousLength === 0 ||
            this.uploadCompletedCnt === previousLength - this.cancelCnt
          ) {
            this.startUpload(previousLength, {
              file: this.progressItems[previousLength].file,
            });
          }
        }
      },
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.unLoadEvent);
  },
  beforeDestroy() {
    this.setVideoPanel(false);
    this.abortProgress();
    this.timer.forEach((t) => clearTimeout(t));
  },
};
</script>

<style lang="scss" scoped>
.video-upload-popup {
  position: fixed;
  bottom: 28px;
  right: 28px;
  width: 400px;
  max-height: 400px;
  min-height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray3;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: $white;
  z-index: 100;
  @media (max-width: $mdBreakPoint) {
    right: 24px;
  }
}
.item-title {
  width: 100%;
  gap: 4px;
  display: grid;
  grid-template-columns: 20px 1fr 32px;
  align-items: center;
  padding: 0px;
  font-weight: 400;
  font-size: 14px;
  color: $black;
}
.progress-message {
  font-size: 12px;
  color: $gray7;
  @include single-line;
}
.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.progress-percent {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
}
.panel-content {
  overflow-y: auto;
  max-height: 344px;
  @include scrollbar($white);
}
</style>
