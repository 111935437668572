var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{ref:"select",class:_vm.customClass ? _vm.customClass : 'poin-select',attrs:{"items":_vm.options,"value":_vm.value,"dense":_vm.dense,"placeholder":_vm.placeholder,"menu-props":{
    bottom: true,
    offsetY: true,
    contentClass: !_vm.customOption
      ? 'p-select-options'
      : `p-select-options ${_vm.customOption}`,
  },"clearable":_vm.clearable,"hide-details":"","single-line":"","outlined":"","color":"gray darken-1","background-color":_vm.readonly ? 'gray lighten-3' : _vm.backgroundColor,"readonly":_vm.readonly,"disabled":_vm.disabled,"item-color":"black","no-data-text":_vm.noDataText},on:{"change":_vm.updateInput},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"text-body-2-regular black--text",class:[
        _vm.textOption(data, true).class,
        { selection: _vm.textOption(data, true).hoverOn },
      ],style:(`width: ${_vm.textOption(data, true).width} !important`),attrs:{"data-title":data.item.text},domProps:{"innerHTML":_vm._s(data.item.text)}})]}},{key:"item",fn:function(data){return [(!_vm.textOption(data).hoverOn)?_c('p',{staticClass:"text-body-2-regular black--text",class:_vm.textOption(data).class,style:(`width: ${_vm.textOption(data).width} !important`),domProps:{"innerHTML":_vm._s(_vm.textOption(data).text)}}):_c('v-tooltip',{attrs:{"content-class":"pa-3 ml-3","right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('p',_vm._g({staticClass:"text-body-2-regular black--text",class:_vm.textOption(data).class,style:(`width: ${_vm.textOption(data).width} !important`),domProps:{"innerHTML":_vm._s(_vm.textOption(data).text)}},on))]}}],null,true)},[_c('p',{staticClass:"text-body-2-regular"},[_vm._v(" "+_vm._s(_vm.textOption(data).text)+" ")])])]}},{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('p-icon',{attrs:{"icon":_vm.appendIcon}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }