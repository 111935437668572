var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"poin-input"},[(_vm.label)?_c('p-label',{attrs:{"label":_vm.label,"required":_vm.required}}):_vm._e(),_c('textarea',{staticClass:"poin-textarea",class:{
      invalid: _vm.invalid,
      readonly: _vm.readonly,
      example: _vm.example,
      disabled: _vm.disabled,
      'success-line': _vm.success,
      'mt-2': _vm.label,
    },style:(`height: ${_vm.height}px`),attrs:{"id":`${_vm._uid}-textarea`,"name":_vm.name,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"minlength":_vm.minlength,"readonly":_vm.readonly || _vm.example,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput,"blur":_vm.blur,"focus":_vm.focus,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterAction.apply(null, arguments)}}}),_vm._v(" "),(!_vm.hideDetails)?_c('div',{staticClass:"d-flex"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.invalid),expression:"invalid"}],staticClass:"error--text text-detail-1-regular error-msg"},[_vm._v(_vm._s(_vm.errorMsg))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invalid && _vm.success),expression:"!invalid && success"}],staticClass:"success--text text-detail-1-regular error-msg"},[_vm._v(_vm._s(_vm.successMsg))]),(_vm.countLength && _vm.value)?_c('span',{staticClass:"ml-auto text-end text-detail-1-regular gray--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.value.length)+"/"+_vm._s(_vm.maxlength || 50)+" ")]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }