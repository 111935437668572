<template>
  <div class="bottom-navigation" :class="{ poincampus: !campusOnly }">
    <router-link :to="homeRoute">
      <p-icon icon="Shop/Black" />
      <span class="text-detail-2-medium black--text">홈</span>
    </router-link>
    <router-link :to="productsRoute" v-if="campusOnly">
      <div style="width: 20px; height: 24px">
        <img
          src="https://file.poincampus.com/assets/images/ProductAsset/40.svg"
          alt="playlist"
          style="width: 20px; object-fit: contain"
        />
      </div>
      <span class="text-detail-2-medium black--text">{{ altProduct }}</span>
    </router-link>
    <router-link :to="playlistRoute">
      <div style="width: 20px; height: 24px">
        <img
          src="https://file.poincampus.com/assets/nav/playlist.png"
          alt="playlist"
          style="width: 20px; object-fit: contain"
        />
      </div>
      <span class="text-detail-2-medium black--text">참여현황</span>
    </router-link>
    <router-link v-if="!campusOnly" :to="campusesRoute">
      <div style="width: 20px; height: 24px">
        <img
          src="https://file.poincampus.com/assets/nav/campuses.png"
          alt="playlist"
          style="width: 20px; object-fit: contain"
        />
      </div>
      <span class="text-detail-2-medium black--text">캠퍼스 목록</span>
    </router-link>
    <router-link v-else-if="isStaff" :to="adminRoute">
      <p-icon icon="Setting/Black" />
      <span class="text-detail-2-medium black--text">캠퍼스 관리</span>
    </router-link>
    <router-link v-else :to="lastAccRoute">
      <p-icon icon="Eye/Black" />
      <span class="text-detail-2-medium black--text">이어보기</span>
    </router-link>
    <!-- <button ref="profile">
      <div
        @click.stop="profileOpen = !profileOpen"
        class="cursor-pointer rounded-circle ml-3"
      >
        <profile-image
          :img-path="profilePath"
          alt="profile"
          size="36px"
          size-mobile="36px"
        />
      </div>
      <v-dialog v-model="profileOpen" max-width="360" content-class="white">
        <profile-menu
          :show="profileOpen"
          :profile-path="profilePath"
          @close="profileOpen = false"
          key="mb-menu"
        />
      </v-dialog>
    </button> -->
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { Authority } from "@/enums/membersEnum";
// import ProfileImage from "@/components/common/Profile-Image";
// import ProfileMenu from "@/components/poin-ui/ProfileMenu";
export default {
  name: "BottomNavigation",
  // components: {
  //   ProfileImage,
  //   ProfileMenu,
  // },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      isMobile: "common/isMobile",
      userId: "users/getUserUuid",
      member: "members/getMemberItem",
      memberId: "members/getMemberId",
      campusId: "campuses/getCampusUuid",
      campusOnly: "common/campusOnly",
      hasRoleOrAuth: "members/hasRoleOrAuth",
      lastAccCont: "members/getLastAccCont",
      altProduct: "campuses/altProduct",
      isStaff: "members/isStaff",
    }),
    homeRoute() {
      if (!this.campusOnly) {
        return {
          name: "poin-home",
        };
      }
      return {
        name: "campus-home",
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    productsRoute() {
      return {
        name: "campus-product-home",
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    playlistRoute() {
      if (!this.campusOnly) {
        return {
          name: "playlist",
        };
      }
      return {
        name: "campus-playlist",
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    campusesRoute() {
      return {
        name: "campuses",
      };
    },
    adminRoute() {
      return {
        name: "campus-admin-dashboard",
        params: this.$route.params,
      };
    },
    lastAccRoute() {
      if (!this.campusOnly || !this.lastAccCont) {
        return this.playlistRoute;
      }
      return {
        name: "campus-playground",
        params: { ...this.$route.params, ...this.lastAccCont },
        query: this.$route.query,
      };
    },
    // profilePath() {
    //   if (this.campusOnly) {
    //     if (this.memberId) {
    //       return (
    //         this.member?.profileImage?.path ||
    //         "https://file.poincampus.com/assets/ProfileImg@2x.png"
    //       );
    //     }
    //   }
    //   if (this.userId) {
    //     return (
    //       this.user?.image?.path ||
    //       "https://file.poincampus.com/assets/ProfileImg@2x.png"
    //     );
    //   }
    //   return "https://file.poincampus.com/assets/ProfileImg@2x.png";
    // },
  },
  methods: {
    // ...mapActions({
    // signOut: "auth/signOut",
    // }),
    // handleClickOutside(event) {
    //   if (!this.$refs.profile.contains(event.target)) {
    //     this.profileOpen = false;
    //   }
    // },
    // setProfilePath() {
    //   if (this.campusOnly) {
    //     if (this.memberId) {
    //       this.profilePath = this.member?.profileImage?.path || "";
    //       return;
    //     }
    //   }
    //   if (this.userId) {
    //     this.profilePath = this.user?.image?.path || "";
    //   }
    // },
  },
  // mounted() {
  //   if (!this.isMobile) {
  //     document.addEventListener("mousedown", this.handleClickOutside);
  //   }
  // },
  // beforeDestroy() {
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // },
};
</script>

<style lang="scss" scoped>
.bottom-navigation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  width: 100%;
  background-color: $gray1;
  border-top: 1px solid $gray3;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  height: 56px;
  &.poincampus {
    grid-template-columns: repeat(3, 1fr);
  }
  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
