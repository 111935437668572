import isEmpty from "lodash.isempty";
import globalStore from "@/store";

const selector = {
  getCognitoId: (state) => state.cognitoId,
  getUser: (state) => state.user || null,
  getUserUuid: (state) => {
    return state.userUuid;
  },
  getUserEmail: (state) => {
    if (state.user) {
      return state.user.email;
    }
    return "";
  },
  getSnsType: (state) => {
    if (state.user) {
      return state.user.snsType;
    }
    return "";
  },
  getSnsTypes: (state) => {
    if (state.user) {
      if (state.user.snsTypes && state.user.snsTypes.length > 1) {
        return state.user.snsTypes;
      }
      return [state.user.snsType];
    }
    return [];
  },
  getInstructor: (state) => state.instructor || null,
  isInstructor: (state) => {
    if (!isEmpty(state.instructor)) {
      return true;
    }
    return false;
  },
  isCampusPlan: (state) => {
    if (!isEmpty(state.user)) {
      return state.user.campusYn === true;
    }
    return false;
  },
  isReqDeleteUser: (state) => {
    if (state.user) {
      return !isEmpty(state.user.delDttm);
    }
    return false;
  },
  hasMembership: (state) => {
    const authenticated = globalStore.getters["auth/getAuthenticated"];
    if (
      !authenticated ||
      !state.user ||
      !state.user.memberShip ||
      !state.user.memberShip.endDate
    ) {
      return false;
    }

    const now = new Date();
    const endDate = new Date(state.user.memberShip.endDate);
    endDate.setTime(endDate.getTime() + 7 * 60 * 60 * 1000);
    return now <= endDate;
  },
  membership: (state) => {
    if (state.user && state.user.memberShip) {
      return state.user.memberShip;
    }
    return null;
  },
  getDeleteStartDate: (state) => {
    if (selector.isReqDeleteUser(state)) {
      return state.delDttm;
    }
    return "";
  },
  getUserSubscrCnt: (state) => state.user.subscrCnt,
  getInstructorSubscrCnt: (state) => state.instructor.subscrCnt,
  getSubscriptions: (state) => state.subscriptions,
  getInstructorAccounts: (state) => state.instructor.accounts,
};

export default selector;
