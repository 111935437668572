import { Role } from "@/enums/membersEnum";

export default {
  /* 캠퍼스 정보 */
  getCampusInfo: (state) => {
    return state.campusInfo;
  },
  getCampusUuid: (state) => {
    return state.campusInfo?.campusId || "";
  },
  getCampusDomain: (state) => {
    return state.campusInfo?.domain || "";
  },
  campusOwnerInfo: (state) => {
    if (state.campusInfo && state.campusInfo.regUser) {
      return state.campusInfo.regUser;
    }
    return {};
  },
  isCampusOwner: (_, __, ___, rootGetters) => {
    return rootGetters["members/getMemberRoleCd"] === Role.A;
  },
  /* 상단 메뉴 */
  getMenus(state) {
    const menus = state.campusInfo?.MENUS?.map((menu) => menu) || [];
    const group = ["HOME", "INTRO"]; // 현재 없는 메뉴, 임시 제거
    return menus
      .filter((menu) => !group.includes(menu.menuType))
      ?.sort((a, b) => a.seq - b.seq);
  },
  getRoutes(_, getters) {
    const domain = getters.getCampusDomain;
    const campusId = getters.getCampusUuid;
    const menus = getters.getMenus;
    const isCustomLink = (menuType) => menuType === "CUSTOM";
    const isCustomPage = (menuType) => menuType === "PAGE";
    const getRouteName = (menuType) => {
      switch (menuType) {
        case "PRODUCT":
          return "campus-product-home";
        case "COMMUNITY":
          return "campus-community-home";
        case "NOTICE":
          return "campus-notice-home";
        case "PAGE":
          return "campus-page";
      }
      return "campus-home";
    };
    const routes = menus
      ?.filter((menu) => !menu.delYn && menu.usageYn)
      ?.map((menu) => {
        return {
          name: menu.name,
          ...(isCustomLink(menu.menuType)
            ? { href: menu.url, newTabYn: menu?.newTabYn ?? true }
            : {
                to: {
                  name: getRouteName(menu.menuType),
                  params: {
                    campusDomain: domain || "home",
                    ...(isCustomPage(menu.menuType) && { path: menu.url }),
                  },
                  ...(!domain && {
                    query: {
                      campusUuid: campusId,
                    },
                  }),
                },
              }),
          newTab: isCustomLink(menu.menuType),
          show: true,
        };
      });
    return routes || [];
  },
  getMenuName: (_, getters) => (menuType) => {
    const menus = getters.getMenus;
    return menus.find((menu) => menu.menuType === menuType)?.name;
  },
  altProduct: (_, getters, ___, rootGetters) => {
    return rootGetters["common/campusOnly"]
      ? getters.getMenuName("PRODUCT") || "프로덕트"
      : "프로덕트";
  },
  getAltNotice: (_, getters) => {
    const altNotice = getters.getMenuName("NOTICE");
    return altNotice;
  },
  getAltCommunity: (_, getters) => {
    const altCommunity = getters.getMenuName("COMMUNITY");
    return altCommunity;
  },
  /* 요금제 */
  getPlan: (state) => {
    const plan = state.subscription?.plan || state.campusInfo?.PLAN || {};
    const usageArr = state.campusInfo?.USAGE || [];
    const usedPlan =
      state.subscription?.usedPlan || state.campusInfo?.usedPlan || {};
    const addons =
      state.subscription?.addons || state.campusInfo?.PLAN_ADDONS || [];
    const card = state.campusInfo?.card || state.subscription?.card || {};
    const usage = filterUsage(usageArr);
    return { ...plan, ...usage, usedPlan, card, addons };
  },
  getUsage: (state) => {
    const usageArr = state.campusInfo?.USAGE || [];
    /*
    {
      membersUsageYn: "멤버수 제한 여부",
      maxMembersCnt: "최대 멤버 수",
      membersCnt: "현재 멤버 수(JOIN)",
      inviteCnt: "초대된 멤버 수", // deprecated
      joinCnt: "현재 회원 수(JOIN)", // deprecated
      requestCnt: "가입 신청한 회원 수",
      volumeUsageYn: "용량 제한 여부",
      maxVolume: "최대 용량",
      volumne: "현재 용량",
    }
    */
    return filterUsage(usageArr);
  },
  getUsedPlan: (state) => {
    // 현재 사용중인 요금 플랜 정보
    return state.campusInfo?.usedPlan;
  },
  isExpired: (state) => {
    return state.campusInfo?.campusStatus !== "ENABLE";
  },
  isExceedMember: (_, getters) => {
    const usage = getters.getUsage;
    if (usage && usage.membersCnt && usage.maxMembersCnt) {
      return usage.membersCnt >= usage.maxMembersCnt;
    }
    return false;
  },
  isExceedVolume: (_, getters) => {
    const usage = getters.getUsage;
    if (usage && usage.volume && usage.maxVolume) {
      return usage.volume >= usage.maxVolume;
    }
    return false;
  },
  getUserCampusList: (state) => {
    // 본인 캠퍼스 + 소속 캠퍼스 모두 리턴. 개별로 접근해야할 필요 있는 경우 개별 getter 생성
    return [
      ...(state.userCampusList.owners || []),
      ...(state.userCampusList.joins || []),
    ];
  },
  getAllCampusList: (state) => {
    return state.userCampusList || [];
  },
  getPlanCode(_, getters) {
    return (
      getters.getPlan?.usedPlan?.planCode || getters.getPlan?.planCode || ""
    );
  },
  getPlanCycle(_, getters) {
    return getters.getPlan?.planCycle || "";
  },
  getPlanName(_, getters) {
    return getters.getPlan?.usedPlan?.name || "";
  },
  isFreePlan(_, getters) {
    return getters.getPlanCode === "FREE";
  },
  isEnterprisePlan(_, getters) {
    return getters.getPlanCode === "ENTERPRISE";
  },
  needEnterprisePlan(_, getters, ___, rootGetters) {
    const quota =
      rootGetters["plans/getPlanList"]
        ?.find((plan) => plan.planCode === "BUSINESS")
        ?.spec?.find((spec) => spec.code === "MAU")?.quota || 0;
    return getters.getCurrentMau > quota;
  },
  getCurrentMau(_, getters) {
    // TODO
    return getters.getPlan?.mauCount || 0;
  },
  isConstraint(state) {
    return state.campusInfo?.campusStatus === "CONSTRAINT";
  },
  mauConstDttm(_, getters) {
    return getters.getPlan?.mauConstDttm;
  },
  getMauQuota(_, getters) {
    return (
      getters.getPlan?.usedPlan.spec?.find((spec) => spec.code === "MAU")
        ?.quota || 0
    );
  },
  isTrialPlan(_, getters) {
    return getters.getPlan?.trialYn || false;
  },
  isStarterPlan(_, getters) {
    return getters.getPlan?.planCode === "STARTER" || false;
  },
  mauOverused(_, getters) {
    return (
      // 무제한인 경우, mau 0
      getters.getMauQuota !== 0 && getters.getCurrentMau > getters.getMauQuota
    );
  },
  getPlanTerminationInfo(_, getters) {
    return {
      terminateYn: getters?.getPlan?.terminateYn || false,
      terminateDttm: getters?.getPlan?.terminateDttm,
    };
  },
  getChangePlan(_, getters) {
    return getters?.getPlan?.changePlan;
  },
  getPlanPaymentDttm(_, getters) {
    return getters?.getPlan?.paymentDttm;
  },
  myCampus: (state) => {
    const myCampuses = state.userCampusList?.owners || [];
    return myCampuses[0];
  },
  getJoinsCnt: (state) => {
    return state.userCampusList.joinsCnt || 0;
  },
  getOwnersCnt: (state) => {
    return state.userCampusList.ownersCnt || 0;
  },
  getRequestsCnt: (state) => {
    return state.userCampusList.requestsCnt || 0;
  },
  getDisplayCampusInfo: (state) => {
    const display = state.campusInfo?.DISPLAY;
    let banner;
    let logo;
    let favicon;
    const banners = [];
    display?.images?.forEach((image) => {
      switch (image.position) {
        case "MAIN": {
          banner = {
            key: image.key,
            path: image.path,
            ...(image.link && { link: image.link }),
            ...(image.seq && { seq: image.seq }),
            bannerLink: image.bannerLink,
            bannerThumbnail: image.bannerThumbnail,
            conts: image.conts || "",
            textColor: image.textColor || "black",
            horizontal: image.horizontal || "CENTER",
          };
          banners.push(banner);
          break;
        }
        case "LOGO": {
          logo = {
            key: image.key || "",
            path: image.path || "",
          };
          break;
        }
        case "FAVICON": {
          favicon = {
            key: image.key || "",
            path: image.path || "",
          };
          break;
        }
        // case "PWA"
        default:
          break;
      }
    });
    return {
      ...display,
      banners,
      logo,
      favicon,
    };
  },
  getSectionCampusInfo: (state) => {
    return state.campusInfo?.PRODUCT_SECTIONS || {};
  },
  getHomeTabsCampusInfo: (state) => {
    return state.campusInfo?.PRODUCT_SECTIONS?.homeSection || {};
  },
  getProductTabsCampusInfo: (state) => {
    return state.campusInfo?.PRODUCT_SECTIONS?.productSection || {};
  },
  salesYn: (state) => {
    return state.campusInfo?.salesStatus === "ENABLE" || false;
  },
  getCampusCommission: (state, getters) => {
    return `${Number(
      (
        (state.campusInfo?.PLAN?.commission ||
          getters.getSpecsDetail("COMMISSION")?.quota ||
          0) * 100
      ).toFixed(2)
    )}%`;
  },
  getCertificate: (state) => {
    return state.certificate;
  },
  getCampusOwnerId: (state) => {
    return state.campusInfo?.userId || "";
  },
  getCampusPolicies: (state) => {
    return state.campusInfo?.POLICY || [];
  },
  /* 문의 관리 발신 이메일 주소 */
  getMailAccounts: (state) => {
    return state.campusInfo?.SITE?.mailAccounts?.[0];
  },
  getCampusType: (state) => {
    return state.campusInfo?.campusType || state.campusInfo?.plan?.campusType;
  },
  isPrivateCampus: (state) => {
    return state.campusInfo?.SETTING?.classListAccess === "MEMBER";
  },
  getSpecs: (state) => {
    return state.campusInfo?.usedPlan?.spec || [];
  },
  getPlanAddons: (state) => {
    return state.campusInfo?.PLAN_ADDONS || [];
  },
  getFeatures: (_, getters) => {
    const addons = getters.getPlanAddons.map((addon) => {
      return {
        ...addon,
        code: addon.planCode,
        serveYn: !addon.delYn,
        viewYn: true,
      };
    });
    return [...addons, ...getters.getSpecs];
  },
  getSpecsDetail: (_, getters) => (code) => {
    const specs = getters.getSpecs;
    return specs.find((spec) => spec.code === code);
  },
  checkServeViewYn: (_, getters) => (code) => {
    return getters.getFeatures.some((spec) => {
      return spec.code === code && spec.serveYn && spec.viewYn;
    });
  },
  checkServeYn: (_, getters) => (code) => {
    if (getters.isConstraint) {
      return false;
    }
    return getters.getFeatures.find((spec) => spec.code === code)?.serveYn;
  },
  checkViewYn: (_, getters) => (code) => {
    return getters.getFeatures.find((spec) => spec.code === code)?.viewYn;
  },
  checkAddonServeYn: (_, getters) => (code) => {
    const addons = [
      "QUIZ",
      "CODING",
      "PRODUCT_BASIC",
      "AI_INSTRUCTOR",
      "CERTIFICATE",
      "EXTRA_VOLUME",
      "APPLICATION",
      "ETC_PAY",
    ];

    if (!addons.includes(code)) {
      return true;
    }

    return getters.getFeatures.find((spec) => spec.code === code)?.serveYn;
  },
  getPopups(state) {
    const popups = state.campusInfo?.POPUPS?.map((popup) => popup) || [];
    return popups.sort((a, b) => a.seq - b.seq);
  },
  getDisplayPopups(_, getters) {
    const validDttm = (popup = {}) => {
      const none = 99999999999999;
      const startDttm = popup.startDttm
        ? new Date(popup.startDttm).getTime()
        : 99999999999999;
      const endDttm = popup.endDttm
        ? new Date(popup.endDttm).getTime()
        : 99999999999999;
      const today = Date.now();
      if (startDttm === none && endDttm === none) {
        return true;
      }
      if (startDttm < today && today < endDttm) {
        return true;
      }
      if (startDttm === none && today < endDttm) {
        return true;
      }
      return false;
    };
    return getters.getPopups?.filter(
      (popup) => popup.usageYn && validDttm(popup)
    );
  },
  onboardingTodosProduct(state) {
    const todos = [
      {
        title: "기본 정보",
        link: "campus-admin-product-detail-settings",
        value: "default",
        code: "ONBOARDING_PRODUCT_SETTING_DEFAULT",
        seq: 11,
      },
      {
        title: "프로덕트 소개",
        link: "campus-admin-product-detail-settings",
        value: "introduction",
        code: "ONBOARDING_PRODUCT_SETTING_INTRO",
        seq: 12,
      },
      {
        title: "판매 정보",
        link: "campus-admin-product-detail-settings",
        value: "sales",
        code: "ONBOARDING_PRODUCT_SETTING_SALES",
        seq: 13,
      },
      {
        title: "콘텐츠",
        link: "campus-admin-product-detail-contents",
        code: "ONBOARDING_PRODUCT_CONTENTS",
        seq: 14,
      },
      {
        title: "콘텐츠 구성",
        link: "campus-admin-product-detail-contents-edit",
        code: "ONBOARDING_PRODUCT_CONTENTS_EDIT",
        seq: 15,
      },
    ];
    return todos.filter(
      (todo) => !state.campusInfo?.spec.some((s) => s.code === todo.code)
    );
  },
  onboardingTodosCampus(state) {
    const todos = [
      {
        title: "사이트 이미지 설정",
        link: "campus-admin-logo",
        code: "ONBOARDING_CAMPUS_LOGO",
        seq: 1,
      },
      {
        title: "사이트 배너 구성",
        link: "campus-admin-banner",
        code: "ONBOARDING_CAMPUS_BANNER",
        seq: 2,
      },
      {
        title: "판매 프로덕트 만들기",
        link: "campus-admin-product-sales",
        code: "ONBOARDING_CAMPUS_PRODUCT",
        seq: 3,
      },
      {
        title: "사업자정보 설정",
        link: "campus-admin-footer",
        code: "ONBOARDING_CAMPUS_FOOTER",
        seq: 4,
      },
      {
        title: "판매 신청",
        link: "campus-admin-sales",
        code: "ONBOARDING_CAMPUS_SALES",
        seq: 5,
      },
    ];
    return todos.filter(
      (todo) => !state.campusInfo?.spec.some((s) => s.code === todo.code)
    );
  },
  getTempVolumes(state) {
    return state.tempVolumes;
  },
};

function filterUsage(usageArr) {
  if (!usageArr) return null;
  let result = {};
  usageArr.forEach((item) => {
    const volEtc =
      item.usage - item.PRODUCT - item.CONTENTS - item.ARTICLE - item.POST;
    switch (item.usageCode) {
      case "MEMBERS":
        result.maxMembersCnt = item.usageYn ? item.quota : null;
        result.membersCnt = item.usage;
        // usageYn이 제대로 체크되지 않고 있어, quota 기준으로 판단 (@lars. 2022.11.25. 10:42)
        result.membersUsageYn = item.usageYn && item.quota > 0;
        result.requestCnt = item.membersCnt?.requestCnt || 0;
        result = { ...result };
        break;
      case "VOLUME":
        result.maxVolume = item.quota;
        result.volume = item.usage || 0;
        result.volumeUsageYn = item.usageYn && item.quota > 0;
        result.volProducts = item.PRODUCT || 0;
        result.volContents = item.CONTENTS || 0;
        result.volArticles = item.ARTICLE || 0;
        result.volPosts = item.POST || 0;
        result.volEtc = volEtc > 0 ? volEtc : 0;
        break;
    }
  });
  return result;
}
