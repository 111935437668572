<template>
  <i :style="variables" :id="_uid" @click="$emit('click')"></i>
</template>

<script>
export default {
  name: "PoinUiIcon",
  props: {
    icon: {
      type: String,
      default: "DragSmall/Gray6",
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
  computed: {
    variables() {
      return {
        "--size": `${this.size}px`,
        "--background-image": `url("${this.iconSrc}")`,
      };
    },
    iconSrc() {
      return this.icon
        ? `https://file.poincampus.com/icons/${this.icon}.svg`
        : "";
    },
  },
};

/* guide */
// https://www.figma.com/file/sb6Top2WrHoaivgOrE9tki/%EC%95%84%EC%9D%B4%EC%BD%98-%EC%A0%95%EB%A6%AC?node-id=0%3A1&t=7RdjhVexa3rsHisT-0

/* path list */
// AddFile/Black
// AddFile/Gray5
// AddFile/Gray8
// AddFile/White
// AlignCenter/Black
// AlignCenter/Gray5
// AlignCenter/Gray8
// AlignCenter/Primary
// AlignCenter/Secondary
// AlignCenter/White
// AlignLeft/Black
// AlignLeft/Gray5
// AlignLeft/Gray8
// AlignLeft/Primary
// AlignLeft/Secondary
// AlignLeft/White
// AlignRight/Black
// AlignRight/Gray5
// AlignRight/Gray8
// AlignRight/Primary
// AlignRight/Secondary
// AlignRight/White
// Arrows/Black
// Arrows/Gray5
// Arrows/Gray8
// Arrows/White
// ArrowSquare/Black
// ArrowSquare/Gray5
// ArrowSquare/Gray8
// ArrowSquare/White
// ArrowTurnDown/Black
// ArrowTurnDown/Gray5
// ArrowTurnDown/Gray8
// ArrowTurnDown/White
// Badge/Black
// Badge/Gray5
// Badge/Gray8
// Badge/White
// Book/Black
// Book/Gray5
// Book/Gray8
// Book/White
// Boxes/Black
// BookBookmark/Black
// BookBookmark/Gray5
// BookBookmark/Gray8
// BookBookmark/White
// BookBookmark/Primary
// Boxes/Black
// Boxes/Gray5
// Boxes/Gray8
// Boxes/White
// Calendar/Black
// Calendar/Gray5
// Calendar/Gray8
// Calendar/White
// Camera/Black
// Camera/Gray5
// Camera/Gray8
// Camera/White
// Card/Black
// Card/Gray5
// Card/Gray8
// Card/White
// CategoryArrow/Black
// CategoryArrow/Gray5
// CategoryArrow/Gray8
// CategoryArrow/White
// ChatFill/Black
// ChatFill/Gray5
// ChatFill/Gray8
// ChatFill/White
// Check/Black
// Check/White
// Check/Success
// CheckCircle
// CheckCircle/Success
// CheckCircle/Primary
// Clip/Black
// Clip/Gray5
// Clip/Gray8
// Clip/White
// ClipBoard/Black
// ClipBoard/Gray5
// ClipBoard/Gray8
// ClipBoard/White
// Close/Black
// Close/Gray5
// Close/Gray8
// Close/White
// Copy/Black
// Copy/Gray5
// Copy/Gray8
// Copy/White
// Crop/Black
// Crop/Gray5
// Crop/Gray8
// Crop/White
// Cube/Black
// Cube/Gray5
// Cube/Gray8
// Cube/White
// DeleteFill/Black
// DeleteFill/Gray5
// DeleteFill/Gray8
// DeleteFill/White
// Document/Black
// Document/Gray5
// Document/Gray8
// Document/White
// DocumentSigned/Black
// DocumentSigned/Gray5
// DocumentSigned/Gray8
// DocumentSigned/White
// Download/Black
// Download/Gray5
// Download/Gray8
// Download/White
// DownloadBold/Black
// DownloadBold/Gray5
// DownloadBold/Gray8
// DownloadBold/White
// DownSm/Black
// DownSm/Gray5
// DownSm/Gray8
// DownSm/White
// DragSmall/Gray6
// DragSmall/Black
// DragSmall/White
// Equality/Black
// Equality/Gray5
// Equality/Gray8
// Equality/White
// Excel/Black
// Excel/Gray5
// Excel/Gray8
// Excel/White
// Eye/Black
// Eye/Gray5
// Eye/Gray8
// Eye/White
// ExternalLink/Primary
// ExternalLink/Black
// ExternalLink/Gray5
// ExternalLink/Gray8
// ExternalLink/White
// File/Black
// File/Gray5
// File/Gray8
// File/White
// Filter/Black
// Filter/Gray5
// Filter/Gray8
// Filter/Primary
// Filter/Secondary
// Filter/White
// Graduate/Black
// Graduate/Gray5
// Graduate/Gray8
// Graduate/White
// Heart/Black
// Heart/Gray5
// Heart/Gray8
// Heart/White
// HeartFill/Gray5
// HeartFill/Primary
// IconView/Black
// IconView/Gray5
// IconView/Gray8
// IconView/White
// Image/Black
// Image/Gray5
// Image/Gray8
// Image/Primary
// Image/White
// Images/Black
// Images/Gray5
// Images/Gray8
// Images/White
// Info/Black
// Info/Gray5
// Info/Gray8
// Info/White
// LeftArrow/Black
// LeftArrow/Gray5
// LeftArrow/Gray8
// LeftArrow/White
// LeftSm/Black
// LeftSm/Gray5
// LeftSm/Gray8
// LeftSm/White
// ListView/Black
// ListView/Gray5
// ListView/Gray8
// ListView/White
// LockFill/Black
// LockFill/Gray5
// LockFill/Gray8
// LockFill/White
// Marker/Black
// Marker/Gray5
// Marker/Gray8
// Marker/White
// Marker/Primary
// MegaPhone/Black
// MegaPhone/Gray5
// MegaPhone/Gray8
// MegaPhone/White
// Menu/Black
// Menu/Gray5
// Menu/Gray8
// Menu/White
// Minus/Black
// Minus/Gray5
// Minus/Gray8
// Minus/White
// More
// Noti/Black
// Noti/Gray5
// Noti/Gray8
// Noti/White
// Plus/Black
// Plus/Gray5
// Plus/Gray8
// Plus/White
// Quit/Black
// Quit/Gray5
// Quit/Gray8
// Quit/White
// Reset/Black
// Reset/Gray5
// Reset/Gray8
// Reset/White
// RightArrow/Black
// RightArrow/Gray5
// RightArrow/Gray8
// RightArrow/White
// RightSm/Black
// RightSm/Gray5
// RightSm/Gray8
// RightSm/White
// Rotate/Black
// Rotate/Gray5
// Rotate/Gray8
// Rotate/White
// RotateLeft/Black
// RotateLeft/Gray5
// RotateLeft/Gray8
// RotateLeft/White
// Runner/Primary
// Reply/Default
// Reply/Disabled
// Reply/Hover
// Reply/Loading
// Search/Black
// Search/Gray5
// Search/Gray8
// Search/White
// Setting/Black
// Setting/Gray5
// Setting/Gray8
// Setting/White
// Share/Black
// Share/Gray5
// Share/Gray8
// Share/White
// Shop/Black
// Shop/Gray5
// Shop/Gray8
// Shop/White
// Smile/Black
// Smile/Gray5
// Smile/Gray8
// Smile/White
// Star/Black
// Star/Gray5
// Star/Gray8
// Star/White
// StarFill/Default
// StarFill/Gray5
// TextAlignCenter/Black
// TextAlignCenter/Gray5
// TextAlignCenter/Gray8
// TextAlignCenter/White
// TextAlignLeft/Black
// TextAlignLeft/Gray5
// TextAlignLeft/Gray8
// TextAlignLeft/White
// TextAlignRight/Black
// TextAlignRight/Gray5
// TextAlignRight/Gray8
// TextAlignRight/White
// TextDown/Gray5
// TextDown/Primary
// TextItalic/Black
// TextItalic/Gray5
// TextItalic/Gray8
// TextItalic/White
// TextMiddle/Gray5
// TextMiddle/Primary
// TextStriketthrought/Black
// TextStriketthrought/Gray5
// TextStriketthrought/Gray8
// TextStriketthrought/White
// TextUnderline/Black
// TextUnderline/Gray5
// TextUnderline/Gray8
// TextUnderline/White
// TextUp/Gray5
// TextUp/Primary
// Trash/Black
// Trash/Gray5
// Trash/Gray8
// Trash/White
// Upload
// UpSm/Black
// UpSm/Gray5
// UpSm/Gray8
// UpSm/White
// Write/Black
// Write/Gray5
// Write/Gray8
// Write/White
// Zoom/Black
// Zoom/Gray5
// Zoom/Gray8
// Zoom/White
// ZoomOut/Black
// ZoomOut/Gray5
// ZoomOut/Gray8
// ZoomOut/White
// Service/AI
// Service/Application
// Service/Cloud
// Service/Coding
// Service/Diploma
// Service/EtcPayment
// Service/ManagementProduct
// Service/Quiz

// Users/Black
// Users/Gray5
// Users/Gray8
// Users/White
// Users/Primary
// UserGear/Black
// UserGear/Gray5
// UserGear/Gray8
// UserGear/White
// UserGear/Primary
// ShoppingBag/Black
// ShoppingBag/Gray5
// ShoppingBag/Gray8
// ShoppingBag/White
// ShoppingBag/Primary
// PhoneCall/Black
// PhoneCall/Gray5
// PhoneCall/Gray8
// PhoneCall/White
// PhoneCall/Primary
// Palette/Black
// Palette/Gray5
// Palette/Gray8
// Palette/White
// Palette/Primary
// MemoCheck/Black
// MemoCheck/Gray5
// MemoCheck/Gray8
// MemoCheck/White
// MemoCheck/Primary
// Megaphone/Black
// Megaphone/Gray5
// Megaphone/Gray8
// Megaphone/White
// Megaphone/Primary
// Marketing/Black
// Marketing/Gray5
// Marketing/Gray8
// Marketing/White
// Marketing/Primary
// Globe/Black
// Globe/Gray5
// Globe/Gray8
// Globe/White
// Globe/Primary
// Folder/Black
// Folder/Gray5
// Folder/Gray8
// Folder/White
// Folder/Primary
// Communication/Black
// Communication/Gray5
// Communication/Gray8
// Communication/White
// Communication/Primary
// CommentUser/Black
// CommentUser/Gray5
// CommentUser/Gray8
// CommentUser/White
// CommentUser/Primary
// Cloud/Black
// Cloud/Gray5
// Cloud/Gray8
// Cloud/White
// Cloud/Primary
// CalendarPen/Black
// CalendarPen/Gray5
// CalendarPen/Gray8
// CalendarPen/White
// CalendarPen/Primary
// CalendarStar/Black
// CalendarStar/Gray5
// CalendarStar/Gray8
// CalendarStar/White
// CalendarStar/Primary
// BrowserPencil/Black
// BrowserPencil/Gray5
// BrowserPencil/Gray8
// BrowserPencil/White
// BrowserPencil/Primary
// Browser/Black
// Browser/Gray5
// Browser/Gray8
// Browser/White
// Browser/Primary
// BoxOpen/Black
// BoxOpen/Gray5
// BoxOpen/Gray8
// BoxOpen/White
// BoxOpen/Primary
// Apps/Primary
// Apps/Black
// Apps/Gray5
// Apps/Gray8
// Apps/White
// Post/Primary
// Post/Black
// Post/Gray5
// Post/Gray8
// Post/White

// Text
// Button
// Image
// Horizontal
// Vertical
// Video
// Line
// Space
// Product
// ArrowFree
// SNS
// Bold/Black
// Bold/White
// Bold/Gray5
// Bold/Gray8
// Bold/Primary
// Chart/Black
// Chart/White
// Chart/Gray5
// Chart/Gray8
// Chart/Primary
// Desktop/Black
// Desktop/White
// Desktop/Gray5
// Desktop/Gray8
// Desktop/Primary
// Image/Black
// Image/White
// Image/Gray5
// Image/Gray8
// Image/Primary
// Italic/Black
// Italic/White
// Italic/Gray5
// Italic/Gray8
// Italic/Primary
// Link/Black
// Link/White
// Link/Gray5
// Link/Gray8
// Link/Primary
// ListDocument/Black
// ListDocument/White
// ListDocument/Gray5
// ListDocument/Gray8
// ListDocument/Primary
// Marker/Black
// Marker/White
// Marker/Gray5
// Marker/Gray8
// Marker/Primary
// MergeTag/Black
// MergeTag/White
// MergeTag/Gray5
// MergeTag/Gray8
// MergeTag/Primary
// Mobile/Black
// Mobile/White
// Mobile/Gray5
// Mobile/Gray8
// Mobile/Primary
// Smile/Black
// Smile/White
// Smile/Gray5
// Smile/Gray8
// Smile/Primary
// Strikethrough/Black
// Strikethrough/White
// Strikethrough/Gray5
// Strikethrough/Gray8
// Strikethrough/Primary
// Underline/Black
// Underline/White
// Underline/Gray5
// Underline/Gray8
// Underline/Primary
// UnorderedList/Black
// UnorderedList/White
// UnorderedList/Gray5
// UnorderedList/Gray8
// UnorderedList/Primary
// UnorderedListNumber/Black
// UnorderedListNumber/White
// UnorderedListNumber/Gray5
// UnorderedListNumber/Gray8
// UnorderedListNumber/Primary
// SocialUrl/Website
// SocialUrl/Instagram
// SocialUrl/Naver
// SocialUrl/Kakao
// SocialUrl/Facebook
</script>

<style lang="scss" scoped>
$width: var(--size);
$height: var(--size);
$url: var(--background-image);

i {
  display: inline-block;
  width: $width;
  height: $height;
  background-image: $url;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
