<template>
  <div class="onboarding__container" v-if="showOnboarding">
    <template v-if="loading">
      <div class="d-flex-column w-full gap-3">
        <div class="skeleton--title skeleton"></div>
        <div class="skeleton--desc1 skeleton"></div>
        <div class="skeleton--desc2 skeleton"></div>
      </div>
      <div class="skeleton--progress skeleton"></div>
      <div class="skeleton--stepper skeleton"></div>
    </template>
    <template v-else>
      <div class="d-flex-column gap-3 w-full">
        <div class="d-flex align-center w-full">
          <h3 class="black--text text-h3-bold">{{ title }}</h3>
          <p-link
            class="ml-auto"
            color="gray"
            v-if="showCompleteBtn"
            @click="onComplete"
            >완료하기</p-link
          >
        </div>
        <p class="text-body-1-regular gray--text text--darken-3">
          {{ description }}
        </p>
        <p v-if="information" class="text-body-1-regular info--text">
          {{ information }}
        </p>
      </div>
      <v-progress-linear
        class="w-full"
        background-color="gray lighten-4"
        color="primary"
        :value="progress"
        height="8"
        rounded
      />
      <div class="d-flex align-center flex-wrap">
        <div
          class="d-flex align-center flex-wrap"
          v-for="(todo, index) in todos"
          :key="index"
        >
          <p-icon
            icon="RightSm/Gray8"
            size="24"
            v-if="index > 0"
            class="mx-2"
          />
          <div class="d-flex align-center gap-1">
            <p-icon
              :icon="
                getChecked(todo) ? 'ProgressComplete' : 'ProgressIncomplete'
              "
              size="20"
            />
            <router-link
              class="todo-link"
              :class="{ 'todo-link--current': isCurrent(todo.link) }"
              :to="{ name: todo.link }"
              >{{ todo.title }}</router-link
            >
          </div>
        </div>
      </div>
      <v-expand-transition>
        <div
          class="d-flex align-center w-full gray lighten-4 rounded"
          v-if="isComplete"
        >
          <p class="text-body-2-regular black--text pa-3">
            ✅ 현재 단계를 완료했습니다. 다음 단계를 진행해보세요!
          </p>
        </div>
      </v-expand-transition>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OnboardingCampus",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      display: "campuses/getDisplayCampusInfo",
      campusInfo: "campuses/getCampusInfo",
    }),
    showOnboarding() {
      return (
        this.campusInfo?.spec?.find((s) => s.code === "ONBOARDING_CAMPUS")
          ?.quota !== 1
      );
    },
    showFab() {
      const routes = [
        ...this.todos.map((todo) => todo.link),
        "campus-admin-dashboard",
      ];
      return this.showOnboarding && !routes.includes(this.$route.name);
    },
    title() {
      switch (this.$route.name) {
        case "campus-admin-logo":
          return "1. 사이트 이미지 설정";
        case "campus-admin-banner":
          return "2. 사이트 배너 구성";
        case "campus-admin-product-sales":
          return "3. 판매 프로덕트 만들기";
        case "campus-admin-footer":
          return "4. 사업자정보 설정";
        case "campus-admin-sales":
          return "5. 판매 신청하기";
        default:
          return "개설을 축하합니다!";
      }
    },
    description() {
      switch (this.$route.name) {
        case "campus-admin-logo":
          return "사이트에서 보이는 파비콘과 로고입니다. 설정을 완료했다면 우측 상단에 있는 [저장] 버튼을 눌러주세요!";
        case "campus-admin-banner":
          return "접속한 유저에게 가장 먼저 보이는 사이트 배너입니다. 미리 보기를 통해 실제로 어떻게 보이는지 확인할 수 있어요. 설정을 완료했다면 우측 상단에 있는 [저장] 버튼을 눌러주세요!";
        case "campus-admin-product-sales":
          return "판매 프로덕트에 콘텐츠를 구성하고 정보를 설정해서 회원들에게 판매할 수 있습니다. 우측 상단의 [프로덕트 만들기]를 클릭해서 판매 프로덕트를 만들어보세요.";
        case "campus-admin-footer":
          return "온라인 판매를 하는 경우, 구매자가 사업자의 신원을 확인할 수 있도록 사업자정보가 노출돼야 합니다. 사업자정보를 입력하고 우측 상단에 있는 [저장] 버튼을 눌러주세요!";
        case "campus-admin-sales":
          return "판매 프로덕트 구성을 완료했나요? 이제 마지막으로 판매 신청을 진행해보세요. 판매 승인이 완료되면 프로덕트를 판매할 수 있습니다. 판매 신청에 관한 정보를 입력하고 [신청하기] 버튼을 눌러주세요!";
        default:
          return "어디서부터 시작해야 할지 막막한가요? 아래의 순서에 따라서 차근차근 나만의 사이트를 꾸며봐요!";
      }
    },
    information() {
      switch (this.$route.name) {
        case "campus-admin-logo":
          return "* 아직 이미지가 없다면 나중에 설정해도 돼요! 우선 [저장] 버튼을 눌러 다음 순서를 진행해보세요.";
        case "campus-admin-banner":
          return "* 아직 이미지가 없다면 나중에 설정해도 돼요! 우선 [저장] 버튼을 눌러 다음 순서를 진행해보세요.";
        case "campus-admin-product-sales":
          return "";
        case "campus-admin-footer":
          return "* 사업자정보는 법적 필수 정보이기 때문에 입력이 꼭 필요해요. 아직 입력할 정보가 없다면, 우선 [저장]을 눌러 단계를 진행해보세요! '판매 신청'을 진행하면, 포인캠퍼스에서 안내를 도와드릴거에요!";
        case "campus-admin-sales":
          return this.progress === 80 &&
            this.campusInfo?.salesStatus !== "ENABLE"
            ? "* 판매를 진행하지 않으면 우측 상단의 [완료하기] 버튼을 눌러주세요."
            : "";
        default:
          return "";
      }
    },
    progress() {
      const a = this.todos?.length || 0;
      const b =
        this.todos?.filter((todo) => this.getChecked(todo))?.length || 0;
      if (a && b) {
        return (b / a) * 100;
      }
      return 0;
    },
    site() {
      return this.campusInfo?.SITE || {};
    },
    showCompleteBtn() {
      return (
        this.todos.findIndex((todo) => todo.link === this.$route.name) ===
          this.todos.length - 1 &&
        this.progress === 80 &&
        this.campusInfo?.salesStatus !== "ENABLE"
      );
    },
    todos() {
      return [
        {
          title: "사이트 이미지 설정",
          link: "campus-admin-logo",
          code: "ONBOARDING_CAMPUS_LOGO",
          seq: 1,
        },
        {
          title: "사이트 배너 구성",
          link: "campus-admin-banner",
          code: "ONBOARDING_CAMPUS_BANNER",
          seq: 2,
        },
        {
          title: "판매 프로덕트 만들기",
          link: "campus-admin-product-sales",
          code: "ONBOARDING_CAMPUS_PRODUCT",
          seq: 3,
        },
        {
          title: "사업자정보 설정",
          link: "campus-admin-footer",
          code: "ONBOARDING_CAMPUS_FOOTER",
          seq: 4,
        },
        {
          title: "판매 신청",
          link: "campus-admin-sales",
          code: "ONBOARDING_CAMPUS_SALES",
          seq: 5,
        },
      ];
    },
    spec() {
      return this.campusInfo?.spec || [];
    },
    currentTodo() {
      return this.todos.find((todo) => {
        if (todo.value) {
          return todo.value === this.$route.query?.tab;
        }
        return todo.link === this.$route.name;
      });
    },
    isComplete() {
      return (
        this.spec.find((s) => s.code === this.currentTodo?.code)?.quota === 1
      );
    },
    todoList() {
      return this.todos?.filter(
        (todo) => !this.spec.some((s) => s.code === todo.code)
      );
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler(progress) {
        if (progress === 100 && this.showOnboarding) {
          this.openCompletePopup();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      reqGetProductsStaff: "products/reqGetProductsStaff",
      reqPutBasicCampusInfo: "campuses/reqPutBasicCampusInfo",
    }),
    getChecked(todo) {
      return this.spec.find((s) => s.code === todo.code)?.quota === 1;
    },
    isCurrent(link) {
      return this.$route?.name === link;
    },
    goNext() {
      this.$router.push({
        name: this.todoList?.[0]?.link,
        params: this.$route.params,
      });
    },
    async openCompletePopup() {
      const oldSpec = this.campusInfo?.spec || [];
      const result = await this.reqPutBasicCampusInfo({
        spec: [
          ...oldSpec,
          {
            title: "캠퍼스 온보딩",
            code: "ONBOARDING_CAMPUS",
            quota: 1,
            seq: 0,
          },
        ],
      });
      if (result?.success) {
        this.$eventBus.$emit("alert", {
          open: true,
          lottie: "check",
          title: "사이트 구성 완료",
          msg: "축하합니다. 사이트 구성을 모두 완료했어요! 이제부터 내가 만든 콘텐츠를 프로덕트로 만들어 마음껏 만들어보세요!",
          callback: () => {
            this.$eventBus.$emit("gtag", {
              name: "click",
              category: "onboarding_campus",
              label: "complete_1",
              value: "500",
            });
          },
        });
      }
    },
    async onComplete() {
      this.$eventBus.$emit("confirmPopup", {
        open: true,
        icon: "info",
        title: "완료하기",
        msg: "판매 신청을 진행하지 않고 시이트 설정을 완료하시겠습니까? 판매 신청은 언제든지 다시 할 수 있어요.",
        btnTxtConfirm: "완료하기",
        btnTxtCancel: "아니오",
        callback: async (confirm) => {
          if (confirm) {
            await this.openCompletePopup();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding__container {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1.5px solid $primary800;
  background: $white;
  box-shadow: 0px 4px 12px 0px rgba(146, 26, 4, 0.1);
  width: 100%;
}
.todo-link {
  font-size: 14px;
  text-decoration: underline;
  color: $gray8;
  background: $white !important;
  &:hover {
    color: $black;
  }
  &.todo-link--current {
    color: $primary;
  }
  @include hover-transition(color);
}

.skeleton--title {
  width: 320px;
  height: 36px;
  background: $gray2;
  border-radius: 16px;
}
.skeleton--desc1 {
  width: 100%;
  height: 24px;
  background: $gray2;
  border-radius: 16px;
}
.skeleton--desc2 {
  width: 50%;
  height: 24px;
  background: $gray2;
  border-radius: 16px;
}
.skeleton--progress {
  width: 100%;
  height: 8px;
  background: $gray1;
  border-radius: 4px;
}
.skeleton--stepper {
  width: 80%;
  height: 24px;
  background: $gray2;
  border-radius: 16px;
}

.skeleton {
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.onboarding--fab {
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  border: 1px solid $primary;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 12px 0px rgba(146, 26, 4, 0.1);
  backdrop-filter: blur(2px);
  position: fixed;
  right: 28px;
  bottom: 100px;
  z-index: 10;
}

.bounce {
  animation: bounce 2s infinite;
}
.heart {
  animation: heartbeat 2s infinite;
}
@keyframes bounce {
  0%,
  10%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-8px);
  }
  40% {
    transform: translateY(-4px);
  }
}
@keyframes heartbeat {
  0%,
  10%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: scale(1);
  }
  20%,
  40% {
    transform: scale(1.1);
  }
}
</style>
