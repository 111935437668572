var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{staticClass:"py-2",attrs:{"close-on-content-click":false,"offset-y":"","left":"","content-class":"menu-btn"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({staticClass:"p-btn border-box",class:[
        {
          'p-size--large': _vm.large,
          'p-size--small': _vm.small,
          'p-size--medium': !_vm.large && !_vm.small,
          'p-btn--disabled': _vm.disabled,
        },
        `p-btn--${_vm.colors.button}`,
      ],style:(_vm.variables),attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.active != _vm.active}}},on),[_c('span',{staticClass:"p-btn__content",class:{ loading: _vm.loading }},[_vm._t("default"),(!_vm.loading)?_c('p-icon',{staticClass:"rotate-icon",style:({ transform: _vm.active ? 'rotate(180deg)' : 'rotate(0deg)' }),attrs:{"icon":`DownSm/${_vm.disabled ? 'Gray5' : _vm.colors.icon}`,"size":"24"}}):_vm._e()],2),(_vm.loading)?_c('div',{staticClass:"p-btn__loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.spinnerColor,"size":"23","width":"2"}})],1):_vm._e()])]}}],null,true),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(!_vm.loading)?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.text,on:{"click":function($event){return _vm.onClick(item)}}},[_c('p',{staticClass:"text-body-2-regular"},[_vm._v(_vm._s(item.text))])])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }