<template>
  <button class="menu-button" @click.stop="callback">
    <p-icon icon="Menu/Black" />
  </button>
</template>

<script>
export default {
  name: "menu-button",
  props: {
    callback: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.menu-button {
  margin: 0;
  padding: 0;
  outline: 0;
  position: absolute;
  display: flex;
  align-items: center;
}

.menu-button img {
  width: 28px;
}
</style>
