<template lang="html">
  <div :style="customStyle" class="profile-img-container"></div>
</template>
<script>
const DEFAULT_IMAGE_PATH =
  "https://file.poincampus.com/assets/ProfileImg@2x.png";

export default {
  name: "profile-image",
  props: {
    imgPath: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "60px",
    },
    sizeMobile: {
      type: String,
      default: "30px",
    },
    borderRadius: {
      type: String,
      default: "50%",
    },
  },
  data() {
    return {};
  },
  computed: {
    getImgPath() {
      return this.imgPath
        ? 'url("' + this.imgPath + '")'
        : `url(${DEFAULT_IMAGE_PATH})`;
    },
    customStyle() {
      return {
        "--img-size": this.size,
        "--mobile-img-size": this.sizeMobile,
        "background-image": this.getImgPath,
        "--border-radius": this.borderRadius,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.profile-img-container {
  width: var(--img-size) !important;
  height: var(--img-size) !important;
  max-width: var(--img-size) !important;
  max-height: var(--img-size) !important;
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius) !important;
}

@media (max-width: $mdBreakPoint) {
  .profile-img-container {
    width: var(--mobile-img-size) !important;
    height: var(--mobile-img-size) !important;
    max-width: var(--mobile-img-size) !important;
    max-height: var(--mobile-img-size) !important;
  }
}
</style>
