<template>
  <ul class="routing-menu-mobile-view">
    <template v-for="(item, index) in routingMenuList">
      <li
        v-if="item.show"
        :key="index"
        class="routing-menu routing-menu--parent"
      >
        <a
          v-if="item.newTab"
          :href="item.href"
          class="routing-menu__item"
          :target="item.newTabYn ? '_blank' : '_self'"
        >
          {{ item.name }}
          <p-icon v-if="item.newTabYn" class="ml-2" icon="ExternalLink/Gray8" />
        </a>
        <template v-else>
          <div
            v-if="item.children"
            class="d-flex justify-space-between align-center w-full cursor-pointer routing-menu__item"
            @click.stop="showHelpChildren = !showHelpChildren"
          >
            <span :class="{ active: helpActive }">{{ item.name }}</span>
            <v-btn icon x-small
              ><v-icon size="24">{{
                showHelpChildren ? "mdi-minus" : "mdi-plus"
              }}</v-icon></v-btn
            >
          </div>
          <router-link
            v-else
            :to="item.to"
            class="routing-menu__item"
            @click.stop="$emit('close')"
          >
            {{ item.name }}
          </router-link>
        </template>
        <v-expand-transition>
          <ul
            v-if="item.children && item.children.length > 0"
            v-show="showHelpChildren"
            class="routing-menu routing-menu--sub"
          >
            <li v-for="(subItem, index) in item.children" :key="index">
              <a
                v-if="subItem.show && subItem.newTab"
                target="_blank"
                :href="subItem.path"
                class="routing-menu__item"
              >
                {{ subItem.name }}
              </a>
              <router-link
                v-else-if="subItem.show"
                :to="subItem.to"
                class="routing-menu__item"
              >
                {{ subItem.name }}
              </router-link>
            </li>
          </ul>
        </v-expand-transition>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { Authority, MemberStatus } from "@/enums/membersEnum";

export default {
  name: "Aside-Mobile-Routing-Menu",
  data() {
    return {
      showHelpChildren: false,
      helpActive: false,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (!this.$route.path.includes("help/")) {
          this.showHelpChildren = false;
          this.helpActive = false;
        } else {
          this.showHelpChildren = true;
          this.helpActive = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      campusInfo: "campuses/getCampusInfo",
      campusUuid: "campuses/getCampusUuid",
      campusDomain: "campuses/getCampusDomain",
      campusOnly: "common/campusOnly",
      userUuid: "users/getUserUuid",
      isCampusJoinMember: "members/isCampusJoinMember",
      isCampusMember: "members/isCampusMember",
      memberStatus: "members/getMemberStatus",
      memberAuthorityCodes: "members/getMemberAuthorityCodes",
      isPoinWebview: "common/isPoinWebview",
      myCampus: "campuses/myCampus",
      menus: "campuses/getRoutes",
      isStaff: "members/isStaff",
    }),
    routingMenuList() {
      // 캠퍼스 진입 후
      // 캠퍼스홈
      const options = {
        params: { campusDomain: this.campusDomain || "home" },
        ...(!this.campusDomain && {
          query: {
            campusUuid: this.campusUuid,
          },
        }),
      };
      if (this.campusOnly && this.menus?.length > 0) {
        return [
          ...this.menus,
          {
            name: "고객센터",
            to: {
              name: "campus-help-faq",
              ...options,
            },
            show: true,
            children: [
              {
                name: "자주묻는질문",
                show: true,
                to: { name: "campus-help-faq", ...options },
              },
              {
                name: "문의하기",
                show: true,
                to: { name: "campus-help-ask", ...options },
              },
            ],
          },
        ];
      } else if (this.campusOnly) {
        return [
          {
            name: "프로덕트",
            to: { name: "campus-product-home", ...options },
            ...options,
            show: true,
          },
          {
            name: "커뮤니티",
            to: { name: "campus-community-home", ...options },
            show: true,
            // show: this.isCampusJoinMember,
          },
          {
            name: "공지사항",
            to: { name: "campus-notice-home", ...options },
            ...options,
            show: true,
          },
          {
            name: "고객센터",
            to: {
              name: "campus-help-faq",
              ...options,
            },
            show: true,
            children: [
              {
                name: "자주묻는질문",
                show: true,
                to: { name: "campus-help-faq", ...options },
              },
              {
                name: "문의하기",
                show: true,
                to: { name: "campus-help-ask", ...options },
              },
            ],
          },
          {
            name: "캠퍼스 관리",
            to: { name: "campus-admin-dashboard", ...options },
            category: "admin",
            show: this.isStaff,
          },
        ];
      }

      // 포인 캠퍼스
      return [
        {
          name: "기능",
          show: true,
          to: { name: "poin-features" },
        },
        {
          name: "요금 플랜",
          show: !this.isPoinWebview,
          to: { name: "poin-pricing" },
        },
        {
          name: "부가 서비스",
          show: !this.isPoinWebview,
          to: { name: "poin-addons" },
        },
        {
          name: "가치 제안",
          show: !this.isPoinWebview,
          to: { name: "poin-promotion" },
        },
        {
          name: "바우처",
          show: !this.isPoinWebview,
          to: { name: "poin-voucher-home" },
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.routing-menu-mobile-view {
  .routing-menu {
    font-weight: 400;
    padding: 12px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;

    .routing-menu__item {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black;
      display: flex;
      align-items: center;
      &:hover {
        color: $grey6;
      }

      &.router-link-exact-active {
        color: $coral;
      }
    }

    &.routing-menu--sub {
      padding-top: 12px;
      padding-bottom: 0;

      .routing-menu__item {
        padding: 4px 0 4px 16px;
        font-size: 16px;
        &.router-link-exact-active {
          font-weight: 600;
          color: $black;
        }
      }
    }
  }
  .active {
    color: $coral;
  }
}
</style>
