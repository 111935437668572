var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"icon-btn",class:{
    'p-size--large': _vm.large,
    'p-size--medium': _vm.medium,
    'p-size--small': _vm.small,
    'p-size--x-small': _vm.xSmall,
    'p-size--xx-small': _vm.xxSmall,
    'p-btn--outlined': _vm.outlined,
    'p-btn--disabled': _vm.disabled,
    hover: _vm.hover,
  },attrs:{"disabled":_vm.disabled},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('i',{style:(_vm.variables),attrs:{"id":_vm._uid}}),(_vm.tooltip)?_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%"},attrs:{"hover-tooltip":_vm.tooltip,"tooltip-position":"bottom"}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }