<template>
  <div :class="{ campus: campusOnly }" class="logo__wrapper" @click="toHome()">
    <img v-if="logoSrc" :src="logoSrc" class="logo-img" contain />
    <h1 v-else class="logo-text single-line">{{ campusName }}</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import globalMixin from "@/mixins/global";

export default {
  name: "poin-logo",
  mixins: [globalMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      campusInfo: "campuses/getCampusInfo",
      campusOnly: "common/campusOnly",
      display: "campuses/getDisplayCampusInfo",
    }),
    logoSrc() {
      if (this.campusOnly && this.campusInfo) {
        return this.display?.logo?.path || "";
      }
      return "https://file.poincampus.com/assets/images/PoinCampusLogo.png";
    },
    campusName() {
      if (this.campusInfo) {
        return this.campusInfo.name;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "S-CoreDream-8Heavy";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.logo__wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 45%;

  .logo-img {
    box-sizing: border-box;
    height: 32px;
    width: auto;
    max-width: 100%;
    padding-bottom: 4px;
    object-fit: contain;
  }

  .logo-text {
    font-size: 22px;
    width: 100%;
    max-width: 360px;
    font-family: "S-CoreDream-8Heavy", sans-serif !important;
    padding-top: 4px;
  }

  &.campus {
    .logo-img {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: $mdBreakPoint) {
  .logo__wrapper {
    .logo-img {
      height: 28px;
    }
  }
}
</style>
