import globalStore from "@/store";
import router from "@/router";
import { path } from "@/utils";

const normalize = (arr, identity = "id") => {
  if (arr && Array.isArray(arr)) {
    const item = {};
    arr.forEach((data) => {
      item[data[identity]] = { ...data };
    });
    return item;
  }
  return [];
};

const toCampusHome = (
  campus = globalStore.getters["campuses/getCampusInfo"],
  replaceYn = false
) => {
  const route = {
    name: "campus-home",
    params: {},
    query: {},
  };
  if (campus.domain) {
    route.params.campusDomain = campus.domain;
  } else {
    route.params.campusDomain = "home";
    route.query.campusUuid = campus.campusId || campus.id;
  }
  replaceYn ? router.replace(route) : router.push(route);
};

const toCampusJoin = (
  campus = globalStore.getters["campuses/getCampusInfo"],
  replaceYn = false,
  to
) => {
  const route = {
    name: globalStore.getters["users/getUserUuid"]
      ? "campus-member-join"
      : "campus-login-select",
    params: {},
    query: {},
  };
  if (campus.domain) {
    route.params.campusDomain = campus.domain;
  } else {
    route.params.campusDomain = "home";
    route.query.campusUuid = campus.campusId || campus.id;
  }
  const locationPath = globalStore.getters["common/getLocationPath"];
  if (!locationPath && to?.fullPath) {
    globalStore.dispatch("common/setLocationPath", to.fullPath);
  }
  replaceYn ? router.replace(route) : router.push(route);
};

const putCampusInfoHandleError = (exception) => {
  let msg;
  let fn;
  switch (exception) {
    case "UNAUTHORIZE":
      msg = "접근 권한이 없습니다.";
      fn = toCampusHome;
      break;
    case "BAD_REQUEST":
      msg = "오류가 발생했습니다. 다시 시도해주세요.";
      break;
    case "REQUEST_NOT_FOUND":
      msg = "캠퍼스 정보를 찾을 수 없습니다.";
      break;
    case "DUPLICATE":
      msg = "중복된 데이터가 이미 존재합니다.";
      break;
    case "NOT_SUPPORTED":
      msg +=
        "<p>해당 기능을 지원하지 않는 요금제입니다.</p><p>요금 플랜을 업그레이드 해주세요.</p>";
      break;
    default:
      msg = "데이터를 불러올 수 없습니다.";
      fn = toCampusHome;
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
  if (fn) {
    fn();
  }
};

const postMemberHandleError = (exception) => {
  let msg;
  let fn;
  switch (exception) {
    case "MemberQuotaExceedError":
      msg = "멤버 정원을 초과했습니다.";
      break;
    case "PlanExpiredError":
      msg = "사용 중지 중입니다.";
      break;
    case "RequestNotFoundError":
      msg = "요금제 정보가 없습니다.";
      break;
    case "ExistUserError":
    case "CUSTOMER_ALREADY_ATTEND_ERROR":
      msg = "이미 참여중인 사용자입니다.";
      break;
    case "INVALID_REQUEST_ERROR":
      msg = "잘못된 요청입니다.";
      break;
    case "UNAUTHORIZE":
      msg = "멤버 관리 권한이 없습니다.";
      fn = toCampusHome;
      break;
    default:
      msg = "다시 시도해주세요.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
  if (fn) {
    fn();
  }
};

const deletePostsHandleError = (result) => {
  let msg;
  switch (result.name) {
    case "RequestNotFoundError":
      msg = "존재하지 않는 포스트입니다.";
      break;
    case "UnauthorizedError":
    case "UNAUTHORIZE":
      msg = "포스트 관리 권한이 없습니다.";
      break;
    default:
      msg = "오류가 발생했습니다.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
};

const reviewPostHandleError = (result) => {
  let msg;
  switch (result.name) {
    case "UNAUTHORIZE":
      msg = "댓글 작성 권한이 없습니다.";
      break;
    case "BAD_REQUEST":
    case "REQUEST_NOT_FOUND":
    default:
      msg = "댓글을 작성할 수 없습니다.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
};
const reviewPutHandleError = (result) => {
  let msg;
  switch (result.name) {
    case "UNAUTHORIZE":
      msg = "댓글 수정 권한이 없습니다.";
      break;
    case "BAD_REQUEST":
    case "REQUEST_NOT_FOUND":
    default:
      msg = "댓글을 수정할 수 없습니다.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
};
const reviewDelHandleError = (result) => {
  let msg;
  switch (result.name) {
    case "UNAUTHORIZE":
      msg = "댓글 삭제 권한이 없습니다.";
      break;
    case "BAD_REQUEST":
    case "REQUEST_NOT_FOUND":
    default:
      msg = "댓글을 삭제할 수 없습니다.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
};

const ratingHandleError = (result) => {
  let msg;
  switch (result.name) {
    case "UNAUTHORIZE":
      msg = "별점 등록 권한이 없습니다.";
      break;
    case "InvalidRequestError":
      msg = "별점은 0점 ~ 5점 이내로만 등록할 수 있습니다.";
      break;
    case "BAD_REQUEST":
    case "REQUEST_NOT_FOUND":
    default:
      msg = "별점을 등록할 수 없습니다.";
      break;
  }
  globalStore.dispatch("common/setAlert", {
    open: true,
    msg,
  });
};

const toLoginPath = () => {
  const campusOnly = globalStore.getters["common/campusOnly"];
  const campusDomain = globalStore.getters["campuses/getCampusDomain"];
  if (!path.checkLoginPath()) {
    globalStore.dispatch(
      "common/setLocationPath",
      window.location.pathname + window.location.search
    );
  }
  const route = {
    name: campusOnly ? "campus-login-select" : "login-select",
    ...(campusOnly && { params: { campusDomain } }),
  };
  router.push(route);
};

const toHome = () => {
  const campusOnly = globalStore.getters["common/campusOnly"];
  if (campusOnly) {
    toCampusHome();
  } else {
    router.push("/");
  }
};

export {
  normalize,
  putCampusInfoHandleError,
  postMemberHandleError,
  toCampusHome,
  toCampusJoin,
  deletePostsHandleError,
  reviewPostHandleError,
  reviewPutHandleError,
  reviewDelHandleError,
  ratingHandleError,
  toLoginPath,
  toHome,
};
