<template>
  <v-radio
    color="primary"
    ckass="w-fit-content"
    :off-icon="disabled || readonly ? '$radio-disabled' : '$radio-blank'"
    :on-icon="disabled || readonly ? '$radio-disabled-on' : '$radio-checked'"
    :readonly="readonly"
    :disabled="disabled"
    :value="value"
    @change="updateInput"
    hide-details
  >
    <template v-slot:label>
      <span
        v-if="label"
        class="text-body-2-regular w-fit-content"
        :class="disabled ? 'gray--text' : 'black--text'"
        >{{ label }}</span
      >
      <slot v-else />
    </template>
  </v-radio>
</template>

<script>
export default {
  name: "PoinUiRadio",
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls__ripple {
    opacity: 0;
  }
  .v-icon__component,
  .v-icon__svg {
    max-height: 20px;
    max-width: 20px;
  }
}
</style>
