var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header__container border-box"},[_c('div',{staticClass:"header__wrap"},[_c('poin-logo',{staticClass:"mr-10"}),_c('routing-menu',{staticClass:"mr-auto"}),(_vm.campusOnly)?[(!_vm.authenticated)?_c('div',{staticClass:"login__wrap"},[_c('span',{on:{"click":_vm.logInOut}},[_vm._v(_vm._s(_vm.text.logIn))]),_vm._v(" | "),_c('span',{on:{"click":_vm.toJoinPath}},[_vm._v(_vm._s(_vm.text.join))])]):[(_vm.isStaff)?_c('router-link',{staticClass:"playlist-btn",attrs:{"to":{
            name: 'campus-admin-dashboard',
            params: _vm.$route.params,
          }}},[_c('p-icon',{staticClass:"mr-1",attrs:{"icon":"Setting/Black","size":"20"}}),_vm._v("사이트 관리")],1):(_vm.lastAccCont)?_c('router-link',{staticClass:"playlist-btn",attrs:{"to":{
            name: 'campus-playground',
            params: { ..._vm.$route.params, ..._vm.lastAccCont },
            query: _vm.$route.query,
          }}},[_c('p-icon',{staticClass:"mr-1",attrs:{"icon":"Eye/Black","size":"20"}}),_vm._v("이어보기")],1):_vm._e(),(!_vm.isStaff)?_c('router-link',{staticClass:"playlist-btn",attrs:{"to":{
            name: 'campus-playlist',
            params: { ..._vm.$route.params, campusDomain: _vm.domain },
            query: _vm.$route.query,
          }}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"20px"}},[_c('img',{staticStyle:{"width":"16px","object-fit":"contain"},attrs:{"src":"https://file.poincampus.com/assets/nav/playlist.png","alt":"playlist"}})]),_vm._v(" 참여현황")]):_vm._e(),_c('user-profile')]]:[_c('router-link',{staticStyle:{"all":"unset"},attrs:{"to":{ name: 'campus-register' }}},[(!_vm.loading && !_vm.campusYn)?_c('button',{staticClass:"campus-register-btn"},[_vm._v(" "+_vm._s(_vm.text.campusRegister)+" ")]):_vm._e()]),(!_vm.authenticated)?_c('button',{staticClass:"login-btn",on:{"click":_vm.logInOut}},[_vm._v(" "+_vm._s(_vm.text.logIn)+" ")]):_c('user-profile',{staticClass:"ml-3"})]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }