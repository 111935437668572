import { Authority } from "@/enums/membersEnum";
import { ContentType, QuestionType } from "@/enums/productsEnum";

export default (apiInstance) => ({
  async reqGetPlaylist(store, payload = {}) {
    const {
      userId = store.rootGetters["users/getUserUuid"],
      campusId,
      productType,
    } = payload;
    if (!userId) {
      return;
    }
    const result = await apiInstance.activities.getPlaylist({
      userId,
      ...(campusId && { campusId }),
      ...(productType && { productType }),
    });
    if (result.success) {
      let products = [];
      if (result?.data?.length > 0) {
        result.data.forEach((campus) => {
          const list = campus.products?.map((product) => {
            return {
              ...product.product,
              member: product.member,
              campusName: campus.name,
            };
          });
          list.forEach((item) => products.push(item));
        });
        products = products.sort((a, b) => {
          if (!a.member || !b.member) return 0;
          const sameMemberStatus =
            a.member?.memberStatus === b.member.memberStatus;
          const aExpired = new Date(a.member?.endDttm) < new Date();
          const bExpired = new Date(b.member?.endDttm) < new Date();
          const sameExpiredStatus = aExpired === bExpired;
          if (sameMemberStatus) {
            if (sameExpiredStatus) {
              return 0;
            }
            // a만 만료인 경우, b가 앞으로 오도록.
            return aExpired ? 1 : -1;
          }
          // 멤버 상태가 다른 경우, JOIN이 앞으로 오도록.
          return a.member.memberStatus === "JOIN" ? -1 : 1;
        });
      }
      store.commit("setPlaylist", products);
    }
    return result;
  },
  async reqGetContentActivities(store, payload = {}) {
    const {
      id,
      contentId,
      keyword,
      limit,
      sort,
      completeStatus,
      quizStatus,
      first,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    if (first) {
      store.commit("clearActivities");
    }
    const lastKey = store.getters["getActivitiesLastKey"];

    if (!id || !contentId) {
      return;
    }
    const result = await apiInstance.activities.getContentActivities({
      id,
      contentId,
      ...(keyword && { keyword }),
      ...(memberId && { memberId }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(campusId && { campusId }),
      ...(completeStatus && { completeStatus }),
      ...(quizStatus && { quizStatus }),
      ...(lastKey && { lastKey }),
    });

    if (result.success) {
      store.commit("setActivities", result.data);
    }

    return result;
  },
  async reqGetProductUser(store, payload = {}) {
    const {
      id,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    if (!id) {
      return;
    }
    const result = await apiInstance.activities.getProductUser({
      id,
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
    });
    if (result.success) {
      const { member, product, communities, review } = result.data;
      const newContents =
        product.CONTENTS?.map((content) => {
          const items = content.items?.map((item) => {
            const children = item.items?.map((child) => {
              const descendants = child.items?.map((descendant) => {
                const id = descendant.id;
                const upperId = descendant.upperId;
                return {
                  ...descendant,
                  ...(descendant.syncYn && descendant.origin),
                  ...(id && { id }),
                  ...(upperId && { upperId }),
                };
              });
              const id = child.id;
              const upperId = child.upperId;
              return {
                ...child,
                ...(child.syncYn && child.origin),
                ...(descendants && { items: descendants }),
                ...(id && { id }),
                ...(upperId && { upperId }),
              };
            });
            const id = item.id;
            const upperId = item.upperId;
            return {
              ...item,
              ...(item.syncYn && item.origin),
              ...(children && { items: children }),
              ...(id && { id }),
              ...(upperId && { upperId }),
            };
          });
          const id = content.id;
          const upperId = content.upperId;
          return {
            ...(content.syncYn && content.origin),
            ...content,
            ...(content.syncYn && content.origin),
            ...(items && { items }),
            ...(id && { id }),
            ...(upperId && { upperId }),
          };
        }) || [];
      store.commit("members/setProductMember", member, { root: true });
      store.commit("setRoomProduct", {
        ...product,
        CONTENTS: newContents,
        communities: communities,
        review: review,
      });
    }
    return result;
  },
  async reqGetContentsProductUser(store, payload = {}) {
    const {
      id,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
    } = payload;

    if (!id) {
      return;
    }
    const getProduct =
      store.rootGetters["members/isProductMember"] ||
      store.rootGetters["members/isStaff"]
        ? apiInstance.activities.getContentsProductUser
        : apiInstance.products.getDetailPublic;
    const result = await getProduct({
      id,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
    });

    if (result.success) {
      const {
        data: { CONTENTS = [] },
      } = result;
      const newContents =
        CONTENTS?.map((content) => {
          const items = content.items?.map((item) => {
            const children = item.items?.map((child) => {
              const descendants = child.items?.map((descendant) => {
                const id = descendant.id;
                const upperId = descendant.upperId;
                return {
                  ...descendant,
                  ...(descendant.syncYn && descendant.origin),
                  ...(id && { id }),
                  ...(upperId && { upperId }),
                };
              });
              const id = child.id;
              const upperId = child.upperId;
              return {
                ...child,
                ...(child.syncYn && child.origin),
                ...(descendants && { items: descendants }),
                ...(id && { id }),
                ...(upperId && { upperId }),
              };
            });
            const id = item.id;
            const upperId = item.upperId;
            return {
              ...item,
              ...(item.syncYn && item.origin),
              ...(children && { items: children }),
              ...(id && { id }),
              ...(upperId && { upperId }),
            };
          });
          const id = content.id;
          const upperId = content.upperId;
          return {
            ...(content.syncYn && content.origin),
            ...content,
            ...(content.syncYn && content.origin),
            ...(items && { items }),
            ...(id && { id }),
            ...(upperId && { upperId }),
          };
        }) || [];

      store.commit("setRoomContents", newContents);
      store.commit("setRoomProduct", { ...result.data, CONTENTS: newContents });
    }

    return result;
  },
  async reqGetContentProductUser(store, payload = {}) {
    const {
      id,
      contentId,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      isApplication = false,
    } = payload;
    if (!id || !contentId) {
      return;
    }
    const getContent =
      store.rootGetters["members/isProductMember"] ||
      store.rootGetters["members/isStaff"] ||
      isApplication
        ? apiInstance.activities.getContentProductUser
        : apiInstance.activities.getPreviewContent;

    const result = await getContent({
      id,
      contentId,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
    });

    if (result.success) {
      const origin = result?.data?.origin;
      if (origin) {
        delete origin.id;
        delete origin.upperId;
        store.commit("setRoomContent", { ...result.data, ...origin });
      } else {
        store.commit("setRoomContent", result.data);
      }
      if (result.data?.contentType === ContentType.QUIZ) {
        const questions = result.data?.items?.map((question) => {
          if (question.origin) {
            delete question.origin.id;
            delete question.origin.upperId;
            if (question.activity) {
              return { ...question, ...question.origin };
            }
            return {
              ...{ ...question, ...question.origin },
              activity: getDefaultActivity(
                question.origin,
                result.data?.activity
              ),
            };
          } else {
            if (question.activity) {
              return question;
            }
            return {
              ...question,
              activity: getDefaultActivity(question, result.data?.activity),
            };
          }
        });
        store.commit("setQuestions", questions);
      }
      if (result.data?.contentType === ContentType.APPLICATION) {
        const questions = result.data?.items?.map((question) => {
          if (question.origin) {
            delete question.origin.id;
            delete question.origin.upperId;
            if (question.activity) {
              return { ...question, ...question.origin };
            }
            return {
              ...{ ...question, ...question.origin },
              activity: getDefaultActivity(
                question.origin,
                result.data?.activity
              ),
            };
          } else {
            if (question.activity) {
              return question;
            }
            return {
              ...question,
              activity: getDefaultActivity(question, result.data?.activity),
            };
          }
        });
        store.commit("setQuestions", questions);
      }
    }
    return result;
  },
  async postProgress(store, payload = {}) {
    const {
      downloadCnts,
      productId,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      contentType,
    } = payload;
    const isProductMember = store.rootGetters["members/isProductMember"];
    const roleCd = store.rootGetters["members/getMemberRoleCd"];
    if (!roleCd || roleCd !== "D") {
      return;
    }
    if (!isProductMember) {
      return;
    }
    const groups = [ContentType.LIVE, ContentType.QUIZ];
    if (groups.includes(contentType)) {
      return;
    }
    const result = await apiInstance.activities.postProgress({
      ...(downloadCnts && { downloadCnts }),
      ...(productId && { productId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(memberId && { memberId }),
    });
    if (!result?.success) {
      let msg = "";
      switch (result.name) {
        case "UNAVAILABLE":
          msg = "참여 일시 정지 상태입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "프로덕트 멤버가 아닙니다.";
          break;
        default:
          msg = "다시 시도해주세요.";
          break;
      }
      store.dispatch("common/setAlert", {
        open: true,
        msg,
      });
      return;
    }
    store.commit("updateActivity", result.data);
    return result;
  },
  async putProgress(store, payload = {}) {
    const {
      lastTime,
      productId,
      compRate,
      clipTime,
      accTime,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    const isProductMember = store.rootGetters["members/isProductMember"];
    const roleCd = store.rootGetters["members/getMemberRoleCd"];
    if (!roleCd || roleCd !== "D") {
      return;
    }
    if (!isProductMember) {
      return;
    }
    const result = await apiInstance.activities.putProgress({
      ...(lastTime && { lastTime }),
      ...(productId && { productId }),
      ...(compRate && { compRate }),
      ...(campusId && { campusId }),
      ...(clipTime && { clipTime }),
      ...(accTime && { accTime }),
      ...(id && { id }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("updateActivity", result.data);
    }
    return result;
  },
  async putLast(store, payload = {}) {
    const {
      lastTime,
      productId,
      accTime,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    const isProductMember = store.rootGetters["members/isProductMember"];
    const roleCd = store.rootGetters["members/getMemberRoleCd"];
    if (!roleCd || roleCd !== "D") {
      return;
    }
    if (!isProductMember) {
      return;
    }
    const result = await apiInstance.activities.putLast({
      ...(lastTime && { lastTime }),
      ...(productId && { productId }),
      ...(campusId && { campusId }),
      ...(accTime && { accTime }),
      ...(id && { id }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("updateActivity", result.data);
    }
    return result;
  },
  async modifyActivity(store, payload = {}) {
    const {
      modifyType,
      answerStatus,
      score,
      remark,
      productId,
      t_memberId,
      completeStatus,
      id,
      targets, // ModifyType.ATTEND 일 때만 사용
      prevScore, // only frontend
      progressStatus,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;

    const result = await apiInstance.activities.modifyActivity({
      ...(modifyType && { modifyType }),
      ...(answerStatus && { answerStatus }),
      ...(score && { score }),
      ...(remark && { remark }),
      ...(productId && { productId }),
      ...(t_memberId && { t_memberId }),
      ...(completeStatus && { completeStatus }),
      ...(id && { id }),
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(targets && { targets }),
      ...(progressStatus && { progressStatus }),
    });
    if (!result.success) {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
        case "UNAUTHORIZE":
          msg = "잘못된 요청입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수항목 누락.";
          break;
        case "NOT_SUPPORTED":
          msg = "부가 서비스 추가 후 사용 가능합니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = " 콘텐츠 정보가 없습니다.";
          break;
        case "DUPLICATE":
          msg = "중복된 요청입니다.";
          break;
        case "BAD_REQUEST":
          msg = " 잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg,
        },
        { root: true }
      );
    } else if (modifyType === "QUESTION_MARK") {
      store.commit("updateQuestionMark", { ...result.data, prevScore });
    }
    return result;
  },
  async postProgressQuiz(store, payload = {}) {
    const {
      quizProgressAction,
      productId,
      id,
      upperId,
      objtAns,
      sbjtAns,
      descAns,
      attaches,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    const isProductMember = store.rootGetters["members/isProductMember"];
    const roleCd = store.rootGetters["members/getMemberRoleCd"];
    if (!roleCd || roleCd !== "D") {
      return;
    }
    if (!isProductMember) {
      return;
    }
    const result = await apiInstance.activities.postProgressQuiz({
      ...(quizProgressAction && { quizProgressAction }),
      ...(productId && { productId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(memberId && { memberId }),
      ...(upperId && { upperId }),
      ...(objtAns && { objtAns }),
      ...(sbjtAns && { sbjtAns }),
      ...(descAns && { descAns }),
      ...(attaches && { attaches }),
    });
    if (result.success) {
      if (result.data.content) {
        if (result.data.content.origin) {
          delete result.data.content.origin.id;
          delete result.data.content.origin.upperId;
          store.commit("setRoomContent", {
            ...{ ...result.data.content, ...result.data.content.origin },
            ...(result.data.activity && { activity: result.data.activity }),
          });
        } else {
          store.commit("setRoomContent", {
            ...result.data.content,
            ...(result.data.activity && { activity: result.data.activity }),
          });
        }
        const questions = result.data.content.items?.map((question) => {
          if (question.origin) {
            delete question.origin.id;
            delete question.origin.upperId;
            if (question.activity) {
              return { ...question, ...question.origin };
            }
            return {
              ...{ ...question, ...question.origin },
              activity: getDefaultActivity(
                question.origin,
                result.data?.activity
              ),
            };
          } else {
            if (question.activity) {
              return question;
            }
            return {
              ...question,
              activity: getDefaultActivity(question, result.data?.activity),
            };
          }
        });
        store.commit("setQuestions", questions);
      }
      if (result.data.activity) {
        store.commit("updateActivity", result.data.activity);
      }
    }
    return result;
  },
  async reqGetQuizStaff(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
      contentId,
      t_memberId,
    } = payload;
    if (!memberId || !campusId || !productId || !contentId || !t_memberId) {
      return {
        success: false,
        message: "퀴즈 정보를 찾을 수 없습니다.",
      };
    }
    const result = await apiInstance.activities.getQuizStaff({
      memberId,
      campusId,
      productId,
      contentId,
      t_memberId,
    });
    if (result.success) {
      const { data = {} } = result;
      const items = data.items?.map((item) => {
        if (item.origin) {
          delete item.origin.id;
          delete item.origin.upperId;
        }
        return {
          ...item,
          ...(item.origin && item.origin),
        };
      });
      if (data.origin) {
        delete data.origin.id;
        delete data.origin.upperId;
      }
      const quiz = {
        ...data,
        ...(data.origin && data.origin),
        items,
      };
      store.commit("setQuiz", quiz);
      store.commit("setQuestions", quiz.items);
    }
    return result;
  },
  async reqGetQuizStats(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
      contentId,
    } = payload;
    if (!memberId || !campusId || !productId || !contentId) {
      return {
        success: false,
        message: "퀴즈 정보를 찾을 수 없습니다.",
      };
    }
    const result = await apiInstance.activities.getQuizStats({
      memberId,
      campusId,
      productId,
      contentId,
    });
    if (result.success) {
      store.commit("setQuizStats", result.data);
    }
    return result;
  },
  //신청서
  async postProgressApplication(store, payload = {}) {
    const {
      progressAction,
      productId,
      id,
      upperId,
      objtAns,
      descAns,
      attaches,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    const isProductMember = store.rootGetters["members/isProductMember"];
    const roleCd = store.rootGetters["members/getMemberRoleCd"];
    if (!roleCd || roleCd !== "D") {
      return;
    }
    if (!isProductMember) {
      return;
    }
    const result = await apiInstance.activities.postProgressApplication({
      ...(progressAction && { progressAction }),
      ...(productId && { productId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(memberId && { memberId }),
      ...(upperId && { upperId }),
      ...(objtAns && { objtAns }),
      ...(descAns && { descAns }),
      ...(attaches && { attaches }),
    });
    if (result.success) {
      if (result.data.content) {
        if (result.data.content.origin) {
          delete result.data.content.origin.id;
          delete result.data.content.origin.upperId;
          store.commit("setRoomContent", {
            ...{ ...result.data.content, ...result.data.content.origin },
            ...(result.data.activity && { activity: result.data.activity }),
          });
        } else {
          store.commit("setRoomContent", {
            ...result.data.content,
            ...(result.data.activity && { activity: result.data.activity }),
          });
        }
        const questions = result.data.content.items?.map((question) => {
          if (question.origin) {
            delete question.origin.id;
            delete question.origin.upperId;
            if (question.activity) {
              return { ...question, ...question.origin };
            }
            return {
              ...{ ...question, ...question.origin },
              activity: getDefaultActivity(
                question.origin,
                result.data?.activity
              ),
            };
          } else {
            if (question.activity) {
              return question;
            }
            return {
              ...question,
              activity: getDefaultActivity(question, result.data?.activity),
            };
          }
        });
        store.commit("setQuestions", questions);
      }
      if (result.data.activity) {
        store.commit("updateActivity", result.data.activity);
      }
    }
    return result;
  },
  async reqGetApplicationStaff(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
      t_memberId,
    } = payload;
    if (!memberId || !campusId || !productId || !t_memberId) {
      return {
        success: false,
        message: "신청서 정보를 찾을 수 없습니다.",
      };
    }
    const result = await apiInstance.activities.getApplicationStaff({
      memberId,
      campusId,
      productId,
      t_memberId,
    });
    if (result?.success) {
      store.commit("setApplication", result.data);
      store.commit("setQuestions", result.data?.items);
    }
    return result;
  },
  // 프로덕트 운영 > 결과 확인 > 결과 통계 조회
  async reqGetApplicationStats(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
    } = payload;
    if (!memberId || !campusId || !productId) {
      return {
        success: false,
        message: "통계 정보를 찾을 수 없습니다.",
      };
    }
    const result = await apiInstance.activities.getApplicationStats({
      memberId,
      campusId,
      productId,
    });
    if (result.success) {
      store.commit("setApplicationStats", result.data);
    }
    return result;
  },
  // 프로덕트 운영 > 결과 확인 > 결과 통계 조회 > 응답자 조회 팝업
  async reqGetApplicationStatUsers(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
      contentId,
      answerYn,
      limit,
      value,
      first,
    } = payload;
    if (first) {
      store.commit("clearApplicationStatUsers");
    }
    const lastKey = store.getters["getApplicationStatUsersLastKey"];

    if (!productId || !contentId) {
      store.commit("clearApplicationStatUsers");
      return { sucess: false };
    }
    const result = await apiInstance.activities.getApplicationStatUsers({
      memberId,
      campusId,
      ...(productId && { productId }),
      ...(contentId && { contentId }),
      ...(answerYn !== undefined && { answerYn }),
      ...(limit && { limit }),
      ...(value && { value }),
      ...(lastKey && { lastKey }),
    });

    if (result.success) {
      store.commit("setApplicationStatUsers", result.data);
    }

    return result;
  },
});

const getDefaultActivity = (question, quizActivity) => {
  const group = ["PASS", "FAIL"];
  if (group.includes(quizActivity?.quizStatus)) {
    return undefined;
  }
  switch (question.questionType) {
    case QuestionType.OBJECT:
      return {
        objtAns: [],
      };
    case QuestionType.SUBJECT:
      return {
        sbjtAns: "",
      };
    case QuestionType.DESC:
      return {
        descAns: "",
      };
    case QuestionType.FILE:
      return {
        attaches: [],
      };
  }
  return {};
};
