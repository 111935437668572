<template>
  <div>
    <!-- only icon text , hover X -->
    <div
      :style="variables"
      v-if="!hoverOn"
      class="p-tip grid-tip"
      :class="{
        'p-tip--info': color === 'info',
        'p-tip--gray5': color === 'gray5',
        'p-tip--gray8': color === 'gray8',
        'p-tip--black': color === 'black',
        'p-tip--primary': color === 'primary',
      }"
    >
      <p-icon :icon="icon" :size="size" />
      <p class="text text-body-2-regular" v-html="text" />
    </div>

    <!-- icon:hover > text -->
    <div
      v-else
      :hover-tooltip="text"
      :tooltip-position="position"
      class="p-tip ml-1 keep-all"
    >
      <p-icon :icon="icon" :size="size" class="cursor-pointer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PoinUiTip",
  props: {
    icon: {
      type: String,
      default: "Info/Blue",
    },
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: "info",
    },
    text: {
      type: String,
      require: true,
    },
    hoverOn: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "left", //left or bottom
    },
  },
  computed: {
    variables() {
      return {
        "--size": `${this.size}px`,
        "--background-image": `url("${this.iconSrc}")`,
      };
    },
    iconSrc() {
      return `https://file.poincampus.com/icons/${this.icon}.svg`;
    },
  },
};
</script>

<style lang="scss" scoped>
$size: var(--size);
.p-tip {
  display: flex;
  align-items: center;
  &.grid-tip {
    display: grid;
    grid-template-columns: $size 1fr;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &--info {
    .text {
      color: $gray7;
    }
  }
  &--primary {
    .text {
      color: $primary;
    }
  }
  &--gray5 {
    .text {
      color: $gray5;
    }
  }
  &--gray8 {
    .text {
      color: $gray8;
    }
  }
  &--black {
    .text {
      color: $black;
    }
  }
}

[hover-tooltip] {
  $caret-height: 4px;
  $caret-width: 6px;
  $tooltip-bg-color: $black;

  position: relative;

  &:hover {
    // text-box
    &::before {
      content: attr(hover-tooltip);
      font-size: 14px;
      position: absolute;
      display: block;
      top: 20px;
      width: max-content;
      height: fit-content;
      transform: translate(0%, -50%);
      animation: fade-in 300ms ease;
      background: $tooltip-bg-color;
      border-radius: 4px;
      padding: 10px;
      color: $white;
      font-weight: 500;
      z-index: 9;
      left: 30px;
      max-width: 420px;
      white-space: pre-line;
    }
    // arrow
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      left: 36px;
      top: 12px;
      border: 4px solid transparent;
      border-left: 0;
      border-right: 6px solid $tooltip-bg-color;
      transform: translate(calc(-100% - 5px), -50%);
      animation: fade-in 300ms ease;
    }
  }
  &[tooltip-position="bottom"] {
    &:hover {
      &::before {
        top: 48px;
        transform: translate(-60%, -50%);
        animation: fade-in 300ms ease;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 26px;
        left: 24px;
        border: 8px solid transparent;
        border-color: transparent transparent $tooltip-bg-color;
        border-width: 0 $caret-width $caret-height;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
