<template>
  <div class="sticky__container w-full" :style="variables">
    <!-- row1 sticky line -->
    <div
      class="sticky__wrapper"
      :class="backgroundColor"
      :style="`
          min-width: ${contentWidth}px;
          width: ${contentWidth}px
        `"
    >
      <div
        class="sticky--line"
        :class="{
          'justify-space-between': !$scopedSlots['customTitle'],
          'gray-3-border-bottom ': borderBottom,
          'mb-5': !sticky,
        }"
      >
        <p-icon-btn
          v-if="onSticky && !title && !hideBack"
          icon="LeftArrow"
          @click="$emit('click')"
        />

        <!-- sticky title -->
        <h2 v-if="title" class="text-h2-medium black--text" v-html="title" />

        <!-- slot: customTitle -->
        <slot v-if="$scopedSlots['customTitle']" name="customTitle" />
        <!-- slot: btns -->
        <slot />
      </div>
    </div>
    <!-- row2 bottom title -->
    <div v-if="text" class="text--line mb-5" :class="{ 'mb-5': !sticky }">
      <h2 class="text-h2-medium black--text" v-html="text" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PoinUiStickyHeader",
  props: {
    //  ------ row1 (sticky O) ------------------
    //  arrow or title --------------------- btns
    //  ------ row2 (sticky X) ------------------
    //  text
    //  -----------------------------------------
    bgColor: {
      type: String,
      default: "white",
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    stickyHeight: {
      type: [Number, String],
      default: 64, // 상위 fixed-header height
    },
    scrollHeight: {
      type: [Number, String],
      default: 40, // sticky 적용 페이지 wrapper padding
    },
    onSticky: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sticky: false,
      contentWidth: 0,
    };
  },
  watch: {
    vuetify: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.contentWidth =
            document?.querySelector(".sticky__container")?.offsetWidth;
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      width: "common/getWindowWidth",
      isMobile: "common/isMobile",
    }),
    vuetify() {
      return this.$vuetify;
    },
    isCampusMain() {
      return !(
        this.$route.name.includes("campus-admin") ||
        this.$route.name.includes("campus-room")
      );
    },
    backgroundColor() {
      if (!this.sticky) {
        return;
      }
      return `sticky--${this.bgColor}`;
    },
    variables() {
      // mixin sticky top position
      // 캠퍼스 관리/프로덕트 수강화면이 아닌 경우 모바일 화면 poin-header sticky 설정이 되어있지 않음,
      // 포인 캠퍼스 메인 화면이거나 개별 캠퍼스 홈인 경우 sticky-header position 최상위 설정
      return {
        "--top": `${this.stickyHeight}px `,
        "--md-top": this.isMobile && !this.isCampusMain ? "56px" : "0px",
      };
    },
  },
  methods: {
    scrollAction() {
      const y = window.scrollY;

      const isSingleLine = !!this.text;

      const stickyElement = isSingleLine
        ? ".sticky__wrapper"
        : ".sticky__container";

      // sticky 적용 대상 다음 형제 요소 선택
      // singleLine: sticky__wrapper next text-line 선택
      // multiLine: sticky-header 적용 컴포넌트 next element 선택
      const nextElement =
        document?.querySelector(stickyElement)?.nextElementSibling;

      if (y > this.scrollHeight) {
        this.sticky = true;
        isSingleLine
          ? (nextElement.style.paddingTop = `${56 + 20}px`) // fixied stickyLine height + margin-bottom
          : (nextElement.style.marginTop = `${56 + 20}px`);
      } else {
        this.sticky = false;
        isSingleLine
          ? (nextElement.style.paddingTop = "0px")
          : (nextElement.style.marginTop = "0px");
      }
    },
  },
  mounted() {
    if (this.onSticky) {
      window.addEventListener("scroll", this.scrollAction);
      this.$eventBus.$on("resizeContentWidth", () => {
        this.contentWidth =
          document?.querySelector(".sticky__container")?.offsetWidth;
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollAction);
  },
};
</script>

<style lang="scss" scoped>
$top: var(--top);
$md-top: var(--md-top);

.sticky__container {
  .sticky__wrapper {
    // campus-header 72px
    // product-room / campus-admin-header 64px
    &.sticky--gray1 {
      @include sticky($gray1, $top, $md-top);
    }
    &.sticky--white {
      @include sticky($white, $top, $md-top);
    }
  }
  .sticky--line {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 56px;
  }
  .text--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0px;
  }
}
</style>
