<script>
import crypto from "crypto";
import { Authority } from "@/enums/membersEnum";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { channel, poinChannelConfig } from "@/config";
import { planCodeFilter, planCycleFilter } from "@/utils/text-format";
export default {
  data() {
    return {
      userCampusInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "users/getUser",
      userId: "users/getUserUuid",
      campusOnly: "common/campusOnly",
      isCampusMember: "members/isCampusMember",
      hasRoleOrAuth: "members/hasRoleOrAuth",
      isStaff: "members/isStaff",
      usage: "campuses/getUsage",
      getChPluginKey: "common/getChPluginKey",
      poinChannelConnected: "common/poinChannelConnected",
    }),
  },
  methods: {
    ...mapActions({
      reqGetCampusInfo: "campuses/reqGetCampusInfo",
    }),
    getHashId(memberId) {
      if (!memberId) {
        return;
      }
      return crypto
        .createHmac("sha256", Buffer.from(channel.memberSecretKey, "hex"))
        .update(memberId)
        .digest("hex");
    },
    async getUserProfile(user = {}) {
      const { name, email, cp, contact, uuid, campusYn, snsType, campusType } =
        user;

      if (!uuid) {
        return { poin_loginYn: false };
      }
      if (campusYn) {
        const result = await this.reqGetCampusInfo({
          campusId: uuid,
          updateInfo: false,
        });
        if (result?.success) {
          this.userCampusInfo = result.data;
        }
      }
      return {
        name: name,
        email: email,
        mobileNumber:
          cp ||
          contact ||
          this.userCampusInfo?.member?.cp ||
          this.userCampusInfo?.member?.contact,
        poin_snsType: snsType,
        poin_memberId: uuid,
        poin_loginYn: true,
        poin_hasRoleOrAuth: this.hasRoleOrAuth({
          authority: [Authority.CAMPUS_SUPER_MANAGE], // 캠퍼스 관리권한 추후 서포트봇 이용할 때 활용 가능.
        }),
        ...(this.campusOnly && {
          poin_isCampusJoinMember: this.isCampusMember,
        }),
        poin_campusYn: campusYn,
        ...(campusYn && {
          poin_campusName: this.userCampusInfo?.name,
          poin_planCode: planCodeFilter(this.userCampusInfo?.PLAN),
          poin_planCycle: planCycleFilter(
            this.userCampusInfo?.usedPlan?.planCycle
          ),
          poin_sales: this.userCampusInfo?.salesStatus || "NONE",
        }),
        poin_productYn: !!this.usage?.volProducts,
        poin_contentYn: !!this.usage?.volContents,
      };
    },
    async getChannelConfig() {
      const profile = await this.getUserProfile(this.user);
      return {
        ...(this.poinChannelConnected && { ...poinChannelConfig }),
        ...(this.userId &&
          this.poinChannelConnected && {
            memberId: this.userId,
            memberHash: this.getHashId(this.userId),
          }),
        ...(!this.poinChannelConnected && {
          zIndex: 99,
          mobileMessengerMode: "newTab",
          language: "ko",
        }),
        pluginKey: this.getChPluginKey,
        profile,
      };
    },
  },
};
</script>
