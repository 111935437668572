<template lang="html">
  <div class="landing-view">
    <h1>잠시만 기다려주세요</h1>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      landingPageLink: "",
    };
  },
  computed: {
    ...mapGetters({}),
    target() {
      return this.$route.params.target
        ? this.$route.params.target.toUpperCase()
        : "";
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      reqGetLandingPageLink: "configure/getLandingPageLink",
    }),
    async getLink() {
      this.landingPageLink = await this.reqGetLandingPageLink(this.target);
      if (this.landingPageLink) {
        window.location = this.landingPageLink;
      } else {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.getLink();
  },
  mounted() {},
};
</script>
<style scoped>
.landing-view {
  display: block;
  margin: 0 auto;
  width: 100%;
  min-height: var(--main-content-height);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
