<template lang="html">
  <div></div>
</template>
<script>
import { path } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import * as webview from "@/utils/webview";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMobile: "common/isMobile",
      isCampusPlan: "users/isCampusPlan",
      authenticated: "auth/getAuthenticated",
      campusOnly: "common/campusOnly",
      campusInfo: "campuses/getCampusInfo",
      campusUuid: "campuses/getCampusUuid",
      campusDomain: "campuses/getCampusDomain",
      isPoinWebview: "common/isPoinWebview",
      locationPath: "common/getLocationPath",
      isPwa: "common/isPwa",
    }),
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
      setLocationPath: "common/setLocationPath",
      reqPutUpdateUsers: "users/reqPutUpdateUsers",
    }),
    goToPoinOn() {
      this.$router.push({ path: "/" });
    },
    goToCampus() {
      window.location.href = "https://poincampus.com";
    },
    toLogInPath() {
      if (!path.checkLoginPath()) {
        this.setLocationPath(window.location.pathname + window.location.search);
      }
      const route = {
        name: this.campusOnly ? "campus-login-select" : "login-select",
        ...(this.campusOnly && { params: { campusDomain: this.campusDomain } }),
        query: this.$route.query,
      };
      if (this.$route.path !== "/login") {
        this.$router.push(route);
      }
    },
    toJoinPath() {
      const name = this.campusOnly ? "campus-join-select" : "join-select";
      this.$router.push({
        name,
        ...(this.campusOnly && { params: { campusDomain: this.campusDomain } }),
        query: this.$route.query,
      });
    },
    toJoinForm(params) {
      const name = this.campusOnly ? "campus-join-form" : "join-form";
      this.$router.push({
        name,
        params: {
          ...(this.campusOnly && { campusDomain: this.campusDomain }),
          ...params,
        },
        query: this.$route.query,
      });
    },
    toTemrsAgree(params) {
      const name = this.campusOnly
        ? "campus-join-terms-agree"
        : "join-terms-agree";
      this.$router.push({
        name,
        params: {
          ...(this.campusOnly && { campusDomain: this.campusDomain }),
          ...params,
        },
        query: this.$route.query,
      });
    },
    toHome() {
      if (this.campusOnly && this.campusInfo && this.campusInfo.campusId) {
        this.toCampusHome();
      } else {
        this.$router.push("/");
      }
    },
    toClassHome() {
      this.$router.push({
        name: "campus-class-detail",
        query: this.$route.query,
      });
    },
    toMyCourseOnSale() {
      if (this.campusOnly && this.campusInfo && this.campusInfo.campusId) {
        this.$router.push({
          name: "campus-onsale",
          query: {
            campusUuid: this.campusInfo.campusId,
          },
        });
      } else {
        this.$router.push("/my-course");
      }
    },
    toMyCourseScrap() {
      if (this.campusOnly && this.campusInfo && this.campusInfo.campusId) {
        this.$router.push({
          name: "campus-scrap",
          query: {
            campusUuid: this.campusInfo.campusId,
          },
        });
      } else {
        this.$router.push("/my-course/scrap");
      }
    },
    async logInOut() {
      if (!this.authenticated) {
        // 웹뷰 로그아웃 호출 - 모바일 로그인 페이지로 이동.
        if (this.isPoinWebview) {
          await this.reqPutUpdateUsers({ deviceId: "" }); // deviceId 삭제를 위해 호출.
          webview.signOut();
        } else {
          this.toLogInPath();
        }
      } else {
        // 웹뷰 로그아웃 호출
        if (this.isPoinWebview) {
          await this.reqPutUpdateUsers({ deviceId: "" }); // deviceId 삭제를 위해 호출.
          await this.signOut();
        } else {
          await this.signOut();
          this.toHome();
        }
      }
    },
    toResetPwd() {
      this.$router.push({
        name: this.campusOnly ? "campus-reset-password" : "reset-password",
        ...(this.campusOnly && { params: { campusDomain: this.campusDomain } }),
      });
    },
    toJoinComplete() {
      this.$router.push({
        path: "/join/complete",
        query: this.$route.query,
      });
    },
    toCampusHome(campus = this.campusInfo, replaceYn = false) {
      const route = {
        name: "campus-home",
        params: {},
        query: {},
      };
      if (campus.domain) {
        route.params.campusDomain = campus.domain;
      } else {
        route.params.campusDomain = "home";
        route.query.campusUuid = campus.campusId;
      }
      if (replaceYn) {
        this.$router.replace(route);
      } else {
        this.$router.push(route);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped></style>
