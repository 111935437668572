<template>
  <div class="editor-viewer" v-html="value"></div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // you can change

export default {
  name: "EditorViewer",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(value) {
      if (!value || !window.Prism) {
        return;
      }
      window.Prism.highlightAll();
    },
  },
  mounted() {
    window.Prism = window.Prism || {};
    window.Prism.manual = true;
    window.Prism.highlightAll();
  },
};
</script>

<style lang="scss" scpoed>
div.v-application .editor-viewer {
  @include hide-scrollbar;
  max-width: 100%;
  overflow: auto;
  font-size: 14px;
  word-break: break-all;
  * {
    word-break: break-all;
  }
  h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    display: block;
    font-size: 1em;
    font-weight: bold;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    font-weight: bold;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    font-weight: bold;
  }
  blockquote {
    border-left: 3px solid #083d45 !important;
    background-color: #f9fafc;
    margin: 0 !important;
    padding: 16px 24px;
  }
  img {
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
  }
  table:not([cellpadding]) td,
  table:not([cellpadding]) th {
    padding: 0.4rem;
  }

  table[border]:not([border="0"]):not([style*="border-width"]) td,
  table[border]:not([border="0"]):not([style*="border-width"]) th {
    border-width: 1px;
  }

  table[border]:not([border="0"]):not([style*="border-style"]) td,
  table[border]:not([border="0"]):not([style*="border-style"]) th {
    border-style: solid;
  }

  table[border]:not([border="0"]):not([style*="border-color"]) td,
  table[border]:not([border="0"]):not([style*="border-color"]) th {
    border-color: #ccc;
  }

  figure {
    display: table;
    margin: 1rem auto;
  }

  figure figcaption {
    color: #999;
    display: block;
    margin-top: 0.25rem;
    text-align: center;
  }

  hr {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 0 0;
  }
  ol,
  ul {
    padding-left: 40px;
    margin-block-start: 14px;
    margin-block-end: 14px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  ul ul,
  ol ol,
  ol ul,
  ul ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    list-style-type: inherit;
  }
  code,
  code *,
  pre,
  pre * {
    font-size: 12px;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  }
  :not(pre) code {
    background-color: #f5f2f0;
    border-radius: 4px;
    padding: 4px 8px;
  }
  a {
    color: $info;
    text-decoration: underline;
  }
}
</style>
