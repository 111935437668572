<script setup>
import _ from "lodash";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  useSlots,
} from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useBreakpoint } from "@/hooks/useBreakpoint";
const route = useRoute();
const router = useRouter();

const { isMobile } = useBreakpoint();

const header = ref("header");

const slots = useSlots();

const props = defineProps({
  categories: {
    type: Array,
  },
  title: {
    type: String,
  },
  asideWidth: {
    type: [String, Number],
    default: 256,
  },
  sub: {
    type: Boolean,
    default: false,
  },
  dark: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["back"]);
const { categories, title, asideWidth, sub, dark } = toRefs(props);
const yOffset = ref(0);
const sticky = ref(false);

const headerHeight = computed(() => (isMobile ? 56 : 64));
const variables = computed(() => {
  return {
    "--aside-width": `${asideWidth.value}px`,
    "--header-height": `${headerHeight.value}px`,
  };
});
const lastIndex = computed(() => {
  const idx = categories.value?.length - 1;
  if (idx < 0) {
    return 0;
  }
  return idx;
});

const scrollFn = _.throttle(() => {
  if (!slots?.actions && !sub.value) {
    return;
  }
  yOffset.value = window.scrollY;
  const nextElement =
    document.querySelector(".admin--title")?.nextElementSibling;
  const paddingTop = dark.value ? 48 : 48 + headerHeight.value;
  if (!nextElement) {
    return;
  }
  if (yOffset.value > paddingTop) {
    sticky.value = true;
    header.value?.classList.add("sticky-header");
    nextElement.style.marginTop = dark.value ? "48px" : "73px";
  } else {
    sticky.value = false;
    header.value?.classList.remove("sticky-header");
    nextElement.style.marginTop = 0;
  }
}, 10);
const back = () => {
  if (route.meta.fromHistory) {
    router.go(-1);
  } else {
    emit("back");
  }
};

onMounted(() => {
  scrollFn();
  window.addEventListener("scroll", scrollFn);
  if (document.querySelector(".admin-alert")) {
    header.value?.classList.add("has-alert");
  } else {
    header.value?.classList.remove("has-alert");
  }
});
onBeforeUnmount(() => {
  window.removeEventListener("scroll", scrollFn);
});
</script>

<template>
  <div class="w-full d-flex-column mb-8 admin--title">
    <div class="d-flex align-center mb-2">
      <template v-for="(category, index) in categories">
        <p-icon
          icon="RightSm/Gray5"
          size="16"
          :key="`right-${index}`"
          v-if="!!index"
        />
        <router-link
          class="gray--text hover-underline text-detail-1-regular"
          :class="lastIndex === index ? 'text--darken-3' : 'text--darken-1'"
          v-if="category.value"
          :to="{
            ...$route,
            name: category.value,
          }"
          :key="category.value"
          >{{ category.text }}</router-link
        >
        <span
          v-else
          :key="`category${index}`"
          class="gray--text text-detail-1-regular"
          :class="lastIndex === index ? 'text--darken-3' : 'text--darken-1'"
        >
          {{ category.text }}
        </span>
      </template>
    </div>
    <div
      class="admin--title__container"
      ref="header"
      :style="variables"
      :class="{ dark }"
    >
      <div class="admin--title__wrap">
        <div class="d-flex align-center gap-1 mr-auto">
          <p-icon-btn icon="LeftArrow" @click="back" v-if="sub" />
          <h2>{{ title }}</h2>
        </div>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin--title a {
  background-color: $white;
}
.admin--title__container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  h2 {
    font-weight: 700;
    color: $black;
    font-size: 28px;
  }
}
.sticky-header {
  z-index: 20;
  position: fixed;
  height: 64px;
  top: 0;
  right: 0;
  background-color: $white;
  width: calc(100vw - var(--aside-width));
  &.has-alert {
    top: 52px;
  }
  h2 {
    display: none;
  }
  .admin--title__wrap {
    justify-content: flex-end;
    height: 100%;
    padding: 0 40px;
  }
  @media (max-width: $lgBreakPoint) {
    width: 100vw;
  }
  @media (max-width: $mdBreakPoint) {
    height: 56px;
    .admin--title__wrap {
      padding: 0 24px;
    }
  }
  @media (max-width: $smBreakPoint) {
    .admin--title__wrap {
      padding: 0 20px;
    }
  }
  &.dark {
    top: var(--header-height);
  }
}
.admin--title__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 1480px;
  height: 100%;
  margin: 0 auto;
}
</style>
