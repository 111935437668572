<template>
  <div class="poin-input poin-file-input">
    <p-label :label="label" :required="required" />
    <div
      class="poin-input-group"
      :style="`height: ${height}px`"
      :class="{
        disabled,
        'success-line': success,
        'mt-2': !hideLabel,
      }"
    >
      <label :for="`${_uid}-file`" ref="fileInput">
        <v-progress-circular
          indeterminate
          color="white"
          width="3"
          size="24"
          v-if="loading"
        />
        <span v-if="filenames" @click.stop="$emit('attachClick')">
          {{ filenames }}
        </span>
        <span class="placeholder" v-else>{{ "파일을 업로드해주세요." }}</span>
      </label>
      <input
        type="file"
        class="poin-input"
        :id="`${_uid}-file`"
        name="fileInput"
        v-if="!filenames"
        @change="fileInputChange"
        :accept="accept"
        :multiple="multiple"
        :disabled="disabled"
      />
      <div
        class="poin-input-suffix"
        aria-hidden="true"
        v-if="filenames"
        @click.stop="onClear"
      >
        <p-icon icon="DeleteFill/Gray8" />
      </div>
      <div
        class="poin-input-suffix"
        aria-hidden="true"
        v-else
        @click.stop="$refs.fileInput.click()"
      >
        <p-icon icon="CloudUpload/Gray8" :size="20" class="cursor-pointer" />
      </div>
    </div>
    <s3-file-uploader
      :accept="accept"
      :acl="acl"
      :uploaderBus="uploaderBus"
      @upload="onAttachUpload"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import Vue from "vue";
import S3FileUploader from "@/components/common/S3FileUploader";
export default {
  name: "PoinUiFileInput",
  components: {
    S3FileUploader,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    acl: {
      type: String,
      default: "public-read",
    },
    accept: {
      type: String,
      default: "*",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    deleteHere: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "파일 업로드",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(f) {
        this.files = f;
      },
    },
  },
  data() {
    return {
      uploaderBus: new Vue(),
      files: [],
      loading: false,
    };
  },
  computed: {
    filenames() {
      if (this.files?.length > 0) {
        const filenames = this.files.reduce(
          (acc, cur, idx) =>
            idx > 0 ? acc + ", " + cur.fileName : cur.fileName,
          ""
        );
        return filenames;
      }
      return "";
    },
    height() {
      return this.dense ? 40 : 48;
    },
  },
  methods: {
    onAttachUpload(uploadedFile) {
      if (uploadedFile.success) {
        this.files.push({
          path: uploadedFile.path,
          key: uploadedFile.key,
          fileName: uploadedFile.fileName,
        });
        this.$emit("input", this.files);
      }
    },
    fileInputChange(e) {
      const files = e.target.files;
      if (files?.length > 0) {
        this.uploaderBus.$emit("upload", files);
      }
    },
    onClear() {
      if (this.deleteHere) {
        this.uploaderBus.$emit("delete", {
          files: this.files,
          callback: (result) => {
            if (result.success) {
              const { deleted } = result;
              if (deleted?.length > 0) {
                deleted.forEach((delFile) => {
                  const idx = this.files?.findIndex(
                    (file) => file.key === delFile.key
                  );
                  if (idx > -1) {
                    this.files.splice(idx, 1);
                  }
                });
              }
              this.$emit("input", this.files);
            }
          },
        });
      } else {
        this.$emit("input", []);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.poin-input-group {
  position: relative;
  align-items: center;
  background-color: $white;
  display: flex;
  padding: 0;
  border: 1px solid $gray3;
  border-radius: 4px;
  outline: none;
  label {
    border: 0;
    width: 100%;
    height: 100%;
    letter-spacing: -0.8px;
    color: $black;
    padding: 0 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      width: calc(100% - 32px);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .placeholder {
      color: $gray6;
    }
  }
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
  }
  .poin-input-suffix {
    position: absolute;
    right: 4px;
    color: $gray6;
    line-height: 0;
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }
  &.invalid {
    border-color: $error;
  }
  &:focus-within {
    border-color: $gray6;
  }
  &.success-line {
    border-color: $success;
  }
  &.disabled {
    background-color: $gray2;
    color: $gray5;
    cursor: not-allowed;
    label {
      cursor: not-allowed;
    }
    .poin-input-suffix {
      cursor: not-allowed;
    }
  }
}
.poin-input-group--error {
  border-color: $error;
  border-style: solid;
}
</style>
