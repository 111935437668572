import {
  ProgressStatus,
  MemberChangeType,
  PaymentStatus,
} from "@/enums/membersEnum";
import { QuizStatus, ApplicationStatus } from "@/enums/productsEnum";

/**
 * 단순 텍스트에서 url을 찾아 a태그로 감싸준다.
 * html 형식의 텍스트에서는 불가.
 * @param inputText
 * @return {*|string}
 */
function linkify(inputText) {
  if (!inputText) return "";
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  // URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a class="blue--text" href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a class="blue--text" href="http://$2" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a class="blue--text" href="mailto:$1">$1</a>'
  );

  return replacedText;
}

/**
 * html 형식의 문자열에서 url 형식의 텍스트 요소를 a태그로 감싸서 반환한다.
 * @param html
 * @return {string}
 */
const linkifyHtml = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const walk = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null);
  while (walk.nextNode()) {
    const linkified = linkify(walk.currentNode.textContent);
    walk.currentNode.textContent = linkified;
  }

  // 삽입한 linkify 텍스트가 html 엔터티 코드로 변환되기때문에, decode 과정 필요하다.
  return decodeHTMLEntities(tempDiv.innerHTML);
};

function strip(str) {
  return str.replace(/^\s+|\s+$/g, "");
}

/**
 * 이모지 제거
 * https://stackoverflow.com/a/69661174
 * @param str
 * @returns {string|*}
 */
const removeEmoji = (str) => {
  if (str) {
    return str.replace(
      /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
      ""
    );
  }
  return "";
};

function phoneNumberFormat(value) {
  let changeValue = strip(value).replace(/-/g, "");
  if (changeValue.length === 10) {
    if (changeValue.substr(0, 2) === "02") {
      changeValue = changeValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      changeValue = changeValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  } else if (changeValue.length === 9) {
    changeValue = changeValue.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (changeValue.length === 8) {
    changeValue = changeValue.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    changeValue = changeValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return changeValue;
}

function birthFormat(value) {
  let changeValue = strip(value).replace(/-/g, "");
  changeValue = changeValue.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  return changeValue;
}

function snsTypeFilter(type) {
  switch (type) {
    case "NONE":
      return "이메일 가입";
    case "GOOGLE":
      return "구글";
    case "NAVER":
      return "네이버";
    case "KAKAO":
      return "카카오";
    case "FACEBOOK":
      return "페이스북";
    case "APPLE":
      return "애플";
    default:
      return "";
  }
}

function encryptor(txt) {
  return txt.replace(/(\w)(\w)/g, function (a) {
    return a[0] + "*";
  });
}

function roldCdFilter(type) {
  switch (type) {
    case "A":
      return "개설자";
    case "B":
      return "운영자";
    case "C":
      return "프로덕트자";
    case "D":
      return "회원";
    default:
      return "";
  }
}

function memberStatusName(status) {
  switch (status) {
    case "JOIN":
      return "승인됨";
    case "INVITE":
      return "초대됨";
    case "REQUEST":
      return "대기중";
    case "REJECT":
      return "거절됨";
    case "RETRY_REQUEST":
      return "재신청";
    default:
      return status;
  }
}

function memberStatusChip(status, member) {
  switch (status) {
    case "DEL": {
      if (
        [
          PaymentStatus.PAY_REJECT,
          PaymentStatus.CANCEL,
          PaymentStatus.PAY_REVERT,
        ].includes(member?.paymentStatus)
      ) {
        return { text: "결제 취소", theme: "error" };
      }
      return { text: "내보냄", theme: "gray" };
    }
    case "JOIN":
      return { text: "참여중", theme: "success" };
    case "REQUEST":
      return { text: "대기중", theme: "info" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "REJECT":
      return { text: "거절됨", theme: "error" };
    case "RETRY_REQUEST":
      return { text: "재신청", theme: "info" };
    case "CANCEL":
      return { text: "신청 취소", theme: "gray" };
    default:
      return { text: "초대됨", theme: "success" };
  }
}
function paymentStatusChip(status) {
  switch (status) {
    case "CANCEL_REQ":
      return { text: "환불 신청", theme: "info" };
    case "CANCEL_PART":
      return { text: "부분 환불", theme: "success" };
    case "CANCEL_REJECT":
      return { text: "환불 거부", theme: "error" };
    case "PAY_REJECT":
      return { text: "결제 취소", theme: "error" };
    case "CANCEL":
      return { text: "전체 환불", theme: "success" };
    case "PAID":
      return { text: "결제 완료", theme: "gray" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "PAY_REVERT":
      return { text: "결제 취소", theme: "error" };
    default:
      return { text: "결제 요청", theme: "info" };
  }
}
function planPaymentStatusFilter(status) {
  switch (status) {
    case "CANCEL":
    case "REFUND":
      return { text: "환불 완료", theme: "gray" };
    case "REFUND_CANCEL":
      return { text: "환불 취소", theme: "error" };
    case "PAID":
      return { text: "결제 완료", theme: "success" };
    case "CANCEL_PART":
      return { text: "부분 취소", theme: "gray" };
    default:
      return { text: "", theme: "" };
  }
}
function planPaymentTypeFilter(status) {
  switch (status) {
    case "TERMINATE":
      return "해지";
    case "CHANGE":
      return "변경";
    case "NEW":
      return "신규";
    default:
      return "정기 결제";
  }
}
function planPaymentTargetFilter(type) {
  switch (type) {
    case "PLAN":
      return "요금 플랜";
    case "ADDON":
      return "부가 서비스";
    default:
      return "";
  }
}

const completionStatusFilter = (status) => {
  switch (status) {
    case ProgressStatus.COMPLETE_FORCE:
      return {
        text: "관리자 완료",
        theme: "error",
      };
    case ProgressStatus.COMPLETE:
      return {
        text: "진행완료",
        theme: "success",
      };
    case ProgressStatus.PROGRESS:
      return {
        text: "진행중",
        theme: "primary",
      };
    case ProgressStatus.EXCEED:
      return {
        text: "시간초과(이탈)",
        theme: "error",
      };
    case ProgressStatus.NONE:
      return {
        text: "진행전",
        theme: "gray",
      };
  }
  return {
    text: "진행전",
    theme: "gray",
  };
};

const memberConfirmTxt = (status) => {
  switch (status) {
    case MemberChangeType.APPROVAL:
      return {
        title: "승인",
        msg: "참여 신청을 승인하시겠습니까?",
        btnTxtConfirm: "승인하기",
        btnTxtCancel: "아니오",
        icon: "confirm",
      };
    case MemberChangeType.REJECT:
      return {
        title: "거절",
        msg: "참여 신청을 거절하시겠습니까?",
        btnTxtConfirm: "거절하기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
    default:
      return {
        title: "내보내기",
        msg: "내보내기된 회원은 더이상 프로덕트에 참여할 수 없습니다. <br />회원을 내보내시겠습니까?",
        btnTxtConfirm: "내보내기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
  }
};

const paymentFilter = (item) => {
  switch (item.paymentWay) {
    case "NONE":
      return "-";
    case "ETC":
      return "기타 결제";
    case "KAKAO":
      return "카카오 페이";
    case "NAVER":
      return "네이버 페이";
    case "BANK":
      return "실시간 계좌이체";
    case "V_BANK":
      return "가상 계좌이체";
    case "CARD":
      return `(${item.org?.name || "카드"})  ${item?.org?.number || ""}`;
    case "CASH":
      return "현금";
    default:
      return "-";
  }
};

const pubTargetFilter = (value) => {
  switch (value) {
    case "MEMBER_ONLY":
      return { text: "회원공개", theme: "info" };
    default:
      return { text: "전체공개", theme: "gray" };
  }
};

const snsIconFilter = (type) => {
  switch (type) {
    case "GOOGLE":
      return "Google/20";
    case "FACEBOOK":
      return "Facebook/20";
    case "KAKAO":
      return "Kakao/20";
    case "NAVER":
      return "Naver/20";
    case "APPLE":
      return "Apple/20";
    default:
      return "Email/20";
  }
};

const salesStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

const etcApplyStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

const planCodeFilter = (plan) => {
  const { planCode, ver } = plan;
  switch (planCode) {
    case "FREE":
      return "무료";
    case "ONDEMAND":
      return "온디맨드";
    case "LIGHT":
      return "라이트";
    case "STARTER":
      return "Starter 플랜";
    case "STANDARD":
      return "Standard 플랜";
    case "PREMIUM":
      return "Premium 플랜";
    case "BUSINESS":
      return "Business 플랜";
    case "ENTERPRISE":
      return "Enterprise 플랜";
    default:
      return planCode;
  }
};
const planCycleFilter = (type) => {
  switch (type) {
    case "MONTHLY":
      return "월간";
    case "ANNUALY":
    case "ANNUALLY":
      return "연간";
  }
};
const feedBackStatusFilter = (type) => {
  switch (type) {
    case "READY":
      return { text: "답변 대기", theme: "gray" };
    case "COMPLETE":
      return { text: "답변 완료", theme: "success" };
    default:
      return;
  }
};
const feedBackAskTargetFilter = (type) => {
  switch (type) {
    case "AI":
      return { text: "🖥️ AI", theme: "info" };
    case "PRO":
      return { text: "전문가", theme: "gray" };
    default:
      return;
  }
};
const markStatusFilter = (type) => {
  switch (type) {
    case QuizStatus.PASS:
    case QuizStatus.FAIL:
      return {
        text: "채점완료",
        theme: "success",
      };
    case QuizStatus.SUBMIT:
      return {
        text: "채점필요",
        theme: "error",
      };
    default:
      return;
  }
};

const getAnswerStatusIcon = (question) => {
  const obj = question?.activity || {};
  switch (obj.answerStatus) {
    case "CORRECT":
      return "Circle/Success";
    case "PART":
      return "Triangle/Secondary";
    case "INCORRECT":
      return "Cross/Error";
    case "NONE":
      return "";
  }
  return "";
};

const applicationStatusFilter = (status) => {
  switch (status) {
    case "PICK":
      return {
        text: "선정",
        theme: "success",
      };
    case "DROP":
      return {
        text: "미선정",
        theme: "gray",
      };
    case "SUBMIT":
      return {
        text: "확인필요",
        theme: "error",
      };
    case "HOLD":
      return {
        text: "보완요청",
        theme: "info",
      };
    default:
      return;
  }
};
const questionTypeFilter = (status) => {
  switch (status) {
    case "OBJT":
      return "객관식";
    case "SBJT":
      return "주관식";
    case "DESC":
      return "서술형";
    case "FILE":
      return "파일제출";
    default:
      return "";
  }
};

const addOnTypeFilter = (planCode) => {
  switch (planCode) {
    case "QUIZ":
      return {
        icon: "Service/Quiz",
        text: "퀴즈&과제",
      };
    case "CODING":
      return {
        icon: "Service/Coding",
        text: "코딩 실습",
      };
    case "PRODUCT_BASIC":
      return {
        icon: "Service/ManagementProduct",
        text: "운영 프로덕트",
      };
    case "AI_INSTRUCTOR":
      return {
        icon: "Service/AI",
        text: "AI 조교",
      };
    case "CERTIFICATE":
      return {
        icon: "Service/Diploma",
        text: "수료증",
      };
    case "EXTRA_VOLUME":
      return {
        icon: "Service/Cloud",
        text: "용량 추가",
      };
    case "APPLICATION":
      return {
        icon: "Service/Application",
        text: "신청서",
      };
    case "RESERVATION":
      return {
        icon: "Service/Reservation",
        text: "예약",
      };
    case "ETC_PAY":
      return {
        icon: "Service/EtcPayment",
        text: "기타 결제",
      };
    default:
      return {
        icon: "BlankImage",
      };
  }
};

const notSupportedConfirmText = (type) => {
  let contentTypeName = "";
  switch (type) {
    case "QUIZ":
      contentTypeName = "퀴즈&과제";
      break;
    case "CODING":
      contentTypeName = "코딩 실습";
      break;
    case "PRODUCT_BASIC":
      contentTypeName = "운영 프로덕트";
      break;
    case "AI_INSTRUCTOR":
      contentTypeName = "AI 조교";
      break;
    case "CERTIFICATE":
      contentTypeName = "수료증";
      break;
    case "EXTRA_VOLUME":
      contentTypeName = "용량 추가";
      break;
    case "APPLICATION":
      contentTypeName = "신청서";
      break;
    case "RESERVATION":
      contentTypeName = "예약";
      break;
    case "ETC_PAY":
      contentTypeName = "기타 결제";
      break;
    default:
      break;
  }
  return {
    title: `'${contentTypeName}' 추가 필요`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ] 에서 '${contentTypeName}' 추가가 필요합니다. 해당 메뉴로 이동하시겠습니까?`,
    btnTxtConfirm: "이동하기",
    btnTxtCancel: "아니오",
    reverse: true,
  };
};
const notSupportedMsg = (type) => {
  const contentTypeName = addOnTypeFilter(type)?.text || "";

  return {
    title: `'${contentTypeName}' 추가 후 이용 가능합니다.`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ]에서 '${contentTypeName}' 추가를 진행해주세요.`,
  };
};
const productTypeName = (type) => {
  let name = "";
  switch (type) {
    case "SALES":
      name = "판매 프로덕트";
      break;
    case "BASIC":
      name = "운영 프로덕트";
      break;
    case "APPLICATION":
      name = "신청서";
      break;
    case "RESERVATION":
      name = "예약";
      break;
    default:
      break;
  }

  return name;
};
const refundToastMsg = (paymentStatus) => {
  let msg = "";
  switch (paymentStatus) {
    case "CANCEL":
      msg = "전체 환불을 완료했습니다.";
      break;
    case "CANCEL_PART":
      msg = "부분 환불을 완료했습니다.";
      break;
    case "CANCEL_REJECT":
      msg = "환불 거부를 완료했습니다.";
      break;
    case "V_BANK_CANCEL_PART":
      msg = "가상계좌 부분 취소를 완료했습니다.";
      break;
    case "V_BANK_CANCEL":
      msg = "가상계좌 결제 취소를 완료했습니다.";
      break;
    default:
      msg = "환불을 완료했습니다.";
      break;
  }

  return msg;
};
const emptyTableMsg = (routeName) => {
  let type = "";
  const regTxt = "등록된";
  const emptyTxt = "없습니다.";
  switch (routeName) {
    case "content":
      type = "콘텐츠가";
      break;
    case "product-sales":
    case "product-basic":
      type = "프로덕트가";
      break;
    case "reservation":
      type = "예약이";
      break;
    case "application":
      type = "신청서가";
      break;
    case "users":
      type = "회원이";
      break;
    case "product-detail-users":
    case "community-dashboard-member":
      type = "참여자가";
      break;
    case "staff":
      type = "직원이";
      break;
    case "community":
      type = "커뮤니티가";
      break;
    case "promotion":
    case "product-detail-promotion":
      type = "프로모션이";
      break;
    case "payment":
      type = "판매 내역이";
      break;
    case "notice":
      type = "공지사항이";
      break;
    case "product-detail-notices":
      type = "안내사항이";
      break;
    case "community-dashboard-notice":
      type = "새 소식이";
      break;
    case "reply":
      type = "댓글이";
      break;
    case "ask":
      type = "문의내역이";
      break;
    case "faq":
      type = "자주묻는질문이";
      break;
    case "plan-histories":
    case "product-detail-payments":
    case "reservation-detail-payments":
      type = "결제 내역이";
      break;
    case "credit-histories":
      type = "크레딧 내역이";
      break;
    case "application-detail-results":
      type = "신청서 목록이";
      break;
    case "product-detail-delegator":
    case "reservation-detail-delegator":
    case "application-detail-delegator":
      type = "담당자가";
      break;
    default:
      type = "데이터가";
      break;
  }
  return `${regTxt} ${type} ${emptyTxt}`;
};

const removeFileExtension = (filename) => {
  return filename?.substr(0, filename.lastIndexOf(".")) || "";
};

/**
 * html 엔터티 코드를 텍스트로 변환해준다.
 * @param text
 * @return {string}
 */
const decodeHTMLEntities = (text) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};

export {
  linkify,
  strip,
  removeEmoji,
  phoneNumberFormat,
  birthFormat,
  snsTypeFilter,
  encryptor,
  roldCdFilter,
  memberStatusName,
  memberStatusChip,
  completionStatusFilter,
  paymentStatusChip,
  memberConfirmTxt,
  paymentFilter,
  pubTargetFilter,
  snsIconFilter,
  planCodeFilter,
  planCycleFilter,
  feedBackStatusFilter,
  feedBackAskTargetFilter,
  markStatusFilter,
  getAnswerStatusIcon,
  applicationStatusFilter,
  questionTypeFilter,
  addOnTypeFilter,
  salesStatusFilter,
  planPaymentStatusFilter,
  planPaymentTypeFilter,
  planPaymentTargetFilter,
  etcApplyStatusFilter,
  notSupportedConfirmText,
  notSupportedMsg,
  removeFileExtension,
  decodeHTMLEntities,
  linkifyHtml,
  productTypeName,
  refundToastMsg,
  emptyTableMsg,
};
