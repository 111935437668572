import { endpointV2 as endpoint } from "@/config";

// 캠퍼스 정보 조회
export async function getCampusInfo({
  campusId,
  memberId,
  domain,
  scope,
  userId,
  headers,
}) {
  const params = {};
  if (campusId) {
    params.campusId = campusId;
  }
  if (memberId) {
    params.memberId = memberId;
  }
  if (domain) {
    params.domain = domain;
  }
  // TODO: scope에 배열 처리. paramsSerializer
  if (scope) {
    params.scope = scope;
  }
  if (userId) {
    params.userId = userId;
  }
  return this.getMethod("get", false, {
    url: `${endpoint.campuses}/`,
    params,
    headers,
  });
}

// 캠퍼스 신규 개설
export async function postCampus({
  userId,
  name,
  profile,
  domain,
  recommenderCode,
}) {
  const data = {
    userId,
    name,
    domain,
  };
  if (profile) {
    data.profile = profile;
  }
  if (recommenderCode) {
    data.recommenderCode = recommenderCode;
  }
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/`,
    data,
  });
}

// 캠퍼스 정보 수정
// TODO: 캠퍼스 관리 화면 작성하면서 완성
export async function putCampusInfo(data) {
  return this.getMethod("put", true, {
    url: `${endpoint.campuses}/`,
    data,
  });
}

// 사용자 소속 캠퍼스 목록 조회 /{userId}/lists
export async function getUserCampusList({ userId }) {
  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/${userId}/lists`,
  });
}

// 도메인 중복 체크
export async function duplicateCheck({ domain, extDomain }) {
  const data = {};
  if (domain) {
    data.domain = domain;
  }
  if (extDomain) {
    data.extDomain = extDomain;
  }
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/duplicate`,
    data,
  });
}

// 대시보드 조회
export async function getDashboard({ campusId, memberId }) {
  const params = {
    memberId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/dashboard/${campusId}`,
    params,
  });
}

// Domains
export async function postCertificates({
  campusId,
  memberId,
  extDomain,
  subYn,
}) {
  const data = {
    campusId,
    memberId,
    extDomain,
    subYn,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/certificates`,
    data,
  });
}

export async function getCertificates({ campusId, memberId }) {
  const params = {
    memberId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/certificates/${campusId}`,
    params,
  });
}

export async function deleteDomains({ campusId, memberId }) {
  const data = {
    campusId,
    memberId,
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.campuses}/domains`,
    data,
  });
}

// 인증 메일 전송, 재전송 /emails/verification
export async function sendMailVerification({
  campusId,
  memberId,
  resendYn,
  email,
}) {
  const data = {};
  if (campusId) {
    data.campusId = campusId;
  }
  if (memberId) {
    data.memberId = memberId;
  }
  if (resendYn) {
    data.resendYn = resendYn;
  }
  if (email) {
    data.email = email;
  }
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/emails/verification`,
    data,
  });
}

// 이메일 인증 상태 조회
export async function getMailVerification({ campusId, email }) {
  const params = {};
  if (campusId) {
    params.campusId = campusId;
  }
  if (email) {
    params.email = email;
  }

  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/emails/verification`,
    params,
  });
}

export async function deleteMailVerification({ campusId, email }) {
  const data = {
    campusId,
    email,
  };

  return this.getMethod("delete", true, {
    url: `${endpoint.campuses}/emails/verification`,
    data,
  });
}

export async function getPlans({ campusId, memberId }) {
  const params = {
    campusId,
    memberId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/plans`,
    params,
  });
}

export async function postPlans({
  campusId,
  userId,
  changeType,
  amount,
  planCycle,
  cardId,
  cardQuota,
  planCode,
}) {
  const data = {
    campusId,
    userId,
    changeType,
    ...(amount && { amount }),
    ...(planCycle && { planCycle }),
    ...(cardId && { cardId }),
    ...(cardQuota && { cardQuota }),
    ...(planCode && { planCode }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/plans`,
    data,
  });
}

export async function putPlans({ campusId, userId }) {
  const data = {
    campusId,
    userId,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.campuses}/plans`,
    data,
  });
}

export async function putPlansCard({ campusId, userId, cardId }) {
  const data = {
    campusId,
    userId,
    cardId,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.campuses}/plans/card`,
    data,
  });
}

export async function postPlanPrice({
  changeType,
  campusId,
  userId,
  planCycle,
  planCode,
}) {
  const data = {
    changeType,
    campusId,
    userId,
    planCycle,
    planCode,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/plans/price`,
    data,
  });
}
// ADMIN > 캠퍼스 요금제 정보 설정
export async function putAdminSubscription({
  campusId,
  amount,
  planCycle,
  planCode,
  startDttm,
  endDttm,
  batchYn,
  cardId,
  addons,
  spec,
}) {
  const data = {
    campusId,
    ...(amount && { amount }),
    ...(planCycle && { planCycle }),
    ...(planCode && { planCode }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(batchYn && { batchYn }),
    ...(cardId && { cardId }),
    ...(addons?.length > 0 && { addons }),
    ...(spec?.length > 0 && { spec }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.campuses}/admin/subscription`,
    data,
  });
}

// 캠퍼스관리 > 요금 플랜 > 요금 플랜 관리 조회
export async function getSubscription({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campuses}/subscription`,
    params,
  });
}

// 캠퍼스 요금 플랜/부가 서비스 가입 및 변경/변경 취소
export async function postSubscription({
  campusId,
  amount,
  planType,
  planCycle,
  cardId,
  cardQuota = "00",
  quota,
  changeType,
  credit,
  planCode,
  memberId,
  terminateType,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(typeof amount === "number" && { amount }),
    ...(planType && { planType }),
    ...(planCycle && { planCycle }),
    ...(cardId && { cardId }),
    ...(quota && { quota }),
    ...(changeType && { changeType }),
    ...(credit && { credit }),
    ...(planCode && { planCode }),
    ...(memberId && { memberId }),
    ...(terminateType && { terminateType }),
    cardQuota,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/subscription`,
    data,
  });
}

// 캠퍼스 요금 플랜/부가 서비스 신규 가입 및 변경시 가격 사전 조회
export async function postSubscriptionPrice({
  campusId,
  planType,
  planCode,
  changeType,
  memberId,
  planCycle,
  quota,
  credit,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(planType && { planType }),
    ...(planCode && { planCode }),
    ...(planCycle && { planCycle }),
    ...(changeType && { changeType }),
    ...(memberId && { memberId }),
    ...(quota && { quota }),
    ...(credit && { credit }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campuses}/subscription/price`,
    data,
  });
}
