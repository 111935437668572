<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    content-class="dates-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateText"
        readonly
        v-bind="attrs"
        v-on="on"
        hide-details
        single-line
        outlined
        :disabled="loading"
        :placeholder="placeholder"
      >
        <template v-slot:append>
          <p-icon
            icon="DeleteFill/Gray5"
            class="cursor-pointer mr-2"
            v-if="clearable && dates"
            @click="dates = ''"
          />
          <p-icon
            icon="Calendar/Black"
            class="cursor-pointer"
            @click="menu = true"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      no-title
      scrollable
      locale="ko-KR"
      color="primary"
      :placeholder="placeholder"
      :day-format="(date) => getDayFormat(date)"
      :min="min"
      :max="max"
    >
      <div
        v-if="!hideBtns"
        class="d-flex w-full border-box"
        style="border-top: 1px solid #e4e6ea"
      >
        <v-btn
          color="cancel"
          class="font-size-14"
          text
          style="
            width: 50%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          "
          @click="menu = false"
          large
        >
          취소
        </v-btn>
        <div
          style="width: 1px; height: 44px; border-right: 1px solid #e4e6ea"
        ></div>
        <v-btn
          color="primary"
          class="font-size-14"
          text
          style="
            width: 50%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          "
          @click="$refs.menu.save(dates)"
          large
        >
          확인
        </v-btn>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "PoinUiDatePicker",
  props: {
    value: {
      type: String,
      default: "",
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideBtns: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      dates: "",
    };
  },
  watch: {
    dates(val) {
      this.$emit("input", val);
    },
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.dates = value;
        } else {
          this.dates = "";
        }
      },
    },
  },
  computed: {
    dateText() {
      return this.dates;
    },
  },
  methods: {
    getDayFormat(date) {
      return format(date, "DD");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input {
    * {
      &:active,
      &:focus,
      &:focus-visible {
        outline: unset !important;
      }
    }
  }
  fieldset {
    border-color: $gray3;
    outline: none;
  }
}
</style>

<style lang="scss">
.dates-menu.v-menu__content {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  box-shadow: unset;
  border: 1px solid $gray3;
  margin-top: 4px;
  .v-picker__actions--no-title {
    padding: 0;
  }
}
</style>
