<template>
  <div
    v-show="!loading"
    class="border-box flex-fill"
    :class="{
      desktop: !isMobile,
      mobile: isMobile,
    }"
  >
    <template v-if="showGlobalMenu">
      <poin-header v-if="!isMobile" id="header" class="header" />
      <poin-mobile-header id="mbHeader" @showAside="showAside" v-else />
    </template>

    <main
      id="main"
      class="main flex-fill"
      :class="{ 'has-bottom-nav': hasBottomNav }"
    >
      <router-view class="router-view" />
    </main>

    <poin-mobile-aside
      id="aside"
      :aside-on="asideOn"
      @close="closeAside"
      v-if="isMobile && showGlobalMenu"
    />

    <notification />
    <bottom-navigation v-if="hasBottomNav" />
    <div class="top" :style="styleObj">
      <v-fab-transition>
        <v-btn
          class="top-btn"
          @click="scrollTop"
          v-show="showTopBtn"
          fab
          small
          absolute
        >
          <p-icon icon="UpSm/White" size="28" class="ma-0" />
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import { passwordSecretKey } from "@/config";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isDev, isLocal } from "@/config";
import PoinHeader from "@/components/layout/header";
import Notification from "@/components/layout/notification/Notification";
import { domUtils } from "@/utils";
import _ from "lodash";
import PoinMobileHeader from "@/components/layout/header/mobile";
import BottomNavigation from "@/components/layout/BottomNavigation";
import globalMixin from "@/mixins/global";
import PoinMobileAside from "@/components/layout/aside/Aside-Mobile";
import ChannelService from "@/utils/ChannelService";
import ChatUserMixin from "@/mixins/ChatUserMixin.vue";

export default {
  name: "poin-app-web",
  components: {
    PoinHeader,
    Notification,
    PoinMobileHeader,
    PoinMobileAside,
    BottomNavigation,
  },
  mixins: [globalMixin, ChatUserMixin],
  data() {
    return {
      isShowFooter: true,
      mainElement: null,
      pending: false,
      asideOn: false,
      lastScrollTop: 0,
      delta: 50,
      scrollY: 0,
      drawer: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "common/isMobile",
      notificationsActive: "notifications/getContainerActive",
      user: "users/getUser",
      authenticated: "auth/getAuthenticated",
      getWindowWidth: "common/getWindowWidth",
      loading: "common/loading",
      showGlobalMenu: "common/showGlobalMenu",
      campusOnly: "common/campusOnly",
      isStaff: "members/isStaff",
      campusInfo: "campuses/getCampusInfo",
      getVideoPanelHeight: "common/getVideoPanelHeight",
      memberId: "members/getMemberId",
      getResetChannelFlag: "common/getResetChannelFlag",
    }),
    isAdmin() {
      return this.$route.name?.includes("campus-admin");
    },
    showTopBtn() {
      if (!this.campusOnly) {
        return true;
      }

      if (
        this.isAdmin &&
        !this.$route.name !== "campus-admin-application-detail-application"
      ) {
        if (
          (this.isMobile && this.scrollY > 1600) ||
          (!this.isMobile && this.scrollY > 2000)
        ) {
          return true;
        }
        return false;
      }
      return (
        this.$route.name &&
        this.$route.name !== "campus-application" &&
        !this.$route.name.includes("playground") &&
        !this.$route.name.includes("quiz")
      );
    },
    hasBottomNav() {
      return this.showGlobalMenu && this.isMobile;
    },
    regCampusPluginKey() {
      return this.campusInfo?.SETTING?.chTalk?.pluginKey;
    },
    regCampusChMember() {
      return !this.isStaff && this.campusOnly && !!this.regCampusPluginKey;
    },
    excludedRoutes() {
      return [
        "campus-playground",
        "campus-application",
        "campus-admin-application-detail-application",
        "campus-admin-product-detail-quiz",
      ];
    },
    styleObj() {
      // poincampus 메인
      if (!this.campusOnly) {
        if (this.hasBottomNav) {
          return {
            bottom: `130px`,
          };
        }
        return {
          bottom: `100px`,
        };
      }
      // campusOnly && 포인 채널톡, 캠퍼스 채널톡 X
      if (!this.regCampusChMember && !this.isStaff) {
        return { bottom: this.isMobile ? "76px" : `28px` };
      }
      if (!this.isMobile && (this.isStaff || this.regCampusChMember)) {
        return {
          bottom: `100px`,
        };
      }
      // mobile
      return { bottom: this.isAdmin ? "80px" : "130px" };
    },
  },
  watch: {
    isAdmin: {
      immediate: true,
      deep: true,
      handler(isAdmin) {
        if (this.isMobile) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.toggleMobileButtonClass(isAdmin);
            }, 500);
          });
        }
      },
    },
    $route(to, __) {
      const excludedRoutes = [
        "campus-playground",
        "campus-application",
        "campus-admin-application-detail-application",
        "campus-admin-product-detail-quiz",
      ];
      // if (excludedRoutes.includes(to.name)) {
      //   ChannelService.hideMessenger();
      //   ChannelService.hideChannelButton();
      // } else {
      //   ChannelService.showChannelButton();
      // }

      this.closeAside();
    },
    // getResetChannelFlag: {
    //   immediate: true,
    //   handler(y) {
    //     if (y) {
    //       this.disconnectPluginKey();
    //       ChannelService.shutdown();
    //       this.onBoot();
    //       this.resetChannel(false);
    //     }
    //   },
    // },

    // memberId: {
    //   immediate: true,
    //   async handler() {
    //     const canUseCampusChTalk = !!this.regCampusPluginKey;
    //
    //     if (!this.campusOnly) {
    //       this.disconnectPluginKey();
    //       ChannelService.shutdown();
    //       this.onBoot();
    //       return;
    //     }
    //
    //     if (this.campusOnly) {
    //       ChannelService.hideChannelButton();
    //       this.disconnectPluginKey();
    //       ChannelService.shutdown();
    //
    //       if (!this.isStaff) {
    //         if (canUseCampusChTalk) {
    //           const bytes = CryptoJS.AES.decrypt(
    //             this.regCampusPluginKey,
    //             passwordSecretKey
    //           );
    //           const decryptedKey = JSON.parse(
    //             bytes.toString(CryptoJS.enc.Utf8)
    //           );
    //           this.updatePluginKeyConnection(decryptedKey);
    //           this.onBoot();
    //           return;
    //         }
    //       } else {
    //         this.onBoot();
    //       }
    //     }
    //   },
    // },
  },
  methods: {
    ...mapActions({
      toggleNotificationsActive: "notifications/toggleContainerActive",
    }),
    ...mapMutations({
      resetChannel: "common/resetChannel",
      updatePluginKeyConnection: "common/updatePluginKeyConnection",
      disconnectPluginKey: "common/disconnectPluginKey",
    }),

    toggleMobileButtonClass(onMove) {
      // const wrapper = document.querySelector("#ch-plugin-entry > div");
      // const shadowRoot = wrapper.shadowRoot;
      // const buttonElement = shadowRoot.querySelector("#ch-shadow-root-wrapper");
      //
      // if (buttonElement && buttonElement.childNodes.length >= 2) {
      //   const secondChildElement = buttonElement.childNodes[1];
      //
      //   if (onMove) {
      //     secondChildElement.classList.add("ch-btn--mobile");
      //
      //     const styleElement = document.createElement("style");
      //     styleElement.textContent = `.ch-btn--mobile { position: fixed !important;
      //     display: flex;
      //     flex-wrap: nowrap;
      //     width: auto;
      //     overflow: visible;
      //     background: transparent;
      //     bottom: 20px !important;
      //     right: 16px;}`;
      //
      //     secondChildElement.appendChild(styleElement);
      //   } else {
      //     secondChildElement.classList.remove("ch-btn--mobile");
      //   }
      // }
    },

    listenScroll() {
      if (!this.isMobile) {
        return;
      }

      const header = document.getElementById("mbHeader");

      if (!header) {
        return;
      }

      const st = window.pageYOffset;

      if (Math.abs(this.lastScrollTop - st) <= this.delta) {
        return;
      }

      if (st > domUtils.getHeaderHeight() && st < this.lastScrollTop) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }

      this.lastScrollTop = st;
    },
    listenClickEvent(event) {
      if (this.mainElement.contains(event.target)) {
        if (this.notificationsActive) {
          this.toggleNotificationsActive();
        }
      }
    },
    showAside() {
      this.asideOn = true;
    },
    closeAside() {
      this.asideOn = false;
    },
    scrollTop() {
      document
        .getElementsByClassName("campus-admin__container")[0]
        ?.scrollTo({ top: 0, behavior: "smooth" });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    async onBoot() {
      const config = await this.getChannelConfig();

      ChannelService.boot(config, (error, user) => {
        if (error) {
          console.log(error);
        } else {
          this.count = user.alert;
        }
      });
    },
  },
  mounted() {
    this.mainElement = document.getElementById("main");
    this.$el.addEventListener("click", this.listenClickEvent, false);
    window.addEventListener(
      "scroll",
      _.throttle(this.listenScroll, 100),
      false
    );
    this.scrollY = window.scrollY;
    window.addEventListener("scroll", this.handleScroll);

    this.$eventBus.$on("app-message", async (payload) => {
      const { "footer-show": footerShow } = payload;
      if (footerShow !== null && footerShow !== undefined) {
        this.isShowFooter = footerShow;
      }
    });
  },
  beforeDestroy() {
    this.$el.removeEventListener("click", this.listenClickEvent);
    window.removeEventListener("scroll", this.listenScroll);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
/* @import 'style/import-material-icons.css'; */
.desktop {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    .router-view {
      width: 100%;
      min-height: var(--main-content-height);
    }
  }
}

.mobile {
  > .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    & + main {
      padding-top: var(--header-height);
    }
  }
  .main.has-bottom-nav {
    padding-bottom: 56px;
  }
}
.top {
  position: fixed;
  bottom: 100px;
  right: 28px;
  z-index: 9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  @media (max-width: $mdBreakPoint) {
    right: 16px;
  }
}
::v-deep .top .theme--light.v-btn.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
