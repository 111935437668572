<template>
  <div class="onboarding__container" v-if="showOnboarding">
    <template v-if="loading">
      <div class="d-flex-column w-full gap-3">
        <div class="skeleton--title skeleton"></div>
        <div class="skeleton--desc1 skeleton"></div>
        <div class="skeleton--desc2 skeleton"></div>
      </div>
      <div class="skeleton--progress skeleton"></div>
      <div class="skeleton--stepper skeleton"></div>
    </template>
    <template v-else>
      <div class="d-flex-column gap-3 w-full">
        <div class="d-flex align-center w-full">
          <h3 class="black--text text-h3-bold">{{ title }}</h3>
        </div>
        <p class="text-body-1-regular gray--text text--darken-3">
          {{ description }}
        </p>
        <p
          v-if="information"
          class="text-body-1-regular"
          :class="tab === 'sales' ? 'error--text' : 'info--text'"
          v-html="information"
        ></p>
      </div>

      <v-progress-linear
        class="w-full"
        background-color="gray lighten-4"
        color="primary"
        :value="progress"
        height="8"
        rounded
      />
      <div class="d-flex align-center flex-wrap">
        <div
          class="d-flex align-center flex-wrap"
          v-for="(todo, index) in todos"
          :key="index"
        >
          <p-icon
            icon="RightSm/Gray8"
            size="24"
            v-if="index > 0"
            class="mx-2"
          />
          <div class="d-flex align-center gap-1">
            <p-icon
              :icon="
                getChecked(todo) ? 'ProgressComplete' : 'ProgressIncomplete'
              "
              size="20"
            />
            <router-link
              class="todo-link"
              :class="{ 'todo-link--current': isCurrent(todo) }"
              :to="{
                name: todo.link,
                params: $route.params,
                query: { tab: todo.value },
              }"
              >{{ todo.title }}</router-link
            >
          </div>
        </div>
      </div>
      <v-expand-transition>
        <div
          class="d-flex align-center w-full gray lighten-4 rounded"
          v-if="isComplete"
        >
          <p class="text-body-2-regular black--text pa-3">
            ✅ 현재 단계를 완료했습니다. 다음 단계를 진행해보세요!
          </p>
        </div>
      </v-expand-transition>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OnboardingProduct",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      campusInfo: "campuses/getCampusInfo",
      product: "products/getProduct",
    }),
    tab() {
      return this.$route.query?.tab;
    },
    showOnboarding() {
      if (this.product?.productType !== "SALES") {
        return false;
      }
      return (
        this.campusInfo?.spec?.find((s) => s.code === "ONBOARDING_PRODUCT")
          ?.quota !== 1
      );
    },
    title() {
      switch (this.$route.name) {
        case "campus-admin-product-detail-settings":
          switch (this.$route.query?.tab) {
            case "default":
              return "1. 기본 정보";
            case "introduction":
              return "2. 프로덕트 소개";
            case "sales":
              return "3. 판매 정보";
            default:
              return "판매 프로덕트를 확인해보세요!";
          }
        case "campus-admin-product-detail-contents":
          return "4. 콘텐츠";
        case "campus-admin-product-detail-contents-edit":
          return "5. 콘텐츠 구성";
        default:
          return "판매 프로덕트를 확인해보세요!";
      }
    },
    description() {
      switch (this.$route.name) {
        case "campus-admin-product-detail-settings":
          switch (this.$route.query?.tab) {
            case "default":
              return "프로덕트의 이름, 썸네일 이미지 등 기본적인 정보입니다. 추가적으로 카테고리, 프로덕트자 프로필, 태그, 관련 링크를 상세하게 설정할 수 있어요!";
            case "introduction":
              return "이미지,  텍스트, 외부 링크 등을 활용하여 프로덕트를 소개하는 내용을 구성할 수 있어요!";
            case "sales":
              return "가격, 공개 여부, 이용기간, 판매기간 등 프로덕트의 판매 정보를 설정할 수 있습니다. 회원을 대상으로 한 프로덕트 노출 여부는 판매 신청이 승인된 이후로 설정 가능해요!";
            default:
              return "판매 프로덕트는 회원에게 판매하는 상품입니다. 아래의 순서에 따라서 차근차근 판매 프로덕트에 대해서 알아봐요!";
          }
        case "campus-admin-product-detail-contents":
          return "콘텐츠는 하나만으로 구성할 수 있고, 여러 콘텐츠를 커리큘럼 안에 조합하여 묶음 단위로 구성할 수 있습니다. 자세한 기능은 [콘텐츠 구성] 버튼을 눌러서 확인해 보세요!";
        case "campus-admin-product-detail-contents-edit":
          return "하단의 [콘텐츠 추가]를 통해 프로덕트의 콘텐츠를 구성할 수 있습니다. 프로덕트에서 콘텐츠를 구성한 경우 프로덕트의 독립적인 콘텐츠가 되며, 드라이브에 저장되지 않아요! 확인을 완료했으면 우측 상단의 [완료하기] 버튼을 눌러주세요!";
        default:
          return "판매 프로덕트는 회원에게 판매하는 상품입니다. 아래의 순서에 따라서 차근차근 판매 프로덕트에 대해서 알아봐요!";
      }
    },
    information() {
      switch (this.$route.name) {
        case "campus-admin-product-detail-settings":
          switch (this.$route.query?.tab) {
            case "default":
              return "";
            case "introduction":
              return "";
            case "sales":
              return "* 회원에게 프로덕트를 노출 시키기 위해선 ‘판매 신청' 과정이 꼭 필요해요! 개설 과정을 차근차근 진행하면서, 마지막에 ‘판매 신청’을 진행해봐요!";
            default:
              return "";
          }
        case "campus-admin-product-detail-contents":
          return "";
        case "campus-admin-product-detail-contents-edit":
          return "<p>* 콘텐츠를 불러오는 기능도 있어요!</p><ul class='disc-inside'><li>[드라이브에서 불러오기] : 드라이브에 동기화된 콘텐츠를 불러옵니다. 수정은 드라이브에서 가능하며, 동기화 해제를 통해 독립된 콘텐츠로 변경할 수 있습니다.</li><li>[프로덕트에서 복사하기] : 복사한 콘텐츠는 동기화된 콘텐츠가 아니므로, 자유롭게 수정해도 기존의 콘텐츠에 아무런 영향을 주지 않습니다.</li></ul>";
        default:
          return "";
      }
    },
    progress() {
      const a = this.todos?.length || 0;
      const b =
        this.todos?.filter((todo) => this.getChecked(todo) || todo.checked)
          ?.length || 0;
      if (a && b) {
        return (b / a) * 100;
      }
      return 0;
    },
    todos() {
      return [
        {
          title: "기본 정보",
          link: "campus-admin-product-detail-settings",
          value: "default",
          code: "ONBOARDING_PRODUCT_SETTING_DEFAULT",
          seq: 11,
        },
        {
          title: "프로덕트 소개",
          link: "campus-admin-product-detail-settings",
          value: "introduction",
          code: "ONBOARDING_PRODUCT_SETTING_INTRO",
          seq: 12,
        },
        {
          title: "판매 정보",
          link: "campus-admin-product-detail-settings",
          value: "sales",
          code: "ONBOARDING_PRODUCT_SETTING_SALES",
          seq: 13,
        },
        {
          title: "콘텐츠",
          link: "campus-admin-product-detail-contents",
          code: "ONBOARDING_PRODUCT_CONTENTS",
          seq: 14,
        },
        {
          title: "콘텐츠 구성",
          link: "campus-admin-product-detail-contents-edit",
          code: "ONBOARDING_PRODUCT_CONTENTS_EDIT",
          seq: 15,
        },
      ];
    },
    spec() {
      return this.campusInfo?.spec || [];
    },
    currentTodo() {
      return this.todos.find((todo) => {
        if (todo.value) {
          return todo.value === this.$route.query?.tab;
        }
        return todo.link === this.$route.name;
      });
    },
    isComplete() {
      return (
        this.spec.find((s) => s.code === this.currentTodo?.code)?.quota === 1
      );
    },
    todoList() {
      return this.todos?.filter(
        (todo) => !this.spec.some((s) => s.code === todo.code)
      );
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler({ name, query }) {
        const currentTodo = this.todos.find((todo) => {
          if (todo.value) {
            return todo.value === query?.tab;
          }
          return todo.link === name;
        });
        if (currentTodo?.code === "ONBOARDING_PRODUCT_CONTENTS_EDIT") {
          return;
        }
        const isLast = this.todoList?.length === 1;
        this.$nextTick(() => {
          if (
            currentTodo?.code &&
            this.spec.find((s) => s.code === currentTodo?.code)?.quota !== 1
          ) {
            this.reqPutBasicCampusInfo({
              spec: [
                ...this.spec,
                {
                  title: `프로덕트 온보딩 > ${currentTodo.title}`,
                  code: currentTodo.code,
                  quota: 1,
                  seq: currentTodo.seq,
                },
              ],
            });
            if (isLast) {
              const onboardingCampusDone =
                this.spec?.find((s) => s.code === "ONBOARDING_CAMPUS")
                  ?.quota !== 1;

              this.$eventBus.$emit("alert", {
                open: true,
                lottie: "check",
                title: "판매 프로덕트 확인 완료",
                msg: onboardingCampusDone
                  ? "판매 프로덕트에 대한 기능 확인을 완료했습니다. 나만의 콘텐츠로 프로덕트를 만들고 판매를 진행하세요!"
                  : "판매 프로덕트에 대한 기능 확인을 완료했습니다. 사이트를 완성하기까지 얼마 남지 않았어요. 계속 진행해볼까요?",
                btnText: onboardingCampusDone ? "완료" : "다음 단계 진행하기",
                callback: async () => {
                  await this.reqPutBasicCampusInfo({
                    spec: [
                      ...this.spec,
                      {
                        title: `프로덕트 온보딩`,
                        code: "ONBOARDING_PRODUCT",
                        quota: 1,
                        seq: 10,
                      },
                    ],
                  });
                  this.$eventBus.$emit("gtag", {
                    name: "click",
                    category: "onboarding_product",
                    label: "complete_1",
                    value: "500",
                  });
                },
              });
            }
          }
        });
      },
    },
  },
  methods: {
    ...mapActions({
      reqPutBasicCampusInfo: "campuses/reqPutBasicCampusInfo",
    }),
    getChecked(todo) {
      return this.spec.find((s) => s.code === todo.code)?.quota === 1;
    },
    isCurrent(todo) {
      return (
        this.$route?.name === todo.link &&
        (!todo.value || todo.value === this.tab)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding__container {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1.5px solid $primary800;
  background: $white;
  box-shadow: 0px 4px 12px 0px rgba(146, 26, 4, 0.1);
  width: 100%;
}
.todo-link {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  color: $gray8 !important;
  background: $white !important;
  &:hover {
    color: $black !important;
  }
  &.todo-link--current {
    color: $primary !important;
  }
  @include hover-transition(color);
}
</style>
