import { endpointV2 as endpoint } from "@/config";
// 사용자의 해당 캠퍼스의 멤버 정보 조회
export async function getMember({ campusId, userId, headers }) {
  if (!campusId || !userId) {
    return;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/users/${userId}/${campusId}`,
    headers,
  });
}

// 사용자의 해당 캠퍼스의 멤버 정보 조회
export async function getProductMember({ campusId, userId, productId }) {
  if (!campusId || !userId || !productId) {
    return;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.members}/${campusId}/${userId}/${productId}`,
  });
}

// 역할 정보 조회
export async function getRoles({ campusId, id }) {
  const params = {
    campusId,
  };

  if (id) {
    params.id = id;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.members}/roles`,
    params,
  });
}
// 수료증 조회
export async function getCertificate({
  campusId,
  userId,
  productId,
  memberId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(userId && { userId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/certificate/${productId}/${memberId}`,
    params,
  });
}

// 프로덕트 멤버관리
export async function postProductsMember({
  productId,
  changeType,
  campusId,
  userId,
  targets,
  memberId,
  multiYn,
}) {
  if (!productId || !changeType) {
    return;
  }
  const data = {
    productId,
    changeType,
    ...(campusId && { campusId }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(targets && targets?.length > 0 && { targets }),
    ...(multiYn && { multiYn }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.members}/products`,
    data,
  });
}

// 프로덕트 > 참여자관리 > 조회
export async function getProductsMembers({
  keyword,
  memberId,
  limit = 40,
  sort,
  progressStatus,
  inProgressStatus,
  campusId,
  order,
  productId,
  memberStatus,
  lastKey,
  expireYn,
}) {
  if (!productId) {
    return;
  }
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && memberId),
    limit,
    ...(sort && { sort }),
    ...(progressStatus && { progressStatus }),
    ...(inProgressStatus && { inProgressStatus }),
    ...(campusId && { campusId }),
    ...(order && { order }),
    ...(memberStatus && { memberStatus }),
    ...(lastKey && { lastKey }),
    ...(expireYn && { expireYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/products/${productId}`,
    params,
  });
}

// 프로덕트 > 참여자 관리 > 엑셀다운로드
export async function getProductJoinMembersExcel({
  campusId,
  memberId,
  productId,
  keyword,
  sort,
  order,
  progressStatus,
  expireYn,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(keyword && { keyword }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(progressStatus && { progressStatus }),
    ...(expireYn && { expireYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/products/${productId}/excel`,
    params,
  });
}
// 프로덕트 > 참여자 관리 > 콘텐츠 별 엑셀다운로드
export async function getProductExcelKey({
  campusId,
  memberId,
  productId,
  keyword,
  sort,
  order,
  progressStatus,
  expireYn,
  fileName,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(keyword && { keyword }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(progressStatus && { progressStatus }),
    ...(expireYn && { expireYn }),
    ...(fileName && { fileName }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/products/${productId}/excel/entire`,
    params,
  });
}

// 프로덕트 > 참여자 관리 > 전체 회원 수료증 다운로드
export async function getCertsAll({ campusId, userId, productId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(userId && { userId }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/certificate/${productId}`,
    params,
  });
}

export async function getProductsMembersSummary({
  memberId,
  campusId,
  productId,
}) {
  if (!productId) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/summary/products/${productId}`,
    params,
  });
}

// 프로덕트 > 참여자관리 > 참여자 상세
export async function getProductsMember({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/products/members/${id}`,
    params,
  });
}

// 멤버 관리
export async function postMemberManagement({
  note,
  productId,
  conts,
  changeType,
  campusId,
  targets,
  userId,
  memberId,
}) {
  if (!changeType || !(targets?.length > 0)) {
    return;
  }
  const data = {
    changeType,
    targets,
    ...(campusId && { campusId }),
    ...(productId && { productId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(note && { note }),
    ...(conts && { conts }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.members}/v2`,
    data,
  });
}

// 사용자 > 캠퍼스 멤버 정보 수정
export async function putMember(data) {
  return this.getMethod("put", true, {
    url: `${endpoint.members}/v2`,
    data,
  });
}

//캠퍼스관리 > 회원 > 전체 목록 엑셀 다운로드
export async function membersExcelDownload({ memberId, campusId }) {
  const params = {
    memberId,
    campusId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/excel/${campusId}`,
    params,
  });
}

// 사용자 > 내 캠퍼스 멤버 목록 조회
export async function getMembersByUserId({ userId }) {
  if (!userId) {
    return;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/users/${userId}`,
  });
}

// 사용자 > 특정 캠퍼스 멤버 정보 단건 조회
export async function getMemberByUserId({ userId, campusId }) {
  if (!userId || !campusId) {
    return;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/users/${userId}/${campusId}`,
  });
}

// 캠퍼스 멤버 조회
export async function getCampusMembers({
  keyword,
  memberId,
  memberType = "MEMBER",
  userId,
  limit = 40,
  excludeProductId,
  excludeComId,
  excludeGroupSk,
  code,
  memberStatus,
  inMemberStatus,
  campusId,
  lastKey,
  sort,
  order,
  includeOwner,
  groupSk,
}) {
  const params = {
    memberId,
    memberType,
    ...(!includeOwner && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(memberStatus && { memberStatus }),
    ...(inMemberStatus?.length > 0 && { inMemberStatus }),
    ...(keyword && { keyword }),
    ...(userId && { userId }),
    ...(code && { code }),
    ...(excludeProductId && { excludeProductId }),
    ...(excludeComId && { excludeComId }),
    ...(excludeGroupSk && { excludeGroupSk }),
    ...(lastKey && { lastKey }),
    ...(includeOwner && { includeOwner }),
    ...(groupSk && { groupSk }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/${campusId}`,
    params,
  });
}

// 캠퍼스 멤버 단건 조회
export async function getCampusMember({ campusId, id, memberId }) {
  const params = {
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/${campusId}/${id}`,
    params,
  });
}

//커뮤니티 참여자관리  조회
export async function getCommunityMembers({
  keyword,
  memberId,
  limit,
  sort,
  campusId,
  order,
  memberStatus,
  lastKey,
  id,
  productId,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(campusId && { campusId }),
    ...(order && { order }),
    ...(memberStatus && { memberStatus }),
    ...(lastKey && { lastKey }),
    ...(id && { id }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/communities/${id}`,
    params,
  });
}
//커뮤니티 참여자 관리 상세조회
export async function getCommunityMember({ memberId, campusId, id }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/communities/members/${id}`,
    params,
  });
}
//커뮤니티 멤버관리
export async function postCommunityMembers({
  productId,
  changeType,
  campusId,
  comId,
  userId,
  targets,
  memberId,
}) {
  const data = {
    ...(productId && { productId }),
    ...(changeType && { changeType }),
    ...(campusId && { campusId }),
    ...(comId && { comId }),
    ...(userId && { userId }),
    ...(targets && { targets }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.members}/communities`,
    data,
  });
}
//멤버관리(product)
//회원 상세 > 프로덕트 참여 목록
export async function getDetailJoinProducts({ campusId, id, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/detail/${id}/products`,
    params,
  });
}
//멤버관리 (community)
//회원 상세  > 커뮤니티 조회
export async function getDetailJoinCommunities({ memberId, campusId, id }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/communities/members/my/${id}`,
    params,
  });
}

// 권한설정(v2) 권한설정 > 유형별 권한정보 조회
export async function getAuthorities({ memberId, campusId, staffYn }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(staffYn && { staffYn }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.members}/v2/roles`,
    params,
  });
}

// 권한설정(v2) 권한설정 > 저장
export async function postAuthorities({ memberId, campusId, targets }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    targets,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.members}/v2/roles`,
    data,
  });
}

export async function getCommunityDelegators({
  keyword,
  memberId,
  limit,
  sort,
  campusId,
  order,
  memberStatus,
  lastKey,
  id,
  productId,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(campusId && { campusId }),
    ...(order && { order }),
    ...(memberStatus && { memberStatus }),
    ...(lastKey && { lastKey }),
    ...(id && { id }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/communities/${id}`,
    params,
  });
}

export async function GetJoinedMember({ memberId, campusId }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.members}/staffs`,
    params,
  });
}

// 일반회원 > 수강일시정지 처리
export async function putPauseProductMember(data) {
  return this.getMethod("put", true, {
    url: `${endpoint.members}/products`,
    data,
  });
}
