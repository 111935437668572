<template>
  <div class="info-horizon" :class="{ large, small }">
    <span class="info-horizon--title" :style="`align-self: ${titleAlign}`">{{
      title
    }}</span>
    <div class="d-flex align-center gap-2">
      <p-link v-if="link" :color="linkColor" @click="onClick">{{
        link
      }}</p-link>
      <p-chip v-if="chip" small :color="theme">{{ chip }}</p-chip>
      <span class="info-horizon--content">
        {{ body }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoHorizon",
  props: {
    item: {
      type: Object,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.item?.title || "";
    },
    titleAlign() {
      return this.item?.titleAlign || "center";
    },
    link() {
      return this.item?.link || "";
    },
    linkColor() {
      return this.item?.linkColor || "primary";
    },
    body() {
      return this.item?.body;
    },
    chip() {
      return this.item?.chip;
    },
    theme() {
      return this.item?.theme || "info";
    },
  },
  methods: {
    onClick() {
      this.item.action();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-horizon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 8px 0;
  .info-horizon--title {
    font-size: 14px;
    font-weight: 500;
    color: $black;
  }
  .info-horizon--content {
    font-size: 16px;
    font-weight: 700;
    color: $black;
    white-space: pre-line;
    text-align: right;
  }
  &.large {
    .info-horizon--title {
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }
    .info-horizon--content {
      font-size: 20px;
      font-weight: 700;
      color: $black;
    }
  }
  &.small {
    .info-horizon--title {
      font-size: 14px;
      font-weight: 400;
      color: $gray8;
    }
    .info-horizon--content {
      font-size: 14px;
      font-weight: 400;
      color: $black;
    }
  }
}
</style>
