export default {
  // 프로덕트 만들기/수정
  applicationsStaff: {
    lastKey: "",
    items: [],
  },
  applicationsUser: {
    lastKey: "",
    items: [],
    sections: [],
  },
  applicationsLastKeyUser: "",
  delegators: [],
  application: {},
};
