<template>
  <div
    :style="`width : ${!isMobile ? width : '100%'}`"
    class="p-filter"
    :class="{ 'p-filter--active': filterYn }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-text-field
      readonly
      outlined
      single-line
      hide-details
      :dense="dense"
      :class="setting.class"
      :value="setting.value"
      :background-color="
        loading || disabled
          ? 'gray lighten-3'
          : hover
            ? 'gray lighten-4'
            : 'white'
      "
      :disabled="loading || disabled"
      @click="openPopup"
      class="cusor-pointer"
    >
      <template v-slot:append>
        <div class="d-flex align-center gap-2" v-if="!disabled">
          <p-icon
            v-if="filterYn"
            icon="DeleteFill/Gray8"
            size="20"
            @click="
              () => {
                onReset();
                onSubmit();
              }
            "
          />
          <p-icon
            :icon="setting.icon"
            @click="openPopup"
            class="cursor-pointer"
            size="24"
          />
        </div>
      </template>
    </v-text-field>
    <p-modal
      :fullscreen="isSm"
      :value="open"
      :width="popupWidth"
      :title="$t('productHome.filter')"
      content-height="400"
      @close="open = false"
    >
      <!-- case 1: RADIO -->
      <div
        v-for="item in arr"
        :key="item.status"
        class="gray-3-border-bottom pb-5"
      >
        <p class="text-body-1-bold">{{ item.subject }}</p>
        <template v-if="item.type === 'RADIO'">
          <v-radio-group v-model="selectedItems[item.status]" row hide-details>
            <p-radio
              style="min-width: 64px"
              class="mb-3"
              v-for="(option, index) in item.items"
              :key="index"
              :value="option.value"
              :label="option.text"
              @input="checkDisableOption(option)"
            />
          </v-radio-group>
        </template>
        <!-- case 2: CHECKBOX -->
        <template v-else-if="item.type === 'CHECKBOX'">
          <div class="d-flex mt-4 flex-wrap">
            <v-checkbox
              multiple
              color="primary"
              off-icon="$checkbox-blank"
              on-icon="$checkbox-checked"
              style="min-width: 64px"
              class="pt-0 mr-5"
              v-for="(option, index) in item.items"
              :key="index"
              :value="option.value"
              :label="option.text"
              v-model="selectedItems[item.status]"
              hide-details
            ></v-checkbox>
          </div>
        </template>
        <!-- case 3: SELECT -->
        <template v-else-if="item.type === 'SELECT'">
          <p-select
            class="mt-4"
            hide-details
            custom-class="w-max-content"
            v-model="selectedItems[item.status]"
            :options="item.items"
          />
        </template>
        <!-- case 3: ON/OFF -->
        <template v-else>
          <div class="d-flex align-center mt-4 flex-wrap">
            <p-switch
              solo
              v-model="selectedItems[item.status]"
              :disabled="item.disabled"
            />
            <span class="text-body-2-regular black--text ml-3">
              {{ item.text }}
            </span>
          </div>
        </template>
      </div>
      <template v-slot:action>
        <p-link color="gray" @click="onReset" class="mr-auto">
          {{ $t("productHome.reset") }}
        </p-link>
        <p-btn :disabled="loading" @click="open = false" color="gray"
          >취소</p-btn
        >
        <p-btn :loading="loading" @click="onSubmit" color="primary"
          >적용하기</p-btn
        >
      </template>
    </p-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PoinUiFilterModal",
  data() {
    return {
      open: false,
      hover: false,

      selectedItems: {}, // {  radioStatusA: 'activeA', checkboxStatusB : ['checkboxA', 'checkboxB', ...] }
      arr: [],
    };
  },
  props: {
    // * selectBox 적용 케이스 > 사용자가 직접 추가, 작성하는 레이블
    // options: [
    //     {
    //       subject: 'title',
    //       status: 'api option text'
    //       type: 'RADIO or CHECKBOX or SELECT'
    //       items: [
    //         {
    //           text: '···',
    //           value: (string),
    //         },
    //       ],
    //     },
    // {
    //       subject: '···',
    //       type: 'SWITCH',
    //       text: '·····',
    //     },
    //   ];
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filterYn: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "170px",
    },
    popupWidth: {
      type: [String, Number],
      default: "auto",
    },
    popupHeight: {
      type: [String, Number],
      default: "640px",
    },
  },
  computed: {
    ...mapGetters({
      isSm: "common/isSm",
      isMobile: "common/isMobile",
      selectedOptions: "common/getSelectedOptions",
    }),
    setting() {
      return {
        value: !this.filterYn ? "필터" : "필터 적용 중",
        icon: `Filter/${this.loading || this.disabled ? "Gray5" : "Gray8"}`,
      };
    },
  },
  methods: {
    ...mapMutations({
      setSelectedOptions: "common/setSelectedOptions",
      clearSelectedOptions: "common/clearSelectedOptions",
    }),
    openPopup() {
      this.arr = _.cloneDeep(this.options);

      this.onReset();

      if (!this.filterYn) {
        // 검색 중인 옵션 값이 없다면 common/selectedOptions 초기화
        this.clearSelectedOptions();
      } else {
        this.setOptions();
      }

      this.open = true;
    },
    setOptions() {
      // getters/selectedOptions 배열을 기반으로 활성화 옵션 객체 생성

      let activeOption = {};

      this.arr.forEach((option) => {
        if (option.status) {
          let matchingItems = [];

          if (option.type === "SWITCH") {
            // 스위치 옵션은 items depth X
            matchingItems = [option].filter((item) =>
              this.selectedOptions.some(
                (activeItem) => activeItem.status === item.status
              )
            );
          } else if (option.type === "CHECKBOX") {
            // 체크박스 옵션은 멀티 선택 가능 && selectedOption 을 arr로 저장
            let findIndex = this.selectedOptions?.findIndex(
              (checked) => checked.status === option.status
            );
            matchingItems = option?.items?.filter((item) =>
              this.selectedOptions?.[findIndex]?.value.some(
                (activeItem) => activeItem === item.value
              )
            );
          } else {
            // SELECT || RADIO
            const selectItem = option?.items?.filter((item) =>
              this.selectedOptions.some(
                (activeItem) => activeItem.value === item.value
              )
            );

            if (selectItem?.length > 0) {
              matchingItems = selectItem;
            } else {
              matchingItems.push({ text: "전체", value: "ALL" });
            }
          }

          if (matchingItems?.length > 0) {
            if (option.type === "CHECKBOX") {
              activeOption[option.status] = matchingItems.map(
                (item) => item.value
              );
            } else if (
              option.type === "RADIO" ||
              option.type === "SWITCH" ||
              option.type === "SELECT"
            ) {
              matchingItems?.forEach((item) => {
                activeOption[option.status] =
                  option.type === "SWITCH" ? true : item.value;

                if (item?.disabled) {
                  this.checkDisableOption(item);
                }
              });
            }
          }
        }
      });

      this.selectedItems = activeOption;
    },
    checkDisableOption(item) {
      this.arr.forEach((option) => {
        if (item.disabled?.includes(option.status)) {
          if (
            Object.entries(this.selectedItems)?.some(
              ([key, value]) => key == option.status && value
            )
          ) {
            this.$set(this.selectedItems, option.status, false);
          }
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    },
    onReset() {
      this.arr?.forEach((option) => {
        if (option.type === "RADIO" || option.type === "SELECT") {
          const allOptionExists = option.items.some(
            (opt) => opt.value === "ALL"
          );

          if (!allOptionExists) {
            // "전체" 옵션이 없는 경우, "전체" 옵션을 추가
            option.items.unshift({
              text: "전체",
              value: "ALL",
            });
          }
          // 기본값으로 "ALL" 옵션 추가
          this.$set(this.selectedItems, option.status, "ALL");
        } else if (option.type === "CHECKBOX") {
          this.$set(this.selectedItems, option.status, []); // init empty array
        } else {
          this.$set(this.selectedItems, option.status, false);
        }
      });
    },

    onSubmit() {
      // selectedItems obj 배열로 변경
      const filteredArr = Object.entries(this.selectedItems)
        .filter(([key, value]) => value) // switch filter
        .map(([key, value]) => ({ status: key, value }));

      // 현재 검색 중인 옵션 값들을 common/selectedOptions 저장
      this.setSelectedOptions(filteredArr);

      // type: RADIO 전체 option인경우 filtered length 0
      this.$emit(
        "submit",
        filteredArr?.filter((item) => item.value !== "ALL")
      );
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$color: var(--color);
::v-deep {
  .v-input {
    * {
      &:active,
      &:focus,
      &:focus-visible {
        outline: unset !important;
      }
      .v-label {
        color: $black;
        font-size: 14px;
      }
    }
  }

  .v-text-field input {
    font-size: 14px;
    cursor: pointer;
  }
  .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center;
  }
  .v-select__slot {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  .v-select__selections input {
    &::placeholder {
      color: $black;
      font-size: 14px;
    }
  }
  .v-input__append-inner {
    cursor: pointer;
  }
  fieldset {
    border-color: $gray4;
    outline: none;
  }
}
.p-filter--active {
  ::v-deep fieldset {
    border-color: $black;
  }
}
.popup {
  position: relative;
  &--header {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: $white;

    .reset--text {
      position: absolute;
      padding: 4px;
      top: 20px;
      right: 16px;
      text-decoration: underline;
      font-weight: 500;
      color: $gray6;
      cursor: pointer;
      font-size: 14px;
    }
  }
  &--content {
    max-height: 520px;
    overflow-y: scroll;
  }
  &--btns {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.gray-3-border-bottom:last-child {
  border: none;
}
</style>
