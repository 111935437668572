import { getDateFormat } from "@/utils/date-format";
import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";
import { vimeo } from "@/config";
import ApiClient from "@/api";
const vimeoApiInstance = new ApiClient(null, { baseURL: vimeo.baseUrl }, [
  "vimeo",
]);

export default (apiInstance) => ({
  async reqGetProductsStaff(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      promotionYn,
      productType,
      limit = 20,
      sort,
      order,
      salesStatus,
      first = true,
      price,
      local = false,
      includeCommId,
      excludeCommId,
      allYn = false,
      categoryId,
      productTypes,
    } = payload;
    if (!local && first) {
      store.commit("clearProductsStaff");
    }
    const lastKey = store.getters["getProductsStaffLastKey"];

    const result = await apiInstance.products.getProducts({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(promotionYn && { promotionYn }),
      ...(productType && { productType }),
      ...(limit && !allYn && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(salesStatus && { salesStatus }),
      ...(price && { price }),
      ...(lastKey && { lastKey }),
      ...(includeCommId && { includeCommId }),
      ...(excludeCommId && { excludeCommId }),
      ...(allYn && { allYn }),
      ...(categoryId && { categoryId }),
      ...(productTypes?.length > 0 && { productTypes }),
    });
    if (!local && result.success) {
      store.commit("updateProductsStaff", result.data);
    }
    return result;
  },
  async reqGetProductsUser(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      promotionYn,
      productType,
      limit = 20,
      sort,
      order,
      salesStatus,
      categoryIds,
      first = true,
      menu,
    } = payload;
    if (first) {
      store.commit("clearProductsUser");
    }
    const lastKey = store.getters["getProductsUserLastKey"];

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getSearch = isMember
      ? apiInstance.products.getSearch
      : apiInstance.products.getSearchPublic;

    const result = await getSearch({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(promotionYn && { promotionYn }),
      ...(productType && { productType }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(salesStatus && { salesStatus }),
      ...(lastKey && { lastKey }),
      ...(categoryIds?.length > 0 && { categoryIds }),
      ...(menu && { menu }),
    });
    if (result?.success) {
      const SET_PRODUCTS = first ? "setProductsUser" : "updateProductsUser";
      store.commit(SET_PRODUCTS, result.data);
    }
    return result;
  },
  async reqGetProduct(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id, local = false } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.products.getProduct({
      memberId,
      campusId,
      id,
    });
    if (result.success) {
      const { data } = result;
      const newContents =
        data?.CONTENTS?.map((content) => {
          const items = content.items?.map((item) => {
            const children = item.items?.map((child) => {
              const descendants = child.items?.map((descendant) => {
                const id = descendant.id;
                const upperId = descendant.upperId;
                return {
                  ...descendant,
                  ...(descendant.syncYn && descendant.origin),
                  ...(id && { id }),
                  ...(upperId && { upperId }),
                  ...(descendant.contentType === ContentTypeEnum.QUESTION &&
                    descendant.questionType === "SBJT" &&
                    !descendant.sbjtAns && {
                      sbjtAns: [],
                    }),
                  ...(descendant.codeYn &&
                    !descendant.codeLang && {
                      codeLang: [],
                    }),
                  ...(descendant.codeYn &&
                    !descendant.codeFiles && {
                      codeFiles: [],
                    }),
                  ...(descendant.codeYn &&
                    !descendant.testSet && {
                      testSet: [
                        {
                          stdin: "",
                          stdout: "",
                        },
                      ],
                    }),
                };
              });
              const id = child.id;
              const upperId = child.upperId;
              return {
                ...child,
                ...(child.syncYn && child.origin),
                ...(descendants && { items: descendants }),
                ...(id && { id }),
                ...(upperId && { upperId }),
                ...(child.contentType === ContentTypeEnum.QUESTION &&
                  child.questionType === "SBJT" &&
                  !child.sbjtAns && {
                    sbjtAns: [],
                  }),
                ...(child.codeYn &&
                  !child.codeLang && {
                    codeLang: [],
                  }),
                ...(child.codeYn &&
                  !child.codeFiles && {
                    codeFiles: [],
                  }),
                ...(child.codeYn &&
                  !child.testSet && {
                    testSet: [
                      {
                        stdin: "",
                        stdout: "",
                      },
                    ],
                  }),
              };
            });
            const id = item.id;
            const upperId = item.upperId;
            return {
              ...item,
              ...(item.syncYn && item.origin),
              ...(children && { items: children }),
              ...(id && { id }),
              ...(upperId && { upperId }),
              ...(item.contentType === ContentTypeEnum.QUESTION &&
                item.questionType === "SBJT" &&
                !item.sbjtAns && {
                  sbjtAns: [],
                }),
              ...(item.codeYn &&
                !item.codeLang && {
                  codeLang: [],
                }),
              ...(item.codeYn &&
                !item.codeFiles && {
                  codeFiles: [],
                }),
              ...(item.codeYn &&
                !item.testSet && {
                  testSet: [
                    {
                      stdin: "",
                      stdout: "",
                    },
                  ],
                }),
            };
          });
          const id = content.id;
          const upperId = content.upperId;
          return {
            ...content,
            ...(content.syncYn && content.origin),
            ...(items && { items }),
            ...(id && { id }),
            ...(upperId && { upperId }),
            ...(content.codeYn &&
              !content.codeLang && {
                codeLang: [],
              }),
            ...(content.codeYn &&
              !content.codeFiles && {
                codeFiles: [],
              }),
            ...(content.codeYn &&
              !content.testSet && {
                testSet: [
                  {
                    stdin: "",
                    stdout: "",
                  },
                ],
              }),
          };
        }) || [];
      if (!local) {
        store.commit("setNewProduct", {
          ...data,
          CONTENTS: newContents,
          startDttm:
            data.startDttm &&
            data.startDttm !== new Date(99999999999999).toJSON()
              ? getDateFormat(data.startDttm, "yyyy-MM-dd HH:mm")
              : 99999999999999,
          endDttm:
            data.endDttm && data.endDttm !== new Date(99999999999999).toJSON()
              ? getDateFormat(data.endDttm, "yyyy-MM-dd HH:mm")
              : 99999999999999,
          SETTING: {
            ...data.SETTING,
            completion: {
              compRate: Math.floor(
                (data.SETTING?.completion?.compRate || 0.8) * 100
              ),
              contents: getCompletionContents(
                data.SETTING?.completion?.contents,
                100
              ),
            },
          },
          DESC: {
            ...data.DESC,
            links: data.DESC.links?.length > 0 ? data.DESC.links : [""],
            managers: data.DESC.managers || [],
          },
        });
        if (data.DESC?.managers?.length > 0) {
          store.commit("updateLocalOptions", {
            managersViewYn: true,
          });
        }
        if (Number(data.limit) > 0) {
          store.commit("updateLocalOptions", {
            limitYn: true,
          });
        }
        if (data.productType === "SALES" && Number(data.price) === 0) {
          store.commit("updateLocalOptions", {
            freeYn: true,
          });
        }
        store.commit("setProduct", { ...result.data, CONTENTS: newContents });
      }
      return { ...result, data: { ...result.data, CONTENTS: newContents } };
    } else {
      return result;
    }
  },
  async reqGetReservation(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.products.getProduct({
      memberId,
      campusId,
      id,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async putProduct(store) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const {
      image,
      period,
      limit,
      startDttm,
      refundPolicyId,
      endDttm,
      DELETE_CONTENTS,
      communityIds,
      DESC,
      tags,
      CONTENTS,
      price,
      joinType,
      name,
      SETTING,
      classfiedYn,
      categoryId,
      productType,
      id,
      publishYn,
    } = store.getters["getNewProduct"];
    const altProduct = store.rootGetters["campuses/altProduct"];
    if (!id) {
      return {
        success: false,
        message: `${altProduct}를 찾을 수 없습니다.`,
      };
    }
    // VALIDATION START
    if (!name) {
      return {
        success: false,
        message: `기본 정보 > ${altProduct} 이름을 입력해주세요.`,
      };
    }
    const nPrice = Number(String(price).replaceAll(",", ""));
    if (productType === "SALES" && 0 < nPrice && nPrice < 1000) {
      return {
        success: false,
        message:
          "운영 및 판매 설정 > 무료 또는 1,000원 이상의 가격으로만 판매 가능합니다.",
      };
    }

    // if (productType === "SALES" && nPrice < 1000 && classfiedYn) {
    //   return {
    //     success: false,
    //     message:
    //       "운영 및 판매 설정 > 무료로 판매하기 선택 시, 비공개 상태로 판매를 할 수 없습니다.",
    //   };
    // }

    const checkName = (content) => {
      if (!content.name) {
        return false;
      }
      return true;
    };

    const noName = CONTENTS?.some(
      (first) =>
        !checkName(first) ||
        first.items?.some(
          (second) =>
            !checkName(second) ||
            second.items?.some(
              (third) =>
                !checkName(third) ||
                third.items?.some((fourth) => !checkName(fourth))
            )
        )
    );

    if (noName) {
      return {
        success: false,
        message: "콘텐츠 구성 > 콘텐츠 이름을 입력해주세요.",
      };
    }

    if (!store.rootGetters["campuses/checkServeYn"]("QUIZ")) {
      const hasQuiz = CONTENTS?.some(
        (item) =>
          item.contentType === ContentTypeEnum.QUIZ ||
          item.items?.some(
            (child) =>
              child.contentType === ContentTypeEnum.QUIZ ||
              child.items?.some(
                (descendant) => descendant.contentType === ContentTypeEnum.QUIZ
              )
          )
      );
      if (hasQuiz) {
        return {
          success: false,
          message: "퀴즈 콘텐츠는 현재 요금 플랜에서 사용하실 수 없습니다.",
        };
      }
    }

    const checkVideoUploadDone = (content) => {
      if (
        content.contentType !== ContentTypeEnum.VIDEO &&
        content.lowContentType !== ContentTypeEnum.VIDEO
      ) {
        return true;
      }
      if (content.syncYn) {
        return true;
      }
      if (content.vod?.id || content.vod?.url) {
        return true;
      }
      if (content.clip?.url && content.clip?.time) {
        return true;
      }
      if (!(content.vod?.id || content.vod?.url) && !content.clip?.url) {
        return true;
      }
      return false;
    };

    const noVideo = CONTENTS?.some(
      (item) =>
        !checkVideoUploadDone(item) ||
        item.items?.some(
          (child) =>
            !checkVideoUploadDone(child) ||
            child.items?.some((descendant) => !checkVideoUploadDone(descendant))
        )
    );

    if (noVideo) {
      const refreshVimeo = async (content) => {
        if (content.contentType !== ContentTypeEnum.VIDEO) {
          return content;
        }
        if (content.clip?.url && !content.clip?.time) {
          const videoUri = content.clip?.url;
          const result = await vimeoApiInstance.vimeo.getVideo({
            videoUri,
          });
          if (result.success) {
            const { duration } = result.data;
            if (duration) {
              return {
                ...content,
                clip: {
                  ...content.clip,
                  time: duration,
                },
              };
            }
          }
        }
        return content;
      };
      const refreshContents = await Promise.all(
        CONTENTS?.map(async (content) => {
          if (content.items?.length > 0) {
            await Promise.all(
              content.items.map(async (child) => {
                if (child.items?.length > 0) {
                  await Promise.all(
                    child.items.map(async (descendant) =>
                      refreshVimeo(descendant)
                    )
                  );
                }
                return refreshVimeo(child);
              })
            );
          }
          return refreshVimeo(content);
        })
      );
      store.commit("updateNewProductContents", refreshContents);
      return {
        success: false,
        message:
          "<p>현재 동영상 업로드 중입니다. 업로드 완료 및 변환 준비가 끝난 이후 저장이 가능합니다.</p>",
      };
    }

    const checkNoCodeLang = (content) => {
      if (!content.codeYn) {
        return true;
      }
      return content.codeLang?.length > 0;
    };

    const noCodeLang = CONTENTS?.some(
      (first) =>
        !checkNoCodeLang(first) ||
        first.items?.some(
          (second) =>
            !checkNoCodeLang(second) ||
            second.items?.some(
              (third) =>
                !checkNoCodeLang(third) ||
                third.items?.some((fourth) => !checkNoCodeLang(fourth))
            )
        )
    );

    if (noCodeLang) {
      return {
        success: false,
        message: "코드 에디터 제공 시 실습 파일 유형을 선택해주세요.",
      };
    }

    const noSealName =
      SETTING?.certificate?.yn &&
      SETTING?.certificate?.seals?.some((seal) => !seal.name);

    if (noSealName) {
      return {
        success: false,
        message: "직인의 발급기관 이름을 입력해주세요.",
      };
    }

    if (
      SETTING?.certificate?.yn &&
      SETTING?.certificate?.dttmYn &&
      (!SETTING?.certificate?.startDttm || !SETTING?.certificate?.endDttm)
    ) {
      return {
        success: false,
        message: "수료증에 표시될 교육기간을 입력해주세요.",
      };
    }
    // VALIDATION END

    const newContents = CONTENTS?.map((content) => {
      delete content.tempId;
      delete content.hide;
      const items = content.items?.map((item) => {
        delete item.tempId;
        delete item.hide;
        const children = item.items?.map((child) => {
          delete child.tempId;
          delete child.hide;
          const descendants = child.items?.map((descendant) => {
            delete descendant.tempId;
            delete descendant.hide;
            return {
              ...(descendant.syncYn
                ? {
                    contentType: descendant.contentType,
                    syncYn: descendant.syncYn,
                    originId: descendant.originId,
                  }
                : descendant),
              ...(descendant?.id && { id: descendant.id }),
              previewYn: descendant.previewYn,
              accessDttm: new Date(descendant.accessDttm).toJSON() || "",
              ...(descendant.contentType === ContentTypeEnum.LIVE && {
                startDttm: new Date(descendant.startDttm).toJSON() || "",
                endDttm: new Date(descendant.endDttm).toJSON() || "",
              }),
              ...(descendant.contentType === ContentTypeEnum.QUIZ && {
                options: {
                  quiz: {
                    ...descendant.options.quiz,
                    startDttm:
                      descendant.options.quiz.limitType === "PERIOD" &&
                      descendant.options.quiz.startDttm
                        ? new Date(descendant.options.quiz.startDttm).toJSON()
                        : "",
                    endDttm:
                      descendant.options.quiz.limitType === "PERIOD" &&
                      descendant.options.quiz.endDttm
                        ? new Date(descendant.options.quiz.endDttm).toJSON()
                        : "",
                  },
                },
              }),
            };
          });
          return {
            ...(child.syncYn
              ? {
                  contentType: child.contentType,
                  syncYn: child.syncYn,
                  originId: child.originId,
                }
              : child),
            ...(descendants && { items: descendants }),
            ...(child?.id && { id: child.id }),
            previewYn: child.previewYn,
            accessDttm: new Date(child.accessDttm).toJSON() || "",
            ...(child.contentType === ContentTypeEnum.LIVE && {
              startDttm: new Date(child.startDttm).toJSON() || "",
              endDttm: new Date(child.endDttm).toJSON() || "",
            }),
            ...(child.contentType === ContentTypeEnum.QUIZ && {
              options: {
                quiz: {
                  ...child.options.quiz,
                  startDttm:
                    child.options.quiz.limitType === "PERIOD" &&
                    child.options.quiz.startDttm
                      ? new Date(child.options.quiz.startDttm).toJSON()
                      : "",
                  endDttm:
                    child.options.quiz.limitType === "PERIOD" &&
                    child.options.quiz.endDttm
                      ? new Date(child.options.quiz.endDttm).toJSON()
                      : "",
                },
              },
            }),
          };
        });
        return {
          ...(item.syncYn
            ? {
                contentType: item.contentType,
                syncYn: item.syncYn,
                originId: item.originId,
              }
            : item),
          ...(children && { items: children }),
          ...(item?.id && { id: item.id }),
          previewYn: item.previewYn,
          accessDttm: new Date(item.accessDttm).toJSON() || "",
          ...(item.contentType === ContentTypeEnum.LIVE && {
            startDttm: new Date(item.startDttm).toJSON() || "",
            endDttm: new Date(item.endDttm).toJSON() || "",
          }),
          ...(item.contentType === ContentTypeEnum.QUIZ && {
            options: {
              quiz: {
                ...item.options.quiz,
                startDttm:
                  item.options.quiz.limitType === "PERIOD" &&
                  item.options.quiz.startDttm
                    ? new Date(item.options.quiz.startDttm).toJSON()
                    : "",
                endDttm:
                  item.options.quiz.limitType === "PERIOD" &&
                  item.options.quiz.endDttm
                    ? new Date(item.options.quiz.endDttm).toJSON()
                    : "",
              },
            },
          }),
        };
      });
      return {
        ...(content.syncYn
          ? {
              contentType: content.contentType,
              syncYn: content.syncYn,
              originId: content.originId,
            }
          : content),
        ...(items && { items }),
        ...(content?.id && { id: content.id }),
        previewYn: content.previewYn,
        accessDttm: new Date(content.accessDttm).toJSON() || "",
        ...(content.contentType === ContentTypeEnum.LIVE && {
          startDttm: new Date(content.startDttm).toJSON() || "",
          endDttm: new Date(content.endDttm).toJSON() || "",
        }),
        ...(content.contentType === ContentTypeEnum.QUIZ && {
          options: {
            quiz: {
              ...content.options.quiz,
              startDttm:
                content.options.quiz.limitType === "PERIOD" &&
                content.options.quiz.startDttm
                  ? new Date(content.options.quiz.startDttm).toJSON()
                  : "",
              endDttm:
                content.options.quiz.limitType === "PERIOD" &&
                content.options.quiz.endDttm
                  ? new Date(content.options.quiz.endDttm).toJSON()
                  : "",
            },
          },
        }),
      };
    });

    const managers = !store.getters["getLocalOptions"].managersViewYn
      ? []
      : DESC?.managers?.map((manager) => {
          const { memberId, name, introduce, image } = manager;
          return {
            ...(memberId && { memberId }),
            ...(name && { name }),
            ...(introduce && { introduce }),
            ...(image && { image }),
          };
        }) || [];

    const pauseYn = !!(SETTING.pauseDays && SETTING.pauseTimes);

    const result = await apiInstance.products.putProduct({
      image,
      period: Number(String(period).replaceAll(",", "")),
      limit: Number(String(limit).replaceAll(",", "")),
      price: nPrice,
      startDttm: new Date(startDttm).toJSON(),
      ...(productType === "SALES" && refundPolicyId && { refundPolicyId }),
      endDttm: new Date(endDttm).toJSON(),
      DELETE_CONTENTS,
      communityIds,
      DESC: {
        ...DESC,
        campusId,
        managers,
      },
      tags,
      CONTENTS: newContents,
      ...(joinType && { joinType }),
      name,
      SETTING: {
        ...SETTING,
        campusId,
        completion: {
          compRate: SETTING.completion.compRate * 0.01,
          contents: getCompletionContents(SETTING.completion.contents, 0.01),
        },
        pauseYn,
        pauseDays: pauseYn ? SETTING.pauseDays : 0,
        pauseTimes: pauseYn ? SETTING.pauseTimes : 0,
      },
      categoryId,
      productType,
      campusId,
      memberId,
      publishYn: classfiedYn ? false : publishYn,
      id,
      classfiedYn: classfiedYn || false,
      ...(productType === "BASIC" &&
        !classfiedYn &&
        joinType && {
          joinType,
        }),
    });

    return result;
  },
  async deleteProduct(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { id } = payload;
    const result = await apiInstance.products.deleteProduct({
      memberId,
      campusId,
      id,
    });
    if (result.success) {
      store.commit("removeProduct", id);
    }
    return result;
  },
  async reqGetCategories(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      manageYn = false,
    } = payload;
    const result = await apiInstance.products.getCategories({
      campusId,
      ...(manageYn && { manageYn }),
    });
    if (result.success) {
      store.commit("setCategories", result.data?.categories);
    }
    return result;
  },
  async postCategories(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { categories = [] } = payload;

    const result = await apiInstance.products.postCategories({
      campusId,
      memberId,
      categories,
    });

    if (result.success) {
      store.commit("setCategories", result.data?.categories);
    }
    return result;
  },
  async putProductStatus(store, payload = {}) {
    const {
      publishYn,
      startDttm,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      endDttm,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      table,
    } = payload;
    const result = await apiInstance.products.putStatus({
      id,
      publishYn,
      ...(startDttm && { startDttm }),
      ...(endDttm && { endDttm }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      const UPDATE_MUTATION = table ? "updateProducts" : "setProduct";
      store.commit(UPDATE_MUTATION, result.data);
    }
    return result;
  },
  async reqGetDetail(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getDetail = isMember
      ? apiInstance.products.getDetail
      : apiInstance.products.getDetailPublic;
    const result = await getDetail({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("setProduct", result.data);
      store.commit("members/setProductMember", result.data.productMember, {
        root: true,
      });
    }
    return result;
  },
  async postPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      id,
      name,
      promoPreYn,
      promoRatio,
      promoPrice,
      promoLimit,
      startDttm,
      endDttm,
      closeType,
      color,
    } = payload;

    const result = await apiInstance.products.postPromotion({
      campusId,
      memberId,
      productId,
      id,
      name,
      promoPreYn,
      promoRatio,
      promoPrice,
      promoLimit,
      startDttm,
      endDttm,
      closeType,
      color,
    });
    if (result.success) {
      store.commit("setPromotion", result.data);
    }

    return result;
  },
  async reqGetPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;

    const result = await apiInstance.products.getPromotion({
      campusId,
      memberId,
      id,
    });
    if (result.success) {
      store.commit("setPromotion", result.data);
    }
    return result;
  },
  async reqGetPromotions(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      promotionStatus,
      promoPreYn,
      keyword,
      first = true,
      sort,
      order,
      lastKey,
      allYn = false, // 필터 > 프로모션 옵션 조회 시 limit 임시 처리
    } = payload;

    if (first) {
      store.commit("clearPromotions");
    }
    const result = await apiInstance.products.getPromotions({
      campusId,
      memberId,
      productId,
      promotionStatus,
      promoPreYn,
      keyword,
      first,
      sort,
      order,
      lastKey,
      ...(allYn && { limit: 100 }),
    });
    if (result.success) {
      store.commit("setPromotions", result.data);
    }
    return result;
  },
  async deletePromotion(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { productId, id } = payload;
    const result = await apiInstance.products.deletePromotion({
      campusId,
      memberId,
      productId,
      id,
    });
    return result;
  },
  async putPromotion(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      promoPreYn,
      promoLimit,
      promoRatio,
      productId,
      color,
      startDttm,
      endDttm,
      closeType,
      promoPrice,
      name,
    } = payload;
    const result = await apiInstance.products.putPromotion({
      campusId,
      memberId,
      id,
      promoPreYn,
      promoLimit,
      promoRatio,
      productId,
      color,
      startDttm,
      endDttm,
      closeType,
      promoPrice,
      name,
    });
    return result;
  },
  async putCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      changeType, // CREATE, DELETE
      targets,
    } = payload;
    if (!changeType || !targets || !productId) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.products.putCommunities({
      productId,
      targets,
      changeType,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    return result;
  },
  async postLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      startUrl,
      productId,
      startDttm,
      service,
      contentId,
      endDttm,
      joinUrl,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(startUrl && { startUrl }),
      ...(productId && { productId }),
      ...(startDttm && { startDttm }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(endDttm && { endDttm }),
      ...(joinUrl && { joinUrl }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
    };
    const result = await apiInstance.products.postLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async putLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      startUrl,
      productId,
      startDttm,
      service,
      contentId,
      endDttm,
      joinUrl,
      meetingId,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(startUrl && { startUrl }),
      ...(productId && { productId }),
      ...(startDttm && { startDttm }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(endDttm && { endDttm }),
      ...(joinUrl && { joinUrl }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(meetingId && { meetingId }),
    };
    const result = await apiInstance.products.putLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async deleteLive(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      redirectUri,
      productId,
      service,
      contentId,
      meetingId,
    } = payload;
    const data = {
      ...(redirectUri && { redirectUri }),
      ...(productId && { productId }),
      ...(service && { service }),
      ...(campusId && { campusId }),
      ...(contentId && { contentId }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(meetingId && { meetingId }),
    };
    const result = await apiInstance.products.deleteLive(data);
    if (result.success) {
      store.commit("updateProduct", {
        CONTENTS: result.data || [],
      });
    }
    return result;
  },
  async getOgTags(_, payload = {}) {
    const { url } = payload;
    return apiInstance.products.getOgTags({ url });
  },

  //담당자 관리
  async reqGetProductDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    // if (first) {
    //   store.commit("clearCommunityMembers");
    // }
    const result = await apiInstance.products.getProductDelegators({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setProductDelegators", result.data);
    }
    return result;
  },
  async reqPostProductDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      changeType,
      targets,
    } = payload;
    const result = await apiInstance.products.postProductDelegators({
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(targets && { targets }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async postProductSales(store, payload = {}) {
    const { name = "프로덕트" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemeberId"];
    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/product.png",
            key: "DEFAULT",
          },
        ],
      },
      period: 0,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
      DELETE_CONTENTS: [],
      communityIds: [],
      DESC: {
        links: [""],
        managers: [],
        introduce: "",
        campusId,
      },
      CONTENTS: [],
      price: 1000,
      limit: 0,
      name,
      SETTING: {
        options: {
          sequencialYn: false,
          video: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
          audio: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
        },
        completion: {
          compRate: 0.8,
          contents: [
            {
              contentType: "VIDEO",
              compRate: 0.8,
            },
            {
              contentType: "AUDIO",
              condition: "",
              compRate: 0.8,
            },
          ],
        },
        certificate: {
          dttmYn: false,
          yn: false,
          startDttm: "",
          seals: [],
          endDttm: "",
          title: "수료증",
          desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
        },
        campusId,
        pauseYn: false,
        pauseDays: 0,
        pauseTimes: 0,
      },
      productType: "SALES",
      campusId,
      memberId,
    });
    return result;
  },
  async postProductBasic(store, payload = {}) {
    const { name = "프로덕트" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemeberId"];
    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/product.png",
            key: "DEFAULT",
          },
        ],
      },
      period: 0,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
      DELETE_CONTENTS: [],
      communityIds: [],
      DESC: {
        links: [""],
        managers: [],
        introduce: "",
        campusId,
      },
      CONTENTS: [],
      price: 0,
      limit: 0,
      name,
      SETTING: {
        options: {
          sequencialYn: false,
          video: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
          audio: {
            seekYn: true,
            playbackYn: true,
            multiple: 0,
          },
        },
        completion: {
          compRate: 0.8,
          contents: [
            {
              contentType: "VIDEO",
              compRate: 0.8,
            },
            {
              contentType: "AUDIO",
              condition: "",
              compRate: 0.8,
            },
          ],
        },
        certificate: {
          dttmYn: false,
          yn: false,
          startDttm: "",
          seals: [],
          endDttm: "",
          title: "수료증",
          desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
        },
        campusId,
        pauseYn: false,
        pauseDays: 0,
        pauseTimes: 0,
      },
      productType: "BASIC",
      campusId,
      memberId,
      classfiedYn: false,
      joinType: "AUTO",
    });
    return result;
  },
  async postProductReservation(store, payload = {}) {
    const { name = "예약" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemeberId"];
    const today = new Date();
    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/Reservation.png",
            key: "DEFAULT",
          },
        ],
      },
      communityIds: [],
      DESC: {
        campusId,
        introduce: "",
        inquiry: {
          etc: "",
          contact: "",
        },
      },
      OPTIONS: [
        {
          name: "예약 옵션",
          contentType: "OPTION",
          publishYn: false,
          image: {
            conts: "",
            textColor: "black",
            horizontal: "CENTER",
            nameYn: false,
            items: [
              {
                path: "https://file.poincampus.com/assets/sample/ReservationOption.png",
                key: "DEFAULT",
              },
            ],
          },
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: "",
          campusId,
          toDttm: today.toJSON(),
          fromDttm: today.toJSON(),
          inquiry: {
            etc: "",
            contact: "",
          },
          place: {
            pre: "",
            post: "",
            lon: 0,
            lat: 0,
            desc: "",
          },
          seq: 0,
          desc: "",
          items: [
            {
              contentType: "PRICE",
              name: "가격 옵션",
              desc: "",
              campusId,
              price: 1000,
              groupPrices: [],
              limit: 0,
              seq: 0,
            },
          ],
        },
      ],
      custAdditional: {
        desc: "",
        items: [],
      },
      limit: 0,
      name,
      productType: "RESERVATION",
      campusId,
      memberId,
      classfiedYn: false,
      refundPolicyId: "",
      publishYn: false,
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
    });
    return result;
  },
  async putReservationDefault(store) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const {
      image,
      refundPolicyId,
      DESC,
      custAdditional,
      tags = "",
      name,
      classfiedYn,
      categoryId,
      productType,
      id,
      publishYn,
    } = store.getters["getReservation"];
    const result = await apiInstance.products.putProduct({
      image,
      refundPolicyId,
      custAdditional,
      DESC,
      tags,
      name,
      classfiedYn,
      categoryId,
      productType,
      id,
      publishYn,
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async putReservationOptionStatus(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { productId, id, publishYn, startDttm } = payload;
    const result = await apiInstance.products.putProduct({
      id: productId,
      productType: "RESERVATION",
      OPTIONS: [
        {
          id,
          campusId,
          contentType: "OPTION",
          publishYn,
          ...(startDttm && { startDttm }),
        },
      ],
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async putReservationOption(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id, option } = payload;
    const result = await apiInstance.products.putProduct({
      id,
      productType: "RESERVATION",
      OPTIONS: [option],
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async deleteReservationOption(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { productId, id } = payload;
    const result = await apiInstance.products.putProduct({
      id: productId,
      productType: "RESERVATION",
      DELETE_OPTIONS: [id],
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async copyReservationOption(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { productId, option } = payload;
    const result = await apiInstance.products.putProduct({
      id: productId,
      productType: "RESERVATION",
      OPTIONS: [
        {
          name: option.name + "의 복사본",
          contentType: "OPTION",
          publishYn: false,
          image: option.image,
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: option.conts,
          campusId,
          toDttm: option.toDttm,
          fromDttm: option.fromDttm,
          inquiry: option.inquiry,
          place: option.place,
          seq: store.getters["getReservation"]?.OPTIONS?.length || 0,
          desc: option.desc,
          items: option.items,
        },
      ],
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async updateReservationOptionsSeq(store) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id, OPTIONS = [] } = store.getters["getReservation"];
    const newOptions = OPTIONS.map((option) => {
      return {
        id: option.id,
        contentType: "OPTION",
        seq: option.seq,
      };
    });
    const result = await apiInstance.products.putProduct({
      id,
      productType: "RESERVATION",
      OPTIONS: newOptions,
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async postReservationOption(store, payload = {}) {
    const { name = "예약 옵션" } = payload;
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id, OPTIONS = [] } = store.getters["getReservation"];
    const today = new Date();
    const result = await apiInstance.products.putProduct({
      id,
      campusId,
      memberId,
      productType: "RESERVATION",
      OPTIONS: [
        {
          name,
          contentType: "OPTION",
          publishYn: false,
          image: {
            conts: "",
            textColor: "black",
            horizontal: "CENTER",
            nameYn: false,
            items: [
              {
                path: "https://file.poincampus.com/assets/sample/ReservationOption.png",
                key: "DEFAULT",
              },
            ],
          },
          startDttm: new Date(99999999999999).toJSON(),
          endDttm: new Date(99999999999999).toJSON(),
          conts: "",
          campusId,
          fromDttm: today.toJSON(),
          toDttm: today.toJSON(),
          inquiry: {
            etc: "",
            contact: "",
          },
          place: {
            pre: "",
            post: "",
            lon: 0,
            lat: 0,
            desc: "",
          },
          seq: OPTIONS.length,
          desc: "",
          items: [
            {
              contentType: "PRICE",
              name: "가격 옵션",
              desc: "",
              campusId,
              price: 1000,
              groupPrices: [],
              limit: 0,
              seq: 0,
            },
          ],
        },
      ],
    });
    if (result?.success) {
      store.commit("setProduct", result.data);
      store.commit("setReservation", result.data);
    }
    return result;
  },
  async postProductClone(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.products.postProductClone({
      id,
      campusId,
      memberId,
    });
    return result;
  },
});

const getCompletionContents = (contents = [], x = 0.01) => {
  let newContents = [];
  const video = contents.find((content) => content.contentType === "VIDEO");
  const audio = contents.find((content) => content.contentType === "AUDIO");

  const newVideo = {
    ...video,
    compRate: (video?.compRate || 80) * x,
  };
  const newAudio = {
    ...audio,
    compRate: (audio?.compRate || 80) * x,
  };

  const videoIdx = contents.findIndex(
    (content) => content.contentType === "VIDEO"
  );
  newContents = [
    ...contents.slice(0, videoIdx),
    ...contents.slice(videoIdx + 1),
  ];

  const audioIdx = newContents.findIndex(
    (content) => content.contentType === "AUDIO"
  );
  newContents = [
    ...newContents.slice(0, audioIdx),
    ...newContents.slice(audioIdx + 1),
  ];

  newContents = [...newContents, newVideo, newAudio];

  return newContents;
};
