<template lang="html">
  <v-dialog
    :value="open"
    :max-width="374"
    persistent
    no-click-animation
    content-class="white progress-popup border-box rounded-lg"
  >
    <div class="progress-popup-header">
      <v-progress-circular
        v-if="!allFilesAborted && !allFilesSucceeded"
        indeterminate
        width="2"
        color="primary"
        size="16"
        class="mr-3"
      />
      <p class="black--text text-body-1-regular">{{ uploadTitle }}</p>
      <p-icon
        icon="Close/Gray8"
        @click="closeClick"
        class="ml-auto cursor-pointer"
      />
    </div>
    <div class="w-full py-3">
      <div
        v-for="(item, index) in progressItems"
        :key="index"
        class="d-flex flex-column px-4 py-3"
      >
        <div class="w-full d-flex align-center">
          <p-icon size="20" icon="Clip/Gray8" style="min-width: 20px" />
          <p class="text-body-2-regular single-line black--text ml-1 mr-3">
            {{ item.file.name }}
          </p>
          <p-icon
            style="min-width: 20px"
            v-if="!item.complete"
            icon="Close/Gray8"
            size="20"
            class="ml-auto cursor-pointer"
            @click="abortClick(index)"
          />

          <p-icon
            style="min-width: 20px"
            v-if="item.success"
            icon="Check/Success"
            size="20"
            class="ml-auto"
          />

          <p-icon-btn
            style="min-width: 20px"
            v-if="isUserAbort(item)"
            icon="Reset"
            color="Gray8"
            xx-small
            class="ml-auto"
            @click="$emit('handleRequest', { mode: 'redo', index })"
          />
        </div>

        <span
          v-if="item.err"
          style="min-width: fit-content"
          class="text-detail-1-regular w-max-content mt-2"
          :class="
            isUserAbort(item) ? 'gray--text text--darken-2' : 'error--text'
          "
          >{{ isUserAbort(item) ? "취소됨" : "실패" }}</span
        >

        <div class="progress-box" v-if="!item.err && !item.success">
          <v-progress-linear
            class="flex-fill my-2"
            color=" primary"
            :value="item.value"
            height="6"
            rounded
          />
          <div class="d-flex align-center">
            <span class="gray--text text--darken-2 text-detail-1-regular">{{
              uploadStatus(item)
            }}</span>
            <span class="black--text text-detail-1-bold ml-auto">{{
              Math.ceil(item.value) + "%"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "progress-popup",
  components: {},
  props: {
    progressItems: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      temp: true,
    };
  },
  computed: {
    allFilesAborted() {
      return this.progressItems?.every(
        (file) => file.err?.code === "RequestAbortedError"
      );
    },
    allFilesSucceeded() {
      return this.progressItems
        ?.filter((file) => !file.err)
        ?.every((file) => file.success);
    },
    uploadTitle() {
      const fileCnt = this.progressItems?.length;
      const successCnt = this.progressItems?.filter(
        (file) => file.success
      )?.length;

      let text = "업로드 중...";
      if (this.allFilesAborted) {
        return (text = "업로드 취소");
      }

      if (this.allFilesSucceeded) {
        text = "업로드 완료";
      }
      return `${text} (${successCnt} / ${fileCnt})`;
    },
  },
  methods: {
    isUserAbort(file) {
      return file.err?.code === "RequestAbortedError";
    },
    uploadStatus(file) {
      if (!file.err) {
        return !file.value ? "준비 중" : "업로드 및 변환 중";
      }
      return "";
    },
    getErrMsg(err) {
      let errMsg = "";
      switch (err.code) {
        case "RequestAbortedError":
          errMsg = "취소";
          break;

        default:
          errMsg = "오류 발생";
          break;
      }
      return errMsg;
    },
    closeClick() {
      const isComplete =
        this.progressItems.find((item) => !item.complete) === undefined;
      if (isComplete) {
        this.$emit("close");
        return;
      }
      this.$eventBus.$emit("confirmPopup", {
        open: true,
        title: "파일 업로드 전체 취소",
        msg: "파일 업로드를 모두 취소하시겠습니까?",
        callback: (confirm) => {
          if (confirm) {
            this.$emit("close");
          }
          this.$eventBus.$emit("confirmPopup", { open: false });
        },
      });
    },
    abortClick(index) {
      this.$eventBus.$emit("confirmPopup", {
        open: true,
        title: "파일 업로드 취소",
        msg: "파일 업로드를 취소하시겠습니까?",
        callback: (confirm) => {
          if (confirm) {
            this.$emit("handleRequest", { mode: "abort", index });
          }
          this.$eventBus.$emit("confirmPopup", { open: false });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.progress-popup {
  overflow: hidden;

  .progress-popup-header {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray3;
    padding: 16px;
  }

  .progress-items-container {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    padding: 16px;
    .progress-item {
      margin-bottom: 4px;
      .file-name {
        font-size: 12px;
        &.err {
          color: $error;
        }
      }
      .progress-content {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
