export default {
  setPlaylist(state, products = []) {
    state.playlist = [...products];
  },
  clearPlaylist(state) {
    state.playlist = [];
  },
  setPlaylistAutoPlayYn(state, yn) {
    state.playlistAutoPlayYn = yn;
  },
  setActivities(state, data) {
    state.activities = {
      lastKey: data.lastKey,
      items: [...state.activities.items, ...data.results],
    };
  },
  clearActivities(state) {
    state.activities = {
      lastKey: "",
      items: [],
    };
  },
  setRoomProduct(state, product) {
    state.roomProduct = product ? { ...product } : null;
  },
  clearRoomProduct(state) {
    state.roomProduct = null;
  },
  setRoomContents(state, contents = []) {
    state.roomContents = [...contents];
  },
  clearRoomContents(state) {
    state.roomContents = [];
  },
  setRoomContent(state, content) {
    state.roomContent = content ? { ...content } : null;
  },
  updateActivity(state, activity) {
    if (activity) {
      state.roomContent = {
        ...state.roomContent,
        activity,
      };

      const findFn = (content) => {
        if (content.id === activity.contentId) {
          return {
            ...content,
            activity,
          };
        }
        return content;
      };

      state.roomContents = state.roomContents.map((content) => {
        if (content?.items?.length > 0) {
          content.items = content.items.map((child) => {
            if (child?.items?.length > 0) {
              child.items = child.items.map((descendant) => {
                return findFn(descendant);
              });
            }
            return findFn(child);
          });
        }
        return findFn(content);
      });
    }
  },
  updateReplyCnt(state, cnt = 0) {
    if (!state.roomContent) {
      return;
    }
    const prevCnt = state.roomContent.replyCnt || 0;
    const replyCnt = prevCnt + cnt;
    if (replyCnt < 0) {
      return;
    }
    state.roomContent = { ...state.roomContent, replyCnt };
  },
  setQuestions(state, questions = []) {
    state.questions = questions;
  },
  updateQuestions(state, question) {
    state.questions = state.questions?.map((item) => {
      if (item.id === question.id) {
        return {
          ...item,
          ...question,
        };
      }
      return item;
    });
  },
  updateQuestionMark(state, activity) {
    state.questions = state.questions?.map((item) => {
      if (item?.activity?.id && item?.activity?.id === activity?.id) {
        return {
          ...item,
          activity,
        };
      }
      return item;
    });
    const newScore = activity?.score || 0;
    const prevScore = activity?.prevScore || 0;
    const quizScore = state.quiz?.activity?.score || 0;
    const score = quizScore - prevScore + newScore;
    state.quiz = {
      ...state.quiz,
      ...(state.quiz?.activity && {
        activity: { ...state.quiz.activity, score },
      }),
    };
  },
  setCurrentIndex(state, index) {
    if (typeof index !== "number") {
      return;
    }
    state.currentIndex = index;
  },
  setQuiz(state, quiz = {}) {
    state.quiz = quiz;
  },
  setQuizProgress(state, progress = "STANDBY") {
    state.quizProgress = progress; // 'STANDBY', 'PROGRESS', 'DONE'
  },
  setCurrentTab(state, value) {
    state.currentTab = value;
  },
  setQuizStats(state, result) {
    state.quizStats = result;
  },
  toggleAside(state) {
    state.aside = !state.aside;
  },
  setApplication(state, application = {}) {
    state.application = application;
  },
  setApplicationProgress(state, progress = "STANDBY") {
    state.applicationProgress = progress; // 'STANDBY', 'PROGRESS', 'DONE'
  },
  setApplicationStats(state, result) {
    state.applicationStats = result;
  },
  setApplicationStatUsers(state, data) {
    state.applicationStatUsers = {
      lastKey: data.lastKey,
      items: [...state.applicationStatUsers.items, ...data.results],
    };
  },
  clearApplicationStatUsers(state) {
    state.applicationStatUsers = {
      lastKey: "",
      items: [],
    };
  },
};
