var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticky__container w-full",style:(_vm.variables)},[_c('div',{staticClass:"sticky__wrapper",class:_vm.backgroundColor,style:(`
        min-width: ${_vm.contentWidth}px;
        width: ${_vm.contentWidth}px
      `)},[_c('div',{staticClass:"sticky--line",class:{
        'justify-space-between': !_vm.$scopedSlots['customTitle'],
        'gray-3-border-bottom ': _vm.borderBottom,
        'mb-5': !_vm.sticky,
      }},[(_vm.onSticky && !_vm.title && !_vm.hideBack)?_c('p-icon-btn',{attrs:{"icon":"LeftArrow"},on:{"click":function($event){return _vm.$emit('click')}}}):_vm._e(),(_vm.title)?_c('h2',{staticClass:"text-h2-medium black--text",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.$scopedSlots['customTitle'])?_vm._t("customTitle"):_vm._e(),_vm._t("default")],2)]),(_vm.text)?_c('div',{staticClass:"text--line mb-5",class:{ 'mb-5': !_vm.sticky }},[_c('h2',{staticClass:"text-h2-medium black--text",domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }