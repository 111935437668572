<template>
  <v-checkbox
    :input-value="value"
    @change="updateInput"
    color="primary"
    :off-icon="disabled || readonly ? '$checkbox-disabled' : '$checkbox-blank'"
    :on-icon="
      disabled || readonly ? '$checkbox-disabled-on' : '$checkbox-checked'
    "
    :size="size"
    hide-details
    class="mt-0 pt-0"
    :readonly="readonly"
    :disabled="disabled"
  >
    <template v-slot:label>
      <span
        v-if="label"
        class="text-body-2-regular"
        :class="disabled ? 'gray--text' : 'black--text'"
        >{{ label }}</span
      >
      <slot v-else />
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "PoinUiCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 20,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls__ripple {
    opacity: 0;
  }
}
</style>
