//프로덕트
exports.Notification = {
  PRODUCT_NOTICE: "PRODUCT_NOTICE", //프로덕트 공지등록 : 프로덕트진행-공지사항
  PRODUCT_MEMBER_JOIN_REQUEST_TO_MANAGER:
    "PRODUCT_MEMBER_JOIN_REQUEST_TO_MANAGER", //운영 프로덕트 참여신청 : 캠퍼스관리-프로덕트-참여자관리
  PRODUCT_MEMBER_JOIN_APPROVAL: "PRODUCT_MEMBER_JOIN_APPROVAL", //참여 신청 승인 : 내 참여현황-프로덕트 상세페이지
  PRODUCT_MEMBER_JOIN_REJECT: "PRODUCT_MEMBER_JOIN_REJECT", //참여 신청 거절
  PRODUCT_MEMBER_REMOVE: "PRODUCT_MEMBER_REMOVE", //프로덕트 회원 내보내기 : 프로덕트 상세페이지
  PRODUCT_MEMBER_ADD: "PRODUCT_MEMBER_ADD", //프로덕트 회원 (관리자) 추기 : 내 참여현황-프로덕트 상세페이지

  PRODUCT_PAY_COMPLETE_TO_MANAGER: "PRODUCT_PAY_COMPLETE_TO_MANAGER", // 관리자 > 회원 프로덕트 구매 완료 안내
  PRODUCT_PAY_CANCEL_REQUEST_TO_MANAGER:
    "PRODUCT_PAY_CANCEL_REQUEST_TO_MANAGER", //회원 > 프로덕트 결제취소 요청
  PRODUCT_PAY_COMPLETE: "PRODUCT_PAY_COMPLETE", //회원 > 결제완료
  PRODUCT_PAY_CANCEL_COMPLETE: "PRODUCT_PAY_CANCEL_COMPLETE", //회원 > 결제 취소완료
  PRODUCT_PAY_CANCEL_REJECT: "PRODUCT_PAY_CANCEL_REJECT", //회원 > 결제 취소 거절
  PRODUCT_REVIEW_CREATE_TO_MANAGER: "PRODUCT_REVIEW_CREATE_TO_MANAGER", //관리자 > 회원 리뷰 등록
  PRODUCT_REVIEW_REPLY: "PRODUCT_REVIEW_REPLY", //회원 > 관리자 리뷰 답변 등록

  PRODUCT_APPLICATION_REGISTER: "PRODUCT_APPLICATION_REGISTER", //관리자 > 회원 신청서 제출
  PRODUCT_APPLICATION_STATUS_PICK: "PRODUCT_APPLICATION_STATUS_PICK", //회원 > 신청서 선정
  PRODUCT_APPLICATION_STATUS_DROP: "PRODUCT_APPLICATION_STATUS_DROP", //회원 > 신청서 미선정
  PRODUCT_APPLICATION_STATUS_HOLD: "PRODUCT_APPLICATION_STATUS_HOLD", //회원 > 신청서 보완요청

  CAMPUS_NOTICE_CREATE: "CAMPUS_NOTICE_CREATE", //캠퍼스 공지등록 => 캠퍼스 공지사항 > 상세 화면
  CAMPUS_MEMBER_JOIN_REQUEST: "CAMPUS_MEMBER_JOIN_REQUEST", //신규 입학신청 : 캠퍼스 관리 - 회원
  CAMPUS_MEMBER_JOIN_REQUEST_CANCEL: "CAMPUS_MEMBER_JOIN_REQUEST_CANCEL", //입학신청 취소
  CAMPUS_MEMBER_JOIN_APPROVAL: "CAMPUS_MEMBER_JOIN_APPROVAL", //가입 승인: 캠퍼스 홈
  CAMPUS_MEMBER_JOIN_REJECT: "CAMPUS_MEMBER_JOIN_REJECT", //입학신청 거절: 캠퍼스 홈
  CAMPUS_MEMBER_REMOVE: "CAMPUS_MEMBER_REMOVE", //회원 내보내기,접근제한 : 캠퍼스 홈
  CAMPUS_SALES_ENABLE: "CAMPUS_SALES_ENABLE", //판매신청 승인
  CAMPUS_SALES_REJECT: "CAMPUS_SALES_REJECT", //판매신청 거절
  CAMPUS_SALES_COMPLEMENTATION: "CAMPUS_SALES_COMPLEMENTATION", //판매신청 보완
  CAMPUS_PLAN_MAU_EXCEED: "CAMPUS_PLAN_MAU_EXCEED", // 캠퍼스 MAU 초과
  CAMPUS_PLAN_CONSTRAINT: "CAMPUS_PLAN_CONSTRAINT", // 캠퍼스 기능 제한
  CAMPUS_PLAN_CONSTRAINT_ENABLE: "CAMPUS_PLAN_CONSTRAINT_ENABLE", // 캠퍼스 기능 제한 해제
  CAMPUS_PLAN_PAY_COMPLETE: "CAMPUS_PLAN_PAY_COMPLETE", // 캠퍼스 요금제 결제 완료
  CAMPUS_PLAN_CHANGE_COMPLETE: "CAMPUS_PLAN_CHANGE_COMPLETE", // 캠퍼스 요금제 변경 완료
  CAMPUS_PLAN_CANCEL_COMPLETE: "CAMPUS_PLAN_CANCEL_COMPLETE", // 캠퍼스 요금제 해지 완료
  CAMPUS_PLAN_CREDIT_OFFER: "CAMPUS_PLAN_CREDIT_OFFER", // admin 크레딧 제공 완료
  CAMPUS_PLAN_ADDON_QUOTA_EXCEED_CODING:
    "CAMPUS_PLAN_ADDON_QUOTA_EXCEED_CODING", // 제공 횟수 소진(코딩 실습)
  CAMPUS_PLAN_ADDON_QUOTA_EXCEED_AI_INSTRUCTOR:
    "CAMPUS_PLAN_ADDON_QUOTA_EXCEED_AI_INSTRUCTOR", // 제공 횟수 소진 (AI 조교)
  CAMPUS_PLAN_ADDON_QUOTA_EXCEED_EXTRA_VOLUME:
    "CAMPUS_PLAN_ADDON_QUOTA_EXCEED_EXTRA_VOLUME", // 추가 용량 소진
  CAMPUS_PLAN_DEMO_START: "CAMPUS_PLAN_DEMO_START", // 데모 설정 알림

  SUPPORT_ASK_ANSWER: "SUPPORT_ASK_ANSWER", //문의 답변 :고객센터 > 1:1문의
  SUPPORT_ASK_CREATE_TO_MANAGER: "SUPPORT_ASK_CREATE_TO_MANAGER", //신규 문의 등록 : 캠퍼스 관리 > 고객센터 > 문의

  COMMUNITY_POST_NOTICE: "COMMUNITY_POST_NOTICE", //커뮤니티 공지등록
  COMMUNITY_SHUTDOWN_N: "COMMUNITY_SHUTDOWN_N", //커뮤니티 운영 재개
  COMMUNITY_SHUTDOWN_Y: "COMMUNITY_SHUTDOWN_Y", //커뮤니티 운영 중단
  COMMUNITY_MEMBER_ADD: "COMMUNITY_MEMBER_ADD", //커뮤니티 참여 알림

  COMMUNITY_MEMBER_JOIN_REQUEST_TO_MANAGER:
    "COMMUNITY_MEMBER_JOIN_REQUEST_TO_MANAGER", //관리자 > 회원 커뮤니티 참가신청
  COMMUNITY_MEMBER_JOIN_APPROVAL: "COMMUNITY_MEMBER_JOIN_APPROVAL", // 회원 커뮤니티 참여 신청 > 승인
  COMMUNITY_MEMBER_JOIN_REJECT: "COMMUNITY_MEMBER_JOIN_REJECT", // 회원 커뮤니티 참여 신청 > 거절
  COMMUNITY_MEMBER_REMOVE: "COMMUNITY_MEMBER_REMOVE", // 커뮤니티 참여 회원 > 내보내기
  COMMUNITY_POST_PRIVATE_TO_MANAGER: "COMMUNITY_POST_PRIVATE_TO_MANAGER", //관리자 > 회원 커뮤니티 비공개글 등록
  COMMUNITY_POST_TO_MANAGER: "COMMUNITY_POST_TO_MANAGER", //관리자 > 회원 커뮤니티 일반글 등록

  ARTICLE_REPLY_CREATE: "ARTICLE_REPLY_CREATE", //공지사항, 커뮤니티, 프로덕트 댓글, 대댓글 알림
  ARTICLE_REPLY_SUPER_DEL: "ARTICLE_REPLY_SUPER_DEL", //댓글 관리자 삭제
  ARTICLE_PRODUCT_FEEDBACK_CREATE: "ARTICLE_PRODUCT_FEEDBACK_CREATE", // 1:1피드백 요청이 등록
  ARTICLE_PRODUCT_FEEDBACK_ANSWER_CREATE:
    "ARTICLE_PRODUCT_FEEDBACK_ANSWER_CREATE", //피드백 요청 답변 작성

  CAMPUS_PLAN_PAY_FAIL_TO_MANAGER: "CAMPUS_PLAN_PAY_FAIL_TO_MANAGER", //관리자 > 요금제 결제 실패
  CAMPUS_PLAN_PAY_FAIL_CHANGE_FREE_TO_MANAGER:
    "CAMPUS_PLAN_PAY_FAIL_CHANGE_FREE_TO_MANAGER", //관리자 > 요금제 결제 3회 실패 후 무료 요금제 전환

  PRODUCT_ETC_REQUEST: "PRODUCT_ETC_REQUEST", // 관리자 > 회원 기타결제 승인요청
  PRODUCT_ETC_REQUEST_APPROVAL: "PRODUCT_ETC_REQUEST_APPROVAL", // 회원 > 기타결제 승인
  PRODUCT_ETC_REJECT: "PRODUCT_ETC_REJECT", // 회원  > 기타결제 거절
  PRODUCT_ETC_CANCEL: "PRODUCT_ETC_CANCEL", // 회원  > 기타결제 취소

  CAMPUS_PAY_ETC_ENABLE: "CAMPUS_PAY_ETC_ENABLE", // 관리자 > 기타결제 사용 승인
  CAMPUS_PAY_ETC_REJECT: "CAMPUS_PAY_ETC_REJECT", // 관리자 > 기타결제 사용 거절
  CAMPUS_PAY_ETC_COMPLEMENTATION: "CAMPUS_PAY_ETC_COMPLEMENTATION", // 관리자 > 기타결제 사용 보완

  PRODUCT_RESERVATION_PAY_COMPLETE: "PRODUCT_RESERVATION_PAY_COMPLETE",
  PRODUCT_RESERVATION_PAY_CANCEL_COMPLETE:
    "PRODUCT_RESERVATION_PAY_CANCEL_COMPLETE",
  PRODUCT_RESERVATION_PAY_CANCEL_REJECT:
    "PRODUCT_RESERVATION_PAY_CANCEL_REJECT",
  PRODUCT_RESERVATION_ALARM_3_DAYS: "PRODUCT_RESERVATION_ALARM_3_DAYS",
  PRODUCT_RESERVATION_ALARM_3_HOURS: "PRODUCT_RESERVATION_ALARM_3_HOURS",
  PRODUCT_RESERVATION_ALARM_REVIEW: "PRODUCT_RESERVATION_ALARM_REVIEW",
  PRODUCT_RESERVATION_ALRAM_CHANGE: "PRODUCT_RESERVATION_ALRAM_CHANGE",
  PRODUCT_RESERVATION_PAY_COMPLETE_TO_MANAGER:
    "PRODUCT_RESERVATION_PAY_COMPLETE_TO_MANAGER",
  PRODUCT_RESERVATION_PAY_CANCEL_REQUEST_TO_MANAGER:
    "PRODUCT_RESERVATION_PAY_CANCEL_REQUEST_TO_MANAGER",
};
