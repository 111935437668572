import globalStore from "@/store";
import { toJsonDateFormat } from "@/utils/date-format";
import AES from "crypto-js/aes";
import { passwordSecretKey } from "@/config";

export default (apiInstance) => ({
  // 캠퍼스 결제 이력 조회
  reqGetUserPaymentsHistories: async (store, payload) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      reset,
      excelYn,
    } = payload;
    if (reset) {
      store.commit("setuserPaymentsHistoriesReset");
    } else {
      payload.lastKey = store.getters["getLastKey"];
    }

    const result = await apiInstance.payments.getUserPaymentsHistories({
      ...payload,
      campusId,
      memberId,
    });

    if (result.success) {
      if (!excelYn) {
        const { lastKey } = result.data;
        store.commit("setUserPaymentsHistories", result.data);
        store.commit("setLastKey", lastKey);
      }
    }
    return result;
  },

  // 캠퍼스 결제 이력 요약 정보
  reqGetPaymentSummary: async (store, payload) => {
    //payload로 넘어온 campusId, memberId 를 풀어서 store getter 로 가져옴
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
    } = payload;

    const result = await apiInstance.payments.getPaymentSummary({
      ...payload,
      campusId,
      memberId,
    });
    if (result.success) {
      store.commit("setPaymentSummary", result.data);
    }
    return result;
  },
  //검색 키워드 초기화
  setCustomerNameKeyword: async (store, keyword) => {
    store.commit("setCustomerNameKeyword", keyword);
  },
  //lastKey
  setLastKey: async (store, lastKey) => {
    store.commit("setLastKey", lastKey);
  },

  // histories -> 판매 프로덕트 결제 내역 결제 취소
  reqDeletePaymentHistories: async (store, payload) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.payments.deletePaymentHistories({
      campusId,
      memberId,
      id,
    });
    return result;
  },
  // 판매클래스 일반 결제 승인 요청
  reqClsPay: async (store, payload) => {
    const {
      userId = globalStore.getters["users/getUserUuid"],
      classId,
      tran_cd,
      enc_data,
      enc_info,
      good_mny,
      good_name,
    } = payload;
    if (
      !classId ||
      !tran_cd ||
      !enc_data ||
      !enc_info ||
      !good_mny ||
      !good_name
    ) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqClsPay({
      userId,
      classId,
      tran_cd,
      enc_data,
      enc_info,
      good_mny,
      good_name,
    });
    return result;
  },
  // 모바일 일반 결제 거래 등록
  reqRegPay: async (store, payload) => {
    const { ordr_idxx, good_mny, good_name, pay_method, Ret_URL, escw_used } =
      payload;
    if (!ordr_idxx || !good_mny || !good_name || !pay_method || !Ret_URL) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqRegPay(payload);
    return result;
  },
  // pc 배치키 발급 요청
  reqBatchIssue: async (store, payload) => {
    const {
      userId = globalStore.getters["users/getUserUuid"],
      tran_cd,
      enc_data,
      enc_info,
      card_mask_no,
    } = payload;
    if (!tran_cd || !enc_data || !enc_info || !userId || !card_mask_no) {
      alert("다시 시도해주세요.");
      return;
    }
    const result = await apiInstance.payments.reqBatchIssue({
      userId,
      tran_cd,
      enc_data,
      enc_info,
      card_mask_no,
    });
    return result;
  },
  /* cards/{uuid} 사용자의 카드 목록 조회 */
  reqGetCards: async (store, payload = {}) => {
    const { userId = store.rootGetters["users/getUserUuid"] } = payload;
    const result = await apiInstance.payments.getCards({ userId });
    if (result.success) {
      store.commit("setCards", result.data);
    }
  },
  /* cards 카드 삭제 */
  reqDeleteCards: async (store, { id }) => {
    const data = { id, userId: store.rootGetters["users/getUserUuid"] };

    const result = await apiInstance.payments.deleteCards(data);
    if (result.success) {
      store.commit("deleteCard", id);
    } else {
      let msg = "";
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>카드 정보를 찾을 수 없습니다.</p>";
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "BAD_REQUEST":
          msg =
            "<p>요금 플랜, 기타결제에서 사용되고 있는 카드는 삭제할 수 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg,
        },
        { root: true }
      );
    }
    return result;
  },
  /* cards 카드 삭제 */
  reqPutCards: async (store, payload) => {
    const { id, remark, color } = payload;
    if (!id) {
      return;
    }
    const data = {
      id,
      userId: store.rootGetters["users/getUserUuid"],
      remark,
      color,
    };

    const result = await apiInstance.payments.putCards(data);
    if (result.success) {
      store.commit("updateCard", result.data);
    }
    return result;
  },
  // 캠퍼스관리 > 요금 플랜 > 요금 플랜 결제이력 조회
  reqGetPlanHistories: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      paymentStatus,
      planHistoryType,
      planType,
      sDate,
      eDate,
      order = "DESC",
      limit,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearAllPlanHistories");
    }
    const lastKey = store.getters["getPlanHistoriesLastKey"];

    const result = await apiInstance.payments.getPlanHistories({
      campusId,
      memberId,
      ...(paymentStatus && { paymentStatus }),
      ...(planHistoryType && { planHistoryType }),
      ...(planType && { planType }),
      ...(sDate && { sDate: toJsonDateFormat(sDate, true) }),
      ...(eDate && { eDate: toJsonDateFormat(eDate) }),
      ...(order && { order }),
      ...(limit && { limit }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setPlanHistories", results);
      store.commit("setPlanHistoriesLastKey", lastKey);
    }
    return result;
  },
  reqGetCheckoutHistory: async (store, payload) => {
    const { campusId = store.rootGetters["campuses/getCampusUuid"], id } =
      payload;
    const result = await apiInstance.payments.getCheckoutHistory({
      campusId,
      id,
    });
    if (result.success) {
      store.commit("setCheckoutHistory", result.data);
    }
    return result;
  },
  reqSalesFree: async (store, payload) => {
    const { classId, userId = store.rootGetters["users/getUserUuid"] } =
      payload;
    const result = await apiInstance.payments.reqSalesFree({
      userId,
      classId,
    });
    return result;
  },
  // 무료/유료 판매 프로덕트 결제 요청
  reqProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      productId,
      promotionId,
      customerName,
      enc_data,
      enc_info,
      tran_cd,
      good_mny,
      good_name,
      ordr_idxx,
      contact,
      email,
      testYn,
      paymentWay,
      priceId,
      couponId,
    } = payload;
    if (!productId || !campusId || !userId || !good_mny || !good_name) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postProductPayment({
      memberId,
      campusId,
      userId,
      productId,
      promotionId,
      customerName,
      enc_data,
      enc_info,
      tran_cd,
      good_mny,
      good_name,
      ordr_idxx,
      contact,
      email,
      testYn,
      paymentWay,
      priceId,
      couponId,
    });
    if (result.success) {
      store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      const altProduct = store.rootGetters["campuses/altProduct"];
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 대상 정보를 찾을 수 없습니다.</p>";
          break;
        case "INVALID_PARAMETER":
        case "BAD_REQUEST":
          msg = "<p>잘못된 결제 금액입니다.</p>";
          break;
        case "LIMIT_EXCEED":
          msg = `<p>품절된 ${altProduct}입니다.`;
          break;
        case "ACCESS_DENIED":
          msg = `<p>구매 불가한 ${altProduct}입니다.</p>`;
          break;
        case "DUPLICATE":
          msg = `<p>이미 구매된 ${altProduct}/프로모션입니다.</p>`;
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "UNAVAILABLE":
          msg =
            "<p>판매신청 미승인 캠퍼스입니다. <br />캠퍼스 관리자에게 문의해주세요.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg,
          csLink: true,
        },
        { root: true }
      );
    }
    return result;
  },
  // 예약 프로덕트 결제 이후 예약 추가정보 업데이트 요청
  putProductAdditional: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      paymentId,
      custAdditionals,
    } = payload;

    if (!paymentId || custAdditionals?.length < 1) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.payments.putProductAdditional({
      campusId,
      paymentId,
      custAdditionals,
    });
    if (result.success) {
      store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "INVALID_PARAMETER":
          msg = "<p>필수항목 누락</p>";
          break;
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  // 기타결제 승인처리
  reqEtcProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      productId,
      targetIds,
    } = payload;
    if (!campusId || !userId) {
      alert("필수 항목 누락. 다시 시도해주세요.");
      return;
    }

    const result = await apiInstance.payments.postEtcProductPayment({
      memberId,
      campusId,
      userId,
      productId,
      targetIds,
    });
    if (result.success) {
      // store.commit("setProductPayment", result.data);
    } else {
      let msg = "";
      const altProduct = store.rootGetters["campuses/altProduct"];
      switch (result.name) {
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 대상 정보를 찾을 수 없습니다.</p>";
          break;
        case "INVALID_PARAMETER":
        case "BAD_REQUEST":
          msg = "<p>잘못된 결제 금액입니다.</p>";
          break;
        case "LIMIT_EXCEED":
          msg = `<p>품절된 ${altProduct}입니다.`;
          break;
        case "ACCESS_DENIED":
          msg = `<p>구매 불가한 ${altProduct}입니다.</p>`;
          break;
        case "DUPLICATE":
          msg = `<p>이미 구매된 ${altProduct}/프로모션입니다.</p>`;
          break;
        case "KCP_ERROR":
          msg = "<p>KCP 오류. 다시 시도해주세요.</p>";
          break;
        case "UNAVAILABLE":
          msg =
            "<p>판매신청 미승인 캠퍼스입니다. <br />캠퍼스 관리자에게 문의해주세요.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg,
        },
        { root: true }
      );
    }
    return result;
  },
  // 캠퍼스관리 > 프로덕트 > 결제 내역 > 관리자 취소 처리
  cancelProductPaymentStaff: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
      paymentStatus,
      cancelReason,
      remark,
      refund,
      mod_depositor,
      mod_account,
      mod_bankcode,
    } = payload;
    let uglyAccount = "";
    if (mod_account) {
      uglyAccount = AES.encrypt(
        String(mod_account),
        passwordSecretKey
      ).toString();
    }
    const result = await apiInstance.payments.cancelProductPaymentStaff({
      paymentId,
      campusId,
      memberId,
      userId,
      paymentStatus,
      cancelReason,
      remark,
      refund,
      mod_depositor,
      mod_account: uglyAccount,
      mod_bankcode,
    });
    if (!result.success) {
      let title = "";
      let msg = "";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "UNAUTHORIZE":
          msg = "<p>권한이 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        case "UNAVAILABLE":
          msg = `<p>취소 처리가 불가한 결제건 입니다.</p>`;
          break;
        case "KCP_ERROR":
          title = "결제 오류";
          msg = result?.message || "KCP_ERROR";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        title,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 조회
  reqGetUserDetailPayments: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      productId,
      promotionId,
      keyword,
      order,
      basis,
      paymentStatus,
      limit,
      first,
      inPaymentStatus,
      productType,
    } = payload;
    if (first) {
      store.commit("clearUserDetailPayments");
    }
    const lastKey = store.rootGetters["payments/getUserDetailPaymentsLastKey"];

    const result = await apiInstance.payments.getUserDetailPayments({
      campusId,
      memberId,
      userId,
      productId,
      promotionId,
      keyword,
      order,
      basis,
      paymentStatus,
      limit,
      lastKey,
      ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
      ...(productType && { productType }),
    });
    if (result.success) {
      store.commit("setUserDetailPayments", result.data);
    } else {
      switch (result.name) {
        case "ACCESS_DENIED":
          globalStore.dispatch("common/setAlert", {
            open: true,
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 요약 조회
  reqGetUserDetailPaymentSummary: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      basis,
    } = payload;
    const result = await apiInstance.payments.getUserDetailPaymentSummary({
      campusId,
      memberId,
      userId,
      basis,
    });
    if (result.success) {
      const { results } = result.data;
      store.commit("setUserDetailPaymentSummary", results);
    } else {
      switch (result.name) {
        case "ACCESS_DENIED":
          globalStore.dispatch("common/setAlert", {
            open: true,
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 조회 (프로덕트 운영, 전체 프로덕트 공통),
  reqGetProductPaymentsHistories: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
      productId,
      promotionId,
      keyword,
      limit,
      paymentStatus,
      basis,
      order,
      first = true,
      inPaymentStatus,
      paymentWay,
      optionName,
      priceName,
      productType,
    } = payload;
    if (first) {
      store.commit("clearPaymentsHistories");
    }
    const lastKey =
      store.rootGetters["payments/getProductPaymentsHistoriesLastKey"];
    const result = await apiInstance.payments.getProductPaymentsHistories({
      campusId,
      memberId,
      userId,
      productId,
      promotionId,
      keyword,
      limit,
      paymentStatus,
      basis,
      order,
      lastKey,
      inPaymentStatus,
      paymentWay,
      optionName,
      priceName,
      productType,
    });

    if (result.success) {
      store.commit("setProductPaymentsHistories", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 조회엑셀다운로드
  reqGetProductPaymentsExcel: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      basis,
    } = payload;

    const result = await apiInstance.payments.getProductPaymentsExcel({
      campusId,
      memberId,
      productId,
      basis,
    });

    if (!result.success) {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "조회 일자를 선택해주세요.";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 요약 조회
  reqGetProductPaymentsSummary: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      basis,
    } = payload;

    const result = await apiInstance.payments.getProductPaymentsSummary({
      campusId,
      memberId,
      productId,
      basis,
    });

    if (result.success) {
      store.commit("setProductPaymentsSummary", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  //캠퍼스관리 > 프로덕트 > 결제 내역 요약 조회 > 대시보드 팝업
  reqGetDashboardPaymentsSummary: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      basis,
    } = payload;

    const result = await apiInstance.payments.getProductPaymentsSummary({
      campusId,
      memberId,
      productId,
      basis,
    });

    if (result.success) {
      store.commit("setDashboardPaymentsSummary", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  //(사용자) 결제 내역 단건조회
  reqGetUserCheckoutHistory: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
    } = payload;

    const result = await apiInstance.payments.getUserCheckoutHistory({
      campusId,
      userId,
      paymentId,
    });
    if (result.success) {
      store.commit("setUserCheckoutHistory", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
        case "UNAUTHORIZE":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "결제 내역을 찾을 수 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 > 프로모션, 프로덕트 드롭다운 목록 조회
  reqGetUserPaymentDropDown: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId,
    } = payload;

    const result = await apiInstance.payments.getUserPaymentDropDown({
      campusId,
      memberId,
      userId,
    });
    if (result.success) {
      store.commit("setPaymentDropDownItems", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  // 마이페이지 > 내 구매내역
  reqGetUserPurchases: async (store, payload = {}) => {
    const {
      campusId,
      userId,
      keyword,
      paymentStatus,
      sDate,
      eDate,
      order,
      limit,
      lastKey,
      first = true,
      productType,
    } = payload;
    if (first) {
      store.commit("clearUserPurchases");
    }
    const result = await apiInstance.payments.getUserPurchases({
      campusId,
      userId,
      keyword,
      paymentStatus,
      sDate,
      eDate,
      order,
      limit,
      lastKey,
      productType,
    });
    if (result.success) {
      store.commit("setUserPurchases", result.data);
    } else {
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg: "사용자 정보를 조회할 수 없습니다. 다시 시도해주세요.",
      });
    }
    return result;
  },
  // 마이페이지 > 내 구매내역 > 사용자 취소요청
  reqPutCancelProductPayment: async (store, payload) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = globalStore.getters["users/getUserUuid"],
      paymentId,
      remark,
      cancelReason,
      paymentStatus,
      refund,
      mod_depositor,
      mod_account,
      mod_bankcode,
    } = payload;
    let uglyAccount = "";
    if (mod_account) {
      uglyAccount = AES.encrypt(mod_account, passwordSecretKey).toString();
    }
    const result = await apiInstance.payments.putCancelProductPayment({
      campusId,
      memberId,
      paymentId,
      userId,
      remark,
      cancelReason,
      paymentStatus,
      refund,
      mod_depositor,
      mod_account: uglyAccount,
      mod_bankcode,
    });
    if (result.success) {
      store.commit("setUserCancelPurchases", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "INVALID_PARAMETER":
          msg = "<p>필수항목 누락</p>";
          break;
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>결제 내역 찾을 수 없습니다.</p>";
          break;
        case "UNAVAILABLE":
          msg = `<p> 취소요청이 불가한 결제건 입니다.<br/> 고객센터에 문의해주세요.</p>`;
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스 관리 > 예약 관리 > 결제내역 > 옵션 필터 목록 조회
  reqGetReservationOptions: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      basis,
      first = true,
    } = payload;

    if (first) {
      store.commit("clearReservationOptions");
    }

    if (!productId || !basis) {
      return { success: false };
    }
    const result = await apiInstance.payments.getReservationOptions({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(productId && { productId }),
      ...(basis && { basis }),
    });
    if (result.success) {
      store.commit("setReservationOptions", result.data);
    } else {
      let msg = "";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "<p>사용자 정보를 조회할 수 없습니다.</p>";
          break;
        case "UNAUTHORIZE":
          msg = "<p>권한이 없습니다.</p>";
          break;
        default:
          msg = "알 수 없는 오류가 발생했습니다.";
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }

    return result;
  },

  reqGetHistorySettlement: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      basis,
    } = payload;

    const result = await apiInstance.payments.getPaymentsHistorySettlement({
      campusId,
      memberId,
      basis,
    });

    if (result.success) {
      store.commit("setPaymentsHistorySettlement", result.data);
    } else {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목 누락";
          break;
        case "UNAUTHORIZE":
          msg = "접근 권한이 없습니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
});
