import globalStore from "@/store";
import { webBaseUrl } from "@/config";
import { naverWcs } from "@/router/utils/naver-wcs";
import { loginPathBeforeEnter } from "@/router/utils/auth-utils";

import SnsSelect from "@/components/common/SnsSelect";
import Vue from "vue";
const vm = new Vue();

export const poincampusRoutes = {
  path: "/",
  component: () => import("@/views/poincampus"),
  children: [
    {
      path: "",
      name: "poin-home",
      component: () => import("@/views/poincampus/Home.vue"),
    },
    {
      path: "features",
      name: "poin-features",
      component: () => import("@/views/poincampus/Features.vue"),
    },
    {
      path: "pricing",
      name: "poin-pricing",
      component: () => import("@/views/poincampus/Pricing.vue"),
    },
    {
      path: "addons",
      name: "poin-addons",
      component: () => import("@/views/poincampus/Addons.vue"),
    },
    {
      path: "addons/:id",
      name: "poin-addon-detail",
      component: () => import("@/views/poincampus/AddonDetail.vue"),
    },
    // {
    //   path: "partner",
    //   name: "campus-main-partner",
    //   component: () => import("@/views/poincampus/Partner"),
    // },
    {
      path: "promotion",
      name: "poin-promotion",
      component: () => import("@/views/poincampus/Promotion.vue"),
    },
    // {
    //   path: "promotion",
    //   component: () => import("@/views/poincampus/promotion"),
    //   children: [
    //     {
    //       path: "",
    //       name: "poin-promotion-home",
    //       component: () => import("@/views/poincampus/promotion/Home"),
    //     },
    //     {
    //       path: "voucher",
    //       name: "poin-promotion-voucher",
    //       component: { template: "<div>PROMOTION</div>" },
    //       beforeEnter(_, __, next) {
    //         vm.$eventBus.$emit("alert", {
    //           open: true,
    //           msg: "종료된 프로모션입니다.",
    //         });
    //         next({
    //           name: "poin-promotion-home",
    //         });
    //       },
    //     },
    //     {
    //       path: "standard-plan",
    //       name: "poin-promotion-standard-plan",
    //       component: () => import("@/views/poincampus/promotion/Standard-Plan"),
    //     },
    //     {
    //       path: "2022-rocket-up",
    //       name: "poin-promotion-rocket-up",
    //       component: () =>
    //         import("@/views/poincampus/promotion/202201-Rocket-Up"),
    //     },
    //     {
    //       path: "anchor-rocket-up",
    //       name: "poin-promotion-rocket-up-2",
    //       component: () =>
    //         import("@/views/poincampus/promotion/202204-Rocket-Up"),
    //     },
    //     {
    //       path: "2022-newyear",
    //       name: "poin-promotion-2022-newyear",
    //       component: () => import("@/views/poincampus/promotion/2022-Newyear"),
    //     },
    //     {
    //       path: "light-plan",
    //       name: "poin-promotion-light-plan",
    //       component: () =>
    //         import("@/views/poincampus/promotion/202205-Light-Plan"),
    //     },
    //     {
    //       path: "999-event",
    //       name: "poin-promotion-999-event",
    //       component: () => import("@/views/poincampus/promotion/999-Event"),
    //     },
    //     {
    //       path: "untact-sports",
    //       name: "poin-promotion-untact-sports",
    //       component: () => import("@/views/poincampus/promotion/Untact-Sports"),
    //     },
    //     {
    //       path: "gomlab",
    //       name: "poin-promotion-gomlab",
    //       component: () => import("@/views/poincampus/promotion/GomLab"),
    //     },
    //   ],
    // },
    {
      path: "voucher",
      component: () => import("@/views/poincampus/voucher"),
      children: [
        {
          path: "",
          name: "poin-voucher-home",
          component: () => import("@/views/poincampus/voucher/Home"),
        },
        {
          path: "untact",
          name: "poin-voucher-untact",
          component: () => import("@/views/poincampus/voucher/Untact"),
        },
        {
          path: "cloud",
          name: "poin-voucher-cloud",
          component: () => import("@/views/poincampus/voucher/Cloud"),
          // beforeEnter(_, __, next) {
          //   vm.$eventBus.$emit("alert", {
          //     open: true,
          //     msg: "모집 마감되었습니다.",
          //   });
          //   next({
          //     name: "poin-voucher-home",
          //   });
          // },
        },
      ],
    },
    {
      path: "list",
      name: "poin-campus-list",
      meta: { authRequired: true },
      beforeEnter(_, __, next) {
        const origin = window.location.origin;
        if (webBaseUrl === origin) {
          next({ name: "campuses" });
        } else {
          next({
            name: "auth-bridge",
            query: {
              next: `${webBaseUrl}/my/campuses`,
            },
          });
        }
      },
    },
    {
      path: "help",
      component: () => import("@/views/poincampus/help"),
      children: [
        {
          path: "notice",
          name: "notice",
          component: { template: "<div>NOTICE</div>" },
          beforeEnter() {
            globalStore.dispatch("common/setLoading", true);
            window.location.href = "https://poinguide.oopy.io/auth/update";
          },
        },
        {
          path: "terms",
          redirect: { name: "terms" },
        },
        {
          path: "privacy",
          redirect: { name: "help-privacy" },
        },
        {
          path: "faq",
          name: "faq",
          component: { template: "<div>FAQ</div>" },
          beforeEnter() {
            globalStore.dispatch("common/setLoading", true);
            window.location.href = "https://poinguide.oopy.io/auth/faq";
          },
        },
        {
          path: "ask",
          component: () => import("@/views/poincampus/help/ask"),
          children: [
            {
              path: "",
              name: "ask",
              component: () => import("@/views/poincampus/help/ask/Ask"),
            },
            {
              path: "write",
              name: "help-ask-write",
              meta: { authRequired: true },
              component: () => import("@/views/poincampus/help/ask/Write"),
            },
          ],
        },
        {
          path: "terms/sale",
          redirect: { name: "terms-of-sales" },
        },
        {
          path: "terms/purchase",
          redirect: { name: "terms-of-purchase" },
        },
      ],
    },
    {
      path: "policy",
      component: () => import("@/views/poincampus/policy"),
      children: [
        {
          path: "",
          name: "policy",
          redirect: { name: "terms" },
        },
        {
          path: "terms",
          name: "terms",
          component: () => import("@/views/poincampus/policy/Provision"),
        },
        {
          path: "privacy",
          name: "help-privacy",
          component: () => import("@/views/poincampus/policy/Privacy"),
        },
        {
          path: "terms/sale",
          name: "terms-of-sale",
          component: () => import("@/views/poincampus/policy/Sales"),
        },
        {
          path: "terms/purchase",
          name: "terms-of-purchase",
          component: () => import("@/views/poincampus/policy/Purchase"),
        },
      ],
    },
    {
      path: "register",
      meta: { authRequired: true },
      component: () => import("@/views/register"),
      children: [
        {
          path: "",
          name: "campus-register",
          meta: { authRequired: true },
          component: () => import("@/views/register/Home"),
        },
        {
          path: "complete",
          name: "register-complete",
          meta: { authRequired: true },
          component: () => import("@/views/register/Complete"),
          beforeEnter(_, __, next) {
            naverWcs(1);
            next();
          },
        },
      ],
    },
    {
      path: "join",
      component() {
        return import("@/views/join");
      },
      children: [
        {
          path: "",
          name: "join-select",
          component: {
            data() {
              return {
                useType: "join",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "agreement",
          name: "join-terms-agree",
          component: () => import("@/views/join/TermsAgree"),
          props: true,
        },
        {
          path: "form",
          name: "join-form",
          component: () => import("@/views/join/Form"),
          props: true,
        },
        {
          path: "code",
          name: "join-code",
          component: () => import("@/views/join/Code"),
          props: true,
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "login",
      component: () => import("@/views/login"),
      children: [
        {
          path: "",
          name: "login-select",
          component: {
            data() {
              return {
                useType: "login",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "reset-password",
          name: "reset-password",
          component: () => import("@/views/login/Reset-Password"),
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "my",
      meta: { authRequired: true },
      component: () => import("@/views/my"),
      children: [
        {
          path: "",
          name: "my",
          redirect: { name: "profile" },
        },
        {
          path: "profile",
          name: "profile",
          meta: { authRequired: true },
          component: () => import("@/views/my/Profile"),
        },
        {
          path: "campuses",
          name: "campuses",
          meta: { authRequired: true },
          component: () => import("@/views/my/Campuses"),
        },
        // {
        //   path: "playlist",
        //   name: "playlist",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Playlist"),
        // },
        // {
        //   path: "reservation",
        //   name: "reservation",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Reservation"),
        // },
        // {
        //   path: "reservation/:campusId/:purchaseId",
        //   name: "reservation-detail",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/ReservationDetail"),
        // },
        // {
        //   path: "purchases",
        //   name: "purchases",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Purchases"),
        // },
        // {
        //   path: "purchases/:campusId/:purchaseId",
        //   name: "purchase-detail",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/PurchaseDetail"),
        // },
        // {
        //   path: "feedback",
        //   name: "feedback",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/FeedBack"),
        // },
        // {
        //   path: "cart",
        //   name: "cart",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Cart"),
        // },
        // {
        //   path: "asks",
        //   name: "asks",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Ask"),
        // },
        {
          path: "notification",
          name: "notification",
          meta: { authRequired: true },
          component: () => import("@/views/my/Notification"),
        },
        {
          path: "account",
          name: "account",
          meta: { authRequired: true },
          component: () => import("@/views/my/Account"),
        },
        // {
        //   path: "cards",
        //   name: "cards",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Cards"),
        // },
      ],
    },
    {
      path: "cancel/:campusId/:purchaseId",
      meta: { authRequired: true },
      component: () => import("@/views/cancel"),
      children: [
        {
          path: "",
          meta: { authRequired: true },
          name: "purchase-cancel",
          component: () => import("@/views/cancel/Cancel"),
        },
        {
          path: "complete",
          meta: { authRequired: true },
          name: "purchase-cancel-complete",
          component: () => import("@/views/cancel/Complete"),
        },
      ],
    },
    {
      path: "certificates/:productId/:memberId",
      meta: { authRequired: true, customLayout: true },
      component: () => import("@/views/certificates"),
    },
  ],
};
