import { getDateFormat } from "@/utils/date-format";
import { Authority, MemberStatus, ProgressStatus } from "@/enums/membersEnum";
import { ContentType } from "@/enums/productsEnum";
import { i18n } from "@/i18n/index";
import _ from "lodash";

export default {
  getNewProduct(state) {
    return state.newProduct;
  },
  getProduct(state) {
    return state.product;
  },
  getReservationOptions(state) {
    return state.product.OPTIONS || [];
  },
  getProductsStaff(state) {
    return state.productsStaff.items;
  },
  getProductsStaffLastKey(state) {
    return state.productsStaff.lastKey;
  },
  getProductsUser(state) {
    return state.productsUser.items;
  },
  getProductsUserLastKey(state) {
    return state.productsUser.lastKey;
  },
  getProductDelegators(state) {
    return state.delegators;
  },
  getCategories(state) {
    return state.categories;
  },
  getLocalOptions(state) {
    return state.localOptions;
  },
  getPromotions(state) {
    return state.promotions;
  },
  getPromotionLastkey(state) {
    return state.promotionLastkey;
  },
  getPromotion(state) {
    return state.promotion;
  },
  getSectionsUser(state) {
    return state.productsUser.sections;
  },
  getHomeSectionUser(state) {
    return state.productsUser.homeSection;
  },
  getProductSectionUser(state) {
    return state.productsUser.productSection;
  },
  getFilteredContents(state, __, ___, rootGetters) {
    if (
      state.product?.SETTING?.options?.sequencialYn &&
      rootGetters["members/isProductMember"]
    ) {
      let wait = false;

      const dateFilter = (date, format = "yyyy.MM.dd(eee) HH:mm") => {
        return date ? getDateFormat(date, format) : "";
      };

      const alreadyComplete = (content) => {
        const group = [ProgressStatus.COMPLETE, ProgressStatus.COMPLETE_FORCE];
        if (group.includes(content?.activity?.completeStatus)) {
          return true;
        }
        return false;
      };

      const canAccess = (content) => {
        if (content?.accessDttm && new Date() < new Date(content.accessDttm)) {
          return false;
        }
        const gAcc = groupAccessDttm(content);
        if (gAcc && new Date() < new Date(gAcc)) {
          return false;
        }
        return true;
      };

      const groupAccessDttm = (item) => {
        if (item && state.product?.CONTENTS?.length > 0) {
          const findFn = (content) => {
            if (content.id && item.id && content.id === item.id) {
              return true;
            }
            return false;
          };
          const contentSelf = state.product?.CONTENTS?.find((content) =>
            findFn(content)
          );
          if (!contentSelf) {
            let parent = state.product?.CONTENTS?.find((content) =>
              content.items?.some((content) => findFn(content))
            );
            const ancestor = state.product?.CONTENTS?.find((content) => {
              const tempParent = content.items?.find((child) =>
                child.items?.some((descendant) => findFn(descendant))
              );
              if (tempParent) {
                parent = tempParent;
                return true;
              }
              return false;
            });
            return dateFilter(ancestor?.accessDttm || parent?.accessDttm);
          }
        }
        return "";
      };

      const isRealContent = (content) => {
        const group = [ContentType.CURRICULUM, ContentType.SECTION];
        if (group.includes(content.contentType)) {
          return false;
        }
        return true;
      };

      const findFirstContent = (content) => {
        if (!isRealContent(content)) {
          return;
        }
        if (alreadyComplete(content)) {
          return;
        }
        if (!canAccess(content)) {
          return;
        }
        wait = true;
      };

      const arr = [];
      const newContents =
        state.product?.CONTENTS?.length > 0
          ? _.cloneDeep(state.product?.CONTENTS)
          : [];
      newContents?.forEach((content) => {
        const success = alreadyComplete(content) || content.previewYn;
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            const success = alreadyComplete(child) || child.previewYn;
            child.wait = success ? false : wait;
            if (!wait) {
              findFirstContent(child);
            }
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                const success =
                  alreadyComplete(descendant) || descendant.previewYn;
                descendant.wait = success ? false : wait;
                if (!wait) {
                  findFirstContent(descendant);
                }
              });
            }
          });
        }
        arr.push({ ...content, wait: success ? false : wait });
        if (!wait) {
          findFirstContent(content);
        }
      });
      return arr;
    }
    return state.product?.CONTENTS || [];
  },
  getReservation(state) {
    return state.reservation;
  },
  getReserveBtn: (_) => (option) => {
    // 1. standby: true 접수 예정
    // 2. standby: falsr startDttm
    // 3. 접수 마강
    // 4. 정원 초과
    // 5. 결제하기
    if (!option) {
      return { text: "", disabled: true };
    }

    const isStandby =
      !option?.startDttm ||
      option?.startDttm === new Date(99999999999999).toJSON();

    const isNotStart = new Date(option?.startDttm) > new Date();

    const isEnd = !option.endDttm
      ? false
      : new Date(option.endDttm) < new Date();

    if (isNotStart) {
      return {
        text: isStandby ? "접수 예정" : getDateFormat(option?.startDttm),
        disabled: true,
      };
    }

    if (isEnd) {
      return { text: "접수 마감", disabled: true };
    }
    if (option.limitExeed) {
      return { text: "정원 초과", disabled: true };
    }

    return { text: "결제하기", disabled: false };
  },
  // Product Detail Getters
  productDetail(state, _, __, rootGetters) {
    const { product = {} } = state;
    const name = product.name;
    const productId = product.id;
    const productType = product.productType;
    const isBasic = productType === "BASIC";
    const isSales = productType === "SALES";
    const image = product.image?.items[0];
    const thumbnail = image?.path
      ? image
      : {
          key: "",
          path: "",
        };
    let horizontal = "";
    switch (product.image?.horizontal) {
      case "LEFT":
        horizontal = "text-left justify-start";
        break;
      case "CENTER":
        horizontal = "text-cneter justify-center";
        break;
      case "RIGHT":
        horizontal = "text-right justify-end";
        break;
      default:
        horizontal = "d-none";
    }
    const color = product.image?.textColor;
    const textColor = color ? `${color}--text` : "d-none";
    const nameYn = product.image?.nameYn;
    const conts = product.image?.conts;
    const thumbnailText = nameYn ? product.name : conts || "";
    const introduce = product.DESC?.introduce || "";
    const contents = product?.CONTENTS || [];
    const spreadContents = (() => {
      const arr = [];
      contents?.forEach((content) => {
        arr.push(content);
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            arr.push({ ...child, sub: true, indent: 1 });
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                arr.push({ ...descendant, sub: true, indent: 2 });
              });
            }
          });
        }
      });
      return arr;
    })();
    const contentsCnt =
      spreadContents?.filter((content) => {
        if (content.contentType === ContentType.CURRICULUM) {
          return false;
        }
        if (content.contentType === ContentType.SECTION) {
          return false;
        }
        return true;
      })?.length || 0;
    const managers = product.DESC?.managers?.map((manager) => {
      if (manager.member) {
        return manager.member;
      }
      return manager;
    });
    const managersViewYn = product.DESC?.managers?.length > 0;
    const links = product.DESC?.links;
    const linksViewYn = links?.length > 0;
    const startDttm = product.startDttm;
    const endDttm = product.endDttm;
    const joinType = product.joinType;
    const classfiedYn = product.classfiedYn;
    const productMember = rootGetters["members/getProductMember"];
    const isExpired = (() => {
      // 거절/내보내기 회원의 endDttm이 처리 시점으로 설정되어 재신청이 불가능한 문제가 있어 예외 처리.
      if (
        productMember?.memberStatus === "DEL" ||
        productMember?.memberStatus === "REJECT"
      ) {
        return false;
      }
      if (!productMember?.endDttm) {
        return false;
      }
      if (productMember.endDttm === new Date(99999999999999).toJSON()) {
        return false;
      }
      const left = new Date(productMember.endDttm) - new Date();
      if (left < 0) {
        return true;
      }
      return false;
    })();
    const productMemberStatus = productMember?.memberStatus;
    const isRequest = productMemberStatus === MemberStatus.REQUEST;
    const isReject = productMemberStatus === MemberStatus.REJECT;
    const isPayRequest = productMemberStatus === MemberStatus.PAY_REQUEST;
    const isJoin = productMemberStatus === MemberStatus.JOIN && !isExpired;
    const limit = product.limit || 0;
    const salesCnt = isSales
      ? product?.STAT?.salesCnt || 0
      : isBasic
        ? product?.STAT?.joinCnt || 0
        : 0;
    const payReqCnt = product?.STAT?.payReqCnt || 0;
    const purchaseCnt = productMember?.purchaseCnt || 0;
    const soldout = limit > 0 && limit - salesCnt - payReqCnt < 1;
    const category = product.categoryId
      ? state.categories?.find(
          (category) => category.id === state.product.categoryId
        )?.name || ""
      : "";
    const rating = product.rating || 0;
    const ratingPeople = product.ratingCnt || 0;
    const star = `${rating} (${ratingPeople})`;
    const prePromotion =
      new Date(state.product?.PROMOTION?.startDttm) < new Date() &&
      new Date() < new Date(state.product?.PROMOTION?.endDttm);
    const isStandby =
      !startDttm || startDttm === new Date(99999999999999).toJSON();
    const isNotStart = prePromotion ? false : new Date(startDttm) > new Date();
    const isEnd = new Date(endDttm) < new Date();
    const period = product.period || 0;
    const periodLabel = period
      ? i18n.tc("productDetail.period", 2, { period })
      : i18n.tc("productDetail.period");
    const periodDescription = period
      ? i18n.tc("productDetail.periodDesc", 2, { period })
      : i18n.tc("productDetail.periodDesc");
    const isProductMember = rootGetters["members/isProductMember"];
    const delegators = product.delegators || [];
    const isDelegator = delegators.includes(rootGetters["members/getMemberId"]);
    const isManager =
      rootGetters["members/hasRoleOrAuth"]({
        authority: [
          Authority.CAMPUS_SUPER_MANAGE,
          Authority.PRODUCT_SUPER_MANAGE,
        ],
      }) || isDelegator;
    const purchaseBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      // 주식분석가달수
      if (
        rootGetters["campuses/getCampusUuid"] ===
        "662a33cf-01e1-427d-994a-0cd71bf4c2ae"
      ) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const joinBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (isJoin) {
        return false;
      }
      if (isExpired) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const fixedBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      if (isBasic && isExpired) {
        return true;
      }
      // 주식분석가달수
      if (
        rootGetters["campuses/getCampusUuid"] ===
        "662a33cf-01e1-427d-994a-0cd71bf4c2ae"
      ) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const btnTheme = (() => {
      if (isRequest) {
        return "gray";
      }
      if (isProductMember) {
        return "primarySub";
      }
      if (isPayRequest) {
        return "primarySub";
      }
      return "primary";
    })();
    const joinTypeText = (() => {
      return classfiedYn
        ? i18n.t("productDetail.unclassified")
        : joinType === "AUTO"
          ? i18n.t("productDetail.joinTypeAuto")
          : joinType === "MANUAL"
            ? i18n.t("productDetail.joinTypeManual")
            : "";
    })();
    const joinBtnText = (() => {
      // 운영 프로덕트
      // 1. 프로덕트 모집 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 모집 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (isJoin) {
        return i18n.t("productDetail.toRoom");
      }
      // @lars 요청으로 November 15th, 2022 10:26 AM 추가했으나 (cdfb3a0)
      // 거절 후 다시 재신청 가능하도록 요청해서 다시 제거
      // if (isReject) {
      //   return "거절됨";
      // }
      if (isRequest) {
        return "승인 대기 중";
      }
      if (isNotStart) {
        return isStandby ? "모집 예정" : getDateFormat(startDttm);
      }
      if (isEnd) {
        return i18n.t("productDetail.basicEnd");
      }
      if (soldout) {
        return "정원 초과"; // PC-674
      }
      if (isExpired) {
        return "기간 만료";
      }
      if (joinType === "MANUAL") {
        return i18n.t("productDetail.joinManual");
      }
      if (joinType === "AUTO") {
        return i18n.t("productDetail.joinAuto");
      }
      return "-";
    })();
    const purchaseBtnText = (() => {
      // 판매 프로덕트
      // 1. 프로덕트 판매 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 판매 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return `${getDateFormat(productMember?.startDttm)} 시작 가능`;
      }
      if (isProductMember) {
        return i18n.t("productDetail.toRoom");
      }
      if (isPayRequest) {
        return "결제신청중";
      }
      if (isNotStart) {
        return isStandby ? "판매 예정" : getDateFormat(startDttm);
      }
      if (isEnd) {
        return i18n.t("productDetail.salesEnd");
      }
      if (soldout) {
        return "판매 마감"; // PC-674
      }
      return purchaseCnt > 0
        ? i18n.t("productDetail.repurchase")
        : i18n.t("productDetail.purchase");
    })();
    const tags =
      product.tags
        ?.split("#")
        ?.filter((tag) => tag)
        ?.map((tag) => "#" + tag) || [];

    return {
      name,
      productId,
      productType,
      isBasic,
      isSales,
      thumbnail,
      horizontal,
      textColor,
      nameYn,
      conts,
      thumbnailText,
      introduce,
      managers,
      managersViewYn,
      links,
      linksViewYn,
      isManager,
      startDttm,
      endDttm,
      joinType,
      classfiedYn,
      productMemberStatus,
      isExpired,
      isJoin,
      isRequest,
      isReject,
      limit,
      salesCnt,
      purchaseCnt,
      soldout,
      category,
      rating,
      star,
      isNotStart,
      isEnd,
      contentsCnt,
      period,
      periodLabel,
      periodDescription,
      isProductMember,
      fixedBtnDisabled,
      btnTheme,
      purchaseBtnDisabled,
      joinBtnDisabled,
      joinTypeText,
      joinBtnText,
      purchaseBtnText,
      tags,
      delegators,
      isDelegator,
    };
  },
};
