import globalStore from "@/store";
import isEmpty from "lodash.isempty";
import { notification } from "@/utils";
import { isLocal } from "@/config";

export const commonRoutes = [
  {
    path: "/mobile",
    component: () => import("@/views/mobile"),
    children: [
      {
        path: "share-entry",
        name: "share-entry",
        component: () => import("@/views/mobile/Share-Entry"),
      },
      {
        path: "",
        name: "mobile-landing",
        component: () => import("@/views/mobile/Landing"),
      },
    ],
  },
  {
    path: "/webview",
    name: "webview",
    component: () => import("@/views/webview"),
    meta: { component: "WEBVIEW" },
    children: [
      {
        path: "campus/home",
        name: "webview-campus-home",
        component: () => import("@/views/campus/Home"),
      },
      {
        path: "auth",
        name: "webview-auth-landing",
        component: () => import("@/views/webview/Auth"),
      },
    ],
  },
  {
    path: "/oauth-callback/:provider",
    name: "login-oauth-callback",
    component: () => import("@/views/login/Oauth-Callback"),
  },
  {
    path: "/zoom",
    name: "zoom",
    children: [
      {
        path: "guide",
        name: "zoom-guide",
        beforeEnter() {
          location.href =
            "https://www.notion.so/ZOOM-cd45a63b8e624896838e9e82c1615651";
        },
      },
    ],
  },
  {
    path: "/guide",
    name: "guide",
    component: { template: "<div>GUIDE</div>" },
    beforeEnter() {
      window.location.href = "https://poinguide.oopy.io";
    },
  },
  {
    path: "/landing/:target",
    name: "landing",
    meta: { component: "LANDING" },
    component: () => import("@/views/landing"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/error"),
  },
  {
    path: "/notification-bridge",
    name: "notification-bridge",
    async beforeEnter(to, from, next) {
      const isAppRoute = !isEmpty(to.query);

      const nextTarget = isAppRoute
        ? JSON.parse(window.atob(to.query.notiType))
        : to.params;

      let route = notification.getNotiTargetRoute(nextTarget);

      if (from.name === route.name) {
        globalStore.commit("notifications/setNotiTarget", route.params);
      }
      // 콘텐츠 댓글  > 직원 or 관리자 알림 유형 분기
      if (
        route.params?.currentTab === "Comment" &&
        globalStore.getters["members/isStaff"]
      ) {
        route = {
          ...route,
          name: "campus-admin-product-detail-contents-view",
          params: { ...route.params, currentTab: "REPLY" },
        };
      }

      if (route) {
        next({ ...route, replace: true });
      } else {
        next("/");
      }
    },
  },
  {
    path: "/auth-bridge",
    name: "auth-bridge",
    beforeEnter(to, __, next) {
      const authenticated = globalStore.getters["auth/getAuthenticated"];
      if (!to.query.next) {
        return next("/error");
      }
      if (!authenticated) {
        window.location.href = to.query.next;
      } else {
        next();
      }
    },
    component: () => import("@/views/auth-bridge"),
  },
  {
    path: "/batch-complete",
    name: "batch-complete",
    component: () => import("@/views/campus/class/checkout/Complete"),
  },
];
