<!-- default: gray-outlined -->
<template>
  <div
    class="p-badge border-box"
    :class="[
      {
        'p-badge--primary-line': color === 'primary',
        'p-badge--gray-line': color === 'gray',
        'p-badge--disabled': disabled,
      },
    ]"
  >
    <span class="p-badge--content">
      <!-- props icon  -->
      <p-icon v-if="icon" :icon="icon" size="20" />
      <!-- text  -->
      <slot />
      <!-- clear icon  -->
      <p-icon
        v-if="clear && !disabled"
        class="cursor-pointer"
        icon="DeleteFill/Gray8"
        size="20"
        @click="$emit('click:clear')"
      />
    </span>
  </div>
</template>

<script>
export default {
  // only outlined: gray | primary
  name: "PoinUiBadgeDelete",
  props: {
    color: {
      type: String,
      default: "gray",
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.p-badge {
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: fit-content;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 10px;
  line-height: 20px;
  color: $gray8;
  background-color: $white;
}
.p-badge--content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.p-badge--disabled {
  pointer-events: none;
}
.p-badge--primary-line {
  border: 1px solid $primary;
  background-color: $white;
  color: $primary;
}
.p-badge--gray-line {
  border: 1px solid $gray3;
  background-color: $white;
  color: $gray8;
}
</style>
