import { endpointV2 as endpoint } from "@/config";

// 캠퍼스관리 > 프로덕트 > 콘텐츠 목록 조회
export async function getContents({
  campusId,
  memberId,
  keyword,
  contentType,
  limit,
  sort,
  order,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(keyword && { keyword }),
    ...(contentType && { contentType }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.contents}/`,
    params,
  });
}

// 캠퍼스관리 > 콘텐츠 > 콘텐츠 만들기
export async function postContent({
  ext,
  startDttm,
  conts,
  campusId,
  endDttm,
  DELETE_CONTENTS,
  vod,
  upperId,
  attaches,
  name,
  options,
  DELETE_PRODUCTS,
  contentType,
  items,
  seq,
  clip,
  live,
  memberId,
  desc,
  file,
  quizFormType,
  lowContentType,
  codeFiles,
  testSetYn,
  testSet,
  codeLang,
  codeYn,
  regDttm,
}) {
  if (!contentType) {
    return;
  }
  const data = {
    contentType,
    ...(ext && { ext }),
    ...(startDttm && { startDttm }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(endDttm && { endDttm }),
    ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
    ...(vod && { vod }),
    ...(upperId && { upperId }),
    ...(attaches && { attaches }),
    ...(file && { file }),
    ...(name && { name }),
    ...(options && { options }),
    ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
    ...(items && { items }),
    ...(seq && { seq }),
    ...(clip && { clip }),
    ...(live && { live }),
    ...(memberId && { memberId }),
    ...(desc && { desc }),
    ...(quizFormType && { quizFormType }),
    ...(lowContentType && { lowContentType }),
    ...(codeFiles && { codeFiles }),
    ...(testSetYn !== undefined && { testSetYn }),
    ...(testSet && { testSet }),
    ...(codeLang && { codeLang }),
    ...(codeYn !== undefined && { codeYn }),
    ...(regDttm && { regDttm }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.contents}/`,
    data,
  });
}

// 캠퍼스관리 > 콘텐츠 > 콘텐츠 수정하기
export async function putContent({
  ext,
  startDttm,
  conts,
  campusId,
  endDttm,
  DELETE_CONTENTS,
  vod,
  upperId,
  attaches,
  name,
  options,
  id,
  DELETE_PRODUCTS,
  contentType,
  items,
  seq,
  clip,
  live,
  memberId,
  desc,
  file,
  quizFormType,
  lowContentType,
  codeFiles,
  testSetYn,
  testSet,
  codeLang,
  codeYn,
}) {
  if (!contentType || !id) {
    return;
  }
  const data = {
    contentType,
    ...(ext && { ext }),
    ...(startDttm && { startDttm }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(endDttm && { endDttm }),
    ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
    ...(vod && { vod }),
    ...(upperId && { upperId }),
    ...(attaches && { attaches }),
    ...(file && { file }),
    ...(name && { name }),
    ...(options && { options }),
    ...(id && { id }),
    ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
    ...(items && { items }),
    ...(seq && { seq }),
    ...(clip && { clip }),
    ...(live && { live }),
    ...(memberId && { memberId }),
    ...(desc && { desc }),
    ...(quizFormType && { quizFormType }),
    ...(lowContentType && { lowContentType }),
    ...(codeFiles && { codeFiles }),
    ...(testSetYn !== undefined && { testSetYn }),
    ...(testSet && { testSet }),
    ...(codeLang && { codeLang }),
    ...(codeYn !== undefined && { codeYn }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.contents}/`,
    data,
  });
}

// 캠퍼스관리 > 콘텐츠 > 콘텐츠 삭제하기
export async function deleteContent({
  campusId,
  memberId,
  id,
  contentType,
  DELETE_PRODUCTS,
}) {
  if (!id || !contentType) {
    return;
  }
  const data = {
    id,
    contentType,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.contents}/`,
    data,
  });
}

// 캠퍼스관리 > 콘텐츠 > 콘텐츠 복제하기
export async function cloneContent({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.contents}/clone`,
    data,
  });
}

// 캠퍼스관리 > 콘텐츠 > 콘텐츠 삭제하기
export async function deleteSync({ campusId, memberId, id, productIds }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productIds && { productIds }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.contents}/sync`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 콘텐츠 단건 조회
export async function getContent({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.contents}/${id}`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 콘텐츠 사용하는 프로덕트 목록 조회
export async function getContentsSyncProducts({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.contents}/${id}/products`,
    params,
  });
}
// 캠퍼스관리 > 드라이브 > 콘텐츠 > 공유 대상자 조회
export async function getContentDelegators({ campusId, memberId, id }) {
  if (!id) {
    return { success: false };
  }
  const params = {
    ...(id && { id }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.contents}/delegators`,
    params,
  });
}
// 캠퍼스관리 > 드라이브 > 공유 대상자 관리 (드라이브의 콘텐츠의 공유 대상자 설정)
export async function putContentDelegators({
  campusId,
  memberId,
  id,
  changeType,
  targets,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    id,
    changeType,
    targets,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.contents}/delegators`,
    data,
  });
}
