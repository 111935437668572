import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";

export default (apiInstance) => ({
  async reqGetContents(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      contentType,
      sort,
      order,
      limit = 20,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearContents");
    }
    const lastKey = store.getters["getContentsLastKey"];

    const result = await apiInstance.contents.getContents({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(contentType && { contentType }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("updateContents", result.data);
    }
    return result;
  },
  async reqGetContent(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.contents.getContent({
      memberId,
      campusId,
      id,
    });

    if (result?.success) {
      const items = result?.data?.items || [];
      if (items.length > 0) {
        const newItems = items?.map((item) => {
          if (item.items?.length > 0) {
            const items = item.items.map((child) => {
              if (child.items?.length > 0) {
                const items = child.items.map((descendant) => {
                  return {
                    ...descendant,
                    ...(descendant.contentType === ContentTypeEnum.QUESTION &&
                      descendant.questionType === "SBJT" &&
                      !descendant.sbjtAns && {
                        sbjtAns: [],
                      }),
                    ...(descendant.codeYn &&
                      !descendant.codeLang && {
                        codeLang: [],
                      }),
                    ...(descendant.codeYn &&
                      !descendant.codeFiles && {
                        codeFiles: [],
                      }),
                    ...(descendant.codeYn &&
                      !descendant.testSet && {
                        testSet: [
                          {
                            stdin: "",
                            stdout: "",
                          },
                        ],
                      }),
                  };
                });
                return {
                  ...child,
                  items,
                  ...(child.contentType === ContentTypeEnum.QUESTION &&
                    child.questionType === "SBJT" &&
                    !child.sbjtAns && {
                      sbjtAns: [],
                    }),
                  ...(child.codeYn &&
                    !child.codeLang && {
                      codeLang: [],
                    }),
                  ...(child.codeYn &&
                    !child.codeFiles && {
                      codeFiles: [],
                    }),
                  ...(child.codeYn &&
                    !child.testSet && {
                      testSet: [
                        {
                          stdin: "",
                          stdout: "",
                        },
                      ],
                    }),
                };
              }
              return {
                ...child,
                ...(child.contentType === ContentTypeEnum.QUESTION &&
                  child.questionType === "SBJT" &&
                  !child.sbjtAns && {
                    sbjtAns: [],
                  }),
                ...(child.codeYn &&
                  !child.codeLang && {
                    codeLang: [],
                  }),
                ...(child.codeYn &&
                  !child.codeFiles && {
                    codeFiles: [],
                  }),
                ...(child.codeYn &&
                  !child.testSet && {
                    testSet: [
                      {
                        stdin: "",
                        stdout: "",
                      },
                    ],
                  }),
              };
            });
            return {
              ...item,
              items,
              ...(item.contentType === ContentTypeEnum.QUESTION &&
                item.questionType === "SBJT" &&
                !item.sbjtAns && {
                  sbjtAns: [],
                }),
            };
          }
          return {
            ...item,
            ...(item.contentType === ContentTypeEnum.QUESTION &&
              item.questionType === "SBJT" &&
              !item.sbjtAns && {
                sbjtAns: [],
              }),
            ...(item.codeYn &&
              !item.codeLang && {
                codeLang: [],
              }),
            ...(item.codeYn &&
              !item.codeFiles && {
                codeFiles: [],
              }),
            ...(item.codeYn &&
              !item.testSet && {
                testSet: [
                  {
                    stdin: "",
                    stdout: "",
                  },
                ],
              }),
          };
        });
        return { ...result, data: { ...result.data, items: newItems } };
      }
      return {
        ...result,
        data: {
          ...result.data,
          ...(result.data.contentType === ContentTypeEnum.QUESTION &&
            result.data.questionType === "SBJT" &&
            !result.data.sbjtAns && {
              sbjtAns: [],
            }),
          ...(result.data.codeYn &&
            !result.data.codeLang && {
              codeLang: [],
            }),
          ...(result.data.codeYn &&
            !result.data.codeFiles && {
              codeFiles: [],
            }),
          ...(result.data.codeYn &&
            !result.data.testSet && {
              testSet: [
                {
                  stdin: "",
                  stdout: "",
                },
              ],
            }),
        },
      };
    }
    return result;
  },
  async postContent(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      ext,
      conts,
      DELETE_CONTENTS,
      vod,
      upperId,
      attaches,
      name,
      options,
      DELETE_PRODUCTS,
      contentType,
      items,
      seq,
      clip,
      live,
      desc,
      file,
      quizFormType,
      lowContentType,
      codeFiles,
      testSetYn,
      testSet,
      codeLang,
      codeYn,
      regDttm,
    } = payload;
    if (!contentType) {
      return {
        success: false,
      };
    }

    const noContentName = items?.some(
      (item) => !item.name || item.items?.some((child) => !child.name)
    );
    if (!name || noContentName) {
      return {
        success: false,
        message: "콘텐츠 이름을 입력해주세요.",
      };
    }

    if (!store.rootGetters["campuses/checkServeYn"]("QUIZ")) {
      const hasQuiz = items?.some(
        (item) =>
          item.contentType === ContentTypeEnum.QUIZ ||
          item.items?.some(
            (child) => child.contentType === ContentTypeEnum.QUIZ
          )
      );
      if (hasQuiz || contentType === ContentTypeEnum.QUIZ) {
        return {
          success: false,
          message: "퀴즈 콘텐츠는 현재 요금 플랜에서 사용하실 수 없습니다.",
        };
      }
    }

    const checkVideoUploadDone = (content) => {
      if (
        content.contentType !== ContentTypeEnum.VIDEO &&
        content.lowContentType !== ContentTypeEnum.VIDEO
      ) {
        return true;
      }
      if (content.vod?.id || content.vod?.url) {
        return true;
      }
      if (content.clip?.url && content.clip?.time) {
        return true;
      }
      if (!(content.vod?.id || content.vod?.url) && !content.clip?.url) {
        return true;
      }
      return false;
    };

    const noVideo = items?.some(
      (item) =>
        !checkVideoUploadDone(item) ||
        item.items?.some((child) => !checkVideoUploadDone(child))
    );

    if (!checkVideoUploadDone(payload) || noVideo) {
      return {
        success: false,
        message:
          "<p>현재 동영상 업로드 중입니다. 업로드 완료 및 변환 준비가 끝난 이후 저장이 가능합니다.</p>",
        local: true,
      };
    }

    const checkNoCodeLang = (content) => {
      if (!content.codeYn) {
        return true;
      }
      return content.codeLang?.length > 0;
    };

    const noCodeLang = items?.some(
      (first) =>
        !checkNoCodeLang(first) ||
        first.items?.some(
          (second) =>
            !checkNoCodeLang(second) ||
            second.items?.some((third) => !checkNoCodeLang(third))
        )
    );

    if (!checkNoCodeLang(payload) || noCodeLang) {
      return {
        success: false,
        message: "코드 에디터 제공 시 실습 파일 유형을 선택해주세요.",
        local: true,
      };
    }

    if (items?.length > 0) {
      items.forEach((item) => {
        delete item.tempId;
        delete item.hide;
        if (item.items?.length > 0) {
          item.items.forEach((child) => {
            delete child.tempId;
            delete child.hide;
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                delete descendant.tempId;
              });
            }
          });
        }
      });
    }
    const result = await apiInstance.contents.postContent({
      contentType,
      ...(ext?.url && { ext }),
      ...(conts && { conts }),
      ...(campusId && { campusId }),
      ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
      ...(vod && { vod }),
      ...(upperId && { upperId }),
      ...(attaches && { attaches }),
      ...(name && { name }),
      ...(options && { options }),
      ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
      ...(items && { items }),
      ...(seq && { seq }),
      ...(clip && { clip }),
      ...(live && { live }),
      ...(memberId && { memberId }),
      ...(desc && { desc }),
      ...(file && { file }),
      ...(quizFormType && { quizFormType }),
      ...(lowContentType && { lowContentType }),
      ...(codeFiles && { codeFiles }),
      ...(testSetYn !== undefined && { testSetYn }),
      ...(testSet && { testSet }),
      ...(codeLang && { codeLang }),
      ...(codeYn !== undefined && { codeYn }),
      ...(regDttm && { regDttm }),
    });
    // if (result.success) {
    // }
    return result;
  },
  async putContent(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      ext,
      conts,
      DELETE_CONTENTS,
      vod,
      upperId,
      attaches,
      name,
      options,
      DELETE_PRODUCTS,
      contentType,
      items,
      seq,
      clip,
      live,
      desc,
      file,
      quizFormType,
      lowContentType,
      codeFiles,
      testSetYn,
      testSet,
      codeLang,
      codeYn,
    } = payload;
    if (!contentType || !id) {
      return {
        success: false,
      };
    }
    const noContentName = items?.some(
      (item) => !item.name || item.items?.some((child) => !child.name)
    );
    if (!name || noContentName) {
      return {
        success: false,
        message: "콘텐츠 이름을 입력해주세요.",
      };
    }

    if (!store.rootGetters["campuses/checkServeYn"]("QUIZ")) {
      const hasQuiz = items?.some(
        (item) =>
          item.contentType === ContentTypeEnum.QUIZ ||
          item.items?.some(
            (child) => child.contentType === ContentTypeEnum.QUIZ
          )
      );
      if (hasQuiz || contentType === ContentTypeEnum.QUIZ) {
        return {
          success: false,
          message: "퀴즈 콘텐츠는 현재 요금 플랜에서 사용하실 수 없습니다.",
        };
      }
    }

    const checkVideoUploadDone = (content) => {
      if (
        content.contentType !== ContentTypeEnum.VIDEO &&
        content.lowContentType !== ContentTypeEnum.VIDEO
      ) {
        return true;
      }
      if (content.vod?.id || content.vod?.url) {
        return true;
      }
      if (content.clip?.url && content.clip?.time) {
        return true;
      }
      if (!(content.vod?.id || content.vod?.url) && !content.clip?.url) {
        return true;
      }
      return false;
    };

    const noVideo = items?.some(
      (item) =>
        !checkVideoUploadDone(item) ||
        item.items?.some((child) => !checkVideoUploadDone(child))
    );

    if (!checkVideoUploadDone(payload) || noVideo) {
      return {
        success: false,
        message:
          "<p>현재 동영상 업로드 중입니다. 업로드 완료 및 변환 준비가 끝난 이후 저장이 가능합니다.</p>",
        local: true,
      };
    }

    const checkNoCodeLang = (content) => {
      if (!content.codeYn) {
        return true;
      }
      return content.codeLang?.length > 0;
    };

    const noCodeLang = items?.some(
      (first) =>
        !checkNoCodeLang(first) ||
        first.items?.some(
          (second) =>
            !checkNoCodeLang(second) ||
            second.items?.some((third) => !checkNoCodeLang(third))
        )
    );

    if (!checkNoCodeLang(payload) || noCodeLang) {
      return {
        success: false,
        message: "코드 에디터 제공 시 실습 파일 유형을 선택해주세요.",
        local: true,
      };
    }

    if (items?.length > 0) {
      items.forEach((item) => {
        delete item.tempId;
        delete item.hide;
        if (item.items?.length > 0) {
          item.items.forEach((child) => {
            delete child.tempId;
            delete child.hide;
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                delete descendant.tempId;
              });
            }
          });
        }
      });
    }
    const result = await apiInstance.contents.putContent({
      contentType,
      ...(ext && { ext }),
      ...(conts && { conts }),
      ...(campusId && { campusId }),
      ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
      ...(vod && { vod }),
      ...(upperId && { upperId }),
      ...(attaches && { attaches }),
      ...(name && { name }),
      ...(options && { options }),
      ...(id && { id }),
      ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
      ...(items && { items }),
      ...(seq && { seq }),
      ...(clip && { clip }),
      ...(live && { live }),
      ...(memberId && { memberId }),
      ...(desc && { desc }),
      ...(file && { file }),
      ...(quizFormType && { quizFormType }),
      ...(lowContentType && { lowContentType }),
      ...(codeFiles && { codeFiles }),
      ...(testSetYn !== undefined && { testSetYn }),
      ...(testSet && { testSet }),
      ...(codeLang && { codeLang }),
      ...(codeYn !== undefined && { codeYn }),
    });
    // if (result.success) {
    //   return result.data;
    // }
    return result;
  },
  async deleteContent(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      contentType,
      DELETE_PRODUCTS,
    } = payload;
    if (!id || !contentType) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.contents.deleteContent({
      id,
      contentType,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(DELETE_PRODUCTS && { DELETE_PRODUCTS }),
    });
    if (result.success) {
      store.commit("removeContent", id);
    }
    return result;
  },
  async deleteSync(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      productIds,
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.contents.deleteSync({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(productIds && { productIds }),
    });
    return result;
  },
  async postClone(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.contents.cloneContent({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    return result;
  },
  async reqGetContentsSyncProducts(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.contents.getContentsSyncProducts({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    return result;
  },
  // 캠퍼스관리 > 드라이브 > 콘텐츠 > 공유 대상자 조회
  async reqGetContentDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.contents.getContentDelegators({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("setContentDelegatorIds", result?.data);
      store.commit("setContentDelegators", result?.data);
    }

    return result;
  },
  // 캠퍼스관리 > 드라이브 > 공유 대상자 관리 (드라이브의 콘텐츠의 공유 대상자 설정)
  async reqPutContentDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      changeType,
      targets,
    } = payload;
    if (!id || !changeType || targets.length < 1) {
      return { success: false };
    }

    const result = await apiInstance.contents.putContentDelegators({
      id,
      changeType,
      targets,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });

    return result;
  },
});
