<template>
  <button
    @click="onClick"
    :disabled="disabled"
    class="p-btn border-box"
    :class="{
      'p-size--large': large,
      'p-size--small': small,
      'p-size--medium': !large && !small,
      'p-btn--primary': color === 'primary',
      'p-btn--primary-sub':
        (color === 'primary' && sub) || color === 'primarySub',
      'p-btn--gray-line': color === 'gray',
      'p-btn--white-line': color === 'whiteLine',
      'p-btn--black': color === 'black',
      'p-btn--transparent': color === 'transparent',
      'p-btn--secondary': color === 'secondary',
      'p-btn--excel': color === 'excel',
      'p-btn--excel-sub': color === 'excel' && sub,
      'p-btn--success': color === 'success',
      'p-btn--disabled': disabled,
      'p-btn--rounded': rounded,
      'p-btn--text': text,
    }"
    :style="variables"
  >
    <span class="p-btn__content" :class="{ loading }">
      <slot />
      <v-icon
        v-if="icon && selfIcon"
        color="gray darken-3"
        class="ml-1"
        size="24"
        >{{ icon }}</v-icon
      >
      <p-icon v-else-if="icon" :icon="`${icon}/Gray8`" size="24" class="ml-1" />
    </span>
    <div class="p-btn__loading" v-if="loading">
      <v-progress-circular
        indeterminate
        :color="spinnerColor"
        size="23"
        width="2"
      />
    </div>
  </button>
</template>

<script>
// -- color --
// 1. primary
// 2. primary
// 3. secondary
// 4. secondaryLine
// 5. gray
// 6. excel
// 7. success
export default {
  name: "PoinUiButton",
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    sub: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: "",
    },
    text: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    variables() {
      return {
        "--min-width": this.minWidth || "fit-content",
      };
    },
    outlined() {
      if (this.color === "gray") {
        return true;
      }
      return this.color.includes("Line");
    },
    spinnerColor() {
      if (this.outlined) {
        return this.color.replace("Line", "");
      }
      if (this.color === "white") {
        return "black";
      }
      return "white";
    },
    selfIcon() {
      return this.icon?.includes("$");
    },
  },
  methods: {
    onClick() {
      if (this.disabled || this.loading) {
        return;
      }
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.p-btn {
  // all: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white;
  min-width: var(--min-width);
  @include hover-before;
}

.p-btn--primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  &:before {
    background-color: $primary800;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
}

.p-btn--primary-sub {
  background-color: $primary300;
  border: 1px solid $primary300;
  color: $primary;
  &:before {
    background-color: $primary100;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
}
.p-btn--primary-line {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}
.p-btn--secondary {
  background-color: $secondary;
  border: 1px solid $secondary;
  color: $white;
  &:before {
    background-color: $secondary800;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
}
.p-btn--secondary-line {
  background-color: $white;
  border: 1px solid $secondary;
  color: $secondary;
}
.p-btn--gray-line {
  background-color: $white;
  border: 1px solid $gray4;
  color: $gray8;
}
.p-btn--white-line {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
}
.p-btn--black-line {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
}
.p-btn--black {
  background-color: $black;
  border: 1px solid $black;
  color: $white;
}
.p-btn--excel {
  background-color: $excelColor;
  border: 1px solid $excelColor;
  color: $white;
}
.p-btn--excel-sub {
  background-color: $excelLightColor;
  border: 1px solid $excelLightColor;
  color: $white;
}
.p-btn--excel-line {
  background-color: transparent;
  border: 1px solid $excelColor;
  color: $white;
}
.p-btn--success {
  background-color: $success;
  border: 1px solid $success;
  color: $white;
}
.p-btn--transparent {
  background-color: transparent;
  border: 1px solid transparent;
  color: $gray8;
}
.p-btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  position: relative;
  &.loading {
    color: transparent !important;
    * {
      color: transparent !important;
    }
  }
}
.p-size--large {
  min-height: 48px;
  max-height: 48px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
}
.p-size--small {
  min-height: 32px;
  max-height: 32px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
}
.p-size--medium {
  min-height: 40px;
  max-height: 40px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
}
.p-btn--rounded {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.p-btn--text {
  color: $black;
  width: fit-content;
  padding: 0 4px;
}
.p-btn--disabled {
  background-color: $gray2 !important;
  border: 1px solid $gray2 !important;
  color: $gray5 !important;
  cursor: not-allowed;
  &:before {
    all: unset;
    box-sizing: border-box;
  }
  &:focus::before,
  &:hover::before {
    opacity: 0;
  }
}
.p-btn__loading {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
