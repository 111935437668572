import { Notification } from "@/enums/notificationsEnum";

export function getNotiTargetRoute(target) {
  const {
    pk,
    notiType,
    supportId,
    campusId,
    paymentId,
    productId,
    communityId,
    postId,
    articleId,
    articleObjType,
    articleUpperId,
    articleTargetId,
    articleTargetRootId,
    targetMemberId,
    productType,
    campusDomain = "home",
  } = target;

  const query = { campusUuid: campusId };
  const isReservation = productType === "RESERVATION";

  /* 관리자 루트 */
  switch (notiType) {
    // 관리자 > 회원 커뮤니티 참여 신청
    case Notification.COMMUNITY_MEMBER_JOIN_REQUEST_TO_MANAGER: {
      return {
        name: "campus-admin-community-dashboard-member",
        query,
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
        },
      };
    }
    // 회원 커뮤니티 참여 신청 > 승인
    case Notification.COMMUNITY_MEMBER_JOIN_APPROVAL: {
      return {
        name: "campus-community-detail",
        query,
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
        },
      };
    }
    // 회원 커뮤니티 참여 신청 > 거절
    case Notification.COMMUNITY_MEMBER_JOIN_REJECT: {
      return {
        name: "campus-community-home",
        query,
        params: {
          campusDomain: campusDomain,
        },
      };
    }
    // 커뮤니티 참여 회원 > 내보내기
    case Notification.COMMUNITY_MEMBER_REMOVE: {
      return {
        name: "campus-community-home",
        query,
        params: {
          campusDomain: campusDomain,
        },
      };
    }
    // 문의 확인 화면
    case Notification.SUPPORT_ASK_CREATE_TO_MANAGER: {
      return {
        name: "campus-admin-ask-view",
        query,
        params: {
          campusDomain: campusDomain,
          slug: supportId,
        },
      };
    }
    // 관리자 > 회원 신청서 제출
    case Notification.PRODUCT_APPLICATION_REGISTER: {
      return {
        name: "campus-admin-application-detail-application",
        query,
        params: {
          campusDomain: campusDomain,
          productId: productId,
          memberId: targetMemberId,
        },
      };
    }
    // 회원 > 신청서 선정
    // 회원 > 신청서 미선정
    // 회원 > 신청서 보완요청
    case Notification.PRODUCT_APPLICATION_STATUS_PICK:
    case Notification.PRODUCT_APPLICATION_STATUS_DROP:
    case Notification.PRODUCT_APPLICATION_STATUS_HOLD: {
      return {
        name: "campus-application",
        query,
        params: {
          campusDomain: campusDomain,
          productId: productId,
        },
      };
    }

    case Notification.PRODUCT_PAY_COMPLETE_TO_MANAGER:
    case Notification.PRODUCT_PAY_CANCEL_REQUEST_TO_MANAGER: {
      return {
        name: "campus-admin-payment-detail",
        query,
        params: {
          campusDomain: campusDomain,
          paymentId: paymentId,
        },
      };
    }
    // 회원 프로덕트 참여 신청
    case Notification.PRODUCT_MEMBER_JOIN_REQUEST_TO_MANAGER: {
      return {
        name: "campus-admin-product-detail-users",
        query,
        params: {
          campusDomain: campusDomain,
          productId: productId,
        },
      };
    }
    // 관리자 > 회원 리뷰 등록
    case Notification.PRODUCT_REVIEW_CREATE_TO_MANAGER: {
      return {
        name: isReservation
          ? "campus-admin-reservation-detail-reviews"
          : "campus-admin-product-detail-reviews",
        params: {
          campusDomain: campusDomain,
          productId: articleTargetId,
        },
        query,
      };
    }
    // 회원 > 관리자 리뷰 답변 등록
    case Notification.PRODUCT_REVIEW_REPLY: {
      return {
        name: "campus-reviews",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    // 회원 입학신청
    case Notification.CAMPUS_MEMBER_JOIN_REQUEST: {
      return {
        name: "campus-admin-users",
        query,
        params: {
          campusDomain: campusDomain,
        },
      };
    }

    //회원 > 문의 확인 화면
    case Notification.SUPPORT_ASK_ANSWER: {
      return {
        name: "campus-help-ask-view",

        params: {
          campusDomain: campusDomain,
          slug: supportId,
        },
        query,
      };
    }
    //회원 > 결제 취소 완료
    //회원 > 결제 취소 거절
    case Notification.PRODUCT_PAY_CANCEL_REJECT:
    case Notification.PRODUCT_PAY_CANCEL_COMPLETE: {
      return {
        name: isReservation
          ? "campus-reservation-detail"
          : "campus-purchase-detail",
        params: {
          campusDomain: campusDomain,
          purchaseId: paymentId,
        },
        query,
      };
    }
    //회원 > 프로덕트 신규 공지 등록
    case Notification.PRODUCT_NOTICE: {
      return {
        name: "campus-room-notice-detail",
        params: {
          campusDomain: campusDomain,
          productId: productId,
          noticeId: articleId,
        },
        query,
      };
    }
    //회원 > 프로덕트 참여 신청 승인 / 관리자 회원 추가
    //회원 > 프러로트 회원 추가(관리자) 처리
    //회원 > 프로덕트 결제 완료
    case Notification.PRODUCT_MEMBER_JOIN_APPROVAL:
    case Notification.PRODUCT_MEMBER_ADD:
    case Notification.PRODUCT_PAY_COMPLETE: {
      if (isReservation && notiType === Notification.PRODUCT_PAY_COMPLETE) {
        return {
          name: "campus-reservation-detail",
          params: {
            campusDomain: campusDomain,
            campusUuid: campusId,
            purchaseId: paymentId,
          },
          query,
        };
      }
      return {
        name: "campus-playlist",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    //회원 > 프로덕트 참여 신청 거절 / 내보내기
    case Notification.PRODUCT_MEMBER_JOIN_REJECT:
    case Notification.PRODUCT_MEMBER_REMOVE: {
      return {
        name: "campus-product-detail",
        params: {
          campusDomain: campusDomain,
          productId: productId,
        },
        query,
      };
    }
    //관리자 > 회원 커뮤니티 일반글 등록
    //관리자 > 커뮤니티 비공개글 등록
    case Notification.COMMUNITY_POST_TO_MANAGER:
    case Notification.COMMUNITY_POST_PRIVATE_TO_MANAGER: {
      return {
        name: "campus-admin-community-dashboard-post-view",
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
          postId: postId,
        },
        query,
      };
    }
    //회원 > 커뮤니티 공지등록
    case Notification.COMMUNITY_POST_NOTICE: {
      return {
        name: "campus-community-post",
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
          postId: postId,
          notiTargetId: pk,
        },
        query,
      };
    }
    //회원 > 커뮤니티 운영 중단/재개
    case Notification.COMMUNITY_SHUTDOWN_N:
    case Notification.COMMUNITY_SHUTDOWN_Y: {
      return {
        name: "campus-community-home",
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
        },
        query,
      };
    }
    //회원 > 참여 알림(관리자 커뮤니티 회원 추가 처리)
    case Notification.COMMUNITY_MEMBER_ADD: {
      return {
        name: "campus-community-detail",
        params: {
          campusDomain: campusDomain,
          communityId: communityId,
        },
        query,
      };
    }
    //회원 > 캠퍼스 공지사항 등록
    case Notification.CAMPUS_NOTICE_CREATE: {
      return {
        name: "campus-notice-detail",
        params: {
          campusDomain: campusDomain,
          id: articleId,
        },
        query,
      };
    }
    //관리자 > 판매신청 승인/거절/보완
    case Notification.CAMPUS_SALES_ENABLE:
    case Notification.CAMPUS_SALES_REJECT:
    case Notification.CAMPUS_SALES_COMPLEMENTATION: {
      return {
        name: "campus-admin-sales",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    // 회원 > 기타결제 승인
    case Notification.PRODUCT_ETC_REQUEST_APPROVAL: {
      if (isReservation) {
        return {
          name: "campus-reservation-detail",
          params: {
            campusDomain: campusDomain,
            purchaseId: paymentId,
          },
          query,
        };
      }

      return {
        name: "campus-room-home",
        params: {
          campusDomain: campusDomain,
          productId: productId,
        },
        query,
      };
    }
    // 회원 > 기타결제 거절
    // 회원 > 기타결제 취소
    case Notification.PRODUCT_ETC_REJECT:
    case Notification.PRODUCT_ETC_CANCEL: {
      const replacePurchaseId = paymentId?.split("---")?.[0];
      return {
        name: isReservation
          ? "campus-reservation-detail"
          : "campus-purchase-detail",
        params: {
          campusDomain: campusDomain,
          purchaseId: replacePurchaseId,
        },
        query,
      };
    }
    // 관리자 > 회원 기타결제 승인 요청
    case Notification.PRODUCT_ETC_REQUEST: {
      return {
        name: "campus-admin-payment-detail",
        params: {
          campusDomain: campusDomain,
          paymentId,
        },
        query,
      };
    }
    // 관리자 > 기타결제 사용 승인
    // 관리자 > 기타결제 사용 거절
    // 관리자 > 기타결제 사용 보완
    case Notification.CAMPUS_PAY_ETC_ENABLE:
    case Notification.CAMPUS_PAY_ETC_REJECT:
    case Notification.CAMPUS_PAY_ETC_COMPLEMENTATION:
      return {
        name: "campus-admin-payment-management",
        params: {
          campusDomain: campusDomain,
        },
        query,
      };
    //관리자 > 회원 1:1피드백 요청 등록
    case Notification.ARTICLE_PRODUCT_FEEDBACK_CREATE: {
      const targetIds = articleTargetId?.split("#");
      const contentId = targetIds[1];

      return {
        name: "campus-admin-product-detail-feedback-detail",
        params: {
          campusDomain: campusDomain,
          productId: productId,
          feedbackId: articleId,
          contentId,
        },
        query,
      };
    }
    //회원 > 피드백 답변 등록
    case Notification.ARTICLE_PRODUCT_FEEDBACK_ANSWER_CREATE: {
      const targetIds = articleTargetRootId?.split("#");
      const productId = targetIds[0];
      const contentId = targetIds[1];
      return {
        name: "campus-room-feedback-detail",
        params: {
          campusDomain: campusDomain,
          feedbackId: articleTargetId,
          productId,
          contentId,
        },
        query,
      };
    }
    // 댓글 등록, 댓글 삭제
    case Notification.ARTICLE_REPLY_CREATE:
    case Notification.ARTICLE_REPLY_SUPER_DEL: {
      const targetIds = articleTargetRootId?.split("#");
      const productId = targetIds[0];
      const contentId = targetIds[1];

      switch (articleObjType) {
        case "CAMPUS_NOTICE_REPLY":
        case "DEL_CAMPUS_NOTICE_REPLY":
          return {
            name: "campus-notice-detail",
            params: {
              campusDomain: campusDomain,
              id: articleTargetId,
              notiTargetId: articleId,
              articleUpperId: articleUpperId,
            },
            query,
          };
        case "PRODUCT_NOTICE_REPLY":
        case "DEL_PRODUCT_NOTICE_REPLY":
          return {
            name: "campus-room-notice-detail",
            params: {
              campusDomain: campusDomain,
              productId: articleTargetRootId,
              noticeId: articleTargetId,
              notiTargetId: articleId,
              articleUpperId: articleUpperId,
            },
            query,
          };
        case "POST_REPLY":
        case "DEL_POST_REPLY":
          return {
            name: "campus-community-post",
            params: {
              campusDomain: campusDomain,
              communityId: articleTargetRootId,
              postId: articleTargetId,
              notiTargetId: articleId,
              articleUpperId: articleUpperId,
            },
            query,
          };
        case "CONTENT_REPLY":
        case "DEL_CONTENT_REPLY":
          return {
            name: "campus-playground",
            params: {
              campusDomain: campusDomain,
              productId: articleTargetRootId,
              contentId: articleTargetId,
              notiTargetId: articleId,
              articleUpperId: articleUpperId,
              currentTab: "Comment",
            },
            query,
          };
        case "PRODUCT_FEEDBACK_REPLY":
        case "DEL_PRODUCT_FEEDBACK_REPLY":
          return {
            name: "campus-room-feedback-detail",
            params: {
              campusDomain: campusDomain,
              productId,
              contentId,
              feedbackId: articleTargetId,
            },
            query,
          };
        default:
          return;
      }
    }
    // 캠퍼스 데모 설정
    // 캠퍼스 MAU 초과
    // 캠퍼스 기능 제한 설정/해제
    //관리자 > 요금제 결제 3회 실패 후 무료 요금제 전환
    //관라자 > 요금제 결제 실패
    case Notification.CAMPUS_PLAN_DEMO_START:
    case Notification.CAMPUS_PLAN_MAU_EXCEED:
    case Notification.CAMPUS_PLAN_CONSTRAINT:
    case Notification.CAMPUS_PLAN_CONSTRAINT_ENABLE:
    case Notification.CAMPUS_PLAN_PAY_FAIL_CHANGE_FREE_TO_MANAGER:
    case Notification.CAMPUS_PLAN_PAY_FAIL_TO_MANAGER: {
      return {
        name: "campus-admin-plan",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    case Notification.CAMPUS_PLAN_PAY_COMPLETE:
    case Notification.CAMPUS_PLAN_CHANGE_COMPLETE:
    case Notification.CAMPUS_PLAN_CANCEL_COMPLETE: {
      return {
        name: "campus-admin-plan-histories",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    case Notification.CAMPUS_PLAN_CREDIT_OFFER: {
      return {
        name: "campus-admin-credit-histories",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    case Notification.CAMPUS_PLAN_ADDON_QUOTA_EXCEED_EXTRA_VOLUME:
    case Notification.CAMPUS_PLAN_ADDON_QUOTA_EXCEED_CODING: {
      return {
        name: "campus-admin-addon",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
    }
    // 관리자 > 회원 예약 결제 완료
    // 관리자 > 회원 예약 결제 취소 요청
    case Notification.PRODUCT_RESERVATION_PAY_COMPLETE_TO_MANAGER:
    case Notification.PRODUCT_RESERVATION_PAY_CANCEL_REQUEST_TO_MANAGER: {
      return {
        name: "campus-admin-reservation-detail-payment-detail",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
          paymentId,
          productId,
        },
        query,
      };
    }
    // 회원 > 예약 결제 완료
    // 회원 > 예약 환불/취소 완료
    // 회원 > 예약 환불/취소 거절
    // 회원 > 예약 일정 시작 D-3일 전 안내
    // 회원 > 예약 일정 시작 3시간 전 안내
    // 회원 > 일정 종료 1일 후 리뷰 작성 안내
    // 회원 > 일정이 종료되지 않은 예약 옵션에 대해서 장소&일정 정보가 변경된 경우 안내
    case Notification.PRODUCT_RESERVATION_PAY_COMPLETE:
    case Notification.PRODUCT_RESERVATION_PAY_CANCEL_COMPLETE:
    case Notification.PRODUCT_RESERVATION_PAY_CANCEL_REJECT:
    case Notification.PRODUCT_RESERVATION_ALARM_3_DAYS:
    case Notification.PRODUCT_RESERVATION_ALARM_3_HOURS:
    case Notification.PRODUCT_RESERVATION_ALARM_REVIEW:
    case Notification.PRODUCT_RESERVATION_ALRAM_CHANGE: {
      return {
        name: "campus-reservation-detail",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
          purchaseId: paymentId,
          ...(notiType === Notification.PRODUCT_RESERVATION_ALARM_REVIEW && {
            openPopupReview: true,
          }),
        },
        query,
      };
    }
    default:
      return {
        name: "campus-home",
        params: {
          campusDomain: campusDomain,
          campusUuid: campusId,
        },
        query,
      };
  }
}
