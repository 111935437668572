<!-- default: gray-outlined -->
<template>
  <div class="p-badge border-box" :class="`p-badge--${badge.color}`">
    <span
      class="p-badge--content"
      :class="{ 'gray--text text--darken-3': dot }"
    >
      <div class="dot" v-if="dot" :class="badge.dotColor" />
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: "PoinUiBadge",
  props: {
    color: {
      type: String,
      default: "gray",
      // primary | secondary | gray | error | success | info
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    badge() {
      let color = this.color;

      if (this.filled) {
        return {
          color,
        };
      }
      // dotColor === this.color
      // font-color: this.dot ? "gray8" : this.filed ? "white" : this.color
      return {
        color: `${color}-line`,
        ...(this.dot && {
          dotColor: color === "gray" ? "gray darken-3" : color,
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.p-badge {
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: fit-content;
  height: 22px;
  font-weight: 700;
  font-size: 10px;
  padding: 0 8px;
  line-height: 20px;
  color: $gray8;
  background-color: $white;
}
.p-badge--primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}
.p-badge--primary-line {
  border: 1px solid $primary;
  background-color: $white;
  color: $primary;
}
.p-badge--gray {
  color: $white;
  background-color: $gray8;
}

.p-badge--gray-line {
  border: 1px solid $gray3;
  background-color: $white;
  color: $gray8;
}

.p-badge--secondary-line {
  border: 1px solid $secondary;
  background-color: $white;
  color: $secondary;
}
.p-badge--secondary {
  background-color: $secondary;
  border: 1px solid $secondary;
  color: $white;
}
.p-badge--error {
  background-color: $error;
  border: 1px solid $error;
  color: $white;
}
.p-badge--error-line {
  border: 1px solid $error;
  background-color: $white;
  color: $error;
}
.p-badge--success {
  background-color: $success;
  border: 1px solid $success;
  color: $white;
}
.p-badge--success-line {
  border: 1px solid $success;
  background-color: $white;
  color: $success;
}
.p-badge--info-line {
  border: 1px solid $info;
  background-color: $white;
  color: $info;
}
.p-badge--info {
  border: 1px solid $info;
  background-color: $info;
  color: $white;
}
.p-badge--content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.p-badge--large {
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 16px;
}
.p-badge--small {
  min-height: 20px;
  max-height: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 10px;
  padding: 0 8px;
  line-height: 16px;
  border-radius: 60px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
