<template>
  <v-select
    :class="customClass ? customClass : 'poin-select'"
    :items="options"
    :value="value"
    :dense="dense"
    :placeholder="placeholder"
    :menu-props="{
      left: false,
      right: true,
      bottom: true,
      offsetY: true,
      nudgeLeft: nudgeLeft,
      contentClass: 'p-select-sort-options',
    }"
    @change="updateInput"
    :clearable="clearable"
    hide-details
    single-line
    :outlined="outlined"
    color="gray darken-1"
    :background-color="readonly ? 'gray lighten-3' : backgroundColor"
    :readonly="readonly"
    :disabled="disabled"
    item-color="black"
    ref="select"
    :no-data-text="noDataText"
  >
    <template v-slot:selection="data">
      <div
        v-if="outlined"
        class="text-body-2-regular black--text"
        :data-title="data.item.text"
        :style="`width: ${textOption(data, true).width} !important`"
        :class="[
          textOption(data, true).class,
          { selection: textOption(data, true).hoverOn },
        ]"
      >
        {{ data.item.text }}
      </div>
      <div
        v-else
        class="text-body-2-regular black--text"
        :data-title="data.item.text"
      >
        {{ data.item.text }}
      </div>
    </template>
    <template v-slot:item="data">
      <p class="text-body-2-regular black--text">{{ data.item.text }}</p>
    </template>
    <template v-slot:append>
      <div class="d-flex align-center">
        <p-icon :icon="appendIcon" />
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from "vuex";
// options: [
//   {
//     text: "",  // 화면에서 보여지는 TEXT 값
//     value: "", // 데이터로 저장되는 실제 VALUE
//   }
// ]
export default {
  name: "PoinUiSelect",
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: "데이터가 없습니다.",
    },
    placeholder: {
      type: String,
      default: "카테고리를 선택해주세요.",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: 170,
    },
    customClass: {
      type: String,
    },
    customOption: {
      type: String,
    },
    appendIcon: {
      type: String,
      default: "DownSm/Gray8",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nudgeLeft: 0,
    };
  },
  computed: {},
  methods: {
    getNudgeLeft() {
      this.$nextTick(() => {
        const selectWidth = this.$refs?.select?.$el?.offsetWidth || 0;
        this.nudgeLeft = selectWidth ? 170 - selectWidth : 0;
      });
    },
    updateInput(val) {
      this.$emit("input", val);
      this.getNudgeLeft();
    },
    onClear() {
      this.$emit("input", "");
      this.$emit("click:clear");
    },
  },
  mounted() {
    this.getNudgeLeft();
  },
};
</script>

<style lang="scss" scoped>
.poin-select {
  padding: 0;
}
::v-deep {
  .v-input {
    * {
      &:active,
      &:focus,
      &:focus-visible {
        outline: unset !important;
      }
      font-size: 14px;
      color: $black;
    }
  }
  .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center;
  }
  .v-select__slot {
    display: flex;
    align-items: center;
    min-height: 40px;
    border: 1px solid $white;
    border-radius: 4px;
  }
  .v-select__selections input {
    &::placeholder {
      color: $gray6;
      font-size: 14px;
    }
  }
  fieldset {
    border-color: $gray3;
    outline: none;
  }
  .v-select__selection--comma {
    all: unset;
    box-sizing: border-box;
  }
  .v-input__icon--clear {
    width: 20px;
    min-width: 20px !important;
    height: 20px;
  }
  .v-icon__component {
    width: 20px;
    height: 20px;
  }
}
.v-select--is-menu-active {
  .v-input__append-inner i {
    transform: rotate(180deg);
  }
  ::v-deep .v-select__slot {
    border-color: $gray8 !important;
  }
}
</style>

<style lang="scss">
.p-select-sort-options {
  transform: translateY(4px);
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  border: 1px solid $gray3;
  box-shadow: unset;
  .v-select-list {
    padding: 12px 0;
  }
  .v-list-item {
    min-height: 40px;
    min-width: 170px;
    width: 170px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    padding: 0 12px;
    &:hover {
      background-color: $gray1;
    }
    &::before {
      all: unset;
      box-sizing: border-box;
    }
    .v-list-item__title {
      color: $black;
      font-size: 14px;
    }
  }
}
.poin-select.v-text-field--single-line:not(.v-text-field--outlined) {
  margin-top: 0;
  flex: unset;
  .v-input__slot::before,
  .v-input__slot::after {
    all: unset;
  }
  .v-input__slot {
    width: fit-content;
  }
  .v-input__control {
    width: fit-content;
    flex-grow: unset;
  }
  .v-select__slot {
    width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
  }
  .v-select__selections {
    flex: unset;
    input {
      display: none;
    }
  }
}
</style>
