<template>
  <v-overlay
    :value="show"
    :absolute="absolute"
    :opacity="opacity"
    :z-index="zIndex"
    :color="bgColor"
  >
    <v-progress-circular indeterminate :color="color" />
  </v-overlay>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    opacity: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 9998,
    },
    color: {
      type: String,
      default: "coral",
    },
    bgColor: {
      type: String,
      default: "#212121",
    },
  },
};
</script>
