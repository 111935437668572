import { endpointV2 as endpoint } from "@/config";

export async function getGroups({ campusId, memberId, name, keyword }) {
  if (!campusId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    ...(keyword && { keyword }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/`,
    params,
  });
}
export async function postGroup({ campusId, name, memberId, desc }) {
  if (!campusId) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    desc,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/`,
    data,
  });
}

// 멤버 관리
export async function postGroupMembers({
  toSk,
  changeType,
  campusId,
  name,
  fromSk,
  memberId,
  memberIds,
}) {
  if (!toSk || !changeType || !campusId) {
    return;
  }
  const data = {
    memberIds,
    changeType,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(toSk && { toSk }),
    ...(name && { name }),
    ...(fromSk && { fromSk }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/members`,
    data,
  });
}
export async function getGroup({ campusId, memberId, sk, name }) {
  if (!campusId || !memberId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sk && { sk }),
    ...(name && { name }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/${sk}`,
    params,
  });
}
export async function putGroup({ campusId, name, memberId, desc, sk }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    desc,
    ...(name && { name }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.groups}/${sk}`,
    data,
  });
}
export async function deleteGroup({ sk }) {
  return this.getMethod("delete", true, {
    url: `${endpoint.groups}/${sk}`,
  });
}
