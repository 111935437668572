<template>
  <v-select
    :class="customClass ? customClass : 'poin-select'"
    :items="options"
    :value="value"
    :dense="dense"
    :placeholder="placeholder"
    :menu-props="{
      bottom: true,
      offsetY: true,
      contentClass: !customOption
        ? 'p-select-options'
        : `p-select-options ${customOption}`,
    }"
    @change="updateInput"
    :clearable="clearable"
    hide-details
    single-line
    outlined
    color="gray darken-1"
    :background-color="readonly ? 'gray lighten-3' : backgroundColor"
    :readonly="readonly"
    :disabled="disabled"
    item-color="black"
    ref="select"
    :no-data-text="noDataText"
  >
    <template v-slot:selection="data">
      <div
        class="text-body-2-regular black--text"
        :data-title="data.item.text"
        :style="`width: ${textOption(data, true).width} !important`"
        :class="[
          textOption(data, true).class,
          { selection: textOption(data, true).hoverOn },
        ]"
        v-html="data.item.text"
      />
    </template>
    <template v-slot:item="data">
      <p
        v-if="!textOption(data).hoverOn"
        v-html="textOption(data).text"
        :style="`width: ${textOption(data).width} !important`"
        :class="textOption(data).class"
        class="text-body-2-regular black--text"
      />
      <v-tooltip v-else content-class="pa-3 ml-3" right color="black">
        <template v-slot:activator="{ on }">
          <p
            v-on="on"
            v-html="textOption(data).text"
            :style="`width: ${textOption(data).width} !important`"
            :class="textOption(data).class"
            class="text-body-2-regular black--text"
          />
        </template>
        <p class="text-body-2-regular">
          {{ textOption(data).text }}
        </p>
      </v-tooltip>
    </template>
    <template v-slot:append>
      <div class="d-flex align-center">
        <p-icon :icon="appendIcon" />
      </div>
    </template>
  </v-select>
</template>

<script>
// options: [
//   {
//     text: "",  // 화면에서 보여지는 TEXT 값
//     value: "", // 데이터로 저장되는 실제 VALUE
//   }
// ]
export default {
  name: "PoinUiSelect",
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: "데이터가 없습니다.",
    },
    placeholder: {
      type: String,
      default: "카테고리를 선택해주세요.",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: 170,
    },
    customClass: {
      type: String,
    },
    customOption: {
      type: String,
    },
    appendIcon: {
      type: String,
      default: "DownSm/Gray8",
    },
  },
  methods: {
    removeTag(str) {
      str = str.replace(/<\/p>/gi, "\n");
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    textOption(data, isSelection) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "14px Noto Sans KR";
      context.letterSpacing = "-0.8px";
      const textWidth =
        context.measureText(this.removeTag(data.item.text)).width + 24; //공백 포함 문자열 width + padding-x
      const select = this.customClass ? `.${this.customClass}` : ".poin-select";
      const parentWidth = isSelection
        ? document?.querySelector(select)?.offsetWidth - 54 // closeIcon + arrow
        : document?.querySelector(select)?.offsetWidth;

      let result = {
        text: data.item.text,
        width: "100%",
      };
      if (textWidth > parentWidth) {
        return {
          ...result,
          width: `${parentWidth - 24}px `,
          class: "single-line",
          hoverOn: true,
        };
      }
      return result;
    },
    updateInput(val) {
      this.$emit("input", val);
    },
    onClear() {
      this.$emit("input", "");
      this.$emit("click:clear");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input {
    * {
      &:active,
      &:focus,
      &:focus-visible {
        outline: unset !important;
      }
      font-size: 14px;
      color: $black;
    }
  }
  .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center;
  }
  .v-select__slot {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  .v-select__selections input {
    &::placeholder {
      color: $gray6;
      font-size: 14px;
    }
  }
  fieldset {
    border-color: $gray4;
    outline: none;
  }
  .v-select__selection--comma {
    all: unset;
    box-sizing: border-box;
  }
  .v-input__icon--clear {
    width: 20px;
    min-width: 20px !important;
    height: 20px;
  }
  .v-icon__component {
    width: 20px;
    height: 20px;
  }
}
.v-select--is-menu-active .v-input__append-inner i {
  transform: rotate(180deg);
}
</style>

<style lang="scss">
.p-select-options {
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  border: 1px solid $gray3;
  box-shadow: unset;
  .v-select-list {
    padding: 12px 0;
  }
  .v-list-item {
    min-height: 40px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    padding: 0 12px;
    &:hover {
      background-color: $gray1;
    }
    &::before {
      all: unset;
      box-sizing: border-box;
    }
    .v-list-item__title {
      color: $black;
      font-size: 14px;
    }
  }
  &::-webkit-scrollbar {
    background-color: $white;
    width: 18px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $white;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray3;
    border-radius: 12px;
    border: 6px solid $white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray5;
    border: 6px solid $white;
  }
}
.selection[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.2s;
  visibility: visible;
}
.selection[data-title]:after {
  position: absolute;
  content: attr(data-title);
  background-color: $black;
  color: $white;
  border-radius: 4px;
  padding: 8px 12px;
  bottom: -38px;
  left: -12px;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
}
.v-select.v-input--is-readonly > .v-input__control > .v-input__slot {
  cursor: default;
}
</style>
