<template>
  <v-menu
    v-model="active"
    :close-on-content-click="false"
    offset-y
    left
    class="py-2"
    content-class="menu-btn"
  >
    <template v-slot:activator="{ on }">
      <button
        @click="active != active"
        v-on="on"
        :disabled="disabled"
        class="p-btn border-box"
        :class="[
          {
            'p-size--large': large,
            'p-size--small': small,
            'p-size--medium': !large && !small,
            'p-btn--disabled': disabled,
          },
          `p-btn--${colors.button}`,
        ]"
        :style="variables"
      >
        <span class="p-btn__content" :class="{ loading }">
          <slot />
          <p-icon
            v-if="!loading"
            :icon="`DownSm/${disabled ? 'Gray5' : colors.icon}`"
            size="24"
            class="rotate-icon"
            :style="{ transform: active ? 'rotate(180deg)' : 'rotate(0deg)' }"
          />
        </span>
        <div class="p-btn__loading" v-if="loading">
          <v-progress-circular
            indeterminate
            :color="spinnerColor"
            size="23"
            width="2"
          />
        </div>
      </button>
    </template>
    <v-list dense v-if="!loading">
      <v-list-item
        v-for="item in items"
        :key="item.text"
        @click="onClick(item)"
      >
        <p class="text-body-2-regular">{{ item.text }}</p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "PoinUiButtonList",
  data() {
    return {
      active: false,
    };
  },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sub: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "$down-sm",
    },
    minWidth: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    variables() {
      return {
        "--min-width": this.minWidth || "fit-content",
      };
    },
    colors() {
      let color = this.color;

      if (!this.outlined && color === "primary") {
        return {
          button: this.sub ? (color += "-sub") : "primary",
          icon: this.sub ? "primary" : "White",
        };
      }
      return {
        button: "outlined",
        icon: "Gray8",
      };
    },
    spinnerColor() {
      if (!this.outlined) {
        return "white";
      }
      return "gray gray--darken3";
    },
  },
  methods: {
    onClick(item) {
      this.active = !this.active;
      if (item.action) {
        item.action(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white;
  min-width: var(--min-width);

  @include hover-before;
}
.p-btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  position: relative;
  gap: 4px;
  &.loading {
    color: transparent !important;
    * {
      color: transparent !important;
    }
  }
}

.p-btn--primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  &:before {
    background-color: $primary800;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
}

.p-btn--primary-sub {
  background-color: $primary300;
  border: 1px solid $primary300;
  color: $primary;
  &:before {
    background-color: $primary100;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
}
.p-btn--outlined {
  background-color: $white;
  border: 1px solid $gray4;
  color: $gray8;
}
.p-size--large {
  min-height: 48px;
  max-height: 48px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
}
.p-size--small {
  min-height: 32px;
  max-height: 32px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
}
.p-size--medium {
  min-height: 40px;
  max-height: 40px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
}
.p-btn--disabled {
  background-color: $gray2 !important;
  border: 1px solid $gray2 !important;
  color: $gray5 !important;
  cursor: not-allowed;
  &:before {
    all: unset;
    box-sizing: border-box;
  }
  &:focus::before,
  &:hover::before {
    opacity: 0;
  }
}
.p-btn__loading {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-menu__content {
  margin-top: 4px;
}
</style>
