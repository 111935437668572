var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hoverOn)?_c('div',{staticClass:"p-tip grid-tip",class:{
      'p-tip--info': _vm.color === 'info',
      'p-tip--gray5': _vm.color === 'gray5',
      'p-tip--gray8': _vm.color === 'gray8',
      'p-tip--black': _vm.color === 'black',
      'p-tip--primary': _vm.color === 'primary',
    },style:(_vm.variables)},[_c('p-icon',{attrs:{"icon":_vm.icon,"size":_vm.size}}),_c('p',{staticClass:"text text-body-2-regular",domProps:{"innerHTML":_vm._s(_vm.text)}})],1):_c('div',{staticClass:"p-tip ml-1 keep-all",attrs:{"hover-tooltip":_vm.text,"tooltip-position":_vm.position}},[_c('p-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.icon,"size":_vm.size}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }