<template>
  <div class="info-vertical">
    <div class="d-flex align-center justify-space-between w-full">
      <div class="d-flex align-center justify-start">
        <p-icon v-if="icon" :icon="icon" class="mr-1" />
        <p-asset v-if="asset" :name="asset" size="20" class="mr-1" />
        <p-label :label="title" :info-text="info" />
      </div>
      <p-link v-if="link" :color="linkColor" @click="onClick">{{
        link
      }}</p-link>
    </div>

    <slot v-if="item.custom" name="custom" />
    <div class="d-flex align-center gap-2" v-else>
      <p :class="`${color}--text`" class="text-body-2-medium text-pre-line">
        {{ body }}
      </p>
      <p-chip v-if="chip" small :color="theme">{{ chip }}</p-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoVertical",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      return this.item?.title || "";
    },
    link() {
      return this.item?.link || "";
    },
    linkColor() {
      return this.item?.linkColor || "primary";
    },
    body() {
      return this.item?.body;
    },
    color() {
      return this.item?.color || "black";
    },
    icon() {
      return this.item?.icon || "";
    },
    asset() {
      return this.item?.asset || "";
    },
    chip() {
      return this.item?.chip;
    },
    theme() {
      return this.item?.theme || "info";
    },
    info() {
      return this.item?.info || "";
    },
  },
  methods: {
    onClick() {
      this.item.action();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-vertical {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
}
</style>
