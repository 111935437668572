import { useBreakpoints } from "@vueuse/core";

export function useBreakpoint() {
  const breakpoints = useBreakpoints({
    sm: 600,
    md: 960,
    lg: 1264,
  });

  const isMobile = breakpoints.smaller("md");
  const isSm = breakpoints.smaller("sm");
  const isMd = breakpoints.between("sm", "md");
  const isLg = breakpoints.between("md", "lg");

  return {
    isMobile,
    isSm,
    isMd,
    isLg,
  };
}
