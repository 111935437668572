<template>
  <v-menu
    v-model="active"
    :close-on-content-click="false"
    offset-y
    left
    class="py-2"
    content-class="menu-btn"
  >
    <template v-slot:activator="{ on }">
      <button
        @click="active != active"
        v-on="on"
        :disabled="disabled"
        class="p-btn border-box"
        :class="[
          {
            'p-btn--disabled': disabled,
            'p-btn--gray-outlined': active,
            'gray-3-outlined': !active && outlined,
          },
        ]"
        :style="variables"
      >
        <div class="p-btn__loading" v-if="loading">
          <v-progress-circular
            indeterminate
            color="gray gray--darken3"
            size="23"
            width="2"
          />
        </div>
        <p-icon v-else icon="More" :size="16" />
      </button>
    </template>
    <v-list dense v-if="!loading">
      <v-list-item
        v-for="item in items"
        :key="item.text"
        @click="onClick(item)"
      >
        <p class="text-body-2-regular">{{ item.text }}</p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "PoinUiButtonMore",
  data() {
    return {
      active: false,
    };
  },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    variables() {
      return {
        "--size": !this.large ? "24px" : "40px",
      };
    },
  },
  methods: {
    onClick(item) {
      this.active = !this.active;
      if (item.action) {
        item.action(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white;
  width: var(--size);
  height: var(--size);
  max-height: var(--size);

  @include hover-before;
}
.p-btn--disabled {
  background-color: $gray2 !important;
  border: 1px solid $gray2 !important;
  color: $gray5 !important;
  cursor: not-allowed;
  &:before {
    all: unset;
    box-sizing: border-box;
  }
  &:focus::before,
  &:hover::before {
    opacity: 0;
  }
}
.p-btn__loading {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-menu__content {
  margin-top: 4px;
}
.p-btn--gray-outlined {
  outline: none;
  cursor: pointer;
  transition: outline 0.3s all;

  &:focus {
    outline: 1px solid $gray8;
  }

  &.p-btn {
    &:before {
      opacity: 0;
    }
  }
}
</style>
