<template>
  <div class="poin-input">
    <label
      v-if="label"
      :for="`${_uid}-input`"
      class="font-size-14 font-weight-medium gray--text text--darken-3"
      >{{ label }}<span class="error--text ml-1" v-if="required">*</span></label
    >
    <div
      class="poin-input-group"
      :style="`height: ${realHeight}px`"
      :class="{
        filled,
        invalid,
        disabled,
        readonly,
        bottom,
        'success-line': success,
        'mt-2': label,
      }"
    >
      <div class="poin-input-prefix" aria-hidden="true" v-if="prefix">
        {{ prefix }}
      </div>
      <div
        class="poin-input-prefix pa-0"
        aria-hidden="true"
        v-if="$scopedSlots['prepend']"
      >
        <slot name="prepend" />
      </div>
      <input
        type="text"
        class="poin-input"
        :id="`datePicker-${id}`"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        :min="min"
        :max="max"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        @input="updateInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.enter.prevent
        :class="{ 'pl-1': prefix, 'pr-1': suffix, 'p-input--dense': dense }"
        onwheel="return false;"
      />
      <div
        class="poin-input-suffix"
        aria-hidden="true"
        v-if="clearable && value"
      >
        <p-icon
          icon="DeleteFill/Gray5"
          :size="20"
          class="cursor-pointer"
          @click="onClear"
        />
      </div>
      <div class="poin-input-suffix" aria-hidden="true">
        <p-icon icon="Calendar/Black" class="cursor-pointer" />
      </div>
    </div>
    <div class="d-flex" v-if="!hideDetails">
      <span
        class="error--text text-detail-1-regular error-msg"
        style="line-height: 16px"
        v-show="invalid"
        >{{ errorMsg }}</span
      >
      <span
        class="success--text text-detail-1-regular error-msg"
        style="line-height: 16px"
        v-show="!invalid && success"
        >{{ successMsg }}</span
      >
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  name: "PoinUiInput",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    successMsg: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      id: uuid(),
    };
  },
  computed: {
    invalid() {
      return this.errorMsg;
    },
    realHeight() {
      return this.height ? this.height : this.dense ? 40 : 48;
    },
  },
  methods: {
    updateInput(e) {
      this.$emit("input", e.target.value);
    },
    onBlur() {
      this.$emit("blur");
    },
    onFocus() {
      this.$emit("focus");
    },
    enterAction() {
      this.$emit("keyup:enter");
    },
    onClear() {
      this.$emit("input", "");
      this.$emit("click:clear");
    },
  },
  mounted() {
    $(`#datePicker-${this.id}`)
      ?.datepicker({
        format: "yyyy-MM-dd", //데이터 포맷 형식(yyyy : 년 mm : 월 dd : 일 )
        // startDate: "-10d", //달력에서 선택 할 수 있는 가장 빠른 날짜. 이전으로는 선택 불가능 ( d : 일 m : 달 y : 년 w : 주)
        // endDate: "+10d", //달력에서 선택 할 수 있는 가장 느린 날짜. 이후로 선택 불가 ( d : 일 m : 달 y : 년 w : 주)
        autoclose: true, //사용자가 날짜를 클릭하면 자동 캘린더가 닫히는 옵션
        calendarWeeks: false, //캘린더 옆에 몇 주차인지 보여주는 옵션 기본값 false 보여주려면 true
        clearBtn: false, //날짜 선택한 값 초기화 해주는 버튼 보여주는 옵션 기본값 false 보여주려면 true
        // datesDisabled: ["2019-06-24", "2019-06-26"], //선택 불가능한 일 설정 하는 배열 위에 있는 format 과 형식이 같아야함.
        // daysOfWeekDisabled: [0, 6], //선택 불가능한 요일 설정 0 : 일요일 ~ 6 : 토요일
        // daysOfWeekHighlighted: [3], //강조 되어야 하는 요일 설정
        disableTouchKeyboard: false, //모바일에서 플러그인 작동 여부 기본값 false 가 작동 true가 작동 안함.
        immediateUpdates: false, //사용자가 보는 화면으로 바로바로 날짜를 변경할지 여부 기본값 :false
        multidate: false, //여러 날짜 선택할 수 있게 하는 옵션 기본값 :false
        multidateSeparator: ",", //여러 날짜를 선택했을 때 사이에 나타나는 글짜 2019-05-01,2019-06-01
        templates: {
          leftArrow: "&laquo;",
          rightArrow: "&raquo;",
        }, //다음달 이전달로 넘어가는 화살표 모양 커스텀 마이징
        showWeekDays: true, // 위에 요일 보여주는 옵션 기본값 : true
        // title: "테스트", //캘린더 상단에 보여주는 타이틀
        todayHighlight: true, //오늘 날짜에 하이라이팅 기능 기본값 :false
        toggleActive: true, //이미 선택된 날짜 선택하면 기본값 : false인경우 그대로 유지 true인 경우 날짜 삭제
        weekStart: 0, //달력 시작 요일 선택하는 것 기본값은 0인 일요일
        language: "ko",
      })
      .on("changeDate", function (e) {
        /* 이벤트의 종류 */
        //show : datePicker가 보이는 순간 호출
        //hide : datePicker가 숨겨지는 순간 호출
        //clearDate: clear 버튼 누르면 호출
        //changeDate : 사용자가 클릭해서 날짜가 변경되면 호출 (개인적으로 가장 많이 사용함)
        //changeMonth : 월이 변경되면 호출
        //changeYear : 년이 변경되는 호출
        //changeCentury : 한 세기가 변경되면 호출 ex) 20세기에서 21세기가 되는 순간
        // console.log(e);
        // e.date를 찍어보면 Thu Jun 27 2019 00:00:00 GMT+0900 (한국 표준시) 위와 같은 형태로 보인다.
      });

    // TODO: methods
    // https://bootstrap-datepicker.readthedocs.io/en/latest/methods.html#setdate
  },
};
</script>

<style scoped lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker3.min.css");
* {
  box-sizing: border-box;
  font-size: 14px;
}
.poin-input--error {
  border-color: $error;
}
/* .poin-input:focus-within {
  .error-msg {
    display: none;
  }
} */
.poin-input-group {
  position: relative;
  align-items: center;
  background-color: $white;
  display: flex;
  padding: 0;
  border: 1px solid $gray3;
  border-radius: 4px;
  outline: none;
  &.filled {
    background-color: $gray1;
    border: 1px solid $gray1;
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: none;
        outline: none;
        background-color: $gray1;
        box-shadow: 0 0 0px 1000px $gray1 inset;
        -webkit-box-shadow: 0 0 0px 1000px $gray1 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
  &.bottom {
    border: none;
    border-bottom: 1px solid $gray3;
    border-radius: 0;
    input {
      padding: 0;
    }
  }
  &.invalid {
    border-color: $error;
  }
  &:focus-within {
    border-color: $gray6;
  }
  &.success-line {
    border-color: $success;
  }
  &.readonly {
    background-color: $gray2;
    border-color: $gray3;
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: none;
        outline: none;
        background-color: $gray2;
        box-shadow: 0 0 0px 1000px $gray2 inset;
        -webkit-box-shadow: 0 0 0px 1000px $gray2 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
  &.disabled {
    background-color: $gray2;
    border-color: $gray3;
    color: $gray5;
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: none;
        outline: none;
        background-color: $gray2;
        box-shadow: 0 0 0px 1000px $gray2 inset;
        -webkit-box-shadow: 0 0 0px 1000px $gray2 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
  input {
    border: 0;
    height: 100%;
    margin-top: 0;
    min-width: 0;
    width: 100%;
    letter-spacing: -0.8px;
    color: $black;
    padding: 0 8px;
    font-size: 14px;
    height: 100%;
    border-radius: 4px;
    z-index: 2;
    position: relative;
    &::placeholder {
      color: $gray6;
      font-size: 14px;
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      outline: none;
      background-color: $white;
      box-shadow: 0 0 0px 1000px $white inset;
      -webkit-box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &.p-input--dense {
      font-size: 14px;
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}
.poin-input-group--error {
  border-color: $error;
  border-style: solid;
}

.poin-input-prefix,
.poin-input-suffix {
  color: $gray6;
  line-height: 0;
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  &.poin-input-prefix {
    padding-left: 8px;
  }
  &.poin-input-suffix {
    padding-right: 8px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
</style>

<style>
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
</style>
