var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"p-btn border-box",class:{
    'p-size--large': _vm.large,
    'p-size--small': _vm.small,
    'p-size--medium': !_vm.large && !_vm.small,
    'p-btn--primary': _vm.color === 'primary',
    'p-btn--primary-sub':
      (_vm.color === 'primary' && _vm.sub) || _vm.color === 'primarySub',
    'p-btn--gray-line': _vm.color === 'gray',
    'p-btn--white-line': _vm.color === 'whiteLine',
    'p-btn--black': _vm.color === 'black',
    'p-btn--transparent': _vm.color === 'transparent',
    'p-btn--secondary': _vm.color === 'secondary',
    'p-btn--excel': _vm.color === 'excel',
    'p-btn--excel-sub': _vm.color === 'excel' && _vm.sub,
    'p-btn--success': _vm.color === 'success',
    'p-btn--disabled': _vm.disabled,
    'p-btn--rounded': _vm.rounded,
    'p-btn--text': _vm.text,
  },style:(_vm.variables),attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('span',{staticClass:"p-btn__content",class:{ loading: _vm.loading }},[_vm._t("default"),(_vm.icon && _vm.selfIcon)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"gray darken-3","size":"24"}},[_vm._v(_vm._s(_vm.icon))]):(_vm.icon)?_c('p-icon',{staticClass:"ml-1",attrs:{"icon":`${_vm.icon}/Gray8`,"size":"24"}}):_vm._e()],2),(_vm.loading)?_c('div',{staticClass:"p-btn__loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.spinnerColor,"size":"23","width":"2"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }