export const ko = {
  productHome: {
    filter: "필터",
    category: "카테고리",
    confirm: "적용하기",
    cancel: "닫기",
    salesYnLabel: "판매 여부",
    salesYnDesc: (ctx) =>
      `판매중인 ${ctx.named("altProduct") || "프로덕트"}만 보기`,
    promotionYnLabel: "프로모션 여부",
    promotionYnDesc: (ctx) =>
      `프로모션 진행중인 ${ctx.named("altProduct") || "프로덕트"}만 보기`,
    reset: "초기화",
    searchPlaceholder: "검색어를 입력하세요.",
    sortRegDttm: "등록일순",
    sortName: "이름순",
    sortSalesCnt: "구매많은순",
    sortPriceAsc: "낮은가격순",
    sortPriceDesc: "높은가격순",
    more: "더보기",
    noSearchResult: "검색 결과가 없습니다.",
  },
  productDetail: {
    emptyIntro: "등록된 소개글이 없습니다.",
    emptyContents: "등록된 콘텐츠가 없습니다.",
    emptyNotices: "작성된 안내사항이 없습니다.",
    emptyReviews: "작성된 리뷰가 없습니다.",
    emptyFeedback: "등록된 피드백이 없습니다.",
    all: "전체보기",
    subTitleLinks: "관련 링크",
    subTitleManagers: "담당자 정보",
    navIntro: "소개",
    navContents: "콘텐츠",
    navNotices: "안내사항",
    navReviews: "리뷰",
    navQna: "Q&A",
    navRefund: "환불 정책",
    purchase: "구매하기",
    repurchase: "재구매하기",
    ask: "문의하기",
    history: "* 구매한 이력이 있습니다.",
    contentsCnt: "{cnt}개의 콘텐츠 제공",
    period: "평생 이용 가능 | {period}일 이용 가능",
    periodDesc: "평생 이용 가능합니다. | {period}일 이용 가능합니다.",
    salesEnd: "판매 마감",
    joinAuto: "참여하기",
    joinManual: "참여 신청하기",
    basicEnd: "모집 마감",
    joinAutoMsg: "'{name}' 참여하시겠습니까?",
    joinManualMsg: "'{name}' 참여 신청하시겠습니까?",
    toRoom: "참여하기",
    joinDisabledMsg: (ctx) =>
      `참여 신청할 수 없는 ${ctx.named("altProduct") || "프로덕트"}입니다.`,
    notFound: "존재하지 않는 {altProduct}입니다.",
    joinTypeAuto: "누구나 참여가능",
    joinTypeManual: "관리자 승인 후 참여 가능",
    unclassified: "비공개 운영",
    joinAutoDone: (ctx) =>
      `${ctx.named("altProduct") || "프로덕트"} 참여가 완료되었습니다.`,
    joinManualDone: "참여 신청이 완료되었습니다.",
    access: (ctx) =>
      `참여할 수 없는 ${ctx.named("altProduct") || "프로덕트"}입니다.`,
    duplicate: (ctx) =>
      `이미 참여중인 ${ctx.named("altProduct") || "프로덕트"}입니다.`,
    backgroundTitle: "백그라운드 재생",
    backgroundMsg:
      "백그라운드 재생법에 대해서 궁금하시다면 여기를 클릭해주세요!",
  },
  productPurchase: {
    myPurchase: "구매내역",
    myPurchaseDesc: (ctx) =>
      `구매한 ${
        ctx.named("altProduct") || "프로덕트"
      } 목록을 확인할 수 있습니다. 구매내역 클릭 시 구매 상세 정보를 확인할 수 있습니다.`,
    emptyMsg: (ctx) => `${ctx.named("type") || "구매"} 내역이 없습니다.`,
    cancelInfo: "취소정보",
    refundInfo: "환불 정보",
    commissionInfo: "수수료 결제 정보",
    commissionRequestInfo: "수수료 결제 예정 정보",
    commissionRefundInfo: "수수료 환불 정보",
    customerInfo: "구매자정보",
    paymentInfo: "결제정보",
    paymentDetail: "결제내역보기",
    paymentDetailTitle: "결제 상세 내역",
    mainBtnText: "메인홈",
    playlistBtnText: "참여현황",
    checkoutBtnText: "결제하기",
    processBtnText: "참여하기",
    refundBtnText: "신청하기",
    cancelBtnText: "환불 진행",
    cancelReqBtnText: "신청 처리",
    etcApprovalBtnText: "결제 승인",
    payCancelBtnText: "결제 취소",
    coupon: "쿠폰할인",
    vat: "(VAT 포함)",
    productPrice: "상품 금액",
    completeDttm: "결제완료일시",
    requestDttm: "결제요청일시",
    cancelReqDttm: "환불신청일시",
    cancelRejectDttm: "환불거부일시",
    cancelCompleteDttm: "환불처리일시",
    cancelCompleteReason: "환불 처리 사유",
    cancelRejectReason: "환불거부사유",
    cancelProduct: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 정보`,
    cancelPurchaseIntent: "구매의사취소",
    cancelRequestText: "환불신청",
    cancelPrice: "환불 금액 (VAT 포함)",
    cancelComplete: (ctx) => `${ctx.named("type") || "환불"} 완료했습니다.`,
    installments: "할부 개월",
    name: "이름",
    contact: "전화번호",
    email: "이메일",
    paymentWay: "결제 수단",
    status: "상태",
    paymentDttm: "결제완료일시",
    payReqDttm: "결제요청일시",
    payRejectDttm: "결제취소일시",
    payApprovalDttm: "결제승인일시",
    payRejectReason: "결제취소사유",
    amount: "금액",
    price: "가격",
    settlementInfo: "지급 예정일이 공휴일일 경우, 다음 영업일에 정산됩니다.",
    settleAmount: "정산지급 예상금액",
    total: "총 결제 금액",
    refundWay: "환불 수단",
    refundAmount: "최종 금액",
    totalAmount: "최종 결제 금액(VAT 포함) ",
    discount: "할인 금액",
    promotion: "프로모션",
    process: "지금 바로 참여해보세요.",
    paymentComplete: "결제를 완료했습니다.",
    virtualAccount: "가상계좌",
    virtualPaymentRequestDttm: "결제요청일시",
    virtualComplete: "가상 계좌로 입금을 진행해주세요.",
    virtualProcess:
      "하단의 가상 계좌정보를 확인하신 후 입금을 진헹해주세요. 입금이 확인되면 정상적으로 결제 완료처리가 됩니다.",
    virtualAdditionalNotice:
      "까지 미입금시 결제요청거부처리 및 가상계좌번호는 소멸됨을 알려드립니다.",
    etcComplete: "안내사항에 따라 결제를 진행해주세요.",
    etcProcess:
      "하단의 기타결제 안내에 따라 결제를 진행해주세요. 관리자의 확인 후 결제 완료처리가 됩니다.",
    refundPrice: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 금액`,
    refundReason: "환불 사유",
    refundReqDttm: "환불신청일시",
    refundCompleteDttm: "환불처리일시",
    refundPlaceholder: "취소사유를 입력해주세요.",
    refundPolicy: (ctx) => `${ctx.named("altProduct") || ""} 환불 정책`,
    refundPolicyBtnText: "상세 환불 정책 보기",
    refundPolicyDesc: (ctx) =>
      `${
        ctx.named("altProduct") || "프로덕트"
      } 구매시 아래의 상세 환불 정책에 따라 진행됩니다.`,
    refundNotice:
      "-카드 결제의 경우 취소기간은 카드 종류와 카드사 정책에 따라 달라질 수 있습니다.",
    emptyPolicyMsg: "내용이 없습니다.",
    completeText: "환불신청을 완료했습니다.",
    completeDesc: "확인 후 신청처리가 진행될 예정입니다.",
    immediateComplete: "환불 처리되었습니다.",
    discontented: "서비스 및 콘텐츠 불만족",
    differentProductInfo: "상품정보상이",
    cancelFreeTitle: "무료 프로덕트 환불",
    cancelFreeMsg:
      "환불 처리한 회원은 더 이상 프로덕트에 참여할 수 없습니다. 계속 진행하시겠습니까?",
    cancelCompletionText: "취소 처리 완료 건수",
    cancelAmountText: "전체 취소 금액 / 결제 금액",
    cancelReqCntText: "결제 취소 신청건",
    dueDate: "정산지급예상일",
    etc: "기타",
    accountHolder: "환불 예금주명",
    accountNumber: "환불 계좌번호",
    bank: "환불 은행",
    etcAmount: "기타 결제 금액",
    etcCardName: "결제 카드 정보",
    etcCommission: "수수료 금액 (VAT 포함)",
    etcCommissionRefund: "수수료 환불 금액 (VAT 포함)",
    quota: "할부 기간",
  },
  productReservation: {
    myHistory: "예약내역",
    myHistoryDesc: "목록 클릭 시 예약 상세 정보를 확인할 수 있습니다.",
    completedDesc: "일정과 장소 정보를 확인해보세요.",
    additionalInfo: "추가정보",
    customerInfo: "예약자정보",
    scheduleInfo: "일정정보",
    schedulePeriod: "전체 일정",
    scheduleDetailConts: "일정 상세 내용",
    placeInfo: "장소정보",
    place: "장소정보",
    address: "주소",
    addressInfo: "상세 안내",
    contact: "연락처",
    etcContact: "기타 연락처",
    title: "예약 정보",
    refundPolicyDesc: "예약 결제 시 아래의 상세 환불 정책에 따라 진행됩니다.",
    refundPolicy: "예약 환불 정책",
    group: "회원 그룹",
    emptyMsg: "예약 내역이 없습니다.",
  },
  productReview: {
    myHistory: "작성 리뷰",
    myHistoryDesc: "작성한 리뷰 목록입니다.",
  },
  adminProductHome: {
    create: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 만들기`,
    searchPlaceholder: "검색어를 입력하세요.",
    salesStatusPlaceholder: "{type}상태",
    promotionStatusPlaceholder: "프로모션",
    sortPlaceholder: "정렬",
    navSales: "판매형",
    navBasic: "운영형",
    sortRegDttm: "등록일순",
    sortName: "이름순",
    sortStartDttm: "{type}시작순",
    sortEndDttm: "{type}마감순",
    statusStandby: "{type}전",
    statusSales: "{type}중",
    statusEnd: "{type}마감",
    promotionN: "미적용",
    promotionY: "진행중",
    notFound: (ctx) =>
      `존재하지 않는 ${ctx.named("altProduct") || "프로덕트"}입니다.`,
    basic: "모집",
    sales: "판매",
    hProduct: (ctx) => `${ctx.named("altProduct") || "프로덕트"}`,
    hStatus: "{type}상태",
    hRegDttm: "등록일시",
    hClassified: "비공개",
    hPublish: "노출",
    hJoinType: "참여방식",
    hPrice: "가격",
    hPromotion: "프로모션상태",
    hDttm: "{type}시작/마감일시",
    productDelete: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 삭제`,
    productEdit: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 수정`,
    deleteConfirmMsg: "해당 프로덕트를 삭제하시겠습니까?",
    deleteUnavailableMember: "사용기간이 남아있는 구매자가 있습니다.",
    deleteUnavailableNotEnd: (ctx) =>
      `<p>${ctx.named("type")} 중인 ${
        ctx.named("altProduct") || "프로덕트"
      }입니다.</p><p>${ctx.named("type")} 마감 후 다시 시도해주세요.</p>`,
    needSalesY:
      "판매 승인 이후 노출 가능합니다. [판매 > 판매 신청]에서 판매 신청을 진행해주세요.",
    mauLimitExceed:
      "MAU를 초과하였습니다. 요금 플랜 업그레이드를 진행해주세요.",
    statusUnavailable: "이미 처리된 요청입니다.",
    deleteFail: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 삭제 불가`,
    statusFail: "노출 On 변경 실패",
    toggleStatus: "노출 상태 {toggle}으로 상태 변경을 완료했습니다.",
    publish: "노출",
  },
  adminProductEdit: {
    titleDevelop: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 만들기`,
    titleEdit: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 수정`,
    createSection: "새로운 섹션",
    guideTitle: "도움말",
    guideText: (ctx) =>
      `${
        ctx.named("altProduct") || "프로덕트"
      }에 대해 알아볼까요? 여기를 클릭해주세요.`,
    navDefault: "기본 정보",
    navIntroduction: (ctx) => `${ctx.named("altProduct") || "프로덕트"} 소개`,
    navSales: "판매 정보",
    navBasic: "운영 정보",
    navOptions: "콘텐츠 옵션",
    navCompletion: "수료 기능",
    navContents: "콘텐츠 구성",
    developFail: (ctx) => `저장 실패`,
    editFail: (ctx) => `저장 실패`,
    notFound: (ctx) =>
      `존재하지 않는 ${ctx.named("altProduct") || "프로덕트"}입니다.`,
    notSupported: (ctx) =>
      `노출할 수 있는 무료 ${
        ctx.named("altProduct") || "프로덕트"
      } 개수를 초과했습니다.`,
    notSupportedBasic: (ctx) =>
      `노출할 수 있는 운영 ${
        ctx.named("altProduct") || "프로덕트"
      } 개수를 초과했습니다.`,
    notSupportedTotal: (ctx) =>
      `노출할 수 있는 ${
        ctx.named("altProduct") || "프로덕트"
      } 개수를 초과했습니다.`,
    notSupportedCertMsg:
      "<p>[캠퍼스 관리 > 대시보드 > 플랜 관리 및 변경]에서 수료증</p><p>제공 설정이 포함된 요금 플랜으로 변경을 진행해주세요.</p>",
    notSupportedCertTitle: "현재 요금 플랜에서 사용하실 수 없습니다.",
    limitExceed: "현재 참여 인원보다 적은 판매 개수 제한은 설정할 수 없습니다.",
    basicProductError: (ctx) =>
      `운영 ${
        ctx.named("altProduct") || "프로덕트"
      } 개수 제한 초과 또는 최소 가격 이하입니다.`,
    freeProductError: (ctx) =>
      `무료 ${
        ctx.named("altProduct") || "프로덕트"
      } 개수 제한 초과 또는 최소 가격 이하입니다.`,
    exit: "나가기",
    exitMsg:
      "<p>저장하지 않은 내용은 모두 사라집니다.</p><p>페이지를 이동하시겠습니까?</p>",
  },
  adminApplication: {
    statusStandby: "접수전",
    statusAccept: "접수중",
    statusEnd: "접수마감",
    developFail: "신청서 만를기 실패",
    desc: "안내사항을 작성하세요.",
    applicationDelete: "신청서 삭제",
    deleteConfirmMsg: "해당 신청서를 삭제하시겠습니까?",
    activityTitle: "신청서 목록",
    pickMsg: "신청서 선정을 완료했습니다.",
    dropMsg: "신청서 미선정을 완료했습니다.",
    holdMsg: "보완요청을 완료했습니다.",
    submitMsg: "보완요청 취소를 완료했습니다.",
  },
  members: {
    addBtn: "회원 추가",
    productMemberAdd: (ctx) =>
      `${ctx.named("altProduct") || "프로덕트"} 회원 추가`,
    selected: "선택된 회원",
    unit: "명",
    addDone: "추가했습니다.",
    hName: "이름",
    hNickname: "닉네임",
    hEmail: "가입계정",
    hContact: "연락처",
    hRegDttm: "등록일시",
    productMemberNotFound: (ctx) =>
      `${
        ctx.named("altProduct") || "프로덕트"
      } 참여자 정보를 찾을 수 없습니다.`,
  },
  sort: {
    placeholder: "정렬",
    name: "이름",
    nickname: "닉네임",
    regDttm: "최신등록일",
  },
  adminContentHome: {
    create: "콘텐츠 만들기",
    searchPlaceholder: "검색어를 입력하세요.",
    contentTypeFilterPlaceholder: "콘텐츠 유형",
    sortPlaceholder: "정렬",
    sortRegDttm: "등록일순",
    sortModDttm: "수정일순",
    sortName: "이름순",
    sortSize: "용량순",
    notFound: "존재하지 않는 콘텐츠입니다.",
    hContentType: "유형",
    hContentName: "콘텐츠명",
    hRegMember: "생성자",
    hDelegatorCnt: "담당자 수",
    hSize: "용량",
    hRegDttm: "등록일",
    hModeDttm: "최종수정일",
    contentDelete: "콘텐츠 삭제",
    contentEdit: "콘텐츠 수정",
    deleteUnavailable: "<p>삭제할 수 없는 콘텐츠입니다.</p>",
    deleteDone: "콘텐츠 삭제를 완료했습니다.",
    statusUnavailable: "이미 처리된 요청입니다.",
    deleteFail: "콘텐츠 삭제 실패",
    guideText: "드라이브에 대해 알아볼까요? 여기를 클릭해주세요.",
    staffGuideText:
      "담당자로 배정되지 않은 콘텐츠일 경우, 조회가 불가능합니다.",
  },
  adminContentEdit: {
    titleDevelop: "콘텐츠 만들기",
    titleEdit: "콘텐츠 수정",
    createSection: "새로운 섹션",
    guideTitle: "도움말",
    guideText: "커리큘럼에 대해 알아볼까요? 여기를 클릭해주세요.",
    contentDelete: "콘텐츠 삭제",
    deleteConfirmMsg: "콘텐츠를 삭제하시겠습니까?",
    navContents: "콘텐츠 구성",
    developFail: "콘텐츠 만들기 실패",
    editFail: "콘텐츠 수정 실패",
    deleteFail: "콘텐츠 삭제 실패",
    notFound: "존재하지 않는 콘텐츠입니다.",
    curriculumDesc: "콘텐츠들의 조합으로 구성하는 유형입니다.",
    videoDesc: "하나의 동영상을 제공하는 유형입니다.",
    multiVideoDesc: "다수의 동영상 콘텐츠를 업로드 할 수 있습니다.",
    liveDesc: "Zoom 등의 라이브 링크를 제공하는 유형입니다.",
    pageDesc: "블로그처럼 텍스트와 이미지 조합으로 제작하는 유형입니다.",
    epubDesc: "PDF파일을 다운로드 또는 뷰어로 제공하는 유형입니다.",
    multiEpubDesc: "다수의 전자책 콘텐츠를 업로드 할 수 있습니다.",
    fileDesc: "자료모음을 다운로드할 수 있게 제공하는 유형입니다.",
    audioDesc: "음성파일을 제공하는 유형입니다.",
    multiAudioDesc: "다수의 오디오 콘텐츠를 업로드 할 수 있습니다.",
    quizDesc: "퀴즈 및 시험 유형을 제작합니다.",
    codingDesc: "코딩 실습을 제공하는 유형입니다.",
    coachingDesc: "고객과 1:1 코칭을 진행할 수 있는 예약 유형입니다.",
    exit: "나가기",
    exitMsg:
      "<p>저장하지 않은 내용은 모두 사라집니다.</p><p>페이지를 이동하시겠습니까?</p>",
    infoSection: "콘텐츠 정보",
    itemsSection: "콘텐츠 구성",
    infoSectionB: "섹션 정보",
    attachesSection: "첨부파일",
    optionsSection: "콘텐츠 옵션",
    notificationSection: "콘텐츠 알림",
    notificationInfo:
      "*시작 알림을 받는 인원이 많을수록 전송 시간이 지연될 수 있습니다. 따라서 충분한 시간을 잡는 것을 권장합니다.",
    liveStartNotification: "라이브 시작 알림 설정",
    liveStartNotificationMsg: "라이브의 시작 알림을 고객에게 보냅니다.",
    liveEndNotification: "라이브 시작 종료 설정",
    liveEndNotificationMsg: "라이브의 종료 알림을 고객에게 보냅니다.",
    name: "콘텐츠 이름",
    nameB: "섹션 이름",
    conts: "콘텐츠 내용",
    desc: "상세 내용",
    descYn: "해당 콘텐츠에 상세 내용을 추가합니다.",
    descYnWarnTitle: "상세 내용 Off",
    descYnWarnMsg:
      "상세 내용 Off 시, 작성된 내용이 삭제됩니다.<br/> 상세 내용 Off 하시겠습니까?",
    audioNotSupport:
      "오디오 플레이어 재생이 지원되지 않는 브라우저입니다. 고객센터로 문의해주세요.",
    audioFile: "오디오 첨부",
    downloadServe: "다운로드 제공",
    playerYn: "플레이어 가능",
    viewerYn: "뷰어 가능",
    downloadYn: "다운로드를 제공합니다.",
    downloadOption: "다운로드 옵션",
    downloadCnt: "다운로드 횟수",
    downloadCntMsg: "다운로드 횟수를 입력하세요.",
    downloadLimitN: "제한 없음",
    downloadLimitY: "횟수 제한",
    videoFile: "비디오 첨부",
    pdfFile: "PDF 첨부",
    fileWarnTitle: "자료 첨부 방식을 변경하시겠습니까?",
    fileWarnMsg: "변경시 현재 등록되어있는 파일 혹은 URL링크가 삭제됩니다.",
    fileTypeLabel: "콘텐츠 유형 선택",
    fileTypeFile: "자료 파일을 제공합니다.",
    fileTypeUrl: "자료를 받을 수 있는 URL링크를 공유합니다.",
    liveTypeLabel: "라이브 등록 방식 선택",
    liveTypeZoom: "Zoom 미팅",
    liveTypeYoutube: "유튜브 라이브",
    liveTypeGoogleMeet: "구글 미트",
    liveTypeEtc: "URL 직접 입력",
    zoomLoginBtn: "Zoom 계정 연동",
    urlPlaceholder: "링크를 입력해주세요.",
    liveStartDttm: "라이브 시작일시",
    liveEndDttm: "라이브 종료일시",
    liveSaveYn: "라이브 다시보기 자동 저장 여부",
    liveSaveY: "자동으로 녹화하여 드라이브에 저장합니다.",
    liveSaveN: "자동 저장을 진행하지 않습니다.",
    replayYn: "라이브 영상 다시보기 제공 여부",
    replayY: "다시보기 영상 제공함",
    replayN: "다시보기 영상 제공안함",
    zoomLoginFail: "Zoom 로그인에 실패했습니다. 다시 시도해주세요.",
    zoomLoginPopup: "팝업이 차단되어 있습니다. 차단을 해제해주세요.",
    zoomWarnTitle: "Zoom 화상수업 안내",
    zoomLoginRequired: "Zoom 계정 로그인이 필요합니다.",
    zoomLoginNotSupport:
      "안드로이드 앱에서는 Zoom 계정에 로그인하실 수 없습니다.\n웹 브라우저, PC를 이용해주세요.",
    zoomCreateFail: "화상수업 생성에 실패했습니다.",
    deleteFile: "파일 삭제",
    deleteTitle: (ctx) => `${ctx.named("type") || "콘텐츠"} 파일 삭제`,
    deleteMsg: (ctx) =>
      `삭제된 첨부파일은 복원할 수 없습니다. <br/> 등록된 ${
        ctx.named("type") || "콘텐츠"
      } 파일을 삭제하시겠습니까?`,
    downloadTitle: (ctx) => `${ctx.named("type") || "콘텐츠"} 파일 다운로드`,
    downloadMsg: (ctx) =>
      `다운로드 받는데 최대 5분 걸릴 수 있습니다. <br/> 등록된 ${
        ctx.named("type") || "콘텐츠"
      } 파일을 다운로드하시겠습니까?`,
    downloadSuccess: "다운로드 완료했습니다.",
    downloadFail: "다운로드에 실패했습니다. 다시 시도해주세요.",
  },
  campusNotice: {
    title: (ctx) => `${ctx.named("type") || "공지사항"}`,
    startDttm: "시작일",
    endDttm: "종료일",
    all: "전체",
    oneMonthPeriod: "1개월",
    threeMonthPeriod: "3개월",
    sixMonthPeriod: "6개월",
    setPeriod: "기간설정",
    emptyMsg: (ctx) =>
      `등록된 ${ctx.named("type") || "공지사항"}(이/가) 없습니다.`,
    notFound: (ctx) =>
      `존재하지 않는 ${ctx.named("type") || "공지사항"}입니다.`,
  },
  adminCampusNotice: {
    conts: "내용",
    title: "제목",
    write: "작성하기",
    more: "더보기",
    edit: "수정 완료",
    register: "등록하기",
    editTitle: (ctx) => `${ctx.named("type") || "공지사항"} 수정`,
    detailTitle: (ctx) => `${ctx.named("type") || "공지사항"} 상세`,
    writeTitle: (ctx) => `${ctx.named("type") || "공지사항"} 작성`,
    deleteTitle: (ctx) => `${ctx.named("type") || "공지사항"} 삭제`,
    loading: "데이터를 불러오는 중입니다.",
    emptyMsg: (ctx) =>
      `캠퍼스 ${ctx.named("type") || "공지사항"}을 작성해주세요.`,
    deleteMsg: (ctx) =>
      `삭제한 ${ctx.named("type") || "공지사항"}은 복구할 수 없습니다.`,
    pubScope: "공개 범위",
    pubMember: "사이트 회원에게만 공개",
    pubAll: "전체 공개 (비회원 포함)",
    titlePlaceholder: "제목을 입력해주세요",
    contsPlaceholder: "내용을 입력해주세요",
    notFound: (ctx) => `${ctx.named("type") || "공지사항"}을 찾을 수 없습니다.`,
    saveText: "저장을 완료했습니다.",
    editText: "수정을 완료했습니다.",
    deleteText: "공지사항 삭제를 완료했습니다.",
  },
  communityUser: {
    title: (ctx) => `${ctx.named("type") || "커뮤니티"}`,
    more: "더보기",
    all: "전체",
    myPost: "내 작성글",
    myReply: "내 댓글",
    register: "등록하기",
    delete: "삭제하기",
    oneMonthPeriod: "1개월",
    threeMonthPeriod: "3개월",
    sixMonthPeriod: "6개월",
    setPeriod: "기간설정",
    allRealTimePost: "실시간 전체글",
    allHotPost: "전체 인기글",
    allPost: "전체글",
    weeklyHotPost: "이번주 인기글🔥",
    notice: "새 소식",
    emptyMsg: (ctx) =>
      `등록된 ${ctx.named("type") || "커뮤니티"}(이/가) 없습니다.`,
    notFound: (ctx) =>
      `존재하지 않는 ${ctx.named("type") || "커뮤니티"}입니다.`,
    deletedCommunity: (ctx) =>
      `삭제된 ${ctx.named("type") || "커뮤니티"}입니다.`,
    connectedProduct: (ctx) => `연결된 ${ctx.named("type") || "프로덕트"} 보기`,
    management: (ctx) => `${ctx.named("type") || "커뮤니티"} 관리`,
    exit: "나가기",
    exitMsg:
      "<p>저장하지 않은 내용은 모두 사라집니다.</p><p>페이지를 이동하시겠습니까?</p>",
    post: {
      write: "게시글 작성",
      edit: "게시글 수정",
      badRequest: "제목의 길이가 100자를 초과하였습니다.",
      private: "비공개 글입니다.",
      deleted: "삭제된 게시글입니다.",
      notFound: "게시글이 존재하지 않습니다.",
      loginEmptyMsg: (ctx) =>
        `${ctx.named("type") || "커뮤니티"}에 참여 후 조회 가능합니다.`,
      notAccess: "게시글에 접근할 수 없습니다.",
      privateAccess: "관리자와 작성자외 접근할 수 없습니다.",
      deleteWarning: (ctx) =>
        `* 삭제할 시, 작성된 모든 ${
          ctx.named("type") || "커뮤니티"
        }에서 글이 삭제됩니다.`,
      deleteTitle: "해당 게시글을 삭제하시겠습니까?",
      deleteMsg: "삭제할 경우 해당 게시글은 게시글 목록에서 사라집니다.",
      deleteAll: (ctx) =>
        `여러 ${ctx.named("type") || "커뮤니티"}에 작성된 게시글인 경우, 모든 ${
          ctx.named("type") || "커뮤니티"
        }에서 글이 지워집니다.`,
    },
  },
  communityProfile: {
    post: "작성글",
    reply: "댓글",
    login: "로그인",
    joinCommunity: (ctx) => `참여중인 ${ctx.named("type") || "커뮤니티"}`,
    management: (ctx) => `${ctx.named("type") || "커뮤니티"} 관리`,
    postWrite: "게시글 작성",
    shutdownCommunity: (ctx) =>
      `운영 중단된 ${ctx.named("type") || "커뮤니티"}입니다.`,
    emptyMsg: (ctx) =>
      `${ctx.named("type") || "커뮤니티"}에 참여하고 함께 소통해보세요!`,
    emptyMemberMsg: (ctx) =>
      `참여중인 ${
        ctx.named("type") || "커뮤니티"
      }(이/가) 없으시네요!\n그룹에 참여하고 함께 소통해보세요~`,
  },
  communityWrite: {
    register: "등록하기",
    reset: "초기화",
    selectedCommunity: (ctx) => `선택된 ${ctx.named("type") || "커뮤니티"} `,
    title: "제목",
    conts: "내용",
    communityGroup: (ctx) => `${ctx.named("type") || "커뮤니티"} 선택`,
    setPrivate: "비밀글 설정",
    modAllText: (ctx) =>
      `여러 ${ctx.named("type") || "커뮤니티"}에 작성된 게시글인 경우, 모든 ${
        ctx.named("type") || "커뮤니티"
      }에서 글이 수정됩니다.`,
    editText: (ctx) =>
      `*수정 시 ${
        ctx.named("type") || "커뮤니티"
      } 만들기 / 삭제가 불가능합니다.`,
    selectCommunityText: (ctx) =>
      `게시글을 등록할 ${ctx.named("type") || "커뮤니티"}를 선택해주세요`,
    setPrivateText: "이 글을 운영자만 볼 수 있도록 합니다.",
    titlePlaceholder: "제목을 입력해주세요",
    contsPlaceholder: "내용을 입력해주세요",
    cancel: "닫기",
    confirm: "선택완료",
    community: (ctx) => `${ctx.named("type") || "커뮤니티"}`,
    selectCommunity: (ctx) => `${ctx.named("type") || "커뮤니티"} 선택`,
  },
  adminCommunity: {
    case: "건",
    unit: "명",
    all: "전체",
    open: "공개",
    close: "비공개",
    cancel: "아니오",
    shutdown: "운영 중단",
    notShutdown: "운영중",
    number: "No.",
    shutdownN: "재시작하기",
    shutdownY: "중단하기",
    joinAuto: "누구나 참여",
    joinManual: "승인후 참여",
    dupCommunity: (ctx) => `중복된 ${ctx.named("type") || "커뮤니티"}입니다.`,
    dupName: "동일한 이름의 커뮤니티가 있습니다.",
    limitExceed:
      "운영 가능한 커뮤니티 수량 제한을 초과하였습니다. 요금 플랜 업그레이드를 진행해주세요. ",
    name: "이름",
    status: "운영 상태",
    memberStatus: "참여 상태",
    openYn: "비공개 여부",
    sortAll: "전체 상태",
    sortPlaceholder: "정렬",
    shutdownNMsg:
      "운영 중단 전 상태로 돌아가며, 기존 참여자 및 게시글들을 확인할 수 있습니다.",
    shutdownYMsg: (ctx) =>
      `더 이상 회원은 해당 ${
        ctx.named("type") || "커뮤니티"
      }에 진입 및 활동을 할 수 없으며, ${
        ctx.named("type") || "커뮤니티"
      } 목록에서 노출되지 않습니다.`,
    shutdownNTitle: "운영 재시작",
    shutdownYTitle: "운영 중단",
    exit: "나가기",
    exitMsg:
      "<p>저장하지 않은 내용은 모두 사라집니다.</p><p>페이지를 이동하시겠습니까?</p>",
    home: {
      postCnt: "총 게시글수",
      membersCnt: "총 참여자수",
      regDttm: "생성일",
      joinType: "참여 방식",
      sortRegDttm: "최신등록일순",
      sortName: "이름순",
      sortMembersCnt: "총 참여자순",
      sortPostCnt: "총 게시글순",
      editCommunity: (ctx) => `${ctx.named("type") || "커뮤니티"} 수정`,
      developCommunity: (ctx) => `${ctx.named("type") || "커뮤니티"} 만들기`,
      nameError: "이름을 꼭 입력해주세요",
      emptyCommunity: (ctx) =>
        `운영중인 ${ctx.named("type") || "커뮤니티"}가 없습니다.`,
    },
    detail: {
      openPost: "공개글",
      closePost: "비공개글",
      allPost: "전체글",
      connectedProduct: (ctx) => `${ctx.named("type") || "프로덕트"} 관리`,
      connectProduct: (ctx) => `${ctx.named("type") || "프로덕트"} 연결`,
      emptyConnected: (ctx) =>
        `연결된 ${ctx.named("type") || "프로덕트"}가 없습니다`,
      disconnect: "연결 해제",
      disconnectTitle: (ctx) => `${ctx.named("type") || "프로덕트"} 연결 해제`,
      disconnectedMsg: (ctx) =>
        `${ctx.named("type") || "프로덕트"} 연결 해제를 완료했습니다.`,
      disconnectMsg: (ctx) =>
        `해제할 경우,아직 참여하지 않았거나 이후 해당 ${
          ctx.named("type") || "프로덕트"
        } 구매자는 자동으로 신청자격이 주어지지 않습니다.`,
      emptyPost: "등록된 게시글이 없습니다.",
      emptyNotice: "등록된 새 소식이 없습니다.",
      subjectError: "제목을 입력해주세요",
      contsError: "내용을 입력해주세요",
      postList: "게시글 목록",
      postDetail: "게시글 상세",
      noticeManagement: "새 소식 관리",
      noticeDetail: "새 소식 상세",
      noticeWrite: "새 소식 작성",
      noticeEdit: "새 소식 수정",
      noticeEditComplete: "새 소식 수정을 완료했습니다.",
      noticeRegisterComplete: "새 소식 등록을 완료했습니다.",
      write: "작성하기",
      cancelSelect: "선택해제",
      productAll: (ctx) => `${ctx.named("type") || "프로덕트"}`,
      emptyProduct: (ctx) =>
        `생성된 ${ctx.named("type") || "프로덕트"}가 없습니다.`,
      emptyMember: "회원이 없습니다.",
      emptyDelegator: "담당자가 없습니다.",
      purchaseProduct: (ctx) => `구매 ${ctx.named("type") || "프로덕트"}`,
      delete: "삭제하기",
      deleted: "삭제를 완료했습니다.",
      deleteTitle: (ctx) => `${ctx.named("type") || "커뮤니티"} 삭제`,
      delPopupWarning: (ctx) =>
        `* 삭제할 시, 작성된 모든 ${
          ctx.named("type") || "커뮤니티"
        }에서 글이 삭제됩니다.`,
      delPopupTitle: "새 소식 삭제",
      delPopupMsg: "삭제할 경우 해당 게시글은 게시글 목록에서 사라집니다.",
      delAllText: (ctx) =>
        `여러 ${ctx.named("type") || "커뮤니티"}에 작성된 게시글인 경우, 모든 ${
          ctx.named("type") || "커뮤니티"
        }에서 글이 지워집니다.`,
      unavailableCommunity: (ctx) =>
        `폐쇄 상태가 아닌 ${ctx.named("type") || "커뮤니티"}입니다.`,
      unavailablePost: "삭제된 게시글입니다.",
      deleteMsg: "삭제 시 모든 데이터가 사라집니다.",
      communityDashboard: (ctx) =>
        `${ctx.named("type") || "커뮤니티"} 대시보드`,
      nickname: "닉네임",
      memberReplyCnt: "작성한 댓글 수",
      memberRegDttm: "참여일시",
      memberPostCnt: "작성한 게시글 수",
      memberPurchaseProduct: (ctx) => `구매 ${ctx.named("type") || "프로덕트"}`,
      approvalMsg: "참여 신청을 승인하시겠습니까?",
      rejectMsg: "참여 신청을 거절하시겠습니까?",
      removeMsg: "회원을 내보내시겠습니까?",
      changeMsg: "참여중으로 변경하시겠습니까?",
      approvalTitle: "승인하기",
      rejectTitle: "거절하기",
      removeTitle: "내보내기",
      changeTitle: "참여중으로 변경",
      approvalToast: "승인하기를 완료했습니다.",
      rejectToast: "거절하기를 완료했습니다.",
      removeToast: "내보내기를 완료했습니다.",
      changeToast: "참여중으로 변경을 완료했습니다.",
      toList: "목록으로",
      done: "완료되었습니다.",
      communityNotFound: (ctx) =>
        `존재하지 않는 ${ctx.named("type") || "커뮤니티"}입니다.`,
      postNotFound: "게시글을 찾을 수 없습니다.",
      postBadRequest: "권한이 없는 비밀글입니다.",
      postSubjectError: "게시글의 제목이 100자를 초과했습니다.",
      memberNotFound: "회원 정보를 찾을 수 없습니다.",
      dupMember: (ctx) =>
        `이미 ${ctx.named("type") || "커뮤니티"}에 참여중인 회원입니다.`,
      selected: "선택됨",
      operation: "운영중",
      operationRestart: "운영 재시작",
      reject: "거절",
      rejectText: "거절하기",
      remove: "내보내기",
      approval: "승인",
      approvalText: "승인하기",
      changeText: "변경하기",
      changeMemberStatus: "참여중으로 변경",
      communityManagement: (ctx) => `${ctx.named("type") || "커뮤니티"} 관리`,
      navPrivatePost: "비공개 게시글",
      navPrivatePostDesc: (ctx) =>
        `${
          ctx.named("type") || "커뮤니티"
        } 관리자만 볼 수 있는 비공개 글을 확인할 수 있습니다.`,
      navPrivatePostValueTitle: "새로운 글건수",
      navProduct: (ctx) => `${ctx.named("type") || "프로덕트"} 연결`,
      navProductDesc: (ctx) =>
        `${ctx.named("type") || "커뮤니티"}에 연결된 ${
          ctx.named("type_2") || "프로덕트"
        }를 관리할 수 있습니다.`,
      navProductDone: (ctx) =>
        `${ctx.named("type") || "프로덕트"} 연결을 완료했습니다.`,
      navProductValueTitle: (ctx) =>
        `연결된 ${ctx.named("type") || "프로덕트"}수`,
      navMember: "️참여자 관리",
      navMemberDesc: (ctx) =>
        `${
          ctx.named("type") || "커뮤니티"
        }에 참여중인 유저를 관리할 수 있습니다.`,
      navMemberValueTitle: "현재 참여중인 참여자 수",
      navDashboard: "대시보드",
      navPost: "게시글 목록",
      navNotice: "새 소식 관리",
      navDelegator: "담당자 관리",
      postWrite: "게시글 작성",
      postEdit: "게시글 수정",
      postEditComplete: "게시글 수정을 완료했습니다.",
      postRegisterComplete: "게시글 등록을 완료했습니다.",
      register: "등록하기",
      modify: "수정 완료",
      requestStatus: "신청중",
      joinStatus: "참여중",
      rejectStatus: "거절됨",
      deleteStatus: "내보냄",
      cancelApplyStatus: "신청 취소",
      cancelStatus: "취소함",
      sortRegDttm: "최신등록일순",
      sortStartDttm: "최신참여일순",
      sortName: "이름순",
      sortPostDttm: "최신게시일순",
      sortReplyCnt: "댓글많은순",
      sortReadCnt: "조회수높은순",
      sortSaleStart: "판매시작순",
      sortSaleEnd: "판매마감순",
    },
  },
  error: {
    access: "접근이 제한된 페이지입니다.",
    invalid: "잘못된 요청입니다.",
    authorize: "권한이 없습니다.",
    default: "오류가 발생했습니다.",
    notFound: (ctx) =>
      `${ctx.named("type") || "콘텐츠"} 정보를 찾을 수 없습니다.`,
  },
  files: {
    title: "파일 첨부",
    label: "첨부파일 추가",
    deleteTitle: "첨부파일 삭제",
    deleteMsg: "업로드된 첨부파일을 삭제하시겠습니까?",
    switch: "첨부파일을 추가합니다.",
    msg: "해당 영역을 누르거나 파일을 드래그해서 놓아주세요.",
    empty: "등록된 파일이 없습니다.",
    offWarnTitle: "첨부파일 추가 Off",
    offWarnMsg:
      "첨부파일 추가 Off 시, 등록한 첨부파일이 모두 삭제됩니다.<br/>첨부파일 추가 Off 하시겠습니까?",
  },
  product: "프로덕트",
  contents: "콘텐츠",
  community: "커뮤니티",
  promotion: "프로모션",
  category: "카테고리",
  free: "무료",
  detail: "상세",
  edit: "수정",
  delete: "삭제",
  fail: "실패",
  add: "추가",
  success: "성공",
  done: "완료",
  cancel: "취소",
  submit: "확인",
  save: "저장",
  section: "섹션",
  create: "추가",
  reset: "초기화",
  deselect: "선택 해제",
  startDttm: "시작일시",
  endDttm: "마감일시",
  regDttm: "등록일시",
  manager: "담당자",
  searchPlaceholder: "검색어를 입력하세요",
  contentType: {
    curriculum: "커리큘럼",
    section: "섹션",
    video: "동영상",
    multiVideo: "동영상 (다중 업로드)",
    live: "라이브",
    page: "페이지",
    epub: "전자책",
    multiEpub: "전자책 (다중 업로드)",
    file: "자료",
    audio: "오디오",
    multiAudio: "오디오 (다중 업로드)",
    quiz: "퀴즈",
    coding: "코딩",
    coaching: "코칭",
  },
  dateOptions: {
    all: "전체",
    oneMonthPeriod: "1개월",
    threeMonthPeriod: "3개월",
    sixMonthPeriod: "6개월",
    setPeriod: "기간설정",
    startDttm: "시작일",
    endDttm: "종료일",
  },
  paymentStatus: {
    ALL: "상태 전체",
    PAID: "결제 완료",
    CANCEL_REQ: "환불 신청", //취소신청
    CANCEL_PART: "부분 환불", //부분취소
    CANCEL: "환불 완료", //전체취소
    CANCEL_REJECT: "환불 거부", //취소거부
    CANCELLATIONS: "환불 완료", // CANCEL, CANCEL_PART, CANCEL_REJECT
    PAY_CANCEL: "결제 취소", // PAY_REVERT, PAY_REJECT
    PAY_REQUEST: "결제 대기",
    PAY_REJECT: "결제승인거부",
    PAY_REVERT: "결제요청취소",
    ETC_REQUEST_REVERT: "기타결제 승인요청취소", // 기타결제 승인 요청 취소
    ETC_REQUEST_REJECT: "기타결제 거절", // 기타결제 거절
    ETC_REQUEST_APPROVAL: "결제승인", // 기타결제 승인
    ETC_CANCEL: "환불", // 기타결제 취소
    V_BANK_CANCEL: "환불", // 가상계좌 취소
    V_BANK_CANCEL_PART: "가상계좌 부분 환불", // 가상계좌 부분 취소
    V_BANK_CANCEL_REQ: "가상계좌 취소 요청", // 가상계좌 취소 요청
  },
  feedback: {
    title: "1:1 피드백",
    requestSubject: "1:1 피드백 요청",
    requestTitle: "요청 내용",
    requestTarget: "요청 대상",
    requestAi: "AI에게 요청 (24시간 가능)",
    requestPro: "전문가에게 요청",
    bugMsg: "코드의 오류를 수정하고 싶어요.",
    optimizeMsg: "코드를 간략하게 정리하고 싶어요.",
    etcMsg: "내용 직접 입력",
    subject: "제목",
    subjectPlaceholder: "제목을 입력해주세요.",
    placeholder: "요청 내용을 입력해주세요.",
    exitTitle: "1:1 피드백 작성을 그만두시겠습니까?",
    exitMsg: "작성한 내용은 저장되지 않습니다.",
    requestMsg: "1:1 피드백을 요청하시겠습니까?",
    AIStatus: "AI 답변 여부",
    onlyAIAnswer: "AI 답변만 보기",
    AIAnswer: "AI 답변",
    ProAnswer: "전문가 답변",
    status: "답변 상태",
    ready: "답변 대기",
    complete: "답변 완료",
  },
  delegator: {
    title: "담당자 설정",
    desc: "담당자는 해당 콘텐츠를 수정할 수 있습니다.",
    count: (ctx) => `담당자 ${ctx.named("cnt") || "0"}명`,
    selectedDelegator: "선택된 직원",
    emptyMsg: "등록된 담당자가 없습니다.",
    deleteMsg: "등록된 담당자를 삭제하시겠습니까?",
    postMsg: (ctx) =>
      `${ctx.named("cnt") || "0"}명의 담당자를 등록하시겠습니까?`,
    deleteDone: "담당자 해제를 완료했습니다.",
  },
  toastMsg: {
    delete: "삭제를 완료했습니다.",
    update: "수정을 완료했습니다.",
    save: "저장을 완료했습니다.",
    member: {
      delete: "내보내기를 완료했습니다.",
      reject: "거절하기를 완료했습니다.",
      approval: "승인하기를 완료했습니다.",
    },
  },
  exit: {
    title: "나가기",
    msg: "<p>저장하지 않은 내용은 모두 사라집니다.</p><p>페이지를 이동하시겠습니까?</p>",
    confirm: "저장하지 않고 나가기",
    cancel: "머무르기",
  },
};
