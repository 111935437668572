import { endpointV2 as endpoint } from "@/config";

// http://swagger.poincampus.com/?urls.primaryName=08.%ED%94%84%EB%A1%9C%EB%8D%95%ED%8A%B8%EA%B4%80%EB%A6%AC#/%ED%94%84%EB%A1%9C%EB%8D%95%ED%8A%B8%EA%B4%80%EB%A6%AC/post_categories

// 캠퍼스관리 > 프로덕트 > 프로덕트 목록 조회
export async function getProducts({
  campusId,
  memberId,
  keyword,
  promotionYn,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  price,
  excludeCommId,
  includeCommId,
  allYn,
  categoryId,
  productTypes,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(keyword && { keyword }),
    ...(promotionYn && { promotionYn }),
    ...(productType && { productType }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(salesStatus && { salesStatus }),
    ...(lastKey && { lastKey }),
    ...(price && { price }),
    ...(excludeCommId && { excludeCommId }),
    ...(includeCommId && { includeCommId }),
    ...(allYn && { allYn }),
    ...(categoryId && { categoryId }),
    ...(productTypes?.length > 0 && { productTypes }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 카테고리 조회
export async function getCategories({ campusId, manageYn }) {
  const params = {
    ...(campusId && { campusId }),
    ...(manageYn && { manageYn }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.products}/categories`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 단건 조회
export async function getProduct({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/${id}`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 만들기
export async function postProduct({
  image,
  period,
  startDttm,
  refundPolicyId,
  campusId,
  endDttm,
  DELETE_CONTENTS,
  communityIds,
  DESC,
  tags,
  CONTENTS,
  price,
  limit,
  joinType,
  name,
  SETTING,
  classfiedYn,
  categoryId,
  productType,
  memberId,
  modifyYn,
  custAdditional,
  OPTIONS,
}) {
  const data = {
    ...(image && { image }),
    period: period || 0,
    ...(startDttm && { startDttm }),
    ...(refundPolicyId && { refundPolicyId }),
    ...(campusId && { campusId }),
    ...(endDttm && { endDttm }),
    ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
    ...(communityIds && { communityIds }),
    ...(DESC && { DESC }),
    ...(tags != null && { tags }),
    ...(CONTENTS && { CONTENTS }),
    ...(OPTIONS && { OPTIONS }),
    ...(custAdditional && { custAdditional }),
    ...(price != null && !isNaN(price) && { price }),
    ...(limit != null && !isNaN(limit) && { limit }),
    ...(name && { name }),
    ...(SETTING && { SETTING }),
    ...(categoryId && { categoryId }),
    ...(productType && { productType }),
    ...(memberId && { memberId }),
    classfiedYn: classfiedYn || false,
    ...(productType === "BASIC" &&
      !classfiedYn &&
      joinType && {
        joinType,
      }),
    modifyYn,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 수정하기
export async function putProduct({
  image,
  period,
  startDttm,
  refundPolicyId,
  campusId,
  endDttm,
  DELETE_CONTENTS,
  DELETE_OPTIONS,
  communityIds,
  DESC,
  tags,
  limit,
  CONTENTS,
  price,
  joinType,
  name,
  SETTING,
  id,
  classfiedYn,
  categoryId,
  productType,
  memberId,
  modifyYn,
  publishYn,
  custAdditional,
  OPTIONS,
}) {
  if (!id) {
    return;
  }
  const data = {
    id,
    ...(image && { image }),
    ...(startDttm && { startDttm }),
    ...(refundPolicyId && { refundPolicyId }),
    ...(campusId && { campusId }),
    ...(endDttm && { endDttm }),
    ...(DELETE_CONTENTS && { DELETE_CONTENTS }),
    ...(DELETE_OPTIONS && { DELETE_OPTIONS }),
    ...(communityIds && { communityIds }),
    ...(DESC && { DESC }),
    ...(tags != null && { tags }),
    ...(CONTENTS && { CONTENTS }),
    ...(OPTIONS && { OPTIONS }),
    ...(custAdditional && { custAdditional }),
    ...(period != null && !isNaN(period) && { period }),
    ...(price != null && !isNaN(price) && { price }),
    ...(limit != null && !isNaN(limit) && { limit }),
    ...(name && { name }),
    ...(SETTING && { SETTING }),
    ...(categoryId && { categoryId }),
    ...(productType && { productType }),
    ...(memberId && { memberId }),
    classfiedYn: classfiedYn || false,
    ...(productType === "BASIC" &&
      !classfiedYn &&
      joinType && {
        joinType,
      }),
    modifyYn,
    ...(publishYn != null && { publishYn }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 삭제하기
export async function deleteProduct({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 카테고리 관리
export async function postCategories({ categories }) {
  const data = {
    categories,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/categories`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 상테변경
export async function putStatus({
  publishYn,
  startDttm,
  campusId,
  endDttm,
  id,
  memberId,
}) {
  if (!id) {
    return;
  }
  const data = {
    publishYn,
    id,
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/status`,
    data,
  });
}

// 메인 > 프로덕트 > 상세 조회 (멤버조회)
export async function getDetail({ campusId, memberId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    id,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/detail`,
    params,
  });
}
// 메인 > 프로덕트 > 상세 조회 (비멤버조회)
export async function getDetailPublic({ campusId, id }) {
  const params = {
    ...(campusId && { campusId }),
    id,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.products}/detail/public`,
    params,
  });
}
// 메인 > 프로덕트 > 조회 (멤버조회)
export async function getSearch({
  campusId,
  memberId,
  keyword,
  promotionYn,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  categoryIds,
  menu,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(categoryIds && { categoryIds }),
    ...(keyword && { keyword }),
    ...(lastKey && { lastKey }),
    ...(limit && { limit }),
    ...(memberId && { memberId }),
    ...(order && { order }),
    ...(productType && { productType }),
    ...(promotionYn && { promotionYn }),
    ...(salesStatus && { salesStatus }),
    ...(sort && { sort }),
    ...(menu && { menu }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/search`,
    params,
  });
}
// 메인 > 프로덕트 > 조회 (비멤버조회)
export async function getSearchPublic({
  campusId,
  keyword,
  promotionYn,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  categoryIds,
  menu,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(keyword && { keyword }),
    ...(promotionYn && { promotionYn }),
    ...(productType && { productType }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(salesStatus && { salesStatus }),
    ...(lastKey && { lastKey }),
    ...(categoryIds && { categoryIds }),
    ...(menu && { menu }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.products}/search/public`,
    params,
  });
}
//캠퍼스 관리 > 프로덕트 > 프로모션 만들기
export async function postPromotion({
  campusId,
  memberId,
  productId,
  id,
  name,
  promoLimit,
  promoPreYn,
  promoRatio,
  promoPrice,
  startDttm,
  endDttm,
  closeType,
  color,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(id && { id }),
    ...(name && { name }),
    ...(promoPreYn && { promoPreYn }),
    ...(promoRatio && { promoRatio }),
    ...(promoLimit && { promoLimit }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(closeType && { closeType }),
    ...(color && { color }),
    promoPrice: promoPrice || 0,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
// 캠퍼스 관리 > 프로모션 > 프로모션 조회
export async function getPromotions({
  campusId,
  memberId,
  productId,
  promotionStatus,
  promoPreYn,
  keyword,
  limit,
  sort,
  order,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(promotionStatus && { promotionStatus }),
    ...(promoPreYn && { promoPreYn }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/promotions`,
    params,
  });
}
// 캠퍼스 관리 > 프로모션 > 프로모션 단건 조회
export async function getPromotion({ campusId, memberId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/promotions/${id}`,
    params,
  });
}
//캠퍼스 관리 > 프로모션 관리 > 프로모션 삭제
export async function deletePromotion({ campusId, memberId, id, productId }) {
  if (!id) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
    ...(productId && { productId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
//캠퍼스 관리 > 프로모션 관리 >  프로모션 수정
export async function putPromotion({
  campusId,
  memberId,
  productId,
  id,
  name,
  promoLimit,
  promoPreYn,
  promoRatio,
  promoPrice,
  startDttm,
  endDttm,
  closeType,
  color,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(id && { id }),
    ...(name && { name }),
    ...(promoPreYn && { promoPreYn }),
    ...(promoRatio && { promoRatio }),
    ...(promoLimit && { promoLimit }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(closeType && { closeType }),
    ...(color && { color }),
    promoPrice: promoPrice || 0,
    promoLimit: promoLimit || 0,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
// 프로덕트 운영 > 연결 커뮤니티 관리
export async function putCommunities({
  productId,
  changeType, // CREATE, DELETE
  campusId,
  targets,
  memberId,
}) {
  if (!changeType || !targets || !productId) {
    return;
  }
  const data = {
    productId,
    targets,
    changeType,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/communities`,
    data,
  });
}

// 프로덕트 > 라이브 운영 > 라이브 미팅룸 만들기
export async function postLive({
  redirectUri,
  startUrl,
  productId,
  startDttm,
  service,
  campusId,
  contentId,
  endDttm,
  joinUrl,
  userId,
  memberId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(startUrl && { startUrl }),
    ...(productId && { productId }),
    ...(startDttm && { startDttm: new Date(startDttm).toJSON() }),
    ...(service && { service }),
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(endDttm && { endDttm: new Date(endDttm).toJSON() }),
    ...(joinUrl && { joinUrl }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}
// 프로덕트 > 라이브 운영 > 라이브 미팅룸 수정
export async function putLive({
  redirectUri,
  startUrl,
  productId,
  startDttm,
  service,
  campusId,
  contentId,
  endDttm,
  joinUrl,
  userId,
  memberId,
  meetingId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(startUrl && { startUrl }),
    ...(productId && { productId }),
    ...(startDttm && { startDttm: new Date(startDttm).toJSON() }),
    ...(service && { service }),
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(endDttm && { endDttm: new Date(endDttm).toJSON() }),
    ...(joinUrl && { joinUrl }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(meetingId && { meetingId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}
// 프로덕트 > 라이브 운영 > 라이브 미팅룸 삭제
export async function deleteLive({
  redirectUri,
  productId,
  service,
  campusId,
  contentId,
  userId,
  memberId,
  meetingId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(productId && { productId }),
    ...(service && { service }), // 사용하지는 않지만 LARS 요청으로 보냄
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(meetingId && { meetingId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}

export async function getOgTags({ url }) {
  return this.getMethod("get", false, {
    url: `${endpoint.products}/ogtags`,
    params: { url },
  });
}

//커뮤니티 담당자 관리  조회
export async function getProductDelegators({ memberId, campusId, id }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/delegators`,
    params,
  });
}
//커뮤니티 담당자 관리
export async function postProductDelegators({
  changeType,
  campusId,
  id,
  targets,
  memberId,
}) {
  const data = {
    ...(changeType && { changeType }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(targets && { targets }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/delegators`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 상테변경
export async function postProductClone({ id, campusId, memberId }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    campusId,
    memberId,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/clone`,
    data,
  });
}
