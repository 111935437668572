import { poinChannelConfig } from "@/config";
const keepLogin = (localStorage) => {
  if (localStorage.getKeepLogin()) {
    return localStorage.getKeepLogin();
  }

  return false;
};

const locationPath = (localStorage) => {
  if (localStorage.getLocationPath()) {
    return localStorage.getLocationPath();
  }
  return "";
};

const uaStr = window.navigator.userAgent;

// 안드로이드 플레이스토어 통해서 설치한 PWA앱인지 확인
const checkTwa = () => {
  const referrer = document.referrer;
  const host = window.location.host;

  const arr = host.split(".");
  let reversed = [];
  for (let i = arr.length - 1; i > -1; i--) {
    reversed.push(arr[i]);
  }
  const result = !!referrer?.startsWith("android-app://" + reversed.join("."));
  return result;
};

export default (localStorage, sessionStorage) => ({
  keepLogin: keepLogin(localStorage),
  locationPath: locationPath(localStorage),
  menu: {
    menus: [],
    current: "",
  },
  windowWidth: 0,
  isMobile: false,
  ua: (() => {
    const uaStrLower = uaStr.toLowerCase();
    if (uaStrLower.match(/kakaotalk/)) {
      return "kakaotalk";
    } else if (uaStrLower.match(/android/i)) {
      return "android";
    } else if (uaStrLower.match(/iphone|ipad|ipod/i)) {
      return "ios";
    } else {
      return "desktop";
    }
  })(),
  campusOnly: false,
  // webview url로 들어왔는지 여부
  isWebview: false,
  // 모바일 앱에서 웹뷰 브라우저로 띄웠는지 여부
  isWebviewBrowser: false,
  isLanding: false,
  globalComponent: "",
  loading: false,
  showGlobalMenu: true,
  isPoinWebview:
    uaStr.indexOf("poinIOS") !== -1 ||
    uaStr.indexOf("poinAOS") !== -1 ||
    checkTwa(),
  isPwa: (() => {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches
    );
  })(),
  isTwa: checkTwa(),
  isSamsungBrowser: !!uaStr.match(/SamsungBrowser/i),
  isPoinAos: uaStr.indexOf("poinAOS") !== -1,
  alert: {},
  confirm: {},
  toast: {},
  adminToast: {},
  md: false, // windowWidth === 960
  sm: false, // windowWidth === 600
  selectedOptions: [], // SelectFilter 컴포넌트에서 현재 검색 중인 옵션을 저장하는 용도로 사용
  connectedChPluginKey: poinChannelConfig.pluginKey, //채널톡 플러그인 키
  resetChannelFlag: false,
  videoPanel: false,
  videoPanelOpen: false,
  videoPanelHeight: 0,
  videoFiles: [],
});
