var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-chip border-box",class:{
    'p-chip--large': _vm.large,
    'p-chip--small': _vm.small && !_vm.filled,
    'p-chip--primary': _vm.color === 'primary' && _vm.filled,
    'p-chip--primary-line': _vm.color === 'primary' && !_vm.filled,
    'p-chip--gray': _vm.color === 'gray' && _vm.filled,
    'p-chip--gray-line': _vm.color === 'gray' && !_vm.filled,
    'p-chip--secondary': _vm.color === 'secondary' && _vm.filled,
    'p-chip--secondary-line': _vm.color === 'secondary' && !_vm.filled,
    'p-chip--error': _vm.color === 'error' && _vm.filled,
    'p-chip--error-line': _vm.color === 'error' && !_vm.filled,
    'p-chip--info': _vm.color === 'info' && _vm.filled,
    'p-chip--info-line': _vm.color === 'info' && !_vm.filled,
    'p-chip--success': _vm.color === 'success' && _vm.filled,
    'p-chip--success-line': _vm.color === 'success',
    'p-chip--disabled': _vm.disabled,
  },style:(`cursor: ${_vm.cursor}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"p-chip--content"},[_vm._t("default"),(_vm.iconData)?_vm._t("icon",function(){return [_c('img',{attrs:{"src":_vm.iconData}})]}):_vm._e(),(_vm.onClose)?_vm._t("close",function(){return [_c('v-icon',{attrs:{"color":_vm.closeIconColor},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close-circle")])]}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }