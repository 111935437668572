export default {
  setUserGroups: (state, groups) => {
    state.userGroups = groups;
  },
  clearUserGroups: (state) => {
    state.userGroups = [];
  },
  deleteUserGroup: (state, sk) => {
    const itemIdx = state.userGroups?.findIndex((group) => group.sk === sk);
    state.userGroups.splice(itemIdx, 1);
  },
  setGroup: (state, group) => {
    state.group = group;
  },
  clearGroup: (state) => {
    state.group = {};
  },
};
