<template>
  <div v-show="show" class="event-alert__container border-box">
    <div class="p-container d-flex align-center justify-center py-3">
      <router-link
        class="d-flex align-center gap-2 text-body-2-medium"
        :to="{ name: url }"
      >
        <v-icon size="20" color="white">$check-circle</v-icon>
        <span> {{ label }} </span>
      </router-link>
      <!-- <v-btn icon @click.stop="show = false"><v-icon>mdi-close</v-icon></v-btn> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationBar",
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped lang="scss">
.event-alert__container {
  box-sizing: border-box;
  background-color: $info;
  a {
    color: $white;
  }
  a:hover {
    span {
      text-decoration: underline;
    }
  }
}
</style>
