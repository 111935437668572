// DOCS: https://developer.kcp.co.kr/page/document/web
import globalStore from "@/store";
import { kcp as kcpConfig } from "@/config";
const { siteCode, batchSiteCode, retUrl, batchRetUrl, batchGroupId } =
  kcpConfig;
import { isMobile } from "./dom-utils";
import { PaymentWay } from "@/enums/membersEnum";
import Vue from "vue";

const vm = new Vue();

/**
 * kcp 결제창 호출
 * params, callback(pc버전에서만 사용)
 * 공통 파라미터
 * - kcpType: [NORMAL(일반결제), BATCH(카드 등록)]
 * - redirect_url: 모바일 결제창 완료 후 이동할 페이지
 * 일반결제 파라미터:
 * - payType: PRODUCT (판매클래스 결제시에만 보냄)
 * - good_name: 상품명
 * - good_mny: 상품 금액
 * - buyr_name: 구매자명
 * - buyr_tel2: 휴대전화번호 // TODO: tel1으로 문자 가는지 테스트.
 * - productId
 * callback 파라미터:
 * success
 * name: 에러 코드
 * message: 에러 message
 *
 */
function kcp(params, callback = () => {}) {
  const { kcpType } = params;
  switch (kcpType) {
    case "NORMAL": {
      if (isMobile()) {
        kcpMobile(params, callback);
      } else {
        kcpPc(params, callback);
      }
      break;
    }
    case "BATCH": {
      if (isMobile()) {
        kcpBatchMobile(params, callback);
      } else {
        kcpBatchPc(params, callback);
      }
    }
  }
}

/**
 * PC버전 일반결제
 * 1. iframe 일반결제창 호출
 * 2. 결제 완료 후 승인 요청 api 호출(poin-payments)
 * 3. 완료 후 callback 호출
 */
const kcpPc = async (params, callback) => {
  const {
    campusId = globalStore.getters["campuses/getCampusUuid"],
    good_name,
    good_mny,
    buyr_name,
    buyr_mail,
    buyr_tel2,
    productId,
    promotionId,
    payment_way,
    naverPointYn,
    userId = globalStore.getters["users/getUserUuid"],
    priceId,
    couponId,
  } = params;
  const payMethodFilter = (paymentWay) => {
    switch (paymentWay) {
      case PaymentWay.CARD:
        return "100000000000";
      case PaymentWay.KAKAO:
        return "100000000000";
      case PaymentWay.NAVER:
        return "100000000000";
      case PaymentWay.BANK:
        return "010000000000";
      case PaymentWay.V_BANK:
        return "001000000000";
      default:
        return "100000000000";
    }
  };
  const pcParams = {
    pay_method: payMethodFilter(payment_way),
    currency: "410",
    site_cd: siteCode,
    site_name: "PoinCampus",
    kcp_pay_title: "포인캠퍼스",
    ordr_idxx: userId + "---" + Date.now(),
    ...(payment_way === "NAVER" && {
      naverpay_direct: "Y",
      ...(naverPointYn && { naverpay_point_direct: "Y" }),
    }),
    ...(payment_way === "KAKAO" && { kakaopay_direct: "Y" }),
    ...(priceId && { priceId }),
    ...params,
  };
  // iframe 생성.
  const iframe = document.createElement("iframe");
  iframe.src = "/kcp/regular-pc.html";
  iframe.classList.add("full-screen");
  iframe.onload = () => {
    const form = iframe.contentDocument.order_info;
    injectFormData(form, pcParams);
    iframe.contentWindow.jsf__pay(form);
  };
  // iframe에서 호출할 handler 등록
  window.kcpHandler = async (key, kcpParams) => {
    const { form } = kcpParams;
    if (key === "success") {
      // 결제창에서 결제 완료 후 승인 요청.
      /**
       * enc_data, enc_info, tran_cd
       */
      const formData = new FormData(form);
      const enc_data = formData.get("enc_data");
      const enc_info = formData.get("enc_info");
      const tran_cd = formData.get("tran_cd");
      if (enc_data && enc_info && tran_cd) {
        let result;
        switch (params.payType) {
          case "PRODUCT":
            vm.$eventBus.$emit("pending", { yn: true });
            // 포인캠퍼스 API 호출: 결제 완료 결과 저장
            result = await globalStore.dispatch("payments/reqProductPayment", {
              enc_data,
              enc_info,
              ordr_idxx: pcParams.ordr_idxx,
              tran_cd,
              good_name,
              good_mny,
              productId,
              customerName: buyr_name,
              contact: buyr_tel2,
              email: buyr_mail,
              promotionId,
              paymentWay: payment_way,
              priceId,
              couponId,
            });
            vm.$eventBus.$emit("pending", { yn: false });
            iframe.remove();
            callback({
              success: result.success,
              err_code: result.name,
              err_msg: result.message,
              id: result.data?.id,
            });
            break;
        }
      } else {
        iframe.remove();
        // 결제창 완료 후 넘어온 데이터가 문제 있는 경우.
        callback({
          success: false,
          err_code: "결제 실패",
          err_msg:
            "결제 정보가 올바르지 않습니다. 고객센터 > 기술 지원으로 문의해주세요.",
        });
      }
    } else if (key === "err") {
      iframe.remove();
      callback({
        success: false,
        err_code: form.res_cd.value,
        err_msg: form.res_msg.value,
        res_cd: form.res_cd.value,
      });
    }
  };

  document.body.appendChild(iframe);
};

/**
 * PC버전 카드등록 (배치키 발급)
 * 1. iframe 카드 등록 결제창 호출
 * 2. 완료 후 등록 요청 api 호출
 * 3. callback 결과 전달.
 */
const kcpBatchPc = async (params, callback) => {
  const {
    campusId = globalStore.getters["campuses/getCampusUuid"],
    userId = globalStore.getters["users/getUserUuid"],
  } = params;
  const pcParams = {
    // default values
    site_cd: batchSiteCode,
    site_name: "PoinCampus",
    ordr_idxx: userId + "---" + Date.now(),
    kcpgroup_id: batchGroupId,
    ...params,
  };
  const iframe = document.createElement("iframe");
  iframe.src = "/kcp/batch-pc.html";
  iframe.classList.add("full-screen");
  iframe.onload = () => {
    const form = iframe.contentDocument.order_info;
    injectFormData(form, pcParams);
    iframe.contentWindow.jsf__pay(form);
  };
  // iframe에서 호출할 handler 등록
  window.kcpHandler = async (key, kcpParams) => {
    const { form } = kcpParams;
    if (key === "success") {
      // 결제창에서 결제 완료 후 승인 요청.
      /**
       * enc_data, enc_info, tran_cd
       */
      const formData = new FormData(form);
      const enc_data = formData.get("enc_data");
      const enc_info = formData.get("enc_info");
      const tran_cd = formData.get("tran_cd");
      const card_mask_no = formData.get("card_mask_no");
      if (enc_data && enc_info && tran_cd) {
        let result;
        vm.$eventBus.$emit("pending", { yn: true });
        result = await globalStore.dispatch("payments/reqBatchIssue", {
          enc_data,
          enc_info,
          tran_cd,
          card_mask_no,
        });
        vm.$eventBus.$emit("pending", { yn: false });
        iframe.remove();
        callback({
          success: result.success,
          err_code: result.name,
          err_msg: result.message,
        });
      } else {
        iframe.remove();
        // 결제창 완료 후 넘어온 데이터가 문제 있는 경우.
        callback({
          success: false,
          err_code: "카드 등록 실패",
          err_msg:
            "카드 정보가 올바르지 않습니다. 고객센터 > 기술 지원으로 문의해주세요.",
        });
      }
    } else if (key === "err") {
      iframe.remove();
      callback({
        success: false,
        err_code: form.res_cd.value,
        err_msg: form.res_msg.value,
        res_cd: form.res_cd.value,
      });
    }
  };

  document.body.appendChild(iframe);
};

/**
 * 모바일 결제
 * 1. 거래 등록 api 호출
 * 2. 거래 등록 후 받은 결제창 url 호출 (form 생성 후 post action)
 * 3. 결제 결과 Ret_URL 로 전달됨. (서버쪽 url)
 * 4. 서버에서 승인 요청 후에 클래스에 멤버 추가함.
 * 5. 결과 redirect_url로 쿼리스트링과 함께 301 redirect.
 */
const kcpMobile = async (params, callback) => {
  const {
    payment_way,
    naverPointYn,
    campusId = globalStore.getters["campuses/getCampusUuid"],
    userId = globalStore.getters["users/getUserUuid"],
  } = params;
  const orderId = userId + "---" + Date.now();
  const payMethodFilter = (paymentWay) => {
    switch (paymentWay) {
      case PaymentWay.CARD:
        return "CARD";
      case PaymentWay.KAKAO:
        return "CARD";
      case PaymentWay.NAVER:
        return "CARD";
      case PaymentWay.BANK:
        return "BANK";
      case PaymentWay.V_BANK:
        return "VCNT";
      default:
        return "CARD";
    }
  };
  const regTradeParams = {
    pay_method: payMethodFilter(payment_way),
    site_cd: siteCode,
    site_name: "PoinCampus",
    kcp_pay_title: "포인캠퍼스",
    ordr_idxx: orderId,
    escw_used: "N",
    user_agent: "",
    Ret_URL: retUrl,
    paymentWay: payment_way,
    ...params,
  };
  let result;
  switch (params.payType) {
    case "PRODUCT":
      vm.$eventBus.$emit("pending", { yn: true });
      result = await globalStore.dispatch("payments/reqRegPay", {
        ...regTradeParams,
      });
      vm.$eventBus.$emit("pending", { yn: false });
      break;
  }
  if (!result.success) {
    callback({
      success: false,
      err_code: result.name,
      err_msg: result.message,
    });
    return;
  }
  const form = document.createElement("form");
  form.method = "post";
  form.style.display = "none";
  const { PayUrl, approvalKey } = result.data;
  const {
    ordr_idxx,
    site_cd,
    good_name,
    good_mny,
    currency = "410",
    Ret_URL,
    buyr_name,
    buyr_mail,
    buyr_tel2,
    pay_method,
    redirect_url,
    escw_used,
    productId,
    promotionId,
    memberId,
    priceId,
    couponId,
  } = regTradeParams;
  const formParams = {
    site_cd,
    pay_method,
    approval_key: approvalKey,
    PayUrl,
    ordr_idxx,
    good_name,
    good_mny,
    currency,
    Ret_URL,
    ActionResult: "card",
    shop_user_id: userId,
    buyr_name,
    buyr_mail,
    buyr_tel2,
    param_opt_1: `${redirect_url}/${orderId}`,
    // 클래스에 멤버 추가하기 위한 정보들.
    param_opt_2: btoa(
      JSON.stringify({
        campusId,
        userId,
        productId,
        memberId,
        ...(promotionId && { promotionId }),
        paymentWay: payment_way,
        ...(priceId && { priceId }),
        ...(couponId && { couponId }),
      })
    ),
    req_tx: "pay",
    escw_used,
    ...(payment_way === "NAVER" && {
      naverpay_direct: "Y",
      ...(naverPointYn && { naverpay_point_direct: "Y" }),
    }),
    ...(payment_way === "KAKAO" && { kakaopay_direct: "Y" }),
    vcnt_expire_term: 1,
  };
  // utf-8 인코딩인 경우 PayUrl에 encodingFilter 주소 추가해야함.
  form.action =
    PayUrl.substring(0, PayUrl.lastIndexOf("/")) +
    "/jsp/encodingFilter/encodingFilter.jsp";
  injectFormData(form, formParams);
  document.body.appendChild(form);
  form.submit();
};

/**
 * 모바일 카드등록
 * 1. 거래 등록 api 호출
 * 2. 거래 등록 결과로 온 PayUrl로 카드등록 결제창 url POST 호출
 * 3. 결제 결과 Ret_URL 로 전달됨 (서버)
 * 4. 서버측에서 등록 요청 api 호출.
 * 5. 서버에서 redirect_url 로 301 redirect
 */
const kcpBatchMobile = async (params, callback) => {
  const { userId = globalStore.getters["users/getUserUuid"] } = params;
  const regTradeParams = {
    pay_method: "AUTH",
    site_cd: batchSiteCode,
    site_name: "PoinCampus",
    kcp_pay_title: "포인캠퍼스",
    ordr_idxx: userId + "---" + Date.now(),
    good_name: "자동결제",
    good_mny: "0",
    Ret_URL: batchRetUrl,
    escw_used: "N",
    ActionResult: "batch",
    kcp_group_id: batchGroupId,
    batch_cardno_return_yn: "Y",
    batchYn: true,
    req_tx: "pay", // 거래 구분. 필수 및 고정 값.
    ...params,
  };
  let result;
  vm.$eventBus.$emit("pending", { yn: true });
  result = await globalStore.dispatch("payments/reqRegPay", {
    ...regTradeParams,
  });
  vm.$eventBus.$emit("pending", { yn: false });
  if (!result.success) {
    callback({
      success: false,
      err_code: result.name,
      err_msg: result.message,
    });
    return;
  }
  const form = document.createElement("form");
  form.method = "post";
  form.style.display = "none";
  const { PayUrl, approvalKey } = result.data;
  const {
    ordr_idxx,
    site_cd,
    good_name,
    good_mny,
    Ret_URL,
    pay_method,
    redirect_url,
    escw_used,
    kcp_group_id,
    batch_cardno_return_yn,
    userUuid = globalStore.getters["users/getUserUuid"],
    req_tx,
    campusId = globalStore.getters["campuses/getCampusUuid"],
  } = regTradeParams;
  const formParams = {
    site_cd,
    pay_method,
    approval_key: approvalKey,
    PayUrl,
    ordr_idxx,
    good_name,
    good_mny,
    Ret_URL,
    escw_used,
    ActionResult: "batch",
    currency: "410",
    param_opt_1: redirect_url,
    // 사용자 id
    param_opt_2: userUuid,
    kcp_group_id,
    batch_cardno_return_yn,
    req_tx,
  };
  form.action = PayUrl;
  injectFormData(form, formParams);
  document.body.appendChild(form);
  form.submit();
};

/**
 * form에 value 주입.
 */
const injectFormData = (form, formParams) => {
  for (const key in formParams) {
    if (form[key]) {
      form[key].value = formParams[key];
    } else {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = formParams[key];
      form.appendChild(input);
    }
  }
};
export { kcp };
