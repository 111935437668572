import { getDateFormat } from "@/utils/date-format";
import { Authority, MemberStatus, ProgressStatus } from "@/enums/membersEnum";
import { ContentType } from "@/enums/productsEnum";
import { i18n } from "@/i18n/index";
import _ from "lodash";

export default {
  getApplication(state) {
    return state.application;
  },
  getApplicationsStaff(state) {
    return state.applicationsStaff.items;
  },
  getApplicationsStaffLastKey(state) {
    return state.applicationsStaff.lastKey;
  },
  getApplicationsUser(state) {
    return state.applicationsUser.items;
  },
  getApplicationsUserLastKey(state) {
    return state.applicationsUser.lastKey;
  },
  getApplicationDelegators(state) {
    return state.delegators;
  },
  getSectionsUser(state) {
    return state.applicationsUser.sections;
  },
  // Application Detail Getters
  applicationDetail(state, _, __, rootGetters) {
    const { application = {} } = state;
    const name = application.name;
    const productId = application.id;
    const productType = application.productType;
    const isBasic = productType === "BASIC";
    const isSales = productType === "SALES";
    const image = application.image?.items[0];
    const thumbnail = image?.path
      ? image
      : {
          key: "blank",
          path: "https://file.poincampus.com/assets/sample/product.png",
        };
    let horizontal = "";
    switch (application.image?.horizontal) {
      case "LEFT":
        horizontal = "text-left justify-start";
        break;
      case "CENTER":
        horizontal = "text-cneter justify-center";
        break;
      case "RIGHT":
        horizontal = "text-right justify-end";
        break;
      default:
        horizontal = "d-none";
    }
    const color = application.image?.textColor;
    const textColor = color ? `${color}--text` : "d-none";
    const nameYn = application.image?.nameYn;
    const conts = application.image?.conts;
    const thumbnailText = nameYn ? application.name : conts || "";
    const introduce = application.DESC?.introduce || "";
    const contents = application?.CONTENTS || [];
    const spreadContents = (() => {
      const arr = [];
      contents?.forEach((content) => {
        arr.push(content);
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            arr.push({ ...child, sub: true, indent: 1 });
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                arr.push({ ...descendant, sub: true, indent: 2 });
              });
            }
          });
        }
      });
      return arr;
    })();
    const contentsCnt =
      spreadContents?.filter((content) => {
        if (content.contentType === ContentType.CURRICULUM) {
          return false;
        }
        if (content.contentType === ContentType.SECTION) {
          return false;
        }
        return true;
      })?.length || 0;
    const managers = application.DESC?.managers?.map((manager) => {
      if (manager.memberId) {
        return manager.member;
      }
      return manager;
    });
    const managersViewYn = application.DESC?.managers?.length > 0;
    const links = application.DESC?.links;
    const linksViewYn = links?.length > 0;
    const startDttm = application.startDttm;
    const endDttm = application.endDttm;
    const joinType = application.joinType;
    const classfiedYn = application.classfiedYn;
    const productMember = rootGetters["members/getProductMember"];
    const isExpired = (() => {
      if (!productMember?.endDttm) {
        return false;
      }
      if (productMember.endDttm === new Date(99999999999999).toJSON()) {
        return false;
      }
      const left = new Date(productMember.endDttm) - new Date();
      if (left < 0) {
        return true;
      }
      return false;
    })();
    const productMemberStatus = productMember?.memberStatus;
    const isRequest = productMemberStatus === MemberStatus.REQUEST;
    const isReject = productMemberStatus === MemberStatus.REJECT;
    const isJoin = productMemberStatus === MemberStatus.JOIN && !isExpired;
    const limit = application.limit || 0;
    const salesCnt = isSales
      ? application?.STAT?.salesCnt || 0
      : isBasic
        ? application?.STAT?.joinCnt || 0
        : 0;
    const purchaseCnt = productMember?.purchaseCnt || 0;
    const soldout = limit > 0 && limit - salesCnt < 1;
    const category = application.categoryId
      ? state.categories?.find(
          (category) => category.id === state.application.categoryId
        )?.name || ""
      : "";
    const rating = application.rating || 0;
    const ratingPeople = application.ratingCnt || 0;
    const star = `${rating} (${ratingPeople})`;
    const prePromotion =
      new Date(state.application?.PROMOTION?.startDttm) < new Date() &&
      new Date() < new Date(state.application?.PROMOTION?.endDttm);
    const isStandby =
      !startDttm || startDttm === new Date(99999999999999).toJSON();
    const isNotStart = prePromotion ? false : new Date(startDttm) > new Date();
    const isEnd = new Date(endDttm) < new Date();
    const period = application.period || 0;
    const periodLabel = period
      ? i18n.tc("productDetail.period", 2, { period })
      : i18n.tc("productDetail.period");
    const periodDescription = period
      ? i18n.tc("productDetail.periodDesc", 2, { period })
      : i18n.tc("productDetail.periodDesc");
    const isProductMember = rootGetters["members/isProductMember"];
    const delegators = application.delegators || [];
    const isDelegator = delegators.includes(rootGetters["members/getMemberId"]);
    const isManager =
      rootGetters["members/hasRoleOrAuth"]({
        authority: [
          Authority.CAMPUS_SUPER_MANAGE,
          Authority.PRODUCT_SUPER_MANAGE,
        ],
      }) || isDelegator;
    const purchaseBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      // 주식분석가달수
      if (
        rootGetters["campuses/getCampusUuid"] ===
        "662a33cf-01e1-427d-994a-0cd71bf4c2ae"
      ) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const joinBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (isJoin) {
        return false;
      }
      return isEnd || isNotStart || soldout;
    })();
    const fixedBtnDisabled = (() => {
      if (isManager) {
        return false;
      }
      if (rootGetters["members/isStaff"]) {
        return true;
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return true;
      }
      if (isProductMember) {
        return false;
      }
      // 주식분석가달수
      if (
        rootGetters["campuses/getCampusUuid"] ===
        "662a33cf-01e1-427d-994a-0cd71bf4c2ae"
      ) {
        return true;
      }
      return isEnd || isNotStart || soldout;
    })();
    const btnTheme = (() => {
      if (isRequest) {
        return "gray";
      }
      if (isProductMember) {
        return "primarySub";
      }
      return "primary";
    })();
    const joinTypeText = (() => {
      return classfiedYn
        ? i18n.t("productDetail.unclassfied")
        : joinType === "AUTO"
          ? i18n.t("productDetail.joinTypeAuto")
          : joinType === "MANUAL"
            ? i18n.t("productDetail.joinTypeManual")
            : "";
    })();
    const joinBtnText = (() => {
      // 운영 프로덕트
      // 1. 프로덕트 모집 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 모집 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (isJoin) {
        return i18n.t("productDetail.toRoom");
      }
      // @lars 요청으로 November 15th, 2022 10:26 AM 추가했으나 (cdfb3a0)
      // 거절 후 다시 재신청 가능하도록 요청해서 다시 제거
      // if (isReject) {
      //   return "거절됨";
      // }
      if (isRequest) {
        return "승인 대기 중";
      }
      if (isNotStart) {
        return isStandby ? "모집 예정" : getDateFormat(startDttm);
      }
      if (isEnd) {
        return i18n.t("productDetail.basicEnd");
      }
      if (soldout) {
        return "정원 초과"; // PC-674
      }
      if (joinType === "MANUAL") {
        return i18n.t("productDetail.joinManual");
      }
      if (joinType === "AUTO") {
        return i18n.t("productDetail.joinAuto");
      }
      return "-";
    })();
    const purchaseBtnText = (() => {
      // 판매 프로덕트
      // 1. 프로덕트 판매 시작일 전 참여 불가 (Disabled)
      // 2. 프로덕트 판매 마감일 이후 참여 불가 (Disabled)
      // 3. 이미 참여한 프로덕트일 경우, room으로 이동
      // 4. joinType에 따라 참여하기(누구나 참여 가능) / 참여 신청하기(승인 후 참여 가능)
      if (rootGetters["members/isStaff"]) {
        return "관리하기";
      }
      if (
        productMember?.memberStatus === "JOIN" &&
        new Date() < new Date(productMember?.startDttm)
      ) {
        return `${getDateFormat(productMember?.startDttm)} 시작 가능`;
      }
      if (isProductMember) {
        return i18n.t("productDetail.toRoom");
      }
      if (isNotStart) {
        return isStandby ? "판매 예정" : getDateFormat(startDttm);
      }
      if (isEnd) {
        return i18n.t("productDetail.salesEnd");
      }
      if (soldout) {
        return "판매 마감"; // PC-674
      }
      return purchaseCnt > 0
        ? i18n.t("productDetail.repurchase")
        : i18n.t("productDetail.purchase");
    })();
    const tags =
      application.tags
        ?.split("#")
        ?.filter((tag) => tag)
        ?.map((tag) => "#" + tag) || [];

    return {
      name,
      productId,
      productType,
      isBasic,
      isSales,
      thumbnail,
      horizontal,
      textColor,
      nameYn,
      conts,
      thumbnailText,
      introduce,
      managers,
      managersViewYn,
      links,
      linksViewYn,
      isManager,
      startDttm,
      endDttm,
      joinType,
      classfiedYn,
      productMemberStatus,
      isExpired,
      isJoin,
      isRequest,
      isReject,
      limit,
      salesCnt,
      purchaseCnt,
      soldout,
      category,
      rating,
      star,
      isNotStart,
      isEnd,
      contentsCnt,
      period,
      periodLabel,
      periodDescription,
      isProductMember,
      fixedBtnDisabled,
      btnTheme,
      purchaseBtnDisabled,
      joinBtnDisabled,
      joinTypeText,
      joinBtnText,
      purchaseBtnText,
      tags,
      delegators,
      isDelegator,
    };
  },
};
