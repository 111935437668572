import globalStore from "@/store";
export default (apiInstance) => ({
  async reqGetGroups(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      name,
      first,
      keyword,
    } = payload;
    const result = await apiInstance.groups.getGroups({
      campusId,
      memberId,
      ...(name && { name }),
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setUserGroups", result.data);
    }
    return result;
  },
  async postGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      name,
      memberId,
      desc,
    } = payload;
    if (!name || !campusId) {
      return;
    }
    const result = await apiInstance.groups.postGroup({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(name && { name }),
      ...(desc && { desc }),
    });
    if (!result?.success) {
      let msg = "오류가 발생했습니다.";
      switch (result?.name) {
        case "INVALID_PARAMETER":
          msg = "<p>파라미터 누락 또는 오류입니다.</p>";
          break;
        case "NOT_SUPPORTED":
          msg =
            "<p>해당 기능을 지원하지 않는 요금제입니다.</p><p>요금 플랜을 업그레이드 해주세요.</p>";
          break;
        case "UNAUTHORIZE":
        case "ACCESS_DENIED":
          msg = "<p>권한이 없습니다.</p>";
          break;
        case "DUPLICATE":
          msg = "<p>이미 참여중인 계정입니다.</p>";
          break;
        case "UNAVAILABLE":
          msg = "<p>가입 가능한 회원수를 초과하였습니다.</p>";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "<p>회원 정보가 존재하지 않습니다.</p>";
          break;
        case "BAD_REQUEST":
          msg = "<p>거절 상태가 아닙니다.</p>";
          break;
      }
      store.dispatch(
        "common/setAlert",
        {
          open: true,
          msg,
        },
        { root: true }
      );
    }
    return result;
  },
  reqDeleteGroup: async (store, payload) => {
    const { sk } = payload;
    const data = {
      sk,
    };
    const result = await apiInstance.groups.deleteGroup(data);
    if (result.success) {
      store.commit("deleteUserGroup", data.sk);
    } else {
      if (result.name === "ACCESS_DENIED") {
        globalStore.dispatch("common/setAlert", {
          open: true,
          msg: "접근이 제한된 페이지입니다.",
        });
      }
    }
    return result;
  },
  async reqGetGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      name,
      sk,
    } = payload;
    // store.commit("clearUserGroups");
    // const lastKey = store.getters["getCampusMembersLastKey"];
    const result = await apiInstance.groups.getGroup({
      campusId,
      memberId,
      ...(name && { name }),
      ...(sk && { sk }),
    });
    if (result.success) {
      store.commit("setGroup", result.data);
      // store.commit("setCampusMembersLastKey", lastKey);
    }
    return result;
  },
  async putGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      sk,
      name,
      desc,
    } = payload;
    const result = await apiInstance.groups.putGroup({
      ...(sk && { sk }),
      ...(campusId && { campusId }),
      ...(name && { name }),
      ...(memberId && { memberId }),
      desc,
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async reqPostGroupMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      toSk,
      changeType,
      fromSk,
      memberIds,
      name,
    } = payload;
    const result = await apiInstance.groups.postGroupMembers({
      ...(toSk && { toSk }),
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(fromSk && { fromSk }),
      ...(name && { name }),
      ...(memberIds && { memberIds }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
});
