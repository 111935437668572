export default (apiInstance) => ({
  async reqPostProductReview(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      conts,
      rating,
      attachImages,
      productId,
      paymentId,
    } = payload;
    const result = await apiInstance.articles.postProductReview({
      campusId,
      memberId,
      ...(conts && { conts }),
      ...(rating && { rating }),
      ...(attachImages?.length > 0 && { attachImages }),
      ...(productId && { productId }),
      ...(paymentId && { paymentId }),
    });
    return result;
  },

  async reqGetProductReviewsAdmin(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      bestYn,
      hideYn,
      replyYn,
      rating,
      productId,
      limit,
      startDate,
      endDate,
      first = true,
      option = true,
    } = payload;
    if (first) {
      store.commit("clearProductReviews");
    }
    if (option) {
      store.commit("clearProductReviewsStat");
    }
    const lastKey = store.getters["getProductReviewsLastKey"];
    const result = await apiInstance.articles.getProductReviewsAdmin({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      bestYn,
      hideYn,
      ...(limit && { limit }),
      replyYn,
      ...(rating && { rating }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(lastKey && { lastKey }),
      ...(productId && { productId }),
    });
    if (result.success) {
      store.commit("setProductReviews", result.data);
    }
    return result;
  },

  async reqPutProductReviewAdmin(store, payload = {}) {
    const {
      reviewId,
      productId,
      replyAttaches,
      bestYn,
      hideYn,
      reply,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.articles.putProductReviewAdmin({
      campusId,
      memberId,
      ...(reviewId && { reviewId }),
      ...(productId && { productId }),
      ...(replyAttaches && { replyAttaches }),
      bestYn,
      hideYn,
      ...(reply && { reply }),
    });
    return result;
  },
  async reqGetProductReviews(store, payload = {}) {
    const {
      productId,
      limit,
      sort,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      order,
      first = true,
      bestYn,
      bestFirst,
      option = true,
    } = payload;
    if (first) {
      store.commit("clearProductReviews");
    }
    if (option) {
      store.commit("clearProductReviewsStat");
    }
    const lastKey = store.getters["getProductReviewsLastKey"];
    const result = await apiInstance.articles.getProductReviews({
      ...(productId && { productId }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      campusId,
      ...(order && { order }),
      ...(lastKey && { lastKey }),
      ...(bestYn && { bestYn }),
      ...(bestFirst && { bestFirst }),
    });
    if (result.success) {
      store.commit("setProductReviews", result.data);
    }
    return result;
  },
  async reqGetMemberProductReviews(store, payload = {}) {
    const {
      memberId,
      campusId,
      targetMemberId,
      first = true,
      keyword,
      limit,
      option = true,
    } = payload;
    if (first) {
      store.commit("clearProductReviews");
    }
    if (option) {
      store.commit("clearProductReviewsStat");
    }
    const lastKey = store.getters["getProductReviewsLastKey"];
    const result = await apiInstance.articles.getMemberProductReviews({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(targetMemberId && { targetMemberId }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(limit && { limit }),
    });
    if (result.success) {
      store.commit("setProductReviews", result.data);
    }
    return result;
  },
  async reqPutProductReview(store, payload = {}) {
    const {
      reviewId,
      productId,
      conts,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      rating,
      attachImages,
      memberId,
    } = payload;
    const result = await apiInstance.articles.putProductReview({
      campusId,
      memberId,
      ...(reviewId && { reviewId }),
      ...(productId && { productId }),
      ...(conts && { conts }),
      ...(rating && { rating }),
      ...(attachImages?.length > 0 && { attachImages }),
    });
    if (result.success) {
      store.commit("updateProductReview", result.data);
    }
    return result;
  },
  async reqDeleteProductReview(store, payload = {}) {
    const {
      reviewId,
      productId,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.articles.deleteProductReview({
      campusId,
      memberId,
      ...(reviewId && { reviewId }),
      ...(productId && { productId }),
    });
    return result;
  },
  async reqGetPublicProductNotices(store, payload = {}) {
    const {
      keyword,
      limit,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      productId,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearProductNotices");
    }
    const lastKey = store.getters["getProductNoticesLastKey"];
    const result = await apiInstance.articles.getPublicProductNotices({
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(campusId && { campusId }),
      ...(lastKey && { lastKey }),
      ...(productId && { productId }),
    });
    if (result.success) {
      store.commit("setProductNotices", result.data);
    }
    return result;
  },
  async reqPostProductNotice(store, payload = {}) {
    const {
      productId,
      subject,
      conts,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      attachImages,
      pubTarget,
      attaches,
    } = payload;
    const result = await apiInstance.articles.postProductNotice({
      ...(productId && { productId }),
      ...(subject && { subject }),
      ...(conts && { conts }),
      campusId,
      memberId,
      ...(attachImages && { attachImages }),
      ...(pubTarget && { pubTarget }),
      ...(attaches && { attaches }),
    });
    return result;
  },
  async reqGetProductNotices(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      limit,
      adminYn,
      endDate,
      pubTarget,
      startDate,
      productId,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearProductNotices");
    }
    const lastKey = store.getters["getProductNoticesLastKey"];
    const result = await apiInstance.articles.getProductNotices({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(adminYn && { adminYn }),
      ...(endDate && { endDate }),
      ...(pubTarget && { pubTarget }),
      ...(startDate && { startDate }),
      ...(productId && { productId }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("setProductNotices", result.data);
    }
    return result;
  },
  async reqGetProductNotice(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      adminYn,
      id,
    } = payload;
    const result = await apiInstance.articles.getProductNotice({
      campusId,
      memberId,
      ...(productId && { productId }),
      ...(adminYn && { adminYn }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setProductNotice", result.data);
    }
    return result;
  },
  async reqGetPublicProductNotice(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      productId,
      id,
    } = payload;
    const result = await apiInstance.articles.getPublicProductNotice({
      campusId,
      ...(productId && { productId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setProductNotice", result.data);
    }
    return result;
  },
  async reqPutProductNotice(store, payload = {}) {
    const {
      productId,
      subject,
      conts,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      attachImages,
      pubTarget,
      attaches,
      id,
    } = payload;
    const result = await apiInstance.articles.putProductNotice({
      ...(productId && { productId }),
      ...(subject && { subject }),
      ...(conts && { conts }),
      ...(id && { id }),
      campusId,
      memberId,
      ...(attachImages && { attachImages }),
      ...(pubTarget && { pubTarget }),
      ...(attaches && { attaches }),
    });
    return result;
  },
  async reqDeleteProductNotice(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      id,
    } = payload;
    const result = await apiInstance.articles.deleteProductNotice({
      campusId,
      memberId,
      ...(productId && { productId }),
      ...(id && { id }),
    });
    return result;
  },

  // 댓글 목록 조회
  async reqGetReplies(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      articleObjType,
      limit,
      targetRootId,
      memberId = store.rootGetters["members/getMemberId"],
      targetId,
      upperId,
      first = true,
      lastKey,
    } = payload;
    if (first) {
      if (upperId) {
        store.commit("clearUnderReplies", upperId);
      } else {
        store.commit("clearReplies");
      }
    }
    const result = await apiInstance.articles.getReplies({
      memberId,
      ...(articleObjType && { articleObjType }),
      ...(limit && { limit }),
      ...(targetRootId && { targetRootId }),
      campusId,
      ...(targetId && { targetId }),
      ...(upperId && { upperId }),
      ...(lastKey && !first && { lastKey }),
    });
    if (result.success) {
      if (upperId) {
        store.commit("setUnderReplies", {
          underReplies: result.data,
          upperId: upperId,
        });
      } else {
        store.commit("setReplies", result.data);
      }
    }
    return result;
  },
  // 캠퍼스 관리 > 댓글 > 목록 조회
  async reqGetRepliesAdmin(store, payload = {}) {
    const {
      memberId,
      limit,
      privateYn,
      targetRootId,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      first = true,
      keyword,
    } = payload;
    if (first) {
      store.commit("clearReplies");
    }
    const lastKey = store.getters["getRepliesLastKey"];
    const result = await apiInstance.articles.getRepliesAdmin({
      ...(memberId && { memberId }),
      ...(limit && { limit }),
      privateYn,
      ...(targetRootId && { targetRootId }),
      ...(campusId && { campusId }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setReplies", result.data);
    }
    return result;
  },
  // 댓글 등록
  async reqPostReply(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      mentionMember,
      articleObjType,
      targetId,
      privateYn,
      targetRootId,
      conts,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      upperId,
      attaches,
    } = payload;
    if (!articleObjType || !targetId || !targetRootId || !conts) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.articles.postReply({
      ...(mentionMember && { mentionMember }),
      ...(articleObjType && { articleObjType }),
      ...(targetId && { targetId }),
      ...(privateYn && { privateYn }),
      ...(targetRootId && { targetRootId }),
      ...(conts && { conts }),
      campusId,
      memberId,
      ...(upperId && { upperId }),
      ...(attaches && { attaches }),
    });
    if (result.success) {
      if (upperId) {
        store.commit("updateUnderReplies", result.data);
      } else {
        store.commit("updateReplies", result.data);
      }
      if (articleObjType === "CONTENT_REPLY") {
        store.commit("activities/updateReplyCnt", 1, { root: true });
      } else if (articleObjType === "POST_REPLY") {
        store.commit("communities/updateReplyCnt", 1, {
          root: true,
        });
      } else {
        store.commit("updateReplyCnt", {
          cnt: 1,
          articleObjType,
        });
      }
    }
    return result;
  },
  // 댓글 수정
  async reqPutReply(store, payload = {}) {
    const {
      id,
      articleObjType,
      targetId,
      privateYn,
      targetRootId,
      conts,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      attaches,
    } = payload;
    if (!id || !articleObjType || !targetId || !targetRootId || !conts) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.articles.putReply({
      ...(articleObjType && { articleObjType }),
      ...(targetId && { targetId }),
      ...(privateYn && { privateYn }),
      ...(targetRootId && { targetRootId }),
      ...(conts && { conts }),
      ...(id && { id }),
      campusId,
      memberId,
      ...(attaches && { attaches }),
    });
    if (result.success) {
      store.commit("updateReply", result.data);
    }
    return result;
  },
  // 댓글 삭제
  async reqDeleteReply(store, payload = {}) {
    const {
      id,
      articleObjType,
      targetId,
      targetRootId,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!id || !targetId || !targetRootId || !articleObjType) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.articles.deleteReply({
      ...(memberId && { memberId }),
      ...(targetId && { targetId }),
      ...(articleObjType && { articleObjType }),
      ...(targetRootId && { targetRootId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("removeReply", id);
      // TODO: 커뮤니티 게시글, 공지사항 게시글에서 댓글 삭제 시, 댓글 수 처리
      if (articleObjType === "CONTENT_REPLY") {
        store.commit("activities/updateReplyCnt", -1, {
          root: true,
        });
      } else if (articleObjType === "POST_REPLY") {
        store.commit("communities/updateReplyCnt", -1, {
          root: true,
        });
      } else {
        store.commit("updateReplyCnt", {
          cnt: -1,
          articleObjType,
        });
      }
    }
    return result;
  },
  async reqGetRepliesMember(store, payload = {}) {
    const {
      keyword,
      memberId = store.rootGetters["members/getMemberId"],
      articleObjType,
      limit,
      privateYn,
      targetRootId,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      targetMemberId,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearReplies");
    }
    if (!memberId || !targetMemberId) {
      return;
    }
    const lastKey = store.getters["getRepliesLastKey"];
    const result = await apiInstance.articles.getRepliesMember({
      ...(keyword && { keyword }),
      ...(memberId && { memberId }),
      ...(articleObjType && { articleObjType }),
      ...(limit && { limit }),
      privateYn,
      ...(targetRootId && { targetRootId }),
      ...(campusId && { campusId }),
      ...(targetMemberId && { targetMemberId }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("setReplies", result.data);
    }
    return result;
  },
  async reqGetRepliesPublic(store, payload = {}) {
    const {
      articleObjType,
      limit,
      targetRootId,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      targetId,
      upperId,
      lastKey,
      first = true,
    } = payload;
    if (first) {
      if (upperId) {
        store.commit("clearUnderReplies", upperId);
      } else {
        store.commit("clearReplies");
      }
    }
    const result = await apiInstance.articles.getRepliesPublic({
      ...(articleObjType && { articleObjType }),
      ...(limit && { limit }),
      ...(targetRootId && { targetRootId }),
      ...(campusId && { campusId }),
      ...(targetId && { targetId }),
      ...(upperId && { upperId }),
      ...(lastKey && !first && { lastKey }),
    });
    if (result.success) {
      if (upperId) {
        store.commit("setUnderReplies", {
          underReplies: result.data,
          upperId: upperId,
        });
      } else {
        store.commit("setReplies", result.data);
      }
    }
    return result;
  },

  // 회원 캠퍼스 공지 조회 (캠퍼스 참여 회원 or 관리자만 조회 가능)
  async reqGetCampusNotices(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      limit,
      startDate,
      endDate,
      pubTarget,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearCampusNotices");
    }
    const lastKey = store.getters["getCampusNoticesLastKey"];
    const result = await apiInstance.articles.getCampusNotices({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(pubTarget && { pubTarget }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("setCampusNotices", result.data);
    }
    return result;
  },
  // 회원 캠퍼스 공지 단건 조회
  async reqGetCampusNotice(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      adminYn,
      first = true,
    } = payload;

    if (!id) {
      return { success: false };
    }
    if (first) {
      store.commit("clearCampusNotice");
    }
    const result = await apiInstance.articles.getCampusNotice({
      ...(id && { id }),
      ...(adminYn && { adminYn }),
      campusId,
      memberId,
    });

    if (result.success) {
      store.commit("setCampusNotice", result.data);
    }
    return result;
  },
  // 비회원 캠퍼스 공지 조회
  async reqGetCampusPublicNotices(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      keyword,
      limit,
      startDate,
      endDate,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearCampusNotices");
    }
    const lastKey = store.getters["getCampusNoticesLastKey"];
    const result = await apiInstance.articles.getCampusPublicNotices({
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      campusId,
      lastKey,
    });
    if (result.success) {
      store.commit("setCampusNotices", result.data);
    }
    return result;
  },
  // 비회원 캠퍼스 공지 단건 조회
  async reqGetCampusPublicUserNotice(store, payload = {}) {
    const { campusId = store.rootGetters["campuses/getCampusUUid"], id } =
      payload;
    const result = await apiInstance.articles.getCampusPublicUserNotice({
      ...(id && { id }),
      campusId,
    });
    if (result.success) {
      store.commit("setCampusNotice", result.data);
    }
    return result;
  },
  // 캠퍼스 공지 생성
  async reqPostCampusNotice(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      pubTarget,
      subject,
      conts,
      attachImages,
      attaches,
    } = payload;
    const result = await apiInstance.articles.postCampusNotice({
      campusId,
      memberId,
      ...(pubTarget && { pubTarget }),
      ...(subject && { subject }),
      ...(conts && { conts }),
      ...(attachImages && { attachImages }),
      ...(attaches && { attaches }),
    });
    return result;
  },
  // 캠퍼스 공지 수정
  async reqPutCampusNotice(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      pubTarget,
      subject,
      conts,
      attachImages,
      attaches,
    } = payload;
    const result = await apiInstance.articles.putCampusNotice({
      id,
      pubTarget,
      subject,
      conts,
      attachImages,
      attaches,
      campusId,
      memberId,
    });
    return result;
  },
  // 캠퍼스 공지 삭제
  async reqDeleteCampusNotice(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.articles.deleteCampusNotice({
      ...(id && { id }),
      campusId,
      memberId,
    });

    if (result?.success) {
      store.commit("clearCampusNotice");
      store.commit("clearCampusNotices");
    }
    return result;
  },
  // 1:1 피드백 생성
  async reqPostFeedBack(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      questionId,
      contentId,
      productId,
      articleObjType = "PRODUCT_FEEDBACK",
      subject,
      conts,
      codeConts,
      askTarget,
      askAIType,
      oldProductName,
      oldContentName,
      oldQuestionName,
    } = payload;

    const result = await apiInstance.articles.postProductFeedBack({
      campusId,
      memberId,
      ...(contentId && { contentId }),
      ...(productId && { productId }),
      ...(questionId && { questionId }),
      ...(articleObjType && { articleObjType }),
      ...(subject && { subject }),
      ...(conts && { conts }),
      ...(codeConts && { codeConts }),
      ...(askTarget && { askTarget }),
      ...(askAIType && { askAIType }),
      ...(oldProductName && { oldProductName }),
      ...(oldContentName && { oldContentName }),
      ...(oldQuestionName && { oldQuestionName }),
    });
    return result;
  },
  // 1:1 피드백 조회
  async reqGetFeedBackList(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      targetMemberId,
      questionId,
      feedbackId,
      productId,
      contentId,
      askTarget,
      limit,
      status,
      contentOnly,
      startDate,
      endDate,
      keyword,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearFeedBackList");
    }
    const lastKey = store.getters["getFeedBackListLastKey"];
    const result = await apiInstance.articles.getProductFeedBack({
      campusId,
      memberId,
      productId,
      ...(targetMemberId && { targetMemberId }),
      ...(questionId && { questionId }),
      ...(feedbackId && { feedbackId }),
      ...(contentId && { contentId }),
      ...(askTarget && { askTarget }),
      ...(limit && { limit }),
      ...(status && { status }),
      ...(contentOnly && { contentOnly }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(keyword && { keyword }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      store.commit("setFeedBackList", result.data);
    }
    return result;
  },
  // 1:1 피드백 단건 조회
  async reqGetFeedBack(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUUid"],
      memberId = store.rootGetters["members/getMemberId"],
      targetMemberId,
      questionId,
      feedbackId,
      productId,
      contentId,
      askTarget,
      status,
      contentOnly,
      startDate,
      endDate,
      keyword,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearFeedBack");
    }
    const result = await apiInstance.articles.getProductFeedBack({
      campusId,
      memberId,
      productId,
      ...(targetMemberId && { targetMemberId }),
      ...(questionId && { questionId }),
      ...(feedbackId && { feedbackId }),
      ...(contentId && { contentId }),
      ...(askTarget && { askTarget }),
      ...(status && { status }),
      ...(contentOnly && { contentOnly }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setFeedBack", result.data);
    }
    return result;
  },
});
