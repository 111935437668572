var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-badge border-box",class:[
    {
      'p-badge--primary-line': _vm.color === 'primary',
      'p-badge--gray-line': _vm.color === 'gray',
      'p-badge--disabled': _vm.disabled,
    },
  ]},[_c('span',{staticClass:"p-badge--content"},[(_vm.icon)?_c('p-icon',{attrs:{"icon":_vm.icon,"size":"20"}}):_vm._e(),_vm._t("default"),(_vm.clear && !_vm.disabled)?_c('p-icon',{staticClass:"cursor-pointer",attrs:{"icon":"DeleteFill/Gray8","size":"20"},on:{"click":function($event){return _vm.$emit('click:clear')}}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }