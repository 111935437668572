<!-- default: gray -->
<template>
  <div
    class="p-chip border-box"
    :style="`cursor: ${cursor}`"
    :class="{
      'p-chip--large': large,
      'p-chip--small': small && !filled,
      'p-chip--primary': color === 'primary' && filled,
      'p-chip--primary-line': color === 'primary' && !filled,
      'p-chip--gray': color === 'gray' && filled,
      'p-chip--gray-line': color === 'gray' && !filled,
      'p-chip--secondary': color === 'secondary' && filled,
      'p-chip--secondary-line': color === 'secondary' && !filled,
      'p-chip--error': color === 'error' && filled,
      'p-chip--error-line': color === 'error' && !filled,
      'p-chip--info': color === 'info' && filled,
      'p-chip--info-line': color === 'info' && !filled,
      'p-chip--success': color === 'success' && filled,
      'p-chip--success-line': color === 'success',
      'p-chip--disabled': disabled,
    }"
    @click="$emit('click')"
  >
    <span class="p-chip--content">
      <slot></slot>
      <slot name="icon" v-if="iconData">
        <img :src="iconData" />
      </slot>
      <slot name="close" v-if="onClose">
        <v-icon @click="$emit('close')" :color="closeIconColor"
          >mdi-close-circle</v-icon
        >
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "PoinUiChip",
  props: {
    color: {
      type: String,
      default: "gray",
    },
    iconData: {
      type: String,
      default: "",
    },
    onClose: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: "default",
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    closeIconColor() {
      return "gray";
    },
  },
};
</script>

<style lang="scss" scoped>
.p-chip {
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: fit-content;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 10px;
  line-height: 20px;
  border: 1px solid $gray3;
  color: $gray8;
  background-color: $white;
}
.p-chip--disabled {
  pointer-events: none;
}
.p-chip--primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}
.p-chip--primary-line {
  border: 1px solid $primary;
  background-color: $white;
  color: $primary;
}
.p-chip--gray {
  border: 1px solid $gray3;
  color: $gray8;
  background-color: $white;
}
.p-chip--secondary {
  background-color: $secondary;
  border: 1px solid $secondary;
  color: $white;
}
.p-chip--secondary-line {
  border: 1px solid $secondary;
  background-color: $white;
  color: $secondary;
}
.p-chip--error {
  background-color: $error;
  border: 1px solid $error;
  color: $white;
}
.p-chip--error-line {
  border: 1px solid $error;
  background-color: $white;
  color: $error;
}
.p-chip--success {
  background-color: $success;
  border: 1px solid $success;
  color: $white;
}
.p-chip--success-line {
  border: 1px solid $success;
  background-color: $white;
  color: $success;
}
.p-chip--info-line {
  border: 1px solid $info;
  background-color: $white;
  color: $info;
}
.p-chip--content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.p-chip--large {
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 16px;
}
.p-chip--small {
  min-height: 20px;
  max-height: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 10px;
  padding: 0 8px;
  line-height: 16px;
  border-radius: 60px;
}
</style>
