<template>
  <button
    @click="onClick"
    :disabled="disabled"
    class="p-btn--link border-box"
    :class="{
      'p-btn--primary': color === 'primary',
      'p-btn--gray': color === 'gray',
      'p-btn--white': color === 'white',
      'p-btn--info': color === 'info',
      'p-btn--black': color === 'black',
      'p-btn--disabled': disabled,
    }"
  >
    <span class="p-btn__content" :class="{ loading }">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: "PoinUiLinkButton",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    onClick() {
      if (this.disabled || this.loading) {
        return;
      }
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.p-btn--link {
  // all: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  width: fit-content;
  text-decoration: underline;
  transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  font-size: 14px;
  font-weight: 500;
}

.p-btn--primary {
  color: $primary800;
  &:focus,
  &:hover {
    color: $primary;
  }
}
.p-btn--gray {
  color: $gray8;
  &:focus,
  &:hover {
    color: $black;
  }
}
.p-btn--white {
  color: $white;
  &:focus,
  &:hover {
    color: $white;
  }
}
.p-btn--info {
  color: $info;
  &:focus,
  &:hover {
    color: $info;
  }
}
.p-btn--info {
  color: $black;
  &:focus,
  &:hover {
    color: $black;
  }
}
.p-btn--disabled {
  color: $gray5;
  cursor: not-allowed;
  &:focus,
  &:hover {
    color: $gray5;
  }
}
</style>
