export default (apiInstance) => ({
  async reqGetApplicationsStaff(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      promotionYn,
      productType,
      limit = 20,
      sort,
      order,
      salesStatus,
      first = true,
      price,
      local = false,
      includeCommId,
      excludeCommId,
      allYn = false,
      categoryId,
    } = payload;
    if (!local && first) {
      store.commit("clearApplicationsStaff");
    }
    const lastKey = store.getters["getApplicationsStaffLastKey"];

    const result = await apiInstance.products.getProducts({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(promotionYn && { promotionYn }),
      ...(productType && { productType }),
      ...(limit && !allYn && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(salesStatus && { salesStatus }),
      ...(price && { price }),
      ...(lastKey && { lastKey }),
      ...(includeCommId && { includeCommId }),
      ...(excludeCommId && { excludeCommId }),
      ...(allYn && { allYn }),
      ...(categoryId && { categoryId }),
    });
    if (!local && result.success) {
      store.commit("updateApplicationsStaff", result.data);
    }
    return result;
  },
  async reqGetApplicationsUser(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      promotionYn,
      productType,
      limit = 20,
      sort,
      order,
      salesStatus,
      categoryIds,
      first = true,
      menu,
    } = payload;
    if (first) {
      store.commit("clearApplicationsUser");
    }
    const lastKey = store.getters["getApplicationsUserLastKey"];

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getSearch = isMember
      ? apiInstance.products.getSearch
      : apiInstance.products.getSearchPublic;

    const result = await getSearch({
      campusId,
      memberId,
      ...(keyword && { keyword }),
      ...(promotionYn && { promotionYn }),
      ...(productType && { productType }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(salesStatus && { salesStatus }),
      ...(lastKey && { lastKey }),
      ...(categoryIds && { categoryIds }),
      ...(menu && { menu }),
    });
    if (result?.success) {
      const SET_APPLICATIONS = first
        ? "setApplicationsUser"
        : "updateApplicationsUser";
      store.commit(SET_APPLICATIONS, result.data);
    }
    return result;
  },
  async reqGetApplication(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { id } = payload;
    if (!id) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.products.getProduct({
      memberId,
      campusId,
      id,
    });
    if (result?.success) {
      const { data } = result;
      const CONTENTS = data.CONTENTS || [];
      store.commit("setApplication", {
        ...result.data,
        CONTENTS,
      });
      return { ...result, data: { ...result.data, CONTENTS } };
    }
    return result;
  },
  async postApplication(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const name = payload.name;

    // VALIDATION START
    if (!name) {
      return {
        success: false,
        message: "기본 정보 > 신청서 이름을 입력해주세요.",
      };
    }

    const CONTENTS = [
      {
        name: "신청서",
        contentType: "APPLICATION",
        conts: "",
        items: [],
      },
    ];

    const result = await apiInstance.products.postProduct({
      image: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/product.png",
            key: "DEFAULT",
          },
        ],
      },
      startDttm: new Date(99999999999999).toJSON(),
      endDttm: new Date(99999999999999).toJSON(),
      CONTENTS,
      name,
      categoryId: "",
      productType: "APPLICATION",
      campusId,
      memberId,
      modifyYn: false,
    });
    return result;
  },
  async putApplication(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { product = {}, content } = payload;
    const {
      image,
      startDttm,
      endDttm,
      name,
      categoryId,
      id,
      modifyYn,
      publishYn,
    } = product;

    if (!id) {
      return {
        success: false,
        message: "신청서를 찾을 수 없습니다.",
      };
    }

    if (!name) {
      return {
        success: false,
        message: "기본 정보 > 신청서 이름을 입력해주세요.",
      };
    }

    if (!content) {
      return {
        success: false,
        message: "신청서 정보가 없습니다.",
      };
    }

    const CONTENTS = [payload.content];

    const result = await apiInstance.products.putProduct({
      image,
      startDttm: new Date(startDttm).toJSON(),
      endDttm: new Date(endDttm).toJSON(),
      CONTENTS,
      name,
      categoryId,
      productType: "APPLICATION",
      campusId,
      memberId,
      id,
      modifyYn,
      publishYn,
    });

    store.commit("setApplication", result.data);

    return result;
  },
  async deleteApplication(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { id } = payload;
    const result = await apiInstance.products.deleteProduct({
      memberId,
      campusId,
      id,
    });
    if (result.success) {
      store.commit("removeApplication", id);
    }
    return result;
  },
  async reqGetCategories(store, payload = {}) {
    const { campusId = store.rootGetters["campuses/getCampusUuid"] } = payload;
    const result = await apiInstance.products.getCategories({
      campusId,
    });
    if (result.success) {
      store.commit("setCategories", result.data?.categories);
    }
    return result;
  },
  async postCategories(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const { categories = [] } = payload;

    const result = await apiInstance.products.postCategories({
      campusId,
      memberId,
      categories,
    });

    if (result.success) {
      store.commit("setCategories", result.data?.categories);
    }
    return result;
  },
  async putApplicationStatus(store, payload = {}) {
    const {
      publishYn,
      startDttm,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      endDttm,
      id,
      memberId = store.rootGetters["members/getMemberId"],
      table,
    } = payload;
    const result = await apiInstance.products.putStatus({
      id,
      publishYn,
      ...(startDttm && { startDttm }),
      ...(endDttm && { endDttm }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      const UPDATE_MUTATION = table
        ? "updateApplications"
        : "updateApplication";
      store.commit(UPDATE_MUTATION, result.data);
    }
    return result;
  },
  async reqGetDetail(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!id) {
      return {
        success: false,
      };
    }

    const isMember =
      memberId && store.rootGetters["members/isCampusJoinMember"];

    const getDetail = isMember
      ? apiInstance.products.getDetail
      : apiInstance.products.getDetailPublic;
    const result = await getDetail({
      id,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    if (result?.success) {
      store.commit("setApplication", result.data);
      store.commit("members/setProductMember", result.data.productMember, {
        root: true,
      });
    }
    return result;
  },
  //담당자 관리
  async reqGetApplicationDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    // if (first) {
    //   store.commit("clearCommunityMembers");
    // }
    const result = await apiInstance.products.getProductDelegators({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setProductDelegators", result.data);
    }
    return result;
  },
  async reqPostApplicationDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      changeType,
      targets,
    } = payload;
    const result = await apiInstance.products.postProductDelegators({
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(targets && { targets }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
});
