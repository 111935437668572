const baseUrl = process.env.VUE_APP_BASE_URL;
const baseUrlV2 = process.env.VUE_APP_BASE_URL_V2;
const isCampus = process.env.VUE_APP_IS_CAMPUS === "true";
const endpoint = {
  purchase: process.env.VUE_APP_PURCHASE_ENDPOINT,
  search: process.env.VUE_APP_SEARCH_ENDPOINT,
  reviews: process.env.VUE_APP_REVIEWS_ENDPOINT,
  auth: process.env.VUE_APP_AUTH_ENDPOINT,
  users: process.env.VUE_APP_USERS_ENDPOINT,
  configure: process.env.VUE_APP_CONFIGURE_ENDPOINT,
  courses: process.env.VUE_APP_COURSES_ENDPOINT,
  supports: process.env.VUE_APP_SUPPORTS_ENDPOINT,
  notifications: process.env.VUE_APP_NOTIFICATIONS_ENDPOINT,
  payments: process.env.VUE_APP_PAYMENTS_ENDPOINT,
  reserves: process.env.VUE_APP_RESERVES_ENDPOINT,
  reservations: process.env.VUE_APP_RESERVATIONS_ENDPOINT,
  reservationCustomer: process.env.VUE_APP_RESERVATION_CUSTOMER_ENDPOINT,
  settlement: process.env.VUE_APP_RESERVATION_SETTLEMENT_ENDPOINT,
  campuses: process.env.VUE_APP_CAMPUSES_ENDPOINT,
  campusesPosts: process.env.VUE_APP_CAMPUSES_POSTS_ENDPOINT,
  campusesMember: process.env.VUE_APP_CAMPUSES_MEMBER_ENDPOINT,
  zoom: process.env.VUE_APP_ZOOM_ENDPOINT,
};

const endpointV2 = {
  campuses: process.env.VUE_APP_CAMPUSES_ENDPOINT_V2,
  members: process.env.VUE_APP_MEMBERS_ENDPOINT_V2,
  plans: process.env.VUE_APP_PLANS_ENDPOINT_V2,
  payments: process.env.VUE_APP_PAYMENTS_ENDPOINT_V2,
  admins: process.env.VUE_APP_ADMINS_ENDPOINT_V2,
  vods: process.env.VUE_APP_VODS_ENDPOINT,
  supports: process.env.VUE_APP_SUPPORTS_ENDPOINT_V2,
  notifications: process.env.VUE_APP_NOTIFICATIONS_ENDPOINT_V2,
  credits: process.env.VUE_APP_CREDITS_ENDPOINT_V2,
  products: process.env.VUE_APP_PRODUCTS_ENDPOINT,
  contents: process.env.VUE_APP_CONTENTS_ENDPOINT,
  communities: process.env.VUE_APP_COMMUNITIES_ENDPOINT,
  activities: process.env.VUE_APP_ACTIVITIES_ENDPOINT,
  articles: process.env.VUE_APP_ARTICLES_ENDPOINT,
  transcodes: process.env.VUE_APP_TRANSCODES_ENDPOINT,
  compiles: process.env.VUE_APP_COMPILES_ENDPOINT,
  groups: process.env.VUE_APP_GROUPS_ENDPOINT,
  coupons: process.env.VUE_APP_COUPONS_ENDPOINT,
};

const facebook = {
  appId: process.env.VUE_APP_FACEBOOK_APPID,
  loginProtocolScheme: process.env.VUE_APP_FACEBOOK_LOGIN_PROTOCOL_SCHEME,
  appSecret: process.env.VUE_APP_FACEBOOK_APP_SECRET,
  androidKeyHash: process.env.VUE_APP_FACEBOOK_ANDROID_KEY_HASH,
};

const google = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  clientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
};

const kakao = {
  appId: process.env.VUE_APP_KAKAO_APP_ID,
  appKey: process.env.VUE_APP_KAKAO_APP_KEY,
  clientId: process.env.VUE_KAKAO_CLIENT_ID,
};

const naver = {
  oauthUrl: process.env.VUE_APP_NAVER_OAUTH_URL,
  redirect_uri: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
  clientId: process.env.VUE_APP_NAVER_CLIENT_ID,
  clientSecret: process.env.VUE_APP_NAVER_CLIENT_SECRET,
  oauthState: process.env.VUE_APP_NAVER_OAUTH_STATE,
};

const vimeo = {
  baseUrl: process.env.VUE_APP_VIMEO_URL,
  accessToken: process.env.VUE_APP_VIMEO_TOKEN,
  deleteAccessToken: process.env.VUE_APP_VIMEO_DELETE_TOKEN,
  // clientId: process.env.VUE_APP_VIMEO_CLIENT_ID,
  // clientSecret: process.env.VUE_APP_VIMEO_CLIENT_SECRET
};

const s3 = {
  attatchmentBucketName: process.env.VUE_APP_S3_ATTACHMENT_BUCKET_NAME,
  s3AccessKeyId: process.env.VUE_APP_AWS_S3_ACCESS_KEY_ID,
  s3SecretAccessKey: process.env.VUE_APP_AWS_S3_SECRET_ACCESS_KEY,
  vodBucketName: process.env.VUE_APP_S3_VOD_BUCKET_NAME,
};

const aws = {
  identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  region: process.env.VUE_APP_AWS_REGION,
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
};

const apple = {
  clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
  redirectURI: process.env.VUE_APP_APPLE_OAUTH_REDIRECT_URI,
};

const zoom = {
  clientId: process.env.VUE_APP_ZOOM_CLIENT_ID,
};

const webBaseUrl = process.env.VUE_APP_WEB_BASE_URL;

const isDev = process.env.VUE_APP_IS_DEV === "true";

const isLocal = process.env.VUE_APP_IS_LOCAL === "true";

// Token Refresh 만료 시간 검사(초)
const authExpriedTimeCheck = 60;

const sentry = {
  dsn: process.env.VUE_APP_SENTRY_DSN,
};

const passwordSecretKey = process.env.VUE_APP_PASSWORD_SECRET_KEY;

const impCode = process.env.VUE_APP_IMP_CODE;

const oAuthRedirectUrl = process.env.VUE_APP_OAUTH_REDIRECT_URL;

const videoIframeUrl = process.env.VUE_APP_VIDEO_IFRAME_URL;

const vodJwtSecret = process.env.VUE_APP_VOD_JWT_SECRET;
const vodDistHostname = process.env.VUE_APP_VOD_DEST_HOST;

const kcp = {
  siteCode: process.env.VUE_APP_KCP_SITE_CODE,
  batchSiteCode: process.env.VUE_APP_KCP_SITE_CODE_BATCH,
  retUrl: process.env.VUE_APP_KCP_RET_URL,
  batchRetUrl: process.env.VUE_APP_KCP_BATCH_RET_URL,
  batchGroupId: process.env.VUE_APP_KCP_BATCH_GROUP_ID,
};
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const firebasePublicVapidKey = process.env.VUE_APP_PUBLIC_VAPID_KEY;

const datadog = {
  applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
};

const channel = {
  baseUrl: process.env.VUE_APP_CHANNEL_URL,
  pluginKey: process.env.VUE_APP_CHANNEL_BTN_PLUGIN_KEY, //btn
  accessSecret: process.env.VUE_APP_CHANNEL_BTN_ACCESS_SECRET, //btn
  apiAccessKey: process.env.VUE_APP_CHANNEL_ACCESS_KEY, //api
  apiAccessSecret: process.env.VUE_APP_CHANNEL_ACCESS_SECRET, //api
  memberSecretKey: process.env.VUE_APP_CHANNEL_MEMBER_SECRET_KEY, //memberScret
};

const poinChannelConfig = {
  pluginKey: process.env.VUE_APP_CHANNEL_BTN_PLUGIN_KEY,
  zIndex: 99,
  customLauncherSelector: ".channel-btn",
  mobileMessengerMode: "newTab",
  language: "ko",
};

const ncp = {
  appName: process.env.VUE_APP_NCP_APP_NAME,
  clientId: process.env.VUE_APP_NCP_CLIENT_ID,
  clientSecret: process.env.VUE_APP_NCP_CLIENT_SECRET,
};

export {
  firebasePublicVapidKey,
  firebaseConfig,
  isCampus,
  baseUrl,
  baseUrlV2,
  endpoint,
  endpointV2,
  facebook,
  google,
  kakao,
  naver,
  vimeo,
  webBaseUrl,
  authExpriedTimeCheck,
  s3,
  aws,
  apple,
  zoom,
  isDev,
  sentry,
  passwordSecretKey,
  impCode,
  oAuthRedirectUrl,
  videoIframeUrl,
  isLocal,
  vodJwtSecret,
  vodDistHostname,
  kcp,
  datadog,
  channel,
  poinChannelConfig,
  ncp,
};
