<template>
  <div
    class="color-picker cursor-pointer w-280"
    v-click-outside="onClickOutside"
  >
    <p-input
      class="color-picker__input w-full"
      :label="label"
      :value="colorHex"
      :disabled="disabled"
      :readonly="loading"
      @focus="show = !show"
      example
    >
      <template v-slot:prepend>
        <div class="ml-3" @click="show = !show">
          <p-asset v-if="colorless" name="Colorless" />
          <div
            v-else
            class="palette"
            :style="`background-color: ${paletteColor}`"
          />
        </div>
      </template>
    </p-input>

    <v-expand-transition>
      <div v-show="show" class="mt-1">
        <v-color-picker
          width="280"
          dot-size="25"
          swatches-max-height="200"
          :mode="mode"
          v-model="pickColors"
          @update:mode="onPick"
          @input="onPick"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  // https://vuetifyjs.com/en/components/color-pickers/
  name: "ColorPicker",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "hexa",
    },
    label: {
      type: String,
      default: "색상",
    },
  },
  data() {
    return {
      modes: ["hsla", "rgba", "hexa"],
      pickColors: null,
      pickColor: null,
      colorless: true,
      show: false,
    };
  },
  watch: {
    color: {
      immediate: true,
      handler(color) {
        if (color) {
          this.colorless = false;
          this.pickColor = color.toUpperCase();
        }
      },
    },
  },
  computed: {
    paletteColor() {
      return this.pickColor;
    },
    colorHex() {
      return this.colorless ? "색상 없음" : this.pickColor;
    },
  },
  methods: {
    onPick() {
      if (!this.show) {
        return;
      }
      this.colorless = false;
      this.pickColor = this.pickColors[this.mode];
      this.$emit("pick", { mode: this.mode, color: this.pickColor });
    },
    onClickOutside() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  .palette {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid $gray3;
  }
  &__input {
    ::v-deep .poin-input-group input:not(:disabled) {
      cursor: pointer;
    }
  }
}
</style>
