<template>
  <header class="header-container">
    <menu-button :callback="menuButtonCallback" />
    <poin-logo class="mx-auto" />
    <user-profile v-if="authenticated" class="user-profile" />
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserProfile from "@/components/poin-ui/UserProfile";
import PoinLogo from "@/components/layout/header/Logo";
import MenuButton from "@/components/layout/header/mobile/Menu-Button";

export default {
  name: "poin-header",
  components: {
    PoinLogo,
    MenuButton,
    UserProfile,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
    }),
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
    menuButtonCallback() {
      this.$emit("showAside");
    },
  },
};
</script>

<style scoped lang="scss">
.header-container {
  width: 100vw;
  height: var(--header-height);
  z-index: var(--header-zIndex);

  box-sizing: border-box;
  padding-left: var(--header-horizontal-padding);
  padding-right: var(--header-horizontal-padding);
  position: relative;
  background-color: $white;
  box-shadow: 0 1px 0 0 $gray3;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.user-profile {
  position: absolute;
  right: 12px;
}
</style>
