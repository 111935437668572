<template>
  <div
    class="poin-tabs hide-scrollbar"
    :style="`min-width: ${minWidth}; min-height: ${
      underlined || landing ? '48px' : small ? '36px' : '40px'
    }`"
    :class="minWidth === 'fit-content' ? 'overflow-visible' : 'overflow-x-auto'"
    ref="tabs"
  >
    <ul class="p-tabs" :class="[underlined ? 'gap-4' : 'gap-3']">
      <template v-for="tab in tabs">
        <li
          v-if="!tab.hide"
          :key="tab.value"
          @click="onClick(tab)"
          :class="[getActive(tab.value), { small }]"
        >
          <span class="p-tab--content">
            {{ tab.text }}
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PoinUiTabs",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    underlined: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    active: {
      type: [String, Number],
      default: "",
    },
    minWidth: {
      type: [String, Number],
      default: "fit-content",
    },
    landing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getActive(value) {
      if (this.active === value) {
        if (this.landing) {
          return `p-tab landing p-tab--landing`;
        }
        if (this.outlined) {
          return `p-tab p-tab--${this.color}-line`;
        }
        return this.underlined
          ? `p-tab-underlined p-tab--${this.color}-underlined active`
          : `p-tab p-tab--${this.color}`;
      }
      return this.landing
        ? "p-tab landing"
        : this.underlined
        ? "p-tab-underlined"
        : "p-tab";
    },
    onClick(tab) {
      if (tab.action) {
        tab.action(tab.value);
      }
      this.$emit("set", tab.value);
      if (this.minWidth === "fit-content") {
        return;
      }

      if (tab.value === this.tabs[this.tabs.length - 1].value) {
        this.$refs.tabs.scrollTo({ left: 10000, behavior: "smooth" });
      } else if (tab.value === this.tabs[0].value) {
        this.$refs.tabs.scrollTo({ left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.poin-tabs {
  display: flex;
  height: fit-content;
  .p-tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 100%;
    height: fit-content;
  }
}
.p-tab {
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: fit-content;
  background-color: $white;
  font-weight: 500;
  background-color: $white;
  border: 1px solid $gray3;
  color: $gray8;
  height: 40px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  @include hover-before;
  &.small {
    height: 36px;
    font-size: 12px;
  }
  &.landing {
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    color: $gray6;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0 20px;
  }
}
.p-tab-underlined {
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $gray5;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  &:hover {
    color: $gray8;
  }

  &.active {
    color: $black;
    &::before {
      content: "";
      position: absolute;
      background-color: $black;
      width: 100%;
      height: 2.5px;
      left: 0;
      bottom: 0;
    }
    &.p-tab--primary-underlined {
      color: $primary;
      &::before {
        background-color: $primary;
      }
    }
  }
}
.p-tab--primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}
.p-tab--primary-line {
  border: 1px solid $primary;
  background-color: $white;
  color: $primary;
}
.p-tab.landing.p-tab--landing {
  border: 1px solid $white;
  background-color: $white;
  color: $primary;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.p-tab--secondary {
  background-color: $secondary;
  border: 1px solid $secondary;
  color: $white;
}
.p-tab--secondary-line {
  border: 1px solid $secondary;
  background-color: $white;
  color: $secondary;
}
.p-tab--content {
  cursor: pointer;
  white-space: nowrap;
}
.overflow-visible {
  overflow: visible;
}
.overflow-x-auto {
  overflow-x: auto;
}
</style>
