import { ProgressStatus } from "@/enums/membersEnum";
import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";
import { getDateFormat } from "@/utils/date-format";
import _ from "lodash";

export default {
  getPlaylist(state) {
    return state.playlist;
  },
  getPlaylistAutoPlayYn(state) {
    return state.playlistAutoPlayYn;
  },
  getActivitiesLastKey(state) {
    return state.activities.lastKey;
  },
  getActivities(state) {
    return state.activities.items;
  },
  getRoomProduct(state) {
    return state.roomProduct;
  },
  getRoomContents(state) {
    return state.roomContents;
  },
  getRoomContent(state) {
    return state.roomContent;
  },
  getFilteredContents(state) {
    if (state.roomProduct?.SETTING?.options?.sequencialYn) {
      let wait = false;

      const dateFilter = (date, format = "yyyy.MM.dd(eee) HH:mm") => {
        return date ? getDateFormat(date, format) : "";
      };

      const alreadyComplete = (content) => {
        const group = [ProgressStatus.COMPLETE, ProgressStatus.COMPLETE_FORCE];
        if (group.includes(content?.activity?.completeStatus)) {
          return true;
        }
        return false;
      };

      const canAccess = (content) => {
        if (content?.accessDttm && new Date() < new Date(content.accessDttm)) {
          return false;
        }
        const gAcc = groupAccessDttm(content);
        if (gAcc && new Date() < new Date(gAcc)) {
          return false;
        }
        return true;
      };

      const groupAccessDttm = (item) => {
        if (item && state.roomContents?.length > 0) {
          const findFn = (content) => {
            if (content.id && item.id && content.id === item.id) {
              return true;
            }
            return false;
          };
          const contentSelf = state.roomContents?.find((content) =>
            findFn(content)
          );
          if (!contentSelf) {
            let parent = state.roomContents?.find((content) =>
              content.items?.some((content) => findFn(content))
            );
            const ancestor = state.roomContents?.find((content) => {
              const tempParent = content.items?.find((child) =>
                child.items?.some((descendant) => findFn(descendant))
              );
              if (tempParent) {
                parent = tempParent;
                return true;
              }
              return false;
            });
            return dateFilter(ancestor?.accessDttm || parent?.accessDttm);
          }
        }
        return "";
      };

      const isRealContent = (content) => {
        const group = [ContentTypeEnum.CURRICULUM, ContentTypeEnum.SECTION];
        if (group.includes(content.contentType)) {
          return false;
        }
        return true;
      };

      const findFirstContent = (content) => {
        if (!isRealContent(content)) {
          return;
        }
        if (alreadyComplete(content)) {
          return;
        }
        if (!canAccess(content)) {
          return;
        }
        wait = true;
      };

      const arr = [];
      const newContents =
        state.roomContents?.length > 0 ? _.cloneDeep(state.roomContents) : [];
      newContents?.forEach((content) => {
        const success = alreadyComplete(content) || content.previewYn;
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            const success = alreadyComplete(child) || child.previewYn;
            child.wait = success ? false : wait;
            if (!wait) {
              findFirstContent(child);
            }
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                const success =
                  alreadyComplete(descendant) || descendant.previewYn;
                descendant.wait = success ? false : wait;
                if (!wait) {
                  findFirstContent(descendant);
                }
              });
            }
          });
        }
        arr.push({ ...content, wait: success ? false : wait });
        if (!wait) {
          findFirstContent(content);
        }
      });
      return arr;
    }
    return state.roomContents || [];
  },
  getQuestions(state) {
    return state.questions;
  },
  getCurrentIndex(state) {
    return state.currentIndex;
  },
  getCurrentTab(state) {
    return state.currentTab;
  },
  getQuestion(state) {
    return state.questions?.[state.currentIndex];
  },
  getQuiz(state) {
    return state.quiz || {};
  },
  getQuizProgress(state) {
    return state.quizProgress;
  },
  getQuizStandby(state) {
    return state.quizProgress === "STANDBY";
  },
  getQuizInProgress(state) {
    return state.quizProgress === "PROGRESS";
  },
  getQuizDone(state) {
    return state.quizProgress === "DONE";
  },
  getLocalVolumes(state) {
    return state.localVolumes;
  },
  getQuizStats(state) {
    return state.quizStats;
  },
  getAside(state) {
    return state.aside;
  },
  getApplication(state) {
    return state.application || {};
  },
  getApplicationProgress(state) {
    return state.applicationProgress;
  },
  getApplicationStandby(state) {
    return state.applicationProgress === "STANDBY";
  },
  getApplicationInProgress(state) {
    return state.applicationProgress === "PROGRESS";
  },
  getApplicationDone(state) {
    return state.applicationProgress === "DONE";
  },
  getApplicationStats(state) {
    return state.applicationStats.stat;
  },
  getApplicationStatItems(state) {
    return state.applicationStats.results;
  },
  getApplicationStatUsers(state) {
    return state.applicationStatUsers.items;
  },
  getApplicationStatUsersLastKey(state) {
    return state.applicationStatUsers.lastKey;
  },
};
