import _ from "lodash";

export default {
  setCommunities(state, result) {
    state.communities.lastKey = result.lastKey;
    state.communities.items = [...state.communities.items, ...result.results];
  },
  clearCommunities(state) {
    state.communities.lastKey = "";
    state.communities.items = [];
  },
  setPosts(state, result) {
    state.posts.lastKey = result.lastKey;
    state.posts.items = [...state.posts.items, ...result.results];
  },
  clearPosts(state) {
    state.posts.lastKey = "";
    state.posts.items = [];
  },
  setMyPosts(state, result) {
    state.myPosts.lastKey = result.lastKey;
    state.myPosts.items = [...state.myPosts.items, ...result.results];
  },
  clearMyPosts(state) {
    state.myPosts.lastKey = "";
    state.myPosts.items = [];
  },
  postPost(state) {
    state.posts.items = [state.posts.items, state.post];
  },
  setPost(state, post) {
    state.post = post;
  },
  updatePost(state, post) {
    state.post = { ...state.post, ...post };
  },
  updateCommunity(state, community) {
    state.community = { ...state.community, ...community };
  },
  setCommunity(state, community) {
    state.community = community;
  },
  setCommunityDashboard(state, community) {
    state.communityDashboard = _.cloneDeep(community);
  },
  clearPost(state) {
    state.post = {
      privateYn: false,
      subject: "",
      comIds: [],
      notiYn: false,
      attachImages: [],
      attaches: [],
      conts: "",
      modAll: false,
    };
  },
  deletePost(state, postId) {
    state.posts.items.filter((post) => {
      return post.id !== postId;
    });
  },
  clearCommunity(state) {
    state.community = {
      name: "",
      joinType: "AUTO",
      openYn: true,
      products: [],
      images: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: false,
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/community.png",
            key: "DEFAULT",
          },
        ],
      },
      addMembers: [],
      thumbnailImage: {
        path: "https://file.poincampus.com/assets/sample/community-thumbnail.png",
        key: "DEFAULT",
      },
    };
  },
  updateReplyCnt(state, cnt = 0) {
    if (!state.post) {
      return;
    }
    const prevCnt = state.post.replyCnt || 0;
    const replyCnt = prevCnt + cnt;
    if (replyCnt < 0) {
      return;
    }
    state.post = { ...state.post, replyCnt };
  },
  setCommunityDelegators(state, delegators) {
    state.community.delegators = delegators?.length > 0 ? delegators : [];
  },
  clearCommunityDelegators(state) {
    state.community.delegators = [];
  },
};
