import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import ApiClient from "@/api";
import { vimeo, datadog, baseUrl, baseUrlV2, isDev, isLocal } from "@/config";
import { i18n } from "@/i18n/index";
import VueClipboard from "vue-clipboard2";
import "@/plugins/directives";
import vuetify from "@/plugins/vuetify";
import PImg from "@/components/poin-ui/Image";
import PIcon from "@/components/poin-ui/Icon";
import PAsset from "@/components/poin-ui/Asset";
import PIconBtn from "@/components/poin-ui/IconButton";
import PBtn from "@/components/poin-ui/Button";
import PBtnList from "@/components/poin-ui/ButtonList";
import PBtnMore from "@/components/poin-ui/ButtonMore";
import PLink from "@/components/poin-ui/LinkButton";
import PInput from "@/components/poin-ui/Input";
import PTextarea from "@/components/poin-ui/Textarea";
import PSelect from "@/components/poin-ui/Select";
import PSelectSort from "@/components/poin-ui/SelectSort";
import PTable from "@/components/poin-ui/Table";
import PCheckbox from "@/components/poin-ui/Checkbox";
import PRadio from "@/components/poin-ui/Radio";
import PChip from "@/components/poin-ui/Chip";
import PBadge from "@/components/poin-ui/Badge";
import PBadgeDelete from "@/components/poin-ui/BadgeDelete";
import PTabs from "@/components/poin-ui/Tabs";
import PFileInput from "@/components/poin-ui/FileInput";
import PDatePicker from "@/components/poin-ui/DatePicker";
import PDateInput from "@/components/poin-ui/DateInput";
import PSwitch from "@/components/poin-ui/Switch";
import PSpinner from "@/components/poin-ui/Spinner";
import PTip from "@/components/poin-ui/Tip";
import PSelectFilter from "@/components/poin-ui/SelectFilter";
import PStickyHeader from "@/components/poin-ui/StickyHeader";
import PColorPicker from "@/components/poin-ui/ColorPicker";
import AdminTitle from "@/components/campus/admin/AdminTitle";
import OnboardingCampus from "@/components/campus/admin/OnboardingCampus";
import OnboardingProduct from "@/components/campus/admin/OnboardingProduct";
import EmptyBox from "@/components/poin-ui/EmptyBox";
import Modal from "@/components/poin-ui/Modal";
import PLabel from "@/components/poin-ui/Label";
import PLottie from "@/components/poin-ui/Lottie";
import InfoVertical from "@/components/poin-ui/InfoVertical";
import InfoHorizon from "@/components/poin-ui/InfoHorizon";
import VueAwesomeSwiper from "vue-awesome-swiper";
import EditorViewer from "@/components/common/EditorViewer";
import "swiper/css/swiper.css";
import { datadogRum } from "@datadog/browser-rum";

Vue.config.productionTip = false;

// datadog
// https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
if (!isDev && !isLocal) {
  datadogRum.init({
    applicationId: datadog.applicationId,
    clientToken: datadog.clientToken,
    site: "datadoghq.com",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ["clickmap"],
    defaultPrivacyLevel: "allow",
    service: "poincampus",
    // allowedTracingUrls: [baseUrl, baseUrlV2],
  });
  datadogRum.startSessionReplayRecording();
}

// vimeo
const vimeoApiInstance = new ApiClient(null, { baseURL: vimeo.baseUrl }, [
  "vimeo",
]);
Object.defineProperties(Vue.prototype, {
  $vimeo: {
    get: function () {
      return vimeoApiInstance;
    },
  },
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// global event bus
const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return EventBus;
    },
  },
});
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueAwesomeSwiper);
Vue.component("PImg", PImg);
Vue.component("PBtn", PBtn);
Vue.component("PBtnList", PBtnList);
Vue.component("PBtnMore", PBtnMore);
Vue.component("PIcon", PIcon);
Vue.component("PAsset", PAsset);
Vue.component("PIconBtn", PIconBtn);
Vue.component("PInput", PInput);
Vue.component("PTextarea", PTextarea);
Vue.component("PSelect", PSelect);
Vue.component("PSelectSort", PSelectSort);
Vue.component("PTable", PTable);
Vue.component("PCheckbox", PCheckbox);
Vue.component("PRadio", PRadio);
Vue.component("PChip", PChip);
Vue.component("PBadge", PBadge);
Vue.component("PBadgeDelete", PBadgeDelete);
Vue.component("PFileInput", PFileInput);
Vue.component("PDatePicker", PDatePicker);
Vue.component("PSwitch", PSwitch);
Vue.component("PSpinner", PSpinner);
Vue.component("PTabs", PTabs);
Vue.component("PTip", PTip);
Vue.component("PLink", PLink);
Vue.component("PFilter", PSelectFilter);
Vue.component("PStickyHeader", PStickyHeader);
Vue.component("PDateInput", PDateInput);
Vue.component("EmptyBox", EmptyBox);
Vue.component("PModal", Modal);
Vue.component("PLabel", PLabel);
Vue.component("PLottie", PLottie);
Vue.component("PColorPicker", PColorPicker);
Vue.component("AdminTitle", AdminTitle);
Vue.component("EditorViewer", EditorViewer);
Vue.component("OnboardingCampus", OnboardingCampus);
Vue.component("OnboardingProduct", OnboardingProduct);
Vue.component("InfoVertical", InfoVertical);
Vue.component("InfoHorizon", InfoHorizon);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
