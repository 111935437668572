export default (apiInstance, localStorage, sessionStorage) => ({
  setKeepLogin: (store, keepLogin) => {
    store.commit("setKeepLogin", keepLogin);
    localStorage.setKeepLogin(keepLogin);
  },
  setLocationPath: (store, path) => {
    store.commit("setLocationPath", path);
    localStorage.setLocationPath(path);
  },
  setMobileMenus: (store, menus) => {
    store.commit("setMobileMenus", menus);
  },
  resetMenu: (store) => {
    store.commit("resetMenu");
  },
  setCurrentMenu: (store, menu) => {
    store.commit("setCurrentMenu", menu);
  },
  setMobileCurrentMenu: (store, menu) => {
    store.commit("setMobileCurrentMenu", menu);
  },
  setWindowWidth: async (store, width) => {
    store.commit("setWindowWidth", width);
  },
  setUa: async (store, ua) => {
    store.commit("setUa", ua);
  },
  setCampusOnly: async (store, yn) => {
    store.commit("setCampusOnly", yn);
  },
  setGlobalComponent: async (store, comp) => {
    store.commit("setGlobalComponent", comp);
  },
  setLoading: async (store, loading) => {
    store.commit("setLoading", loading);
  },
  setShowGlobalMenu: async (store, yn) => {
    store.commit("setShowGlobalMenu", yn);
  },
  setIsPoinWebview: async (store, ua) => {
    let yn = false;
    if (ua.indexOf("poinIOS") !== -1) {
      yn = true;
    } else if (ua.indexOf("poinAOS") !== -1) {
      yn = true;
      store.commit("setIsPoinAos", true);
    }
    store.commit("setIsPoinWebview", yn);
  },
  setIsPwa: async (store) => {
    // https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
    const isPwa = ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches
    );
    store.commit("setIsPwa", isPwa);
  },
  setIsSamsungBrowser: async (store, ua) => {
    store.commit("setIsSamsungBrowser", !!ua.match(/SamsungBrowser/i));
  },
  setAlert: (store, alert) => {
    store.commit("setAlert", alert);
  },
  setConfirm: (store, confirm) => {
    store.commit("setConfirm", confirm);
  },
  setToast: (store, toast) => {
    store.commit("setToast", toast);
  },
  setAdminToast: (store, adminToast) => {
    store.commit("setAdminToast", adminToast);
  },
});
