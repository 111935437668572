<template>
  <div
    id="lottie"
    class="lottie"
    :style="`width: ${size}px; height; ${size}px`"
  ></div>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "LottiePlayer",
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        lottie.destroy();
        this.$nextTick(() => {
          lottie.loadAnimation({
            container: document.getElementById("lottie"), // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: `https://file.poincampus.com/lottie/${this.value}.json`, // the path to the animation json
          });
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.lottie {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
