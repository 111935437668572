<template>
  <div class="d-flex align-center gap-3">
    <div
      class="notification-icon"
      @click.stop="clickNotification"
      v-if="!isStaff || isAdmin"
    >
      <p-icon icon="Noti/Black" />
      <span v-show="count > 0" class="count-badge">{{ countNormalized }}</span>
    </div>
    <div ref="profile">
      <div
        @click.stop="profileOpen = !profileOpen"
        class="cursor-pointer rounded-circle"
      >
        <profile-image
          :imgPath="profilePath"
          alt="profile"
          size="36px"
          size-mobile="36px"
        />
      </div>
      <profile-menu
        v-if="!isMobile"
        :show="profileOpen"
        :profile-path="profilePath"
        @close="profileOpen = false"
        key="pc-menu"
      />
      <v-dialog
        v-else
        v-model="profileOpen"
        max-width="360"
        content-class="white"
      >
        <profile-menu
          :show="profileOpen"
          :profile-path="profilePath"
          @close="profileOpen = false"
          key="mb-menu"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import ProfileImage from "@/components/common/Profile-Image";
import ProfileMenu from "@/components/poin-ui/ProfileMenu";
export default {
  name: "UserProfile",
  components: {
    ProfileImage,
    ProfileMenu,
  },
  data() {
    return {
      loading: false,
      profileOpen: false,
      // profilePath: "",
      timeoutId: () => {},
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      myCampus: "campuses/myCampus",
      isMobile: "common/isMobile",
      count: "notifications/getNewCount",
      userUuid: "users/getUserUuid",
      member: "members/getMemberItem",
      memberId: "members/getMemberId",
      campusId: "campuses/getCampusUuid",
      campusOnly: "common/campusOnly",
      isStaff: "members/isStaff",
    }),
    isAdmin() {
      return this.$route.name?.includes("campus-admin");
    },
    countNormalized() {
      if (this.count > 99) {
        return "99+";
      }
      return this.count;
    },
    profilePath() {
      if (this.campusOnly) {
        if (this.memberId) {
          return (
            this.member?.profileImage?.path ||
            "https://file.poincampus.com/assets/ProfileImg@2x.png"
          );
        }
      }
      if (this.userUuid) {
        return (
          this.user?.image?.path ||
          "https://file.poincampus.com/assets/ProfileImg@2x.png"
        );
      }
      return "https://file.poincampus.com/assets/ProfileImg@2x.png";
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler({ name }) {
        clearTimeout(this.timeoutId);
        this.profileOpen = false;
        if (!["auth-bridge"].includes(name)) {
          this.timeoutId = setTimeout(this.reqGetNotificationCnt, 500);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
      reqGetNotificationCnt: "notifications/reqGetNotificationCnt",
      reqGetUserCampusList: "campuses/reqGetUserCampusList",
      toggleContainerActive: "notifications/toggleContainerActive",
    }),
    handleClickOutside(event) {
      if (!this.$refs.profile.contains(event.target)) {
        this.profileOpen = false;
      }
    },
    clickNotification() {
      this.toggleContainerActive();
    },
    setProfilePath() {
      if (this.campusOnly) {
        if (this.memberId) {
          this.profilePath = this.member?.profileImage?.path || "";
          return;
        }
      }
      if (this.userUuid) {
        this.profilePath = this.user?.image?.path || "";
      }
    },
  },
  mounted() {
    if (!this.isMobile) {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.notification-icon {
  border-radius: 50%;
  border: 1px solid $gray4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  .count-badge {
    box-sizing: border-box;
    position: absolute;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
    font-size: 10px;
    font-weight: bold;
    background: $coral;
    color: $white;
    text-align: center;
    border-radius: 24px;
    top: -4px;
    left: 24px;
  }
  &:hover {
    background: $gray1;
    transition: all 0.3s ease-in-out;
  }
}
</style>
