var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-upload-popup"},[_c('div',{staticClass:"d-flex align-center w-full pa-4 gap-3 cursor-pointer",on:{"click":_vm.panelChange}},[(!_vm.entireYn)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","color":"primary","size":"16"}}):_vm._e(),_c('p',{staticClass:"black--text text-body-1-regular flex-fill"},[_vm._v(" "+_vm._s(_vm.entireCompletion)+" ("+_vm._s(_vm.completedCnt)+" / "+_vm._s(_vm.progressItems.length)+") ")]),_c('p-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.getVideoPanelOpen ? 'DownSm/Gray8' : 'UpSm/Gray8',"size":"24"}}),_c('p-icon',{staticClass:"cursor-pointer",attrs:{"icon":"Close/Gray8","size":"24"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.abortClick.apply(null, arguments)}}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getVideoPanelOpen),expression:"getVideoPanelOpen"}],staticClass:"w-full gray-3-border-top panel-content"},[_c('ul',{staticClass:"d-flex-column w-full py-3"},_vm._l((_vm.progressItems),function(item,index){return _c('li',{key:index,staticClass:"d-flex-column gap-2 py-3 px-4 w-full",attrs:{"value":index}},[_c('div',{staticClass:"w-full d-flex align-center"},[_c('div',{staticClass:"item-title"},[_c('p-asset',{attrs:{"name":_vm.videoIcon,"size":"20"}}),_c('p',{staticClass:"single-line"},[_vm._v(" "+_vm._s(item.contentName)+" ")]),(
                  item.status === 'contentSaved' && !item.abort && !item.err
                )?_c('p-icon',{staticClass:"ml-auto cursor-pointer",attrs:{"icon":"Check/Success","size":"20"}}):(_vm.canDeleteVideo(index) && !item.abort)?_c('p-icon',{staticClass:"ml-auto cursor-pointer",attrs:{"icon":"Close/Black","size":"20"},on:{"click":function($event){return _vm.deleteVimeo(index)}}}):_vm._e(),(item.abort && !item.err)?_c('p',{staticClass:"ml-auto text-detail-1-regular gray--text text--darken-3"},[_vm._v(" 취소됨 ")]):(item.err)?_c('p',{staticClass:"ml-auto text-detail-1-regular error--text"},[_vm._v(" 실패 ")]):_vm._e()],1)]),(
              item.vimeoStatus.status !== 'available' &&
              !item.abort &&
              !item.err
            )?_c('div',{staticClass:"w-full d-flex-column align-center gap-1"},[_c('v-progress-linear',{staticClass:"w-full",attrs:{"color":item.err ? 'error' : 'primary',"value":item.uploadStatus.progress,"background-color":"gray lighten-3","height":"6","rounded":""}}),_c('div',{staticClass:"progress"},[_c('p',{staticClass:"progress-message"},[_vm._v(" "+_vm._s(item.message)+" ")]),_c('p',{staticClass:"progress-percent"},[_vm._v(" "+_vm._s(item.uploadStatus.progress)+"% ")])])],1):_vm._e()])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }