<template>
  <v-expand-transition>
    <div
      v-if="show"
      class="menu__container border-box"
      :style="rightPositionStyle"
    >
      <div class="profile__wrap pa-5 w-full d-flex align-center">
        <profile-image
          :img-path="profilePath"
          alt="profile"
          size="60px"
          size-mobile="60px"
        />
        <div class="d-flex justify-center flex-column">
          <div class="d-flex align-center">
            <span class="black--text text-body-1-medium mw-160">{{
              nickname
            }}</span>
            <p-chip
              v-if="roleName"
              class="ml-2"
              focus
              :color="roleColor"
              small
              >{{ roleName }}</p-chip
            >
          </div>
          <div class="d-flex align-center single-line mt-1">
            <span class="text-body-2-regular gray--text text--darken-3">{{
              user.email
            }}</span>
            <sns-icon :sns-type="snsType" class="ml-2" />
          </div>
        </div>
      </div>
      <div
        v-if="isStaff && !isAdmin"
        class="w-full d-flex-column pa-5 border-t border-gray-300"
      >
        <ul class="information">
          <li>관리자 계정입니다. [사이트 관리]를 통해 설정을 진행해주세요.</li>
        </ul>
      </div>
      <nav class="navigation__wrap w-full" v-else>
        <ul class="d-flex flex-column w-full">
          <template v-for="item in navigation">
            <li
              v-if="item.show"
              :key="item.name"
              @click.stop="item.action"
              class="d-flex w-full px-5 text-body-1-medium py-4 align-center cursor-pointer"
            >
              <img
                :src="`https://file.poincampus.com/assets/nav/${item.name}.png`"
                :alt="item.name"
                class="mr-3"
              />
              <span>{{ item.label }}</span>
            </li>
          </template>
        </ul>
      </nav>
      <div class="logout__wrap">
        <v-btn
          color="gray darken-3"
          text
          class="w-full font-size-14 font-weight-regular"
          :min-height="48"
          @click.stop="logInOut"
          large
        >
          로그아웃
        </v-btn>
      </div>
    </div>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import globalMixin from "@/mixins/global";
import { textFormat } from "@/utils";
import ProfileImage from "@/components/common/Profile-Image";
import SnsIcon from "@/components/poin-ui/ProfileSnsIcon";

export default {
  name: "ProfileMenu",
  mixins: [globalMixin],
  components: {
    ProfileImage,
    SnsIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    profilePath: {
      type: String,
      default: "https://file.poincampus.com/assets/ProfileImg@2x.png",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      campusUuid: "campuses/getCampusUuid",
      campusDomain: "campuses/getCampusDomain",
      campusOnly: "common/campusOnly",
      myCampus: "campuses/myCampus",
      getWindowWidth: "common/getWindowWidth",
      snsTypes: "users/getSnsTypes",
      memberStatus: "members/getMemberStatus",
      member: "members/getMemberItem",
      isPwa: "common/isPwa",
      isStaff: "members/isStaff",
    }),
    nickname() {
      if (this.campusOnly) {
        return this.member?.nickname || this.user?.nickname || this.user.name;
      }
      return this.user?.nickname || this.user.name;
    },
    isAdmin() {
      return this.$route.name?.includes("campus-admin");
    },
    rightPositionStyle() {
      return !this.isAdmin && this.getWindowWidth > 1200
        ? { right: "calc((100vw - 1200px) / 2)" }
        : !this.isMobile
          ? { right: "20px" }
          : {};
    },
    snsType() {
      return this.snsTypes[0]?.toLowerCase() || "none";
    },
    snsTypesFiltered() {
      if (this.snsTypes && this.snsTypes.length > 0) {
        return {
          txt: textFormat.snsTypeFilter(this.snsTypes[0]),
          img: `/icon/login/${this.snsTypes[0].toLowerCase()}.png`,
        };
      }
      return { txt: "자체가입", img: "/icon/login/none.png" };
    },
    roleName() {
      if (this.memberStatus !== "JOIN") {
        return;
      }
      return this.member.role?.name;
    },
    roleCode() {
      if (this.memberStatus !== "JOIN") {
        return;
      }
      return this.member.role?.code;
    },
    roleColor() {
      switch (this.roleCode) {
        case "A":
          return "primary";
        case "B":
          return "info";
        case "C":
          return "info";
        case "D":
          return "gray";
      }
      return "info";
    },
    navigation() {
      return [
        // {
        //   name: "profile",
        //   label: "프로필 설정",
        //   show: true,
        //   action: () => {
        //     if (this.isAdmin) {
        //       this.$router.push({
        //         name: "campus-admin-profile",
        //         params: this.$route.params,
        //         query: this.$route.query,
        //       });
        //     } else if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-profile",
        //         params: this.$route.params,
        //         query: this.$route.query,
        //       });
        //     } else {
        //       this.$router.push({ name: "profile" });
        //     }
        //   },
        // },
        // {
        //   name: "campuses",
        //   label: "캠퍼스 목록",
        //   show: !this.isPwa && !this.isAdmin,
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-campuses",
        //         params: this.$route.params,
        //         query: this.$route.query,
        //       });
        //     } else {
        //       this.$router.push({ name: "campuses" });
        //     }
        //   },
        // },
        {
          name: "playlist",
          label: "참여현황",
          show: !this.isAdmin && this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-playlist",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "playlist" });
            }
          },
        },
        // {
        //   name: "cart-list",
        //   label: "찜 목록",
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-cart",
        //         ...(this.campusDomain && { campusDomain: this.campusDomain }),
        //       });
        //     } else {
        //       this.$router.push({ name: "cart" });
        //     }
        //   },
        // },
        {
          name: "reservation",
          label: "예약내역",
          show: !this.isAdmin && this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-reservation",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "reservation" });
            }
          },
        },
        {
          name: "purchases",
          label: "구매내역",
          show: !this.isAdmin && this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-purchases",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "purchases" });
            }
          },
        },
        {
          name: "feedback",
          label: "1:1 피드백",
          show: !this.isAdmin && this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-feedback",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "feedback" });
            }
          },
        },
        // {
        //   name: "asks",
        //   label: "문의내역",
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-asks",
        //         ...(this.campusDomain && { campusDomain: this.campusDomain }),
        //       });
        //     } else {
        //       this.$router.push({ name: "asks" });
        //     }
        //   },
        // },
        {
          name: "account",
          label: "계정 설정",
          show: true,
          action: () => {
            if (this.isAdmin) {
              this.$router.push({
                name: "campus-admin-account",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else if (this.campusOnly) {
              this.$router.push({
                name: "campus-account",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "account" });
            }
          },
        },
        // {
        //   name: "cards",
        //   label: "카드 관리",
        //   show: !this.isAdmin,
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-cards",
        //         params: this.$route.params,
        //         query: this.$route.query,
        //       });
        //     } else {
        //       this.$router.push({ name: "cards" });
        //     }
        //   },
        // },
      ];
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
    campusClick() {
      this.toCampusHome(this.myCampus);
    },
    campusListClick() {
      this.$router.push({ name: "poin-campus-list" });
    },
    campusNameClick(item) {
      this.toCampusHome(item);
    },
    goToPrivacy() {
      if (this.campusOnly) {
        this.$router.push({
          name: "campus-privacy",
          params: this.$route.params,
          query: this.$route.query,
        });
      } else {
        this.$router.push("/privacy");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile__wrap {
  display: grid;
  gap: 24px;
  grid-template-columns: 60px 1fr;
}
.menu__container {
  position: absolute;
  top: 64px;
  background: $white;
  border-radius: 4px;
  border: 1px solid $gray3;
  max-width: 360px;
  width: 100%;
  z-index: 12;

  .navigation__wrap,
  .logout__wrap {
    border-top: 1px solid $gray3;
    li:hover {
      background-color: #f2f2f3;
      transition: all 0.3s ease-in-out;
    }
    .emoji {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }

  @media (max-width: $mdBreakPoint) {
    position: relative;
    top: unset;
  }
}
.mw-160 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 160px;
}
</style>
