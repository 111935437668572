<template>
  <div class="table__wrapper border-box" :class="{ mini }" :style="variables">
    <table>
      <thead>
        <tr :class="{ 'pr-2': items.length > visibleCnt && scroll }">
          <template v-for="header in headers">
            <th v-if="header.value === 'checked'" :key="header.value">
              <p-checkbox v-model="check" @input="checkAll" :key="compKey" />
            </th>
            <th
              v-else
              :key="`${header.value}`"
              :class="{ fixed: header.fixed }"
            >
              <slot
                :name="`${header.value}Header`"
                v-if="$scopedSlots[`${header.value}Header`]"
              />
              <template v-else>
                {{ header.text }}
              </template>
            </th>
          </template>
        </tr>
      </thead>
      <tbody v-if="!scroll">
        <tr v-if="!items.length" class="no-data">
          <td>
            <v-progress-circular
              v-if="loading"
              class="mr-2"
              indeterminate
              color="black"
              :size="16"
              width="2"
            />{{ loading ? "데이터를 불러오는 중입니다." : emptyMessage }}
          </td>
        </tr>
        <template v-else>
          <tr
            v-for="(item, index) in items"
            :key="index"
            @click="onRowClick(item)"
            :class="{ checked: item.checked, disabled: item.disabledRow }"
          >
            <td v-for="key in noFixedKeys" :key="key">
              <slot
                :name="key"
                :item="item"
                v-if="$scopedSlots[key]"
              /><template v-else>{{ item[key] }}</template>
            </td>
            <td v-for="key in fixedKeys" :key="key" class="fixed">
              <slot
                :name="key"
                :item="item"
                v-if="$scopedSlots[key]"
              /><template v-else>{{ item[key] }}</template>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <div
          :style="`height: ${52 * visibleCnt + 1}px; overflow: auto`"
          class="p-scroll"
        >
          <tr v-if="!items.length" class="no-data">
            <td>
              <v-progress-circular
                v-if="loading"
                class="mr-2"
                indeterminate
                color="black"
                :size="16"
                width="2"
              />{{ loading ? "데이터를 불러오는 중입니다." : emptyMessage }}
            </td>
          </tr>
          <template v-else>
            <tr
              v-for="(item, index) in items"
              :key="index"
              @click="onRowClick(item)"
              :class="{ checked: item.checked, disabled: item.disabledRow }"
            >
              <td v-for="key in noFixedKeys" :key="key">
                <slot
                  :name="key"
                  :item="item"
                  v-if="$scopedSlots[key]"
                /><template v-else>{{ item[key] }}</template>
              </td>
              <td v-for="key in fixedKeys" :key="key" class="fixed">
                <slot
                  :name="key"
                  :item="item"
                  v-if="$scopedSlots[key]"
                /><template v-else>{{ item[key] }}</template>
              </td>
            </tr>
          </template>
        </div>
      </tbody>
    </table>
  </div>
</template>

<script>
import { emptyTableMsg } from "@/utils/text-format";
import { v4 as uuid } from "uuid";
export default {
  name: "PoinUiTable",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    gridTemplateColumns: {
      type: String,
      default: "",
    },
    emptyMsg: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "",
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    visibleCnt: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      check: false,
      compKey: uuid(),
    };
  },
  computed: {
    variables() {
      const basic = this.keys.includes("checked")
        ? `48px repeat(${this.keys?.length - 1 || 0}, 1fr)`
        : `repeat(${this.keys?.length || 0}, 1fr)`;
      return {
        "--grid-template-columns":
          this.items?.length > 0
            ? this.gridTemplateColumns ||
              this.headers.map((item) => item.width).join(" ") ||
              basic
            : basic,
        ...(this.minHeight && { "min-height": this.minHeight }),
      };
    },
    keys() {
      return this.headers?.map((header) => header.value);
    },
    noFixedKeys() {
      return this.headers
        ?.filter((header) => !header.fixed)
        ?.map((header) => header.value);
    },
    fixedKeys() {
      return this.headers
        ?.filter((header) => header.fixed)
        ?.map((header) => header.value);
    },
    emptyMessage() {
      if (this.emptyMsg) {
        return this.emptyMsg;
      }

      const routeName = this.$route.name;
      const newStr = routeName.replace("campus-admin-", "");

      return emptyTableMsg(newStr);
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(items) {
        if (items?.length > 0) {
          const allChecked = items.every(
            (item) => item.checked || item.disabled
          );
          const allDisabled = items.every((item) => item.disabled);
          if (!allDisabled && allChecked) {
            this.check = true;
            return;
          }
        }
        this.check = false;
        this.compKey = uuid();
      },
    },
  },
  methods: {
    onRowClick(item) {
      this.$emit("click:row", item);
    },
    checkAll(val) {
      this.$emit("checkAll", val);
    },
  },
};
</script>

<style lang="scss" scoped>
$grid-template-columns: var(--grid-template-columns);

.table__wrapper {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  @include scrollbar($white);
  scrollbar-gutter: auto;
  table {
    width: 100%;
    thead {
      vertical-align: middle;
      background-color: $gray1;
      th {
        background-color: $gray1;
        color: $gray8;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        vertical-align: middle;
        padding: 0 12px;
        height: 52px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    tbody {
      tr {
        border-top: 1px solid $gray3;
      }
      td {
        background-color: $white;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        vertical-align: middle;
        display: flex;
        align-items: center;
        white-space: pre-line;
        color: $black;
        padding: 0 12px;
        line-height: 18px;
        height: 52px;
      }
    }
    tr {
      display: grid;
      grid-template-columns: $grid-template-columns;
      position: relative;
    }
    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checked {
      background-color: $primary100;
    }
    .disabled {
      background-color: $gray2;
      td {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          cursor: not-allowed;
          z-index: 1;
          background-color: $gray2;
          color: $gray6;
        }
      }
    }
  }
  &.mini {
    table {
      thead {
        background-color: $white;
        vertical-align: middle;
        border-bottom: 1px solid $gray3;
        th {
          border-top: 1px solid $gray3;
          background-color: $white;
        }
      }
      tbody {
        tr {
          border-top: none;
          background-color: $white;
          &.checked {
            background-color: $primary100;
          }
        }
        td {
          color: $black;
          padding: 0 12px;
          line-height: 18px;
          // height: 56px;
          position: relative;
        }
      }
    }
  }
  .fixed {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 3;
    box-shadow: -1px 0 6px -2px rgba(0, 0, 0, 0.12);
  }
}
</style>
