import globalStore from "@/store";

export default {
  setCampusInfo: (state, campusInfo) => {
    const { campusId, member } = campusInfo;
    if (state.campusInfo?.campusId === campusId) {
      state.campusInfo = { ...state.campusInfo, ...campusInfo };
    } else {
      state.campusInfo = { ...campusInfo };
    }
    globalStore.commit("members/setMemberItem", member);
  },
  replaceCampusInfo: (state, campusInfo) => {
    const { campusId, member } = campusInfo;
    state.campusInfo = { ...campusInfo };
    globalStore.commit("members/setMemberItem", member);
  },
  updateCampusInfo: (state, campusInfo) => {
    const { campusId } = campusInfo;
    state.campusInfo = { ...state.campusInfo, ...campusInfo };
  },
  clearCampusInfo: (state) => {
    state.campusInfo = null;
    globalStore.commit("members/clearMemberItem");
  },
  setUserCampusList: (state, userCampusList) => {
    state.userCampusList = {
      ...userCampusList,
      all: [
        ...(userCampusList.owners || []),
        ...(userCampusList.requests || []),
        ...(userCampusList.joins || []),
      ],
    };
  },
  setUserAllCampusList: (state, allList) => {
    state.userAllCampusList = { ...allList };
  },
  setDashboard: (state, dashboard) => {
    if (!dashboard) return;
    state.campusInfo = {
      ...state.campusInfo,
      ...dashboard,
    };
  },
  setCertificate: (state, certificate) => {
    state.certificate = { ...certificate };
  },
  clearCertificate: (state) => {
    state.certificate = null;
  },
  setMailAccounts: (state, site) => {
    state.campusInfo = {
      ...state.campusInfo,
      SITE: site,
    };
  },
  clearMailVerification: (state) => {
    state.campusInfo.SITE.mailAccounts = [];
  },
  setSubscription(state, data) {
    state.subscription = data;
  },
  setTempVolumes(state, volumes = 0) {
    state.tempVolumes = volumes;
  },
};
