import { endpointV2 as endpoint } from "@/config";

export async function getCoupons({
  campusId,
  memberId,
  limit = 10,
  order,
  sort,
  keyword,
  lastKey,
  publishYn,
  discountType,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(sort && { sort }),
    ...(keyword && { keyword }),
    ...(lastKey && { lastKey }),
    ...(publishYn != null && { publishYn }),
    ...(discountType && { discountType }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/`,
    params,
  });
}

export async function postCoupon(params = {}) {
  const data = {
    ...params,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.coupons}/`,
    data,
  });
}

export async function putCoupon(params = {}) {
  const data = {
    ...params,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.coupons}/`,
    data,
  });
}

export async function deleteCoupon(params = {}) {
  const data = {
    ...params,
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.coupons}/`,
    data,
  });
}

export async function getCoupon({ campusId, memberId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/${id}`,
    params,
  });
}

export async function getCouponsCounts({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/counts`,
    params,
  });
}

export async function getMyCoupons({
  campusId,
  userId,
  limit,
  order,
  sDate,
  eDate,
  useYn,
  expireYn,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(userId && { userId }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(useYn != null && { useYn }),
    ...(expireYn != null && { expireYn }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/users`,
    params,
  });
}

export function registerCoupon({ code, campusId, userId }) {
  const data = {
    code,
    ...(campusId && { campusId }),
    ...(userId && { userId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.coupons}/users`,
    data,
  });
}

export async function getCouponDetail({ campusId, userId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(userId && { userId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/users/${id}`,
    params,
  });
}

export async function getCouponCounts({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.coupons}/counts`,
    params,
  });
}
