import { endpointV2 as endpoint } from "@/config";

// 마이페이지 > 내 참여현황
export async function getPlaylist({ userId, campusId, productType }) {
  if (!userId) {
    return;
  }
  const params = {
    userId,
    ...(campusId && { campusId }),
    ...(productType && { productType }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/users/products`,
    params,
  });
}

// 프로덕트 운영 > 콘텐츠 운영 조회
export async function getContentActivities({
  id,
  contentId,
  keyword,
  memberId,
  limit,
  sort,
  campusId,
  completeStatus,
  quizStatus,
  lastKey,
}) {
  if (!id || !contentId) {
    return;
  }
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(campusId && { campusId }),
    ...(completeStatus && { completeStatus }),
    ...(quizStatus && { quizStatus }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/products/${id}/contents/${contentId}`,
    params,
  });
}

// 사용자 > 프로덕트 진행 > 홈 조회
export async function getProductUser({ id, memberId, campusId }) {
  if (!id) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/users/products/${id}`,
    params,
  });
}

// 사용자 > 프로덕트 진행 > 콘텐츠 리스트 조회
export async function getContentsProductUser({
  id,
  memberId,
  userId,
  campusId,
}) {
  if (!id) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/users/products/${id}/contents`,
    params,
  });
}

// 사용자 > 프로덕트 진행 > 콘텐츠 > 상세정보 조회
export async function getContentProductUser({
  id,
  contentId,
  memberId,
  userId,
  campusId,
}) {
  if (!id || !contentId) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(campusId && { campusId }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.activities}/users/products/${id}/contents/${contentId}`,
    params,
  });
}

// 사용자 > 프로덕트 진행 > 무료공개 컨텐트 접근
export async function getPreviewContent({ id, contentId, campusId }) {
  if (!id || !contentId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
  };

  return this.getMethod("get", false, {
    url: `${endpoint.activities}/previews/products/${id}/contents/${contentId}`,
    params,
  });
}

// 콘텐츠 진도율 데이터 입력
export async function postProgress({
  downloadCnts,
  productId,
  campusId,
  id,
  memberId,
}) {
  const data = {
    ...(downloadCnts && { downloadCnts }),
    ...(productId && { productId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.activities}/progress`,
    data,
  });
}

// 비디오,오디오 콘텐츠 진도율 데이터 입력
export async function putProgress({
  lastTime,
  productId,
  compRate,
  campusId,
  clipTime,
  accTime = 0,
  id,
  memberId,
}) {
  const data = {
    accTime,
    ...(lastTime && { lastTime }),
    ...(productId && { productId }),
    ...(compRate && { compRate }),
    ...(campusId && { campusId }),
    ...(clipTime && { clipTime }),
    ...(id && { id }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.activities}/progress`,
    data,
  });
}

// 비디오,오디오 콘텐츠 최종 시청시점 데이터 입력
export async function putLast({
  lastTime,
  productId,
  campusId,
  accTime = 0,
  id,
  memberId,
}) {
  const data = {
    accTime,
    ...(lastTime && { lastTime }),
    ...(productId && { productId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.activities}/last`,
    data,
  });
}

// 프로덕트 운영 > 콘텐츠 운영 (재생가능시간 리셋하기, 다운제공수 리셋하기, 진행상태변경(출석처리) , 라이브종료시 참여자 상태 출석 완료로 변경)
export async function modifyActivity({
  modifyType,
  answerStatus,
  score,
  remark,
  productId,
  t_memberId,
  campusId,
  completeStatus,
  progressStatus,
  id,
  memberId,
  targets, // ModifyType.ATTEND 일 때만 사용
}) {
  const data = {
    ...(modifyType && { modifyType }),
    ...(answerStatus && { answerStatus }),
    ...(score && { score }),
    ...(remark && { remark }),
    ...(productId && { productId }),
    ...(t_memberId && { t_memberId }),
    ...(campusId && { campusId }),
    ...(completeStatus && { completeStatus }),
    ...(progressStatus && { progressStatus }),
    ...(id && { id }),
    ...(memberId && { memberId }),
    ...(targets && { targets }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.activities}/`,
    data,
  });
}

// 콘텐츠 진도율 데이터 입력
export async function postProgressQuiz({
  quizProgressAction,
  productId,
  campusId,
  id,
  memberId,
  upperId,
  objtAns,
  sbjtAns,
  descAns,
  attaches,
}) {
  const data = {
    ...(quizProgressAction && { quizProgressAction }),
    ...(productId && { productId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(memberId && { memberId }),
    ...(upperId && { upperId }),
    ...(objtAns && { objtAns }),
    ...(sbjtAns && { sbjtAns }),
    ...(descAns && { descAns }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.activities}/progress/quiz`,
    data,
  });
}

export async function getQuizStaff({
  campusId,
  memberId,
  productId,
  contentId,
  t_memberId,
}) {
  if (!campusId || !memberId || !productId || !contentId || !t_memberId) {
    return {
      success: false,
    };
  }
  const params = {
    campusId,
    memberId,
    productId,
    contentId,
    t_memberId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/quiz`,
    params,
  });
}
// 콘텐츠 운영 > 퀴즈 > 결과 통계
export async function getQuizStats({
  campusId,
  memberId,
  productId,
  contentId,
}) {
  if (!campusId || !memberId || !productId || !contentId) {
    return {
      success: false,
    };
  }
  const params = {
    campusId,
    memberId,
    productId,
    contentId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/quiz/stats`,
    params,
  });
}

// 신청서 진도율 데이터 입력
export async function postProgressApplication({
  progressAction,
  productId,
  campusId,
  id,
  memberId,
  upperId,
  objtAns,
  descAns,
  attaches,
}) {
  const data = {
    ...(progressAction && { progressAction }),
    ...(productId && { productId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(memberId && { memberId }),
    ...(upperId && { upperId }),
    ...(objtAns && { objtAns }),
    ...(descAns && { descAns }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.activities}/progress/application`,
    data,
  });
}

export async function getApplicationStaff({
  campusId,
  memberId,
  productId,
  t_memberId,
}) {
  if (!campusId || !memberId || !productId || !t_memberId) {
    return {
      success: false,
    };
  }
  const params = {
    campusId,
    memberId,
    productId,
    t_memberId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/applications`,
    params,
  });
}
// 신청서 > 결과 통계
export async function getApplicationStats({ campusId, memberId, productId }) {
  if (!campusId || !memberId || !productId) {
    return {
      success: false,
    };
  }
  const params = {
    campusId,
    memberId,
    productId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/applications/stats`,
    params,
  });
}
// 신청서 > 결과 통계 > 응답자 조회
export async function getApplicationStatUsers({
  campusId,
  memberId,
  productId,
  contentId,
  answerYn,
  value,
  limit,
  lastKey,
}) {
  const params = {
    campusId,
    memberId,
    productId,
    ...(answerYn !== undefined && { answerYn }),
    ...(value && { value }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.activities}/applications/stats/${contentId}`,
    params,
  });
}
