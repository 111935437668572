<template>
  <v-dialog
    :max-width="maxWidth"
    :value="value"
    :fullscreen="fullscreen"
    persistent
    no-click-animation
    :content-class="`${contentClass} white hide-scrollbar p-modal`"
  >
    <div class="p-modal--title">
      <h5 class="text-h5-medium black--text text-left">{{ title }}</h5>
      <p-icon-btn icon="Close" @click="close" x-small />
    </div>
    <div class="p-modal--header" v-if="$scopedSlots['header']">
      <slot name="header" />
    </div>
    <div
      class="p-modal--content"
      :style="`max-height: ${this.contentMaxHeight}`"
    >
      <div
        class="p-modal--content__wrap"
        :style="`min-height: ${this.contentMinHeight}`"
      >
        <slot />
      </div>
    </div>
    <div class="p-modal--action" v-if="$scopedSlots['action']">
      <slot name="action" />
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 600,
    },
    title: {
      type: String,
      default: "",
    },
    contentHeight: {
      type: [String, Number],
      default: 340,
    },
    contentClass: {
      type: String,
      default: "",
    },
  },
  watch: {
    height(h) {
      // console.log(h);
    },
  },
  computed: {
    height() {
      return this.$vuetify?.breakpoint?.height || window.innerHeight;
    },
    contentMaxHeight() {
      const titleHeight = 59;
      const bottomHeihgt = this.$scopedSlots["action"] ? 73 : 0;
      const headerHeight = this.$scopedSlots["header"] ? 48 : 0;
      const height = titleHeight + bottomHeihgt + headerHeight;
      // return (
      //   Math.min(
      //     this.height * 0.8 - height,
      //     680 - bottomHeihgt - headerHeight
      //   ) + "px"
      // );
      if (this.fullscreen) {
        return `calc(100vh - ${height}px)`;
      }
      return `calc(90vh - ${height}px)`;
    },
    contentMinHeight() {
      const headerHeight = this.$scopedSlots["header"] ? 48 : 0;
      return this.contentHeight - headerHeight + "px";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    overflow: hidden;
  }
  .v-dialog--fullscreen {
    .p-modal--content {
      padding-bottom: 100px;
    }
    .p-modal--action {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
}
.p-modal--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-bottom: 1px solid $gray3;
  h5 {
    line-height: 26px;
    height: 26px;
  }
}
.p-modal--header {
  width: 100%;
  position: sticky;
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  top: 0;
  left: 0;
}
.p-modal--content {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  @include scrollbar($white);
  .p-modal--content__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
  }
}
.p-modal--action {
  width: 100%;
  padding: 16px 20px;
  background-color: $white;
  display: flex;
  position: sticky;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  bottom: 0;
  left: 0;
  border-top: 1px solid $gray3;
  z-index: 2;
  margin-top: 20px;
}
</style>

<style>
.p-modal {
  max-height: unset !important;
}
</style>
