var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"p-btn--link border-box",class:{
    'p-btn--primary': _vm.color === 'primary',
    'p-btn--gray': _vm.color === 'gray',
    'p-btn--white': _vm.color === 'white',
    'p-btn--info': _vm.color === 'info',
    'p-btn--black': _vm.color === 'black',
    'p-btn--disabled': _vm.disabled,
  },attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('span',{staticClass:"p-btn__content",class:{ loading: _vm.loading }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }