<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    width="329"
    class="aside-mobile-z-index"
  >
    <div
      class="border-box mt-4 d-flex align-center justify-end"
      style="width: 329px; padding-right: 14px"
    >
      <v-btn icon plain @click.stop="closeClickCallback" color="black"
        ><v-icon size="24">mdi-close</v-icon></v-btn
      >
    </div>
    <div class="px-6 mt-1 pb-10 border-box">
      <poin-logo />
      <nav class="mt-7">
        <routing-menu />
      </nav>
      <div class="d-flex align-center mt-10" v-if="!authenticated">
        <v-btn
          v-if="campusOnly"
          dark
          color="primary"
          class="font-size-14 font-weight-bold px-3 mr-2"
          @click.stop="toJoinPath"
          min-width="120"
          min-height="40"
          >회원가입</v-btn
        >
        <v-btn
          v-else
          dark
          color="primary"
          class="font-size-14 font-weight-bold px-3 mr-2"
          @click.stop="$router.push({ name: 'campus-register' })"
          min-width="120"
          min-height="40"
          >무료로 시작하기</v-btn
        >
        <v-btn
          outlined
          color="primary"
          class="font-size-14 font-weight-bold px-3"
          @click.stop="toLogInPath"
          min-width="120"
          min-height="40"
          >로그인</v-btn
        >
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PoinLogo from "@/components/layout/header/Logo";
import globalMixin from "@/mixins/global";
import RoutingMenu from "@/components/layout/aside/Aside-Mobile-Routing-Menu";

export default {
  name: "poin-aside",
  mixins: [globalMixin],
  components: {
    RoutingMenu,
    PoinLogo,
  },
  computed: {
    ...mapGetters({
      menus: "common/getMobileMenus",
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      instructor: "users/getInstructor",
      campusOnly: "common/campusOnly",
    }),
  },
  data() {
    return {
      alertOpen: false,
      drawer: false,
    };
  },
  props: {
    asideOn: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    asideOn: {
      immediate: true,
      handler() {
        this.drawer = this.asideOn;
      },
    },
    drawer: {
      handler(open) {
        if (!open) {
          this.closeClickCallback();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
      setMobileMenus: "common/setMobileMenus",
    }),
    closeClickCallback() {
      this.$emit("close");
    },
    searchOpen() {
      this.$emit("searchOpen");
      document.getElementById("app").style.setProperty("--aside-zIndex", "0");
    },
  },
};
</script>

<style scoped lang="scss">
.aside-mobile-z-index {
  z-index: var(--aside-zIndex);
}
::v-deep .logo__wrapper {
  max-width: 100%;
}
</style>
